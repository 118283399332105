import React, { memo } from "react";

import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { useBackgroundSelectorTranslation } from "./translation";
import ColorSelect, { IColor, colors } from "../ColorSelect";
import { allowedValuesToggleModeHandler } from "../CustomExpressionEditor/component";

export const BG_COLORS = [...colors, "transparent"] as IColor[];

type Props = {
  config: Record<string, any>;
  onChange: (value: string) => void;
  configKey?: string;
  defaultValue?: string | null;
};

export const BackgroundSelector = memo<Props>(
  ({ config, configKey = "background", onChange, defaultValue = null }) => {
    const {
      borderColorLabel,
      bgColorLabel,
    } = useBackgroundSelectorTranslation();
    const isOutlined = config?.variant === "outlined";

    const customBgColorSelect = ({
      value: textValue,
      onChange: onTextChange,
    }: NonExpressionEditorProps) => (
      <ColorSelect
        value={textValue}
        onChange={onTextChange}
        defaultValue={defaultValue ?? "transparent"}
        colorItems={BG_COLORS}
        outlined={isOutlined}
      />
    );

    return (
      <CustomExpressionEditor
        label={isOutlined ? borderColorLabel : bgColorLabel}
        value={config[configKey]}
        config={config}
        onChange={onChange}
        nonExpressionEditor={customBgColorSelect}
        onToggleMode={allowedValuesToggleModeHandler(BG_COLORS)(
          config[configKey],
          onChange,
          defaultValue,
        )}
      />
    );
  },
);
