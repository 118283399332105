import React, { memo } from "react";
import { areEqual } from "react-window";
import { Draggable } from "react-beautiful-dnd";

import { IFixedRow } from "core";
import { useElementEditorContext } from "core/editor";
import { UntransformedTabsConfig } from "../../types";
import { TabConfig, TabConfigProps } from "./TabConfig";
import { useTabsContentContext } from "./TabsContext";

type IRow = IFixedRow<TabConfigProps>;

export const TabRow = memo<IRow>(({ data: items, index, style }) => {
  const { handleTabButtonClick } = useTabsContentContext();
  const {
    elementModel: { config },
  } = useElementEditorContext<UntransformedTabsConfig>();
  const { ...rest } = items[index];
  const defaultTab = index === config.default;

  return (
    <Draggable key={rest.name} draggableId={rest.name} index={index}>
      {(provided, snapshot) => (
        <TabConfig
          {...rest}
          defaultTab={defaultTab}
          provided={provided}
          snapshot={snapshot}
          style={style}
          onButtonClick={handleTabButtonClick}
          index={index}
        />
      )}
    </Draggable>
  );
}, areEqual);
