import React, { memo } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ReactMde, { getDefaultToolbarCommands } from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

import { DefaultMarkdownInputProps } from "./types";
import { TElementWithPosition } from "core";
import { useStyles } from "./style";
import { useTheme } from "@material-ui/core";
import {
  GRID_SPACING_FACTOR,
  SPACING_MULTIPLICATOR,
} from "../default_grid/components";

const DefaultMarkdownInput = memo<DefaultMarkdownInputProps>(
  ({
    value,
    element: {
      config: { disabled },
      i18n: { label },
    },
    element,
    required,
    changeValue,
  }) => {
    const theme = useTheme();
    const classes = useStyles();

    const space = theme.spacing();
    const positionHeight =
      "position" in element
        ? ((element as unknown) as TElementWithPosition).position.height
        : null;
    const height =
      positionHeight === null
        ? undefined
        : positionHeight * SPACING_MULTIPLICATOR(false) * space +
          (positionHeight - 1) * GRID_SPACING_FACTOR * space +
          /* hidden grip - https://github.com/andrerpena/react-mde/blob/master/src/styles/react-mde.scss#L3 */
          10 -
          /* toolbar */
          space * 6 -
          /* form label & margin - 13.6 = htmlFontSize * 0.865rem = 16 * 0.865 */
          (label ? 13.6 * 3 : 0) -
          // unknown??
          14;

    const handleValueChange = (newValue: string) => {
      changeValue(newValue ? newValue : null);
    };

    return (
      <FormControl fullWidth={true} disabled={disabled}>
        {label && (
          <FormLabel component="p" required={required}>
            {label}
          </FormLabel>
        )}
        <ReactMde
          readOnly={disabled}
          value={value ?? ""}
          onChange={handleValueChange}
          disablePreview
          initialEditorHeight={height}
          minEditorHeight={height}
          maxEditorHeight={height}
          key={height}
          toolbarCommands={getDefaultToolbarCommands()}
          classes={{
            textArea: "default-markdown-input-text-area",
            grip: classes.grip,
            toolbar: classes.toolbar,
          }}
        />
      </FormControl>
    );
  },
);

export default DefaultMarkdownInput;
