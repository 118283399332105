import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggleBtnClass: {
      width: "100%",
      marginBottom: theme.spacing(),
    },
    marginTop: {
      marginTop: theme.spacing(),
    },
  }),
);
