import React, { memo } from "react";
import { createStyles, makeStyles } from "@material-ui/core";

import { createUseTranslation } from "core/session/translation";
import { StyledTypography } from "../../../../../elementTypes/common/StyledTypography";

export const StyledStateName = memo<{
  stateName: string;
}>(({ stateName }) => {
  const translation = useTranslation();
  const classes = useStyles();
  return (
    <StyledTypography
      fitContent={true}
      typographyProps={{ variant: "h5", className: classes.text }}
      text={stateName}
      tooltip={translation.stateTooltip}
      color="primary"
    />
  );
});

const translations = {
  en: {
    stateTooltip: "State Name",
  },
};

export const useStyles = makeStyles(
  createStyles({
    text: {
      textTransform: "uppercase",
    },
  }),
);

export const useTranslation = createUseTranslation(translations);
