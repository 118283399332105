import React, { memo, useEffect, useRef } from "react";

import clsx from "classnames";

import colorString from "color-string";

import * as L from "leaflet";

import "leaflet/dist/leaflet.css";

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import "elementTypes/common/leaflet/DefaultIcon";

import { PropsFromRedux } from "./container";
import { IElementComponentProps } from "core";
import { GeoJSONField } from "./types";
import {
  OSM_ATTRIBUTION,
  OSM_TILE_LAYER_URL,
} from "elementTypes/common/leaflet/constants";
import { DEFAULT_CONFIG } from "./constants";

import { useStyles } from "./styles";
import { useTheme } from "@material-ui/core";
import { Colors, ContrastColors } from "elementTypes/common/StyledTypography";

// https://stackoverflow.com/a/6234804
function escapeHtml(unsafe: string | null | undefined) {
  return unsafe
    ? unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
    : "";
}

const DefaultGeoJSONField = memo<
  IElementComponentProps<Record<string, unknown>, GeoJSONField> & PropsFromRedux
>(
  ({
    value,
    getStyle,
    getTooltip,
    getMarkerBackgroundColor,
    getMarkerDisplayText,
    element: {
      id,
      config: { tileLayerUrl, updateMapView = DEFAULT_CONFIG.updateMapView },
      config,
    },
  }) => {
    const mapRef = useRef<L.Map | null>(null);

    const initializedDataRef = useRef(false);

    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {
      const map = (mapRef.current = L.map(id));

      // set the default center and zoom, needed when no features set
      map.setView([51.505, -0.09], 13);

      L.tileLayer(tileLayerUrl ?? OSM_TILE_LAYER_URL, {
        attribution: tileLayerUrl ? undefined : OSM_ATTRIBUTION,
      }).addTo(map);

      return () => {
        mapRef.current?.remove();
      };
    }, [config, id, tileLayerUrl]);

    useEffect(() => {
      const map = mapRef.current;

      if (!map) {
        // should never happen, used to satisfy TS
        return;
      }

      const geoJsonLayer = L.geoJSON(value as any, {
        pointToLayer: (feature, latlng) => {
          let markerLabel = "";
          if (getMarkerDisplayText) {
            try {
              markerLabel = getMarkerDisplayText(feature);
            } catch {}
          }
          let backgroundColorConfig = "";
          if (getMarkerBackgroundColor) {
            try {
              backgroundColorConfig =
                typeof getMarkerBackgroundColor === "string"
                  ? getMarkerBackgroundColor
                  : getMarkerBackgroundColor(feature);
            } catch {}
          }

          // default colors
          let backgroundColor = "white";
          let contrastColor = "black";

          // if the color is supported by us (material-ui) directly, use it
          if (
            Colors[backgroundColorConfig] &&
            ContrastColors[backgroundColorConfig]
          ) {
            const backgroundColorPath = Colors[backgroundColorConfig].split(
              ".",
            );

            backgroundColor =
              theme.palette[backgroundColorPath[0]][backgroundColorPath[1]];

            const contrastColorPath = ContrastColors[
              backgroundColorConfig
            ].split(".");

            contrastColor =
              theme.palette[contrastColorPath[0]][contrastColorPath[1]];
          } else if (backgroundColorConfig) {
            // if the color is not supported, see if it's a valid CSS color
            const parsedColor = colorString.get.rgb(backgroundColorConfig);
            if (parsedColor !== null) {
              backgroundColor = backgroundColorConfig;
              // material-uis getContrastText function does not support color names (e.g. "red")
              // thus we have to convert the color first
              contrastColor = theme.palette.getContrastText(
                colorString.to.hex(parsedColor),
              );
            }
          }

          return L.marker(latlng, {
            icon: L.divIcon({
              className: "",
              iconSize: [40, 56],
              iconAnchor: [20, 56],
              tooltipAnchor: [20, -36],
              popupAnchor: [0, -56],
              html: `<div class='${clsx(classes.icon, {
                [classes.empty]: !markerLabel,
              })}' style='--bg-color:${backgroundColor};--color:${contrastColor}'><div>${
                escapeHtml(markerLabel) ?? ""
              }</div></div>`,
            }),
          });
        },
        style: (feature) => {
          try {
            return getStyle?.(feature);
          } catch {
            return {};
          }
        },
        onEachFeature: (feature, layer) => {
          if (getTooltip) {
            try {
              const tooltip = getTooltip(feature);
              if (tooltip) {
                layer.bindTooltip(tooltip);
              }
            } catch {}
          }
        },
      });
      geoJsonLayer.addTo(map);

      if (!initializedDataRef.current || updateMapView) {
        const bounds = geoJsonLayer.getBounds();
        // invalid when initially empty
        if (bounds.isValid()) {
          map.fitBounds(geoJsonLayer.getBounds());
        }
        initializedDataRef.current = true;
      }

      return () => {
        // delete layers if they are not the main two layers
        // I guess this can be checked with _container, but I'm not sure
        // there might be a better way to check this
        map.eachLayer((l) => {
          if (!(l as any)._container) {
            l.removeFrom(map);
          }
        });
      };
    }, [
      value,
      id,
      updateMapView,
      getStyle,
      getTooltip,
      classes,
      getMarkerDisplayText,
      getMarkerBackgroundColor,
      theme.palette,
    ]);

    return <div id={id} style={{ width: "100%", height: "100%" }}></div>;
  },
);

DefaultGeoJSONField.displayName = "DefaultGeoJSONField";

export default DefaultGeoJSONField;
