import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import Chip, { ChipProps } from "@material-ui/core/Chip";

import { useStyles } from "./style";

type Props = {
  values: any[];
} & ChipProps;

export const ArrayField = ({ values, ...chipProps }: Props) => {
  const { root, child } = useStyles();
  const items = useMemo(
    () =>
      (values ?? []).map((v, i) => (
        <Chip
          key={i}
          label={v}
          {...chipProps}
          className={`${child} ${chipProps.className}`}
        />
      )),
    [values, chipProps, child],
  );

  return (
    <Box display="flex" gridGap={8} className={root}>
      {items}
    </Box>
  );
};
