import React, { ChangeEvent, memo, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { formatDistanceToNow, formatRelative } from "date-fns";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { omit } from "ramda";

import { parseQueries, stringifyQueries } from "core/router/reduxModule/utils";
import history from "core/router/reduxModule/history";
import Button from "elementTypes/common/Button";
import Link from "elementTypes/common/Link";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { LoadingComponent } from "layouts/common/Loading";
import { useAuditTableList, useTableAudit } from "queries/admin/auditData";

import { Table as TableComponent, TableRow } from "staticPages/admin/common";
import { default as commonStyles } from "staticPages/admin/styles";
import useStyles from "./styles";
import { AuditTable } from "./types";

type Row = {
  handleClick: (params: AuditTable) => void;
} & AuditTable;

const Row = memo<Row>(({ handleClick, ...props }) => {
  const {
    id,
    transactionID,
    userName,
    userID,
    schemaName,
    tableName,
    operation,
    timestamp,
  } = props;

  const onClick = () => handleClick(props);

  return (
    <TableRow rowId={id}>
      <Typography>{transactionID}</Typography>
      {userID ? (
        <Link
          variant="body2"
          title="View user info"
          href={`/admin/users/view/${userID}`}
        >
          {userID}
        </Link>
      ) : (
        <span />
      )}
      <Typography>{userName}</Typography>
      <Typography>{schemaName}</Typography>
      <Typography>{tableName}</Typography>
      <Typography>{operation}</Typography>
      <Tooltip title={formatRelative(new Date(timestamp), new Date())}>
        <Typography>
          {formatDistanceToNow(new Date(timestamp), {
            addSuffix: true,
          })}
        </Typography>
      </Tooltip>
      <Button color="primary" onClick={onClick} label="Details" />
    </TableRow>
  );
});

const getLocationSearch = (filter: Record<string, string | undefined | null>) =>
  stringifyQueries({
    ...(parseQueries(history.location.search) ?? {}),
    ...filter,
  });

const changeLocation = (filter: Record<string, string | undefined | null>) => {
  const search = getLocationSearch(filter);

  const nextLoaction = {
    ...history.location,
    search,
  };

  history.replace(nextLoaction);
};

const defaultState = { tableName: "", schemaName: "" };
type State = typeof defaultState;

const getInitialState = () =>
  (omit(["ui"], parseQueries(history.location.search)) ??
    defaultState) as State;

const titles = [
  "Transaction ID",
  "User ID",
  "Username",
  "Schema Name",
  "Table Name",
  "Operation",
  "Date",
  "Actions",
];

const headers = titles.map((title) => ({
  name: title.toLowerCase(),
  title,
}));

export const AuditsPage = memo(() => {
  const [filter, setFilter] = useState<State>(getInitialState);

  const {
    data: autocompleteData,
    isLoading: listIsLoading,
  } = useAuditTableList();

  const auditResult = useTableAudit(filter);
  const classes = useStyles();
  const [auditSelected, setAuditSelected] = useState<AuditTable | null>(null);

  const list = useMemo(
    () =>
      autocompleteData?.reduce((result, table) => {
        return {
          ...result,
          [table.schema]: {
            name: table.schema,
            tables: [
              ...(result?.[table.schema]?.tables ?? []),
              { name: table.table, schema: table.schema },
            ],
          },
        };
      }, {}),
    [autocompleteData],
  );

  const handleFilterChange = (nextfilter: string) => (
    _e: ChangeEvent<unknown>,
    value: { name: string; schema?: string } | null,
  ) => {
    const nextQuery = {
      [nextfilter]: value?.name,
      ...(nextfilter === "tableName" && {
        schemaName: value?.schema ?? filter.schemaName,
      }),
      ...(nextfilter === "schemaName" && {
        tableName: "",
      }),
    };
    changeLocation(nextQuery);

    setFilter((prevFilter) => ({
      ...prevFilter,
      ...nextQuery,
    }));
  };

  const handleDetailsButtonClick = (audit: AuditTable) =>
    setAuditSelected(audit);
  const handleDetailsDialogClose = () => setAuditSelected(null);

  const { horizontallyCenter } = commonStyles();

  const { data: auditData } = auditResult;

  const rows = useMemo(
    () =>
      (auditData ?? []).map((audit) => (
        <Row key={audit.id} {...audit} handleClick={handleDetailsButtonClick} />
      )),
    [auditData],
  );
  const schemaOptions = Object.values(list ?? {}) as { name: string }[];
  const tableOptions = filter.schemaName
    ? list?.[filter.schemaName]?.tables
    : Object.values(list ?? {}).flatMap((item: any) => item.tables);

  const handleDataReload = () => auditResult.refetch();

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">Audit Tables</Typography>
      </Grid>
      <Grid item={true} xs={12} sm={4} className={classes.filters}>
        {listIsLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <Autocomplete
              className={classes.filter}
              options={schemaOptions}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={handleFilterChange("schemaName")}
              value={filter.schemaName ? { name: filter.schemaName } : null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Schema"
                  name="schemaName"
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                />
              )}
            />
            <Autocomplete
              className={classes.filter}
              options={tableOptions}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value?.name}
              onChange={handleFilterChange("tableName")}
              value={filter.tableName ? { name: filter.tableName } : null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Table"
                  name="tableName"
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                />
              )}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <TableComponent
          rows={rows}
          headers={headers}
          onDataReload={handleDataReload}
          loading={auditResult?.isLoading}
          error={auditResult?.error?.message}
        />
      </Grid>

      <DialogWrapper
        open={Boolean(auditSelected)}
        title={"Details"}
        cancelTitle="Close"
        handleClose={handleDetailsDialogClose}
        className={classes.dialog}
        maxWidth={false}
        keepMounted={false}
      >
        <ReactDiffViewer
          leftTitle="Before"
          rightTitle="After"
          oldValue={`${JSON.stringify(auditSelected?.before, null, 2)}`}
          newValue={`${JSON.stringify(auditSelected?.after, null, 2)}`}
          splitView={true}
          compareMethod={DiffMethod.WORDS}
          styles={{
            diffRemoved: {
              overflowX: "auto",
              whiteSpace: "pre",
            },
            diffAdded: {
              overflowX: "auto",
              whiteSpace: "pre",
            },
          }}
        />
      </DialogWrapper>
    </Grid>
  );
});
