import React from "react";
import { connect } from "react-redux";

import {
  OptionsObject,
  SnackbarKey,
  SnackbarProviderProps,
  WithSnackbarProps,
  withSnackbar,
} from "notistack";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";

import { actions } from "core/session/reduxModule/actions";

export const SNACKBAR_ANCHOR_ORIGIN: SnackbarProviderProps["anchorOrigin"] = {
  horizontal: "center",
  vertical: "top",
};

const mapDispatchToProps = {
  removeSnackbar: actions.removeSnackbar,
};

type Props = WithSnackbarProps &
  typeof mapDispatchToProps & { snackbarKey?: SnackbarKey };

const Component = withSnackbar<Props>(
  ({ snackbarKey, closeSnackbar, removeSnackbar }) => {
    const onClick = () => {
      closeSnackbar(snackbarKey);
      removeSnackbar(snackbarKey);
    };

    return (
      <Tooltip title="Dismiss">
        <IconButton
          color="inherit"
          size="small"
          centerRipple={true}
          onClick={onClick}
        >
          <ClearIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  },
);

const connector = connect(null, mapDispatchToProps);
const SnackbarComponent = connector(Component);

export const SnackbarDefaultAction = (key: OptionsObject["key"]) => (
  <SnackbarComponent snackbarKey={key} />
);
