import React, { memo } from "react";

import { DataGrid, GridColDef, GridRowData } from "@material-ui/data-grid";

import { useColumnValues } from "../../../../../queries/admin";

const columnsDef: GridColDef[] = [
  { field: "name", headerName: "Row values", flex: 1 },
];

type ColumnProps = {
  schema: string;
  table: string;
  column: string;
};

export const WorkflowSetupColumns = memo(
  ({ schema, table, column }: ColumnProps) => {
    const { data } = useColumnValues(
      {
        schema,
        table,
        column,
      },
      { enabled: column !== "" },
    );

    const rows = (data ?? []).map(
      (row: Record<string, unknown>, index: number) => ({
        id: index,
        ...row,
      }),
    ) as GridRowData[];

    return (
      <DataGrid
        rows={rows}
        columns={columnsDef}
        pageSize={5}
        rowsPerPageOptions={[5]}
        autoHeight
      />
    );
  },
);
