import React, { ChangeEvent, memo } from "react";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Slider, { SliderProps } from "@material-ui/core/Slider";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";
import { SliderInput } from "./types";

export const DEFAULTS = {
  min: 0,
  max: 100,
  step: 1,
  marks: false,
  valueLabelDisplay: "on",
  color: "primary",
  orientation: "horizontal",
  track: "normal",
} as const;

const DefaultSliderInput = memo<
  ConnectedReduxModuleProps<ReduxModule, SliderInput>
>(
  ({
    value,
    element: {
      config: {
        marks = DEFAULTS.marks,
        min = DEFAULTS.min,
        max = DEFAULTS.max,
        color = DEFAULTS.color,
        orientation = DEFAULTS.orientation,
        step = DEFAULTS.step,
        track = DEFAULTS.track,
        valueLabelDisplay,
      },
      i18n: { label },
    },
    changeValue,
    disabled,
    required,
  }) => {
    const handleValueChange = (
      _e: ChangeEvent<Record<string, unknown>>,
      newValue: number | number[],
    ) => {
      // this component will only be able to handle single number inputs
      // a separate component should be used for range input
      if (typeof newValue === "number") {
        changeValue(newValue as number);
      }
    };

    const generatedMarks: SliderProps["marks"] = Array.isArray(marks)
      ? marks.map((i) => {
          if (typeof i === "number") {
            return {
              value: i,
              label: String(i),
            };
          } else {
            return i;
          }
        })
      : marks;

    const commonProps = {
      "aria-label": label,
      valueLabelDisplay:
        valueLabelDisplay ?? (disabled ? ("on" as const) : ("auto" as const)),
      value,
      onChange: handleValueChange,
      min,
      max,
      color,
      orientation,
      step,
      track,
    };

    return (
      <FormControl fullWidth={true} style={{ height: "100%" }}>
        <FormLabel required={required}>{label}</FormLabel>
        <Slider
          marks={generatedMarks}
          {...commonProps}
          disabled={disabled}
          data-disabled={disabled}
        />
      </FormControl>
    );
  },
);

export default DefaultSliderInput;
