import React, { ChangeEvent, ComponentProps, memo, useCallback } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import isBoolean from "lodash/isBoolean";

import { Section, useElementEditorContext } from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { UntransformedDefaultModalDialogConfig } from "../../types";
import { useDefaultModalDialogTranslation } from "../translation";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { openButtonDisabled },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDefaultModalDialogConfig>();
  const translation = useDefaultModalDialogTranslation();

  const wrappedChangePath = useCallback(
    (newValue: string) => {
      changeConfigValue("openButtonDisabled", newValue);
    },
    [changeConfigValue],
  );

  const isOpenButtonDisabledNonExpressionEditor: ComponentProps<
    typeof CustomExpressionEditor
  >["nonExpressionEditor"] = useCallback(
    ({ value, onChange }) => {
      const handleChange = (
        _event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
      ) => onChange(checked);

      return (
        <FormControlLabel
          control={
            <Switch
              checked={isBoolean(value) ? value : value === true}
              onChange={handleChange}
            />
          }
          label={translation.isOpenButtonDisabled}
        />
      );
    },
    [translation.isOpenButtonDisabled],
  );

  return (
    <Section title={translation.configSectionTitle} wrapped={true}>
      <CustomExpressionEditor
        value={openButtonDisabled ?? ""}
        config={config}
        onChange={wrappedChangePath}
        label={translation.isOpenButtonDisabled}
        nonExpressionEditor={isOpenButtonDisabledNonExpressionEditor}
      />
    </Section>
  );
});
