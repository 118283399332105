import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import Button from "elementTypes/common/Button";
import { actions as routerActions } from "core/router/reduxModule";
import { useSnackbar } from "utils/hooks/useSnackbar";
import useStyles from "../styles";
import { SettingsConfig } from "../../../types";
import { useUpdateConfig } from "../../../../../../../queries/admin";
import { getApiError } from "../../../../../../../queries/utils";

export const Form = memo<{ config: SettingsConfig }>(({ config }) => {
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();
  const { formPaper, footer, dividerFooter } = useStyles();

  const updateConfig = useUpdateConfig({
    onSuccess: () => {
      showSnackbar("Settings Configuration edited successfully", "success");
      dispatch(routerActions.push("/admin/settings"));
    },
    onError: (error) => {
      const msg = getApiError(error) ?? "Settings Configuration was not edited";
      showSnackbar(msg, "error");
    },
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isValid, dirty },
  } = useForm<SettingsConfig>({
    mode: "onBlur",
    defaultValues: {
      logoPath: config?.logoPath,
      identifierInputTitle: config?.identifierInputTitle,
    },
  });

  const submit = (data: SettingsConfig) => {
    const result: SettingsConfig = { ...data };
    if (data.logoPath === "") {
      // delete if empty
      result.logoPath = null;
    }
    if (data.identifierInputTitle === "") {
      // delete if empty
      result.identifierInputTitle = null;
    }
    updateConfig.mutate(result);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(submit)}>
      <Paper variant="outlined" className={formPaper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="logoPath"
              label="Logo path"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({})}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="identifierInputTitle"
              label="Identifier Input Title"
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({})}
            />
          </Grid>

          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              disabled={isSubmitting || !isValid || !dirty}
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Edit"
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
