import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { ArrowHeadType, useStoreState } from "react-flow-renderer";
import {
  Chip,
  ChipProps,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import { useTranslation } from "core/session";
import { getBoxToBoxArrow } from "perfect-arrows";

interface ButtonEdgeProps {
  id: string;
  source: string;
  target: string;
  style: Record<string, any>;
  data: any;
  arrowHeadType: ArrowHeadType;
  markerEndId: string;
  selected?: boolean;
}

// calculate bezier curve center point
// based on https://math.stackexchange.com/a/1361717
function getBezierCenterCoordinate(co1: number, co2: number, co3: number) {
  return (co1 - 2 * co2 + co3) * 0.25 + 2 * (co2 - co1) * 0.5 + co1;
}

function Transition({
  id,
  data,
  source,
  target,
  style = {},
  selected = false,
}: ButtonEdgeProps) {
  const nodes = useStoreState((state) => state.nodes);
  const sourceNode = nodes.find((n) => n.id === source)!;
  const targetNode = nodes.find((n) => n.id === target)!;

  const classes = useStyles({ selected });
  const translation = useTranslation(data?.i18n);

  // used to correctly center the transition label
  const targetRef = useRef<HTMLDivElement | null>();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, []);

  const arrow = getBoxToBoxArrow(
    sourceNode.__rf.position.x,
    sourceNode.__rf.position.y,
    sourceNode.__rf.width,
    sourceNode.__rf.height,
    targetNode.__rf.position.x,
    targetNode.__rf.position.y,
    targetNode.__rf.width,
    targetNode.__rf.height,
  );

  const [sx, sy, cx, cy, ex, ey, ae] = arrow;
  const endAngleAsDegrees = ae * (180 / Math.PI);

  const ccx = getBezierCenterCoordinate(sx, cx, ex);
  const ccy = getBezierCenterCoordinate(sy, cy, ey);

  return (
    <g className={classes.group}>
      <path
        id={id}
        style={style}
        className={`react-flow__edge-path ${classes.path}`}
        d={`M${sx},${sy} Q${cx},${cy} ${ex},${ey}`}
        fill="none"
      />
      <polygon
        points="0,-6 12,0, 0,6"
        transform={`translate(${ex},${ey}) rotate(${endAngleAsDegrees})`}
      />
      <foreignObject
        width={dimensions.width}
        height={dimensions.height}
        x={ccx - dimensions.width / 2}
        y={ccy - dimensions.height / 2}
        className={classes.transitionContainer}
      >
        <Tooltip title={translation.shortDescription ?? ""}>
          <Chip
            className={`${classes.edgeButtonClass} react-flow__edge-btn`}
            size="small"
            label={translation.title}
            // material-ui typing bug; does not accept "undefined"
            ref={(targetRef as unknown) as ChipProps["ref"]}
          />
        </Tooltip>
      </foreignObject>
    </g>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles<string, { selected: boolean }>({
    group: {
      "& .react-flow__edge-path": {
        stroke: theme.palette.grey[400],
      },
      "&:hover .react-flow__edge-path": {
        stroke: theme.palette.text.primary,
      },
      "&:hover .react-flow__edge-btn": {
        borderColor: theme.palette.text.primary,
      },
    },
    path: {
      cursor: "pointer",
      transition: "stroke 0.5s ease-in-out",
    },
    transitionContainer: {
      textAlign: "center",
    },
    edgeButtonClass: {
      display: "flex",
      width: "100px",
      borderRadius: theme.spacing(),
      color: ({ selected }) =>
        selected ? theme.palette.common.white : theme.palette.text.primary,
      backgroundColor: ({ selected }) =>
        selected ? theme.palette.text.primary : theme.palette.common.white,
      borderColor: ({ selected }) =>
        selected ? theme.palette.text.primary : theme.palette.grey[400],
      border: "1px solid",
      transition: "border-color 0.5s ease-in-out",

      "&:hover, &:focus": {
        cursor: "pointer",
      },
    },
  }),
);

export default memo(Transition);
