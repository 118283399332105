import React, { memo, useCallback } from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import { Language, Translation } from "core/types";
import { UntransformedAutocompleteInputConfig } from "../../types";
import { ToggleButton } from "../../../common/ToggleButton";
import { useStyles } from "./styles";
import { useAutocompleteEditorTranslation } from "../translation";
import { Options } from "./Options";
import { IAutocompleteValue } from "../../components";
import { Filter } from "./Filter";

enum Mode {
  reference = "reference",
  options = "options",
}

type Props = {
  language: Language;
  handleChangeLanguage: (lang: Language) => void;
};

export const DataSourceComponent = memo<Props>(
  ({ language, handleChangeLanguage }) => {
    const classes = useStyles();
    const {
      elementModel: {
        config: { reference, options = [] },
      },
      changeConfigValue,
    } = useElementEditorContext<UntransformedAutocompleteInputConfig>();
    const editorTranslation = useEditorTranslation();
    const translation = useAutocompleteEditorTranslation();
    const { getViewByName } = useObjectViewList();

    const mode = reference ? Mode.reference : Mode.options;

    const handleChangeReference = useCallback(
      (newValue: UntransformedAutocompleteInputConfig["reference"]) =>
        changeConfigValue(Mode.reference, newValue),
      [changeConfigValue],
    );

    const { viewName, columnName, columnLabel } = reference ?? {
      viewName: "",
      columnName: "",
      columnLabel: "",
    };

    const getDefaultColumn = useCallback(
      (nextViewName: string) =>
        getViewByName(nextViewName)?.identifyingField?.name,
      [getViewByName],
    );

    const handleViewNameChange = (newViewName: string) =>
      handleChangeReference({
        viewName: newViewName,
        columnName: getDefaultColumn(newViewName) ?? "",
        columnLabel: getDefaultColumn(newViewName) ?? "",
      });

    const handleFieldChange = (key: string, fieldValue: IAutocompleteValue) => {
      handleChangeReference({
        viewName,
        columnName,
        columnLabel,
        [key]: fieldValue as string,
      });
    };

    const handleChangeOptions = useCallback(
      (
        newValue: ({
          value: number | string | boolean;
          i18n: Translation<"label">;
        } | null)[],
      ) => changeConfigValue(Mode.options, newValue),
      [changeConfigValue],
    );

    const handleModeChange = (_: any, newValue: Mode) => {
      const isReference = newValue === Mode.reference;
      changeConfigValue(isReference ? Mode.options : Mode.reference, undefined);
      if (isReference) {
        changeConfigValue(
          Mode.reference,
          reference ?? { viewName, columnName, columnLabel },
        );
      } else {
        changeConfigValue(Mode.options, options);
      }
    };

    const fields = [
      {
        label: editorTranslation.identifierNameLabel,
        value: columnName ?? "",
        name: "columnName",
      },
      {
        label: translation.labelLabel,
        value: columnLabel ?? "",
        name: "columnLabel",
      },
    ];

    const modeButtons = Object.values(Mode).map((btnMode) => (
      <ToggleButton key={btnMode} value={btnMode} fullWidth>
        {translation[`${btnMode}Label`]}
      </ToggleButton>
    ));

    return (
      <>
        <Section title={translation.optionsTitle} wrapped={true}>
          <ToggleButtonGroup
            exclusive
            value={mode}
            onChange={handleModeChange}
            className={classes.toggleBtnClass}
            size="small"
          >
            {modeButtons}
          </ToggleButtonGroup>
          {mode === Mode.reference ? (
            <ViewAutocomplete
              viewValue={viewName}
              viewLabel={editorTranslation.viewLabel}
              onViewNameChange={handleViewNameChange}
              onViewFieldChange={handleFieldChange}
              fields={fields}
            />
          ) : (
            <Options
              options={options as any}
              onChange={handleChangeOptions}
              language={language}
              handleChangeLanguage={handleChangeLanguage}
            />
          )}
        </Section>
        {mode === Mode.reference && <Filter />}
      </>
    );
  },
);
