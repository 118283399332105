import { createUseTranslation } from "core/session/translation";

export const editorFormTranslation = {
  en: {
    linkToLabel: "Link To",
    typeLabel: "Type",
    newTypeLabel: "New Property Type",
    createLabel: "Create",
    editLabel: "Edit",
    detailLabel: "Detail",
    validationTitle: "Fields Validation",
    deleteSchemaTooltip: "Delete Schema",
    addSchemaTooltip: "Add Schema",
    requiredTooltip: "Required",
    defaultRequiredTooltip: "Field is not nullable, required by default",
    fieldTypeTooltip: "Field Type",
    nullTypeTooltip: "Field Value can be Null",
    createValidation: "Create Validation",
    removeValidation: "Remove Validation",
    customValidationLabel: "Custom Validation",
    defaultDataTitle: "Default Data",
    createDefaultData: "Create Default Data",
    removeDefaultData: "Remove Default Data",
    defaultDataLabel: "Default Data",
    stateFieldNameLabel: "Workflow Field Name",
    exclusiveLabel: "Exclusive",
    itemsTypeLabel: "Items Type",
    itemsLabel: "Items",
    minItemsLabel: "Minimum Items",
    minLabel: "Minimum",
    maxLabel: "Maximum",
    patternLabel: "Pattern",
    uniqueItemsLabel: "Unique Items",
    editSchemaTitle: "Edit Field Validation",
    propertiesTitle: "Properties",
    newPropertyTooltip: "Add New Property",
    hideSaveAndStayLabel: "Hide Save and Stay button",
    disableSpacingLabel: "Disable Spacing",
    stayLabel: "Stay",
    goBackLabel: "Go Back",
    navigateToPageLabel: "Navigate",
    stayTooltip: "Stay on the Current Page",
    goBackTooltip: "Go Back to the Previous Page",
    navigateToPageTooltip: "Navigate To Page",
    linkToTitleLabel: "On Success",
    formTypeLabel: "Form Type",
  },
};

export const useEditorFormTranslation = createUseTranslation(
  editorFormTranslation,
);
