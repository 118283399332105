import React, { memo, useState } from "react";

import { Section, useElementEditorContext } from "core/editor";

import { UntransformedDefaultModalDialogConfig } from "../../types";
import { Language } from "core/types";
import { useSessionContext } from "core/session/SessionContext";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { useDefaultModalDialogTranslation } from "../translation";
import { TranslationEditor } from "core/editor/common/TranslationEditor/TranslationEditor";

export const TranslationComponent = memo(() => {
  const { translationSectionTitle } = useDefaultModalDialogTranslation();
  const {
    elementModel: { i18n },
    changeTranslation,
  } = useElementEditorContext<UntransformedDefaultModalDialogConfig>();
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);

  const changeLanguage = (l: Language) => setLang(l);

  return (
    <Section
      title={translationSectionTitle}
      wrapped={true}
      cardActions={
        <LanguageSwitch
          language={lang}
          changeLanguage={changeLanguage}
          colorVariant="dark"
          fullWidth={true}
        />
      }
    >
      <TranslationEditor
        translationKey="modalTitle"
        i18n={i18n}
        changeTranslation={changeTranslation}
        language={language}
      />
      <TranslationEditor
        translationKey="openButtonLabel"
        i18n={i18n}
        changeTranslation={changeTranslation}
        language={language}
      />
    </Section>
  );
});
