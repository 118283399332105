import { createUseTranslation } from "core/session/translation";

export const commonStaticPagesTranslation = {
  en: {
    appTitle: "Applications",
    generateAppTitle: "Generate New Application",
    editAppTitle: "Edit Application",
    importAppTitle: "Import Application",
    SaveButton: "Save",
    DeleteButton: "Delete",
    deletedMessage: "App was deleted successfully",
    saveErrorMessage: "App was not saved. Error:",
    saveSuccessMessage: "App successfully saved",
    exportAppMessage: "App was correctly exported",
    createAppMessage: "Application was succesfully created",
    editAppMessage: "Application was succesfully edited",
    generateLabel: "GENERATE",
    createLabel: "IMPORT",
    languageLabel: "Language",
    noDataLabel: "No Available Queries",
    editTooltip: "Edit",
    startTooltip: "Start",
    exportTooltip: "Export",
    deleteTooltip: "Delete",
  },
};

export const useCommonStaticPagesTranslation = createUseTranslation(
  commonStaticPagesTranslation,
);
