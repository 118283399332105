import React, { memo, useCallback, useMemo, useState } from "react";

import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { CodeInputConfig } from "../../types";
import { useCodeInputEditorTranslation } from "../translation";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";

const languageOptions = [
  "javascript",
  "sql",
  "python",
  "yaml",
  "xml",
  "markdown",
];

const languageAutocompleteOptions = languageOptions.map((languageOption) => ({
  value: languageOption,
  label: languageOption,
}));

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config: { language },
    },
    changeConfigValue,
  } = useElementEditorContext<CodeInputConfig>();
  const editorTranslation = useEditorTranslation();
  const translation = useCodeInputEditorTranslation();
  const [searchLanguageInputValue, setSearchLanguageInputValue] = useState<
    string
  >("");

  const handleLanguageChange = useCallback(
    (newValue: CodeInputConfig["language"]) =>
      changeConfigValue("language", newValue),
    [changeConfigValue],
  );

  const selectedLanguageValue = useMemo(
    () =>
      languageAutocompleteOptions.find(
        (o: SelectOption) => o.value === language,
      ),
    [language],
  );

  const handleLanguageInputChange = (value: IAutocompleteValue) => {
    setSearchLanguageInputValue("");
    handleLanguageChange(
      value as Exclude<CodeInputConfig["language"], undefined>,
    );
  };

  return (
    <Section title={editorTranslation.configTitle} wrapped={true}>
      <BaseAutocomplete
        options={languageAutocompleteOptions}
        onChange={handleLanguageInputChange}
        valueObject={selectedLanguageValue ?? null}
        name="language"
        label={translation.languageInputLabel}
        isLoading={false}
        searchInputValue={searchLanguageInputValue}
        onInputChange={setSearchLanguageInputValue}
        virtualizedList={true}
        isClearable={false}
      />
    </Section>
  );
});
