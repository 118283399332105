import { getTranslatedTextSaga } from "core/session/translation/createUseTranslation";
import { TextInput } from "elementTypes/default_text_input/types";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { Actions, Selectors, Types } from "./types";
import { textInputEditorTranslation } from "../../editor/translation";

const testPattern = (pattern: string, value: string) => {
  const regex = new RegExp(pattern);
  return regex.test(value);
};

export function buildSaga(
  actions: Actions,
  types: Types,
  element: TextInput,
  selectors: Selectors,
) {
  function* validateValue() {
    const { regexPattern } = element.config;
    const value: string = yield select(selectors.value);

    const message: string = yield call(
      getTranslatedTextSaga,
      textInputEditorTranslation,
      "regexError",
    );

    if (regexPattern) {
      const error = testPattern(regexPattern, value) ? null : message;
      yield put(actions.setError(error));
    }
  }

  return function* saga() {
    const handleValidate: unknown = yield takeLatest(
      types.VALUE_CHANGE,
      validateValue,
    );
    yield all([handleValidate]);
  };
}
