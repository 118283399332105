import React, { ChangeEvent, memo, useCallback, useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import {
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";
import { UntransformedNumberInputConfig } from "../../types";
import { useStyles } from "../styles";
import { useNumberInputEditorTranslation } from "../translation";

enum Inputs {
  startAdornment = "startAdornment",
  endAdornment = "endAdornment",
}
const styles = [Inputs.startAdornment, Inputs.endAdornment];

const StyleInput = memo<{ name: string }>(({ name }) => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedNumberInputConfig>();
  const { controlClass } = useStyles();
  const translation = useNumberInputEditorTranslation();
  const [isIcon, setIsIcon] = useState<boolean>(
    !!config[`${name}Icon`]?.length,
  );

  const inputName = (isIcon
    ? `${name}Icon`
    : name) as keyof UntransformedNumberInputConfig;

  const changeConfig = useCallback(
    (newValue: string) => changeConfigValue(inputName, newValue),
    [changeConfigValue, inputName],
  );

  const value = isIcon ? config[`${name}Icon`] : config[name];

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    changeConfig(e.target.value);

  const handleModeChange = (_: any, newValue: string) => {
    const nextIsIcon = newValue === "icon";
    if (config[name] !== config[`${name}Icon`]) {
      changeConfigValue(inputName, "");
      changeConfigValue(
        (nextIsIcon
          ? `${name}Icon`
          : name) as keyof UntransformedNumberInputConfig,
        value,
      );
    }
    setIsIcon(nextIsIcon);
  };

  return (
    <>
      {isIcon ? (
        <IconAutocomplete
          name={inputName}
          label={translation[name]}
          value={value}
          onChange={changeConfig}
        />
      ) : (
        <TextField
          value={value}
          name={inputName}
          label={translation[name]}
          fullWidth={true}
          onChange={onInputChange}
        />
      )}
      <FormControl fullWidth={true} className={controlClass}>
        <FormLabel component="p">{translation.applyTitle}</FormLabel>
        <RadioGroup
          row={true}
          value={isIcon ? "icon" : "text"}
          onChange={handleModeChange}
        >
          <FormControlLabel
            control={<Radio color="primary" />}
            label={translation.textLabel}
            value="text"
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label={translation.iconLabel}
            value="icon"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
});

export const StylingComponent = memo(() => {
  const content = styles.map((inputName) => (
    <StyleInput name={inputName} key={inputName} />
  ));

  const translation = useNumberInputEditorTranslation();

  return (
    <Section title={translation.stylingTitle} wrapped={true}>
      {content}
    </Section>
  );
});
