import React, { memo } from "react";

import { Chip, TextField } from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { omit } from "ramda";

import { useStyles } from "./style";
import { PropsFromRedux } from "./container";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";

type Props = PropsFromRedux;

const DefaultArrayTextField = memo<Props>(
  ({
    values,
    element: {
      config: { maxLength, color, size, variant },
    },
  }) => {
    const { chip } = useStyles();

    const renderTags = (
      tags: SelectOption[],
      getTagProps: AutocompleteGetTagProps,
    ) =>
      (
        tags ?? []
      ).map(
        ({ label: tagLabel, value: tagValue }: SelectOption, index: number) => (
          <Chip
            key={`${index}-${tagValue}`}
            className={chip}
            label={tagLabel}
            {...omit(["onDelete"], getTagProps({ index }))}
            color={color}
            variant={variant}
            size={size}
          />
        ),
      );

    const inputValue = (values ?? []).map((value) => ({
      label: value,
      value,
    }));

    const renderInput = (params: AutocompleteRenderInputParams) => (
      <TextField
        variant="standard"
        {...params}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          readOnly: true,
        }}
      />
    );

    return (
      <Autocomplete
        multiple
        open={false}
        limitTags={maxLength ?? 100}
        renderTags={renderTags}
        renderInput={renderInput}
        options={[]}
        value={inputValue}
        popupIcon=""
        closeIcon=""
      />
    );
  },
);

export default DefaultArrayTextField;
