import { dissoc, omit } from "ramda";
import { INSTANTIATED } from "../../constants";
import { buildElementTypeGetter } from "../../getElementType";
import { createElement } from "./utils";
import {
  IElement,
  IElementModel,
  TCompiledRoute,
  TElementModelWithPosition,
} from "../../types";
import { handleActions } from "../../utils/redux";
import { compileRoute, getSampleUrl } from "../../router/reduxModule";
import { types } from "./actions";
import { ActionTypes, IState } from "./types";

const INITIAL_STATE: IState = {
  editModeOn: false,
  activeGrid: {},
  selectedByPageId: {},
  updatedElements: {},
  nextElementId: 0,
  draggableElementParams: null,
  uiReleases: null,
  uiSavePoints: null,
  viewList: null,
  errors: null,
  saving: false,
  releasing: false,
  saveError: null,
  updatedLayoutDefinition: null,
  updatedMenu: null,
  creatingPage: false,
  newRoutes: null,
  usedUrls: [],
  newPages: {},
  isDragging: false,
  isResizing: false,
};

const DEFAULT_EDITOR_STATE = omit(
  [
    "editModeOn",
    "uiReleases",
    "uiSavePoints",
    "viewList",
    "newRoutes",
    "usedUrls",
  ],
  INITIAL_STATE,
);

export const reducer = handleActions<IState, ActionTypes>(INITIAL_STATE, {
  [types.LOAD_UI_RELEASES_SUCCESS]: (
    state,
    { payload }: ActionTypes["loadUiReleasesSuccess"],
  ) => ({
    ...state,
    uiReleases: payload.uiReleases,
    errors: dissoc("uiReleaseError", state.errors),
  }),
  [types.LOAD_UI_RELEASES_ERROR]: (state, { payload }) => ({
    ...state,
    uiReleases: null,
    errors: {
      ...state.errors,
      uiReleaseError: payload.error,
    },
  }),
  [types.LOAD_VIEWS_SUCCESS]: (state, { payload }) => ({
    ...state,
    viewList: payload.viewList,
    errors: dissoc("viewListError", state.errors),
  }),
  [types.LOAD_VIEWS_ERROR]: (state, { payload }) => ({
    ...state,
    viewList: null,
    errors: {
      ...state.errors,
      viewListError: payload.error,
    },
  }),
  [types.LOAD_UI_SAVE_POINTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    uiSavePoints: payload.uiSavePoints,
    errors: dissoc("uiSavePointError", state.errors),
  }),
  [types.LOAD_UI_SAVE_POINTS_ERROR]: (state, { payload }) => ({
    ...state,
    uiSavePoints: null,
    errors: {
      ...state.errors,
      uiSavePointError: payload.error,
    },
  }),
  [types.EDIT_MODE_TOGGLE]: (state) => ({
    ...state,
    editModeOn: !state.editModeOn,
    selectedByPageId: {},
  }),
  [types.EDIT_MODE_EXIT]: () => ({
    ...INITIAL_STATE,
  }),
  [types.SET_ERRORS]: (state, { payload }) => ({
    ...state,
    errors: {
      ...payload.errors,
    },
  }),
  [types.SET_ROUTES]: (state, { payload }) => ({
    ...state,
    newRoutes: [...payload.newRoutes],
    usedUrls: (payload.newRoutes as TCompiledRoute[]).map((r) =>
      getSampleUrl(r[3]),
    ),
  }),
  [types.ELEMENT_SELECT]: (state, { payload }) => ({
    ...state,
    selectedByPageId: {
      ...state.selectedByPageId,
      [payload.page.id]: {
        element: payload.element.originalId
          ? retrieveModelFromElement(payload.element)
          : payload.element,
        type: payload.type,
      },
    },
  }),
  [types.ELEMENT_UNSELECT]: (state, { payload }) => ({
    ...state,
    selectedByPageId: dissoc(payload.page.id, state.selectedByPageId),
  }),
  [types.GRID_SET_ACTIVE]: (state, { payload }) => ({
    ...state,
    activeGrid: {
      ...state.activeGrid,
      [payload.page.id]: payload.element.originalId
        ? retrieveModelFromElement(payload.element)
        : payload.element,
    },
  }),
  [types.CONFIG_VALUE_CHANGE]: (state, { payload }) => {
    const { id } = payload.selected.element;
    let updated = state.updatedElements[id] || payload.selected.element;
    updated = {
      ...updated,
      config: {
        ...updated.config,
        [payload.key]: payload.value,
      },
    };
    return updateElement(id, updated, state);
  },
  [types.TRANSLATION_VALUE_CHANGE]: (state, { payload }) => {
    const { id } = payload.selected.element;
    let updated = state.updatedElements[id] || payload.selected.element;
    updated = {
      ...updated,
      i18n: {
        ...payload.value,
      },
    };
    return updateElement(id, updated, state);
  },
  [types.POSITION_VALUE_CHANGE]: (state, { payload }) => {
    const { id } = payload.selected.element;
    let updated = state.updatedElements[id] || payload.selected.element;
    updated = {
      ...updated,
      position: {
        ...(updated["position"] || {}),
        [payload.key]: payload.value,
      },
    };
    return updateElement(id, updated, state);
  },
  [types.ELEMENT_DRAGGABLE]: (state, { payload }) => ({
    ...state,
    draggableElementParams: payload.params,
  }),
  [types.ELEMENT_SET_IS_DRAGGING]: (state, { payload }) => ({
    ...state,
    isDragging: payload.flag,
  }),
  [types.ELEMENT_SET_IS_RESIZING]: (state, { payload }) => ({
    ...state,
    isResizing: payload.flag,
  }),
  [types.ELEMENT_CREATE]: (state, { payload }) => {
    const getElementType = buildElementTypeGetter(payload.elementTypes);
    const [newElement, nextElementId] = createElement(
      getElementType,
      payload.type,
      state.nextElementId,
      payload.defaultElement,
      payload.position,
      payload.elementName,
    );

    const childName = payload.childName || "content";
    let rootElement = payload.rootElement || payload.page.element;
    if ("originalId" in rootElement) {
      rootElement = retrieveModelFromElement(rootElement);
    }
    const rootElementType = payload.elementTypes[rootElement.type.name];
    const { id } = rootElement;
    let updated = state.updatedElements[id] || rootElement;
    const child = updated.children[childName];
    updated = {
      ...updated,
      children: {
        ...updated.children,
        [childName]: rootElementType.childrenMetadata[childName].isArray
          ? { ...child, elements: [...child.elements, newElement] }
          : { ...child, element: newElement },
      },
    };

    const isGrid = newElement.type.name === "default_grid";

    return {
      ...state,
      nextElementId,
      updatedElements: {
        ...state.updatedElements,
        [id]: updated,
      },
      selectedByPageId: {
        ...state.selectedByPageId,
        ...(!isGrid &&
          !payload.disableSelection && {
            [payload.page.id]: {
              element: newElement,
              type: payload.type,
            },
          }),
      },
      activeGrid: {
        ...state.activeGrid,
        ...(payload.type.name === "default_grid" && {
          [payload.page.id]: newElement,
        }),
      },
    };
  },
  [types.ELEMENT_DELETE]: (state, { payload }) => {
    const childName = payload.childName || "content";
    let rootElement = payload.rootElement || payload.page.element;
    if (rootElement.originalId) {
      rootElement = retrieveModelFromElement(rootElement);
    }
    const rootElementType = payload.elementTypes[rootElement.type.name];
    const { id } = rootElement;
    let updated = state.updatedElements[id] || rootElement;
    const child = updated.children[childName];
    updated = {
      ...updated,
      children: {
        ...updated.children,
        [childName]: rootElementType.childrenMetadata[childName].isArray
          ? {
              ...child,
              elements: [
                ...child.elements.filter(
                  (el: IElement) => el.id !== payload.element.id,
                ),
              ],
            }
          : { ...child, element: {} },
      },
    };

    const nextState = updateElement(id, updated, state);
    return {
      ...nextState,
      selectedByPageId: dissoc(payload.page.id, state.selectedByPageId),
    };
  },
  [types.NEXT_ELEMENT_ID_UPDATE]: (state, action) => ({
    ...state,
    nextElementId: action.payload,
  }),
  [types.UPDATE_ELEMENTS]: (state, { payload }) => ({
    ...state,
    updatedElements: {
      ...state.updatedElements,
      ...payload.updatedElements,
    },
    selectedByPageId: {
      ...state.selectedByPageId,
      ...(payload.selected && {
        [payload.page.id]: {
          ...payload.selected,
        },
      }),
    },
  }),
  [types.UPDATE_LAYOUT_DEFINITION]: (state, { payload }) => ({
    ...state,
    updatedLayoutDefinition: payload.updatedLayoutDefinition,
  }),
  [types.UPDATE_MENU_ENTRIES]: (state, { payload }) => ({
    ...state,
    updatedMenu: payload.updatedMenu,
  }),
  [types.RELEASE]: (state) => ({
    ...state,
    releasing: true,
  }),
  [types.RELEASE_SUCCESS]: (state) => ({
    ...state,
    ...DEFAULT_EDITOR_STATE,
    errors: {
      ...dissoc("release", state.errors),
    },
  }),
  [types.RELEASE_ERROR]: (state, { payload }) => ({
    ...state,
    releasing: false,
    errors: {
      ...state.errors,
      release: payload,
    },
  }),
  [types.SAVE]: (state) => ({
    ...state,
    saving: true,
    saveError: null,
  }),
  [types.SAVE_SUCCESS]: (state) => ({
    ...state,
    ...DEFAULT_EDITOR_STATE,
  }),
  [types.SAVE_ERROR]: (state, { payload }) => ({
    ...state,
    saving: false,
    saveError: payload,
  }),
  [types.CLEAN_ACTIONS_ERRORS]: (state) => ({
    ...state,
    saveError: null,
    errors: {
      ...dissoc("release", state.errors),
    },
  }),
  [types.ELEMENT_CHILDREN_UPDATE]: (state, { payload }) => {
    let rootElement = payload.element;
    if (rootElement.originalId) {
      rootElement = retrieveModelFromElement(rootElement);
    }
    const childName = payload.childName ?? "content";
    const childKey = payload.childKey ?? "elements";
    const { id } = rootElement;
    let updated = state.updatedElements[id] || rootElement;

    updated = {
      ...updated,
      children: {
        ...updated.children,
        [childName]: {
          [childKey]: payload.children,
        },
      },
    };

    return {
      ...state,
      updatedElements: {
        ...state.updatedElements,
        [id]: updated,
      },
      selectedByPageId: {
        ...state.selectedByPageId,
        [payload.page.id]: {
          ...state.selectedByPageId[payload.page.id],
          element: updated,
        },
      },
    };
  },
  [types.PAGE_CREATE]: (state) => ({
    ...state,
    creatingPage: true,
  }),
  [types.PAGE_CREATE_SUCCESS]: (
    state,
    { payload: { page, sampleUrl, pages } },
  ) => ({
    ...state,
    creatingPage: false,
    newRoutes: [...(state.newRoutes ?? []), compileRoute(page)],
    usedUrls: [...state.usedUrls, sampleUrl],
    newPages: {
      ...pages,
      [page.id]: page,
    },
    errors: {
      ...dissoc("page", state.errors),
    },
  }),
  [types.PAGE_CREATE_ERROR]: (state, { payload }) => ({
    ...state,
    creatingPage: false,
    errors: {
      ...dissoc("page", state.errors),
      ...(payload.error && { page: payload.error }),
    },
  }),
  [types.PAGE_UPDATE_SUCCESS]: (state, { payload: { page, pages } }) => ({
    ...state,
    newPages: {
      ...pages,
      [page.id]: page,
    },
    errors: {
      ...dissoc("page", state.errors),
    },
  }),
  [types.PAGE_DELETE_SUCCESS]: (
    state,
    { payload: { pages, page, sampleUrl } },
  ) => ({
    ...state,
    usedUrls: state.usedUrls.filter((usedUrl) => usedUrl !== sampleUrl),
    newRoutes: [
      ...(state.newRoutes ?? []).filter(
        (newRoute) => newRoute[3] !== sampleUrl,
      ),
    ],
    newPages: {
      ...omit([page.id], pages),
    },
  }),
  [types.DISCARD_CHANGES]: (state) => ({
    ...state,
    ...DEFAULT_EDITOR_STATE,
  }),
  [types.INITIALIZE_SELECTION]: (
    state,
    action: ActionTypes["initializeSelection"],
  ) => {
    if (state.activeGrid[action.payload.page.id]) {
      return state;
    } else {
      return {
        ...state,
        activeGrid: {
          ...state.activeGrid,
          [action.payload.page.id]:
            state.activeGrid[action.payload.page.id] ??
            action.payload.page.element,
        },
      };
    }
  },
});

function retrieveModelFromElement(element: IElement): IElementModel {
  const {
    [INSTANTIATED]: _,
    scope,
    originalId,
    originalConfig,
    ...elementModel
  } = element;
  return {
    ...elementModel,
    id: originalId,
    config: originalConfig,
  };
}

function updateElement(
  id: string,
  element: IElementModel | TElementModelWithPosition,
  state: IState,
): IState {
  return {
    ...state,
    updatedElements: {
      ...state.updatedElements,
      [id]: element,
    },
  };
}
