export enum QueryKeys {
  createCustomQuery = "createCustomQuery",
  createSimpleQuery = "createSimpleQuery",
  createState = "createState",
  fetchApps = "fetchApps",
  fetchColumnValues = "fetchColumnValues",
  fetchFileGroups = "fetchFileGroups",
  fetchFiles = "fetchFiles",
  fetchFileTypes = "fetchFileTypes",
  fetchLDAPConfig = "fetchLDAPConfig",
  fetchModel = "fetchModel",
  fetchObjectViews = "fetchObjectViews",
  fetchQueries = "fetchQueries",
  fetchRoles = "fetchRoles",
  fetchStateNode = "fetchStateNode",
  fetchStateTransition = "fetchStateTransition",
  fetchUIList = "fetchUIList",
  fetchUsers = "fetchUsers",
  fetchWorkflow = "fetchWorkflow",
  fetchDataPreview = "fetchDataPreview",
  fetchTableAudit = "fetchTableAudit",
  fetchAuditTableList = "fetchAuditTableList",
  fetchLoginConfig = "fetchLoginConfig",
  fetchUser = "fetchUser",
  fetchFunctions = "fetchFunctions",
  getFilesByGroupType = "getFilesByGroupType",
  fetchCustomQuery = "fetchCustomQuery",
}
