import { IFile } from "staticPages/admin/pages/files/types";
import { Role, RoleOption } from "./types";

export const getRoleOptions = (roles: Role[] = []) =>
  roles.map(
    (role) =>
      ({
        label: role.name,
        value: role.name,
      } as RoleOption),
  );

// get data for autocomplete

export const getOptions = <ItemType>(name: string) => (list: ItemType[] = []) =>
  list.map((item) => ({
    label: item[name],
    value: item[name],
  }));

export const getFileOptions = (files: IFile[] = []) =>
  files.map(
    (file) =>
      ({
        label: file.realName,
        value: file.fileName,
        title: `${file.realName}${
          file.createdAt
            ? ` - ${new Date(file.createdAt).toLocaleString()}`
            : ""
        }`,
      } as { label: string; value: string; title: string }),
  );
