import React, { memo } from "react";
import { ToggleButtonGroup } from "@material-ui/lab";

import { ToggleButton } from "elementTypes/common/ToggleButton";
import { useDataBaseTranslation } from "../translation";
import { LayoutOptions } from "../component";
import { MuiIcon } from "../../../../../elementTypes/common/MuiIcon";

interface IProps {
  value: LayoutOptions;
  onChange: (event: any, value: LayoutOptions) => void;
}

export const ToggleLayoutPanel = memo<IProps>(({ value, onChange }) => {
  const translation = useDataBaseTranslation();

  const layoutOptions = Object.keys(LayoutOptions).map((option) => (
    <ToggleButton
      key={option}
      value={LayoutOptions[option]}
      tooltip={translation[`${option}Tooltip`]}
      size="small"
    >
      <MuiIcon icon={`border_${option}`} fontSize="large" />
    </ToggleButton>
  ));

  return (
    <ToggleButtonGroup value={value} onChange={onChange} exclusive>
      {layoutOptions}
    </ToggleButtonGroup>
  );
});
