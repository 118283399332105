import * as t from "io-ts";
import { types } from "core/runtime-typing";
import {
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  UntransformedConfig,
} from "core/types";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";
import { FormInputConfig } from "../common";
import { SelectorTypes } from "core/types/element";

export const numberInputSelectors: SelectorTypes<NumberInputConfig> = {
  value: types.nullable(types.number()),
  disabled: types.boolean(),
  touched: types.boolean(),
};

export const NumberInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(t.union([t.null, t.number])),
  FormInputConfig,
  t.partial({
    startAdornment: t.string,
    endAdornment: t.string,
    startAdornmentIcon: t.string,
    endAdornmentIcon: t.string,
    precision: t.number,
    prefix: t.string,
    suffix: t.string,
    thousandSeparator: t.boolean,
    format: t.string,
  }),
]);

export type NumberInputConfig = t.TypeOf<typeof NumberInputConfig>;

export type UntransformedNumberInputConfig = UntransformedConfig<
  NumberInputConfig
>;

export const NumberInputTranslationKeys = ["label"] as const;

export type NumberInputTranslationKeys = typeof NumberInputTranslationKeys[number];

export type NumberInput = IElement<
  NumberInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  NumberInputTranslationKeys
>;
