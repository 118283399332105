import { createTypeSafeContext } from "utils/createTypeSafeContext";
import { Language } from "../types";

interface ISessionContext {
  language: Language;
}

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  ISessionContext
>();

export const useSessionContext = useTypeSafeContext;
export const SessionContextProvider = Provider;
export const SessionContextConsumer = Consumer;
