import React, { ChangeEvent, memo } from "react";
import { FixedSizeList, areEqual } from "react-window";
import { Box, Checkbox, Tooltip, Typography } from "@material-ui/core";
import { dissoc } from "ramda";
import { NodeData } from "./types";
import { useRoles } from "../../../../../queries/admin";
import { Role } from "../../../../../queries/admin/types";
import Button from "../../../../../elementTypes/common/Button";
import { IFixedRow } from "../../../../../core";
import { LoadingComponent } from "../../../../../layouts/common/Loading";
import { useStyles } from "./styles";
import { useErdTranslation } from "./translation";
import { BasicQuery } from "./queryBuilder/basicQuery";
import { usePermissionContext } from "../permissionComponent";

type Props = {
  nodeData: NodeData;
};

type IRow = IFixedRow<Role>;

const ITEM_SIZE = 44;

enum Permissions {
  select = "SELECT",
  insert = "INSERT",
  update = "UPDATE",
  delete = "DELETE",
}

export const QueryBuilder = memo<Props>(({ nodeData }) => {
  return <BasicQuery nodeData={nodeData} />;
});

export const titleToName = (value: string) =>
  value
    .replace(/[^a-zA-Z0-9-_]/g, "_")
    .replace(/[-_]+$/g, "")
    .replace(/[_]+/g, "_")
    .toLowerCase();

export const handleTitleValidate = (value: string) => {
  const val = value.toString().trim();
  return !!val;
};

export const handleNameValidate = (value: string) => {
  const val = value.toString().trim();
  // PostgreSQL identifiers must be less than 64 bytes long
  // using Blob to check actual byte size
  return !!val && new Blob([val]).size < 64;
};

const cellStyles = (index: number) => ({
  display: "flex",
  alignItems: "center",
  px: 1,
  ...(index !== 0 && {
    justifyContent: "center",
  }),
});

export const PermissionTable = memo(() => {
  const {
    permissions = {},
    setPermissions,
    isEditMode,
  } = usePermissionContext();
  const { tableRow } = useStyles();
  const { data: roles, isLoading } = useRoles();

  const translation = useErdTranslation();
  const headers = [translation.userColumnTitle, ...Object.values(Permissions)];

  const updatePermissions = (key: string, value: string[]) =>
    setPermissions(
      value.length
        ? { ...permissions, [key]: value }
        : dissoc(key, permissions),
    );

  const selectCellPermissions = (type: Permissions) => () => {
    if (!roles) {
      return;
    }

    const allPermissionsSet = roles.some(
      ({ name }: Role) => !permissions[name]?.includes(type),
    );

    const nextVal = roles.reduce(
      (res, role) => ({
        ...res,
        [role.name]: allPermissionsSet
          ? [...new Set([...(permissions[role.name] ?? []), type])]
          : permissions[role.name]?.filter((permission) => permission !== type),
      }),
      {} as Record<string, string[]>,
    );

    setPermissions(nextVal);
  };

  const tableHead = headers.map((head, index: number) => (
    <Box key={head} {...cellStyles(index)}>
      {index !== 0 ? (
        <Button
          variant="text"
          tooltip={translation.columnSelectTooltip}
          label={head}
          // adjust when editing permissions will be implemented
          {...(!isEditMode && {
            onClick: selectCellPermissions(head as Permissions),
          })}
        />
      ) : (
        head
      )}
    </Box>
  ));

  const Row = memo<IRow>(({ index, style }) => {
    if (!roles) {
      return null;
    }

    const name = roles[index].name;
    const rowPermissions = permissions[name] ?? [];

    const handleChange = (
      ev: ChangeEvent<HTMLInputElement>,
      checked: boolean,
    ) => {
      let nextVal = rowPermissions;
      if (checked) {
        nextVal = [...new Set([...nextVal, ev.target.name])];
      } else {
        nextVal = nextVal.filter((permission) => permission !== ev.target.name);
      }
      updatePermissions(name, nextVal);
    };

    const allPermissionsSet = () =>
      rowPermissions.length === Object.values(Permissions).length;

    const selectRowPermissions = () => {
      const nextVal = allPermissionsSet() ? [] : Object.values(Permissions);
      updatePermissions(name, nextVal);
    };

    const columns = headers.map((title, i) => (
      <Box
        key={`${name}-${title}${i}`}
        {...cellStyles(i)}
        style={{ cursor: "pointer" }}
      >
        {i === 0 ? (
          <Tooltip title={translation.rowSelectTooltip}>
            <Typography variant="body1">{name}</Typography>
          </Tooltip>
        ) : (
          <Checkbox
            name={title}
            checked={permissions[name]?.includes(title)}
            // remove when editing permissions will be implemented
            disabled={isEditMode}
            // adjust when editing permissions will be implemented
            {...(!isEditMode && {
              onChange: handleChange,
            })}
          />
        )}
      </Box>
    ));

    return (
      <Box
        style={style}
        display="grid"
        gridTemplateColumns=" 2fr repeat(4, 1fr)"
        onClick={!isEditMode ? selectRowPermissions : undefined}
        {...(index !== roles.length - 1 && {
          className: tableRow,
        })}
      >
        {columns}
      </Box>
    );
  }, areEqual);

  return (
    <Box border="1px solid" borderRadius="borderRadius" borderColor="divider">
      <Box
        display="grid"
        gridTemplateColumns=" 2fr repeat(4, 1fr)"
        className={tableRow}
      >
        {tableHead}
      </Box>
      {isLoading && <LoadingComponent />}
      {roles && roles?.length && (
        <FixedSizeList
          height={ITEM_SIZE * Math.min(roles.length, 8)}
          itemCount={roles.length}
          itemSize={ITEM_SIZE}
          width="100%"
          itemData={roles}
        >
          {Row}
        </FixedSizeList>
      )}
    </Box>
  );
});
