import React, { FC, memo } from "react";
import { Controller } from "react-hook-form";

import {
  Box,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import { UICreateStateForm } from "./types";
import { useStateFormTranslations } from "./translation";
import {
  HookForm,
  useHookFormContext,
} from "../../../../../../../elementTypes/common/HookForm";
import { PermissionComponent } from "../StateTransitionForm/PermissionComponent";
import IconButton from "elementTypes/common/IconButton";
import Button from "elementTypes/common/Button";
import { StyledStateName } from "../../../components/StyledStateName";
import { useSelectedState } from "../../utils";

interface StateFormProps {
  onSubmit: (data: Record<string, unknown>) => void;
  onDelete?: () => void;
  node?: UICreateStateForm;
  stateChangesFound?: boolean;
}

const StateForm: FC<StateFormProps> = ({
  node,
  stateChangesFound,
  onSubmit,
  onDelete,
}) => {
  const { noStateTransitions } = useStateFormTranslations();

  return (
    <HookForm
      onSubmit={onSubmit}
      boxProps={{ bgcolor: "background.paper", height: "100%" }}
    >
      <FormContent node={node} onDelete={onDelete} />
      {!!node?.state && !stateChangesFound && (
        <Box mt={2}>
          <Alert severity="warning">{noStateTransitions}</Alert>
        </Box>
      )}
    </HookForm>
  );
};

const FormContent = memo<{
  node?: UICreateStateForm;
  onDelete?: () => void;
}>(({ node, onDelete }) => {
  const { control, errors, watch } = useHookFormContext();
  const { setSelectedElement } = useSelectedState();

  const handleClose = () => setSelectedElement(null);

  const {
    cancelButton,
    submitButton,
    createState,
    descriptionLabelField,
    editState,
    stateLabelField,
    titleLabelField,
    editableLabel,
    deleteState,
  } = useStateFormTranslations();

  const isEditing = Boolean(node);

  const isEditable = watch("isEditable") ?? node?.isEditable ?? true;

  const title = (
    <Typography variant="h5">
      {node ? (
        <>
          {editState} &#8209; <StyledStateName stateName={node.state} />
        </>
      ) : (
        createState
      )}
    </Typography>
  );

  return (
    <Card
      variant="outlined"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      component="aside"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        py={1}
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gridGap={8}>
          <IconButton
            onClick={handleClose}
            tooltip={cancelButton}
            icon="arrow_back"
          />
          {title}
        </Box>
        {node && (
          <IconButton
            onClick={onDelete}
            icon="delete_outlined"
            tooltip={deleteState}
          />
        )}
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        width="100%"
      >
        <Box
          py={1}
          px={1.5}
          display="flex"
          flexDirection="column"
          flex={1}
          gridGap={16}
        >
          <FormControl fullWidth>
            <Controller
              as={<TextField label={titleLabelField} autoComplete={"off"} />}
              error={Boolean(errors?.title)}
              control={control}
              name="title"
              defaultValue={node?.title ?? ""}
              rules={{
                required: true,
              }}
            />
          </FormControl>
          {!node && (
            <FormControl fullWidth>
              <Controller
                as={
                  <TextField
                    label={stateLabelField}
                    fullWidth={true}
                    InputProps={{
                      readOnly: isEditing,
                    }}
                  />
                }
                control={control}
                name="state"
                defaultValue={""}
              />
            </FormControl>
          )}
          <FormControl fullWidth>
            <Controller
              as={
                <TextField label={descriptionLabelField} autoComplete={"off"} />
              }
              error={Boolean(errors?.longDescription)}
              control={control}
              name="longDescription"
              defaultValue={node?.longDescription ?? ""}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Controller
                as={<Switch color="primary" />}
                name="isEditable"
                control={control}
                defaultValue={node?.isEditable ?? true}
              />
            }
            label={editableLabel}
          />
          {isEditable && <PermissionComponent node={node} control={control} />}
        </Box>
        <Divider />
        <Box p={1} display="flex" justifyContent="flex-end">
          <Button label={submitButton} type="submit" color="primary" />
        </Box>
      </Box>
    </Card>
  );
});

export default memo(StateForm);
