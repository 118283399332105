import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";

import { Section, useElementEditorContext } from "core/editor";
import { actions as editorActions } from "core/editor/reduxModule";
import { selectors as routerSelectors } from "core/router/reduxModule";
import IconButton from "elementTypes/common/IconButton";
import { TabsChildren, UntransformedTabsConfig } from "../../types";
import { useDefaultTabsEditorTranslation } from "../translation";
import { TabConfigProps } from "./TabConfig";
import { TabDialog } from "./TabDialog";
import { TabsContent } from "./TabsContent";
import { TabsContentProvider } from "./TabsContext";

const newTabParams = (order: number) => ({
  name: "",
  defaultTab: false,
  isNew: true,
  index: order - 1,
  config: {},
});

export const TabsComponent = memo(() => {
  const {
    elementModel,
    elementModel: {
      config: { labels, default: defaultTab },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTabsConfig>();
  const { addTabTooltip, tabsTitle } = useDefaultTabsEditorTranslation();
  const dispatch = useDispatch();
  const [tabDetails, setTabDetails] = useState<TabConfigProps | null>(null);
  const page = useSelector(routerSelectors.page);

  const {
    content: { elements: tabs },
  } = elementModel.children as TabsChildren;

  const createElement = useCallback(
    (...params: Parameters<typeof editorActions.createElement>) => {
      dispatch(editorActions.createElement(...params));
    },
    [dispatch],
  );

  const updateChildren = useCallback(
    (...params: Parameters<typeof editorActions.updateElementChildren>) => {
      dispatch(editorActions.updateElementChildren(...params));
    },
    [dispatch],
  );

  const handleAddTab = () => setTabDetails(newTabParams(tabs.length + 1));
  const handleDialogClose = () => setTabDetails(null);

  const deleteTab = (index: number) => {
    const newLabels = labels.filter(
      (_label, labelIndex) => labelIndex !== index,
    );
    const newTabs = tabs.filter(
      (_: any, tabIndex: number) => tabIndex !== index,
    );

    if (defaultTab === index) {
      changeConfigValue("default", 0);
    }

    changeConfigValue("labels", newLabels);

    updateChildren(elementModel, newTabs, page!, "content");
  };

  const handleTabButtonClick = (
    type: "edit" | "delete",
    params: TabConfigProps | number,
  ) => {
    switch (type) {
      case "edit":
        setTabDetails(params as TabConfigProps);
        break;
      case "delete":
        deleteTab(params as number);
        break;
      default:
        break;
    }
  };

  return (
    <TabsContentProvider
      value={{
        tabDetails,
        page,
        setTabDetails,
        updateChildren,
        handleDialogClose,
        handleTabButtonClick,
        deleteTab,
        createElement,
      }}
    >
      <Section
        title={tabsTitle}
        classes={{ root: "editor-tabs-section-tabs" }}
        headerAction={
          <Box display="flex" alignItems="center">
            <IconButton
              icon="add"
              onClick={handleAddTab}
              color="primary"
              tooltip={addTabTooltip}
              data-testid={"add-tab-button"}
            />
          </Box>
        }
      >
        <TabsContent />
      </Section>
      <TabDialog />
    </TabsContentProvider>
  );
});
