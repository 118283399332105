import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import maintenancePath from "layouts/common/maintenance.svg";
import { useMaintenancePageTranslation } from "./translation";
import useStyles from "./styles";
import Button from "elementTypes/common/Button";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { actions } from "./reduxModule";
import verticalLogoPath from "../common/verticalLogo.svg";

export const MaintenancePage = memo(() => {
  const { root, image, logo, cardContent } = useStyles();
  const translation = useMaintenancePageTranslation();
  const { error } = useSelector(sessionSelectors.loadAppMetadata);
  const dispatch = useDispatch();

  const handleRetryClick = () => dispatch(sessionActions.bootstrap());

  useEffect(() => {
    if (!error) {
      dispatch(actions.redirect());
    }
  }, [error, dispatch]);

  return (
    <>
      <Helmet>
        <title>{translation.apiNotAvailableText}</title>
      </Helmet>

      <Card className={root}>
        <CardContent className={cardContent}>
          <img className={logo} src={verticalLogoPath} alt="logo" />
          <Typography variant="h2" align="center">
            {translation.apiNotAvailableText}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            iconRight="refresh"
            label={translation.retryText}
            size="large"
            variant="contained"
            onClick={handleRetryClick}
          />
        </CardActions>
        <CardMedia>
          <img src={maintenancePath} alt="Maintenance" className={image} />
        </CardMedia>
      </Card>
    </>
  );
});
