import { createUseTranslation } from "core/session/translation";

const stateFormTranslations = {
  en: {
    cancelButton: "Cancel",
    submitButton: "Submit",
    createState: "Create State",
    editState: "Edit State",
    noStateTransitions: "No state transitions!",
    titleLabelField: "Title",
    stateLabelField: "Value",
    descriptionLabelField: "Description",
    success: "The changes were saved successfully",
    editableLabel: "Can be updated by User",
    successDelete: "Delete State successfully",
    deleteState: "Delete State",
  },
  es: {
    createState: "Crear estado",
    editState: "Editar estado",
    noStateTransitions: "No hay transiciones de estado!",
    titleLabelField: "Título",
    stateLabelField: "Estado",
    descriptionLabelField: "Descripción",
    success: "Los cambios se guardaron de manera satisfactoria",
  },
};

export const useStateFormTranslations = createUseTranslation(
  stateFormTranslations,
);
