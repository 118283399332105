import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import { Box } from "@material-ui/core";
import dagre from "dagre";
import ReactFlow, {
  ArrowHeadType,
  Controls,
  Elements,
  FlowElement,
  Node,
  OnLoadParams,
  Position,
  isEdge,
  isNode,
} from "react-flow-renderer";

import { edgeRawStyle, useStyles } from "./styles";
import { LayoutOptions } from "../component";
import { MiniMap } from "../components/MiniMap";
import { NodeData } from "./types";
import { TableNodeMenuProvider } from "./tableNodeMenu";
import TableNode, {
  MAX_COLUMNS_QUANTITY,
  TABLE_BODY_ROW_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_WIDTH,
} from "./tableNode";
import SchemaNode from "./SchemaNode";

const NODE_TYPES = {
  schema: SchemaNode,
  table: TableNode,
};

type ElementTableNode = NodeData;

export type ElementSchemaNode = {
  schema: string;
  width: number;
  height: number;
};

type ElementDataNode = ElementTableNode | ElementSchemaNode;

interface IProps {
  directionValue: LayoutOptions;
  sourceContent: Elements<ElementDataNode>;
}

const SCHEMA_PADDING = 10;

type SchemaNode = Node<ElementSchemaNode>;

const getTableHeight = (columns: NodeData["columns"]) => {
  const getHeight = (columnsQuantity: number) =>
    columnsQuantity * TABLE_BODY_ROW_HEIGHT + TABLE_HEADER_HEIGHT + 2; // 2 === border height

  return getHeight(Math.min(columns.length, MAX_COLUMNS_QUANTITY));
};

const getBottomY = (el: Node) =>
  el.position.y + getTableHeight(el.data?.columns);

const getLayoutedElements = (
  elements: Elements<ElementDataNode>,
  directionValue: LayoutOptions,
): Elements => {
  const isHorizontal = directionValue === LayoutOptions.horizontal;

  const dagreGraph = new dagre.graphlib.Graph({
    compound: true,
    directed: true,
  });
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: directionValue });

  const schemas: string[] = [];

  elements.forEach((el: FlowElement<ElementDataNode>) => {
    if (isNode(el)) {
      const height = getTableHeight((el?.data as NodeData).columns);

      dagreGraph.setNode(el.id, {
        width: TABLE_WIDTH,
        height,
      });

      const schemaName = ((el.data as unknown) as NodeData).schemaName;
      if (!schemas.includes(schemaName)) {
        schemas.push(schemaName);

        dagreGraph.setNode(schemaName, {
          label: schemaName,
          clusterLabelPos: "top",
        });
      }
      dagreGraph.setParent(el.id, schemaName);
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  const tableNodes = elements.filter(isNode).map((el) => {
    const nodeWithPosition = dagreGraph.node(el.id);

    const targetPosition = isHorizontal ? Position.Left : Position.Top;
    const sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    const height = getTableHeight((el?.data as NodeData).columns);

    const position = {
      x: nodeWithPosition.x - TABLE_WIDTH / 2 + Math.random() / 1000,
      y: nodeWithPosition.y - height / 2,
    };

    return {
      ...el,
      position,
      sourcePosition,
      targetPosition,
    };
  });

  const edgeNodes = elements.filter(isEdge).map((el) => ({
    ...el,
    labelStyle: edgeRawStyle,
    animated: false,
    arrowHeadType: ArrowHeadType.ArrowClosed,
    style: { strokeWidth: 2 },
  }));

  const schemaNodes = schemas.map((schema) => {
    const tables = tableNodes.filter(
      (n) => (n.data as ElementTableNode).schemaName === schema,
    );

    // the calculated position and size by dagre is wrong
    // calculating manually via table positions and sizes within the schema

    const minTableX = Math.min(...tables.map((t) => t.position.x));
    const minTableY = Math.min(...tables.map((t) => t.position.y));
    const maxTableX = Math.max(...tables.map((t) => t.position.x));

    const maxObject = tables.reduce((prev, current) => {
      const prevBottomY = getBottomY(prev);
      const currentBottomY = getBottomY(current);
      const condition = isHorizontal
        ? prev.position.y > current.position.y
        : prevBottomY > currentBottomY;

      return condition
        ? { ...prev, bottomY: prevBottomY }
        : { ...current, bottomY: currentBottomY };
    }, tables[0]) as Node & { bottomY: number };

    const position = {
      x: minTableX - SCHEMA_PADDING,
      y: minTableY - SCHEMA_PADDING,
    };

    const realWidth = maxTableX - minTableX + TABLE_WIDTH;
    const realHeight = maxObject.bottomY - minTableY;

    return {
      id: schema,
      type: "schema",
      position,
      data: {
        schema,
        width: realWidth + 2 * SCHEMA_PADDING,
        height: realHeight + 2 * SCHEMA_PADDING,
      },
      selectable: false,
    };
  });

  return [...schemaNodes, ...tableNodes, ...edgeNodes];
};

const Component = memo<IProps>(({ directionValue, sourceContent }) => {
  const [elements, setElements] = useState<Elements>([]);
  const reactFlowInstance = useRef<OnLoadParams | null>(null);
  const { layoutflow } = useStyles();

  const onLayout = useCallback(() => {
    const layoutedElements = getLayoutedElements(sourceContent, directionValue);
    setElements(layoutedElements);
  }, [sourceContent, directionValue]);

  const onLoad = (instance: OnLoadParams) => {
    reactFlowInstance.current = instance;
    onLayout();
  };

  useEffect(() => {
    onLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceContent, directionValue]);

  useEffect(() => {
    reactFlowInstance.current?.fitView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  return (
    <TableNodeMenuProvider>
      <Box className={layoutflow}>
        <ReactFlow
          elements={elements}
          nodeTypes={NODE_TYPES}
          onLoad={onLoad}
          maxZoom={1}
          minZoom={0.3}
          defaultZoom={0.5}
          nodesConnectable={false}
          nodesDraggable={false}
          paneMoveable
          zoomOnScroll
        >
          <MiniMap />
          <Controls showInteractive={false} />
        </ReactFlow>
      </Box>
    </TableNodeMenuProvider>
  );
});

export const ErdChartComponent = (props: IProps) => <Component {...props} />;
