import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColType,
} from "@material-ui/data-grid";

import { GeneralType } from "core";
import { CustomColumnProps } from "./component";
import { renderCell, renderEditCell } from "./components";

export const getGridColumnType = (type: string): GridColType => {
  switch (type) {
    case "boolean":
    case "number":
    case "date":
    case "dateTime":
      return type;
    case "integer":
      return "number";
    default:
      return "string";
  }
};

export const getDefaultAlign = (index?: number) => ({
  align: index === 0 ? "left" : ("center" as GridAlignment),
  headerAlign: index === 0 ? "left" : ("center" as GridAlignment),
});

export const getCustomColumnProps = ({
  generalType,
  component,
  editComponent,
  index,
  ...rest
}: CustomColumnProps & { generalType: GeneralType }): Partial<GridColDef> &
  CustomColumnProps => ({
  type: getGridColumnType(generalType.type),
  ...((shouldRenderCustom(generalType) || component || editComponent) && {
    renderEditCell,
    renderCell,
  }),
  component,
  editComponent,
  generalType,
  sortable: !generalType?.isArray && generalType?.type !== "json",
  ...getDefaultAlign(index),
  ...rest,
});

export const shouldRenderCustom = (generalType: GeneralType) =>
  generalType.isArray || generalType.type !== "boolean";

export const onCustomInputChange = (params: GridCellParams) => (
  nextVal: GridCellParams["value"],
) => {
  const editProps = { value: nextVal };

  params.api.setEditCellProps({
    id: params.id,
    field: params.field,
    props: editProps,
  });
};
