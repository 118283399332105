/* eslint-disable no-new-func */

import { isRight, parseJSON, toError } from "fp-ts/lib/Either";
import * as t from "io-ts";
import { failure } from "io-ts/lib/PathReporter";
import { DECODE_UTILS, EXPRESSION_TAG } from "../constants";
import { ILocation } from "../router/reduxModule";
import { getDidYouMean } from "../utils/didYouMean";
import { getElementDisplay, getTranslatedText } from "../utils/element";
import { getOrThrow } from "../utils/io-ts";
import { IElement, IElementModel, IElementProps } from "./element";
import { DEFAULT_LANGUAGE_CODE, Language } from "./i18n";
import { GetModule, IReduxModule } from "./redux";

type GetCurrentLanguage = (state: any) => Language;

const EXPRESSION_TAG_LEN = EXPRESSION_TAG.length;

export function isCustomExpression(o: unknown): o is string {
  return typeof o === "string" && o.startsWith(EXPRESSION_TAG);
}

export function getExpression(value: string) {
  return value.slice(EXPRESSION_TAG_LEN);
}

export const parseQueryValue = (value: string) => {
  const res = parseJSON(value, toError);
  return isRight(res) ? res.right : value;
};

export function buildCustomExpressionValue(
  value: string | number | boolean | null,
): string {
  return EXPRESSION_TAG + String(value);
}

export const customExpression = <C extends t.Mixed>(codec: C) => {
  type T = t.TypeOf<C>;
  type TE = (state: any) => T;
  return new t.Type<TE, string, unknown>(
    "CustomExpression",
    (u): u is TE => isCustomExpression(u) || isRight(codec.decode(u)),
    (i: unknown, context: t.Context) => {
      const {
        props,
        getModule,
        getCurrentLanguage,
        element,
        location,
        id,
      } = (context[0].actual as any)[DECODE_UTILS];
      if (isCustomExpression(i)) {
        return validateExpression(
          getExpression(i),
          props,
          getModule,
          getCurrentLanguage,
          element,
          location,
          id,
          codec,
          context,
        );
      }
      const result = codec.validate(i, context);
      return isRight(result) ? t.success(() => i as T) : result;
    },
    codec.encode,
  );
};

/**
 * TODO:
 * see if the selector can track selector dependencies and return a cached value.
 * DONT TRY TO CACHE THE MODULE PROXY BECAUSE MODULES CAN HAVE THE SAME ID BUT CHANGE.
 */

const USES_ELEMENTS = Symbol();

// used to determine whether a module proxy is somehow part of the expression result
const IS_MODULE_PROXY = Symbol();

type TReducedLocation = Pick<ILocation, "pathname" | "queries">;

export function getCustomExpressionScopeLocation(
  location: ILocation,
): TReducedLocation {
  const queries = Object.keys(location.queries).reduce((result, key) => {
    const value = parseQueryValue(location.queries[key]);
    return {
      ...result,
      [key]: value,
    };
  }, {});

  return {
    pathname: location.pathname,
    queries,
  };
}

function validateExpression<C extends t.Mixed>(
  value: string,
  props: IElementProps,
  getModule: GetModule,
  getCurrentLanguage: GetCurrentLanguage,
  element: IElement,
  fullLocation: ILocation,
  id: string,
  codec: C,
  context: t.Context,
) {
  type T = t.TypeOf<C>;

  const location = getCustomExpressionScopeLocation(fullLocation);

  const isFunction = isRight(codec.decode(() => null));

  if (!isFunction) {
    // see if the value can be cached
    // do not attempt this if the codec is a function
    // reason: the function body could use the elements - elements is a proxy
    // throwing an error on `get`
    // but as the function will be called by the element itself, the error
    // cannot be handled here
    try {
      const staticValue = getPropsOnlyValue(value, props, location);
      const result = codec.validate(staticValue, context);
      return isRight(result) ? t.success(() => staticValue as T) : result;
    } catch (error) {
      if (error !== USES_ELEMENTS) {
        throw error;
      }
    }
  }
  const funcBody = `'use strict'; return ${value}`;
  // const expressionFunc = new Function(...[...SCOPE_NAMES, funcBody]);
  const expressionFunc = new Function(
    "props",
    "element",
    "elements",
    "location",
    "i18n",
    funcBody,
  );
  return t.success((state: any) => {
    const elementModule = getModule(id);
    const elementProxy = elementModule
      ? createModuleProxy(state, elementModule)
      : EMPTY_MODULE_PROXY;
    const val = expressionFunc(
      props,
      elementProxy,
      createElementsProxy(state, getModule, fullLocation),
      location,
      createI18nProxy(state, element, getCurrentLanguage),
    );

    // check if val contains element module
    // this most likely happens while editing a custom expression
    // if not caught early, this can potentially lead to infinite loops
    if (containsModule(val)) {
      throw new Error("Value must not contain module.");
    }

    return getOrThrow(
      codec.validate(val, context),
      (errors: any) =>
        new Error(
          `Invalid config value in expression return value for ${getElementDisplay(
            element,
          )}:\n${failure(errors).join(
            "\n",
          )}\nThe whole config is:\n${JSON.stringify(element.config)}`,
        ),
    );
  });
}

const DETECT_ELEMENTS_USAGE = new Proxy(
  {},
  {
    get: () => {
      throw USES_ELEMENTS;
    },
  },
);

function getPropsOnlyValue(
  value: string,
  props: IElementProps,
  location: TReducedLocation,
) {
  const funcBody = `'use strict'; return ${value}`;
  return new Function(
    "props",
    "element",
    "elements",
    "location",
    "i18n",
    funcBody,
  )(
    props,
    DETECT_ELEMENTS_USAGE,
    DETECT_ELEMENTS_USAGE,
    location,
    DETECT_ELEMENTS_USAGE,
  );
}

function createElementsProxy(
  state: any,
  getModule: GetModule,
  location: ILocation,
) {
  return new Proxy(
    {},
    {
      get: (_, prop) => {
        if (prop === Symbol.toStringTag) {
          return () => "Elements";
        }
        if (prop === "toJSON") {
          return () => "{}";
        }
        if (prop === "length") {
          return 0;
        }
        const module = getModule(prop.toString(), { location });
        return module ? createModuleProxy(state, module) : EMPTY_MODULE_PROXY;
      },
    },
  );
}

const EMPTY_MODULE_PROXY = new Proxy(
  {},
  {
    // TODO: make modules have a reference to the element, so we can say the module's element ID in the exception
    get: (_, prop) => {
      throw new Error(
        `Cannot read selector "${prop.toString()}". Module has no selectors`,
      );
    },
  },
);

function createModuleProxy(state: any, module: IReduxModule) {
  // TODO: make modules have a reference to the element, so we can say the module's element ID in the exception
  const keys = Object.keys(module.selectors || {});
  return new Proxy(
    {},
    {
      get: (_, prop) => {
        if (prop === Symbol.toStringTag) {
          return () => "Module";
        }
        if (prop === "toJSON") {
          return () => "{}";
        }
        if (prop === "length") {
          return Object.keys(module.selectors || {}).length;
        }
        if (prop === IS_MODULE_PROXY) {
          return true;
        }
        const selector = (module.selectors || {})[prop.toString()];
        if (!selector) {
          const didYouMean = getDidYouMean(
            Object.keys(module.selectors || {}),
            prop.toString(),
          );
          throw new Error(
            `Module has no selector named "${prop.toString()}".${didYouMean}`,
          );
        }
        return selector(state);
      },
      ownKeys: () => keys,
    },
  );
}

function createI18nProxy<Key extends keyof any>(
  state: any,
  element: IElementModel<any, any, Key>,
  getCurrentLanguage: GetCurrentLanguage,
) {
  if (!Object.keys(element.i18n).length) {
    return EMPTY_I18N_PROXY;
  }
  return new Proxy({}, {
    get: (_, prop: Key) => {
      if (prop === Symbol.toStringTag) {
        return () => "I18N";
      }
      if (prop === "toJSON") {
        return () => "{}";
      }
      if (prop === "length") {
        return Object.keys(element.i18n || {}).length;
      }
      const currentLanguage = getCurrentLanguage(state);
      const value = getTranslatedText(currentLanguage, element.i18n, prop);
      if (value === undefined) {
        const didYouMean = getDidYouMean(
          Object.keys(element.i18n[DEFAULT_LANGUAGE_CODE] || {}),
          prop.toString(),
        );
        throw new Error(
          `Element has no translation key "${prop.toString()}".${didYouMean}`,
        );
      }
      return value;
    },
  } as ProxyHandler<{}>);
}

const EMPTY_I18N_PROXY = new Proxy(
  {},
  {
    get: (_, prop) => {
      throw new Error(
        `Cannot read i18n key "${prop.toString()}". Element has no translations`,
      );
    },
  },
);

/**
 * check if a value contains an element module
 *
 * does not support recursive data structures!
 *
 * TODO check if this works fine for all cases
 */
function containsModule(value: unknown): boolean {
  if (typeof value !== "object" || value === null) {
    return false;
  } else {
    if (Array.isArray(value)) {
      return value.some(containsModule);
    } else {
      if (value[IS_MODULE_PROXY]) {
        return true;
      } else {
        return Object.values(value).some(containsModule);
      }
    }
  }
}
