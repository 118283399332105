// createUserRole

import { UseMutationOptions, UseQueryOptions } from "react-query";

import AdminService from "services/admin";
import { Role } from "./types";
import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "../utils";
import { QueryKeys } from "./queryKeys";

const services = AdminService.getInstance();

export const fetchRoles: Query<Role[]> = {
  queryKey: QueryKeys.fetchRoles,
  queryFn: services.getAllRoles,
};

export const useRoles = <SelectorResult = Role[]>(
  options?: UseQueryOptions<Role[], Error, SelectorResult>,
) => useAuthenticatedQuery(fetchRoles, undefined, options);

export const useCreateUserRole = (
  options: UseMutationOptions<{ name: string }, unknown, { name: string }>,
) => useAuthenticatedMutation(services.createUserRole, options);
