import React, { memo } from "react";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

import { selectors as routerSelectors } from "core/router/reduxModule";
import { Form } from "./components/Form";
import BackButton from "elementTypes/common/BackButton";
import { useRoles, useUser } from "../../../../../../queries/admin";
import { getRoleOptions } from "../../../../../../queries/admin/utils";

export const EditUsersPage = memo(() => {
  const { userId } = useSelector(routerSelectors.params);

  const { data: roleOptions } = useRoles({
    select: getRoleOptions,
  });

  const user = useUser({ userId }, { enabled: !!userId });
  return (
    <>
      <Box display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon href="/admin/users" />
        <Typography variant="h5">Editing - {user?.data?.email}</Typography>
      </Box>
      {user?.data && roleOptions && (
        <Form roleOptions={roleOptions} user={user?.data} />
      )}
    </>
  );
});
