import React, { memo } from "react";

import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import BackButton from "elementTypes/common/BackButton";
import { Form } from "./components/Form";
import { useLoginConfig } from "../../../../../../queries/admin";
import { LoadingComponent } from "../../../../../../layouts/common/Loading";

export const EditSettingsPage = memo(() => {
  const { data: config, isLoading } = useLoginConfig();

  return (
    <>
      <Box display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon href="/admin/settings" />
        <Typography variant="h5">Editing</Typography>
      </Box>
      {isLoading && <LoadingComponent />}
      {config && <Form config={config} />}
    </>
  );
});
