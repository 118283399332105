import request from "superagent";

import { FILE_STORAGE_PREFIX } from "services/api/constants";
import { getResponseError, withAuthHeader, withCommonHeaders } from "utils/api";
import { UICreateAPIData } from "staticPages/admin/pages/apps/pages/create/types";
import { IUIGenerateForm } from "staticPages/admin/pages/apps/pages/generate/types";
import { FileForm } from "staticPages/admin/pages/files/pages/upload/types";
import { UserForm } from "staticPages/admin/pages/users/pages/create/types";
import { EditUserForm } from "staticPages/admin/pages/users/pages/edit/types";
import { Definition, Translation } from "core/types";
import { SettingsConfig } from "staticPages/admin/pages/settings/types";
import { IUIEditForm } from "staticPages/admin/pages/apps/pages/edit/types";
import { LDAPData } from "staticPages/admin/pages/ldap/types";
import {
  CreateStateParams,
  CreateStateTransitionParams,
  DeleteStateParams,
  DeleteStateTransitionParams,
  UpdateStateParams,
  UpdateStateTransitionParams,
  WorkflowActivationParams,
} from "staticPages/admin/pages/modelBuilder/workflow/types";
import {
  HistoryTrackingSwitchParams,
  WorkflowSetupParams,
} from "staticPages/admin/pages/modelBuilder/erd/types";
import { DeleteQueryParams } from "staticPages/admin/pages/modelBuilder/components/types";

const LDAP_CONFIG_URL = "/app/auth/config/ldap";
export default class AdminService {
  private static _instance: AdminService;

  public static getInstance(): AdminService {
    if (!AdminService._instance) {
      AdminService._instance = new AdminService();
    }

    return AdminService._instance;
  }

  public async getAllUi(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/ui/admin/list"),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async generateUi(token: string, uiData: IUIGenerateForm) {
    try {
      const data = {
        ...uiData,
        objectViews: uiData.objectViews.map((v) => ({ id: v })),
      };

      const { body } = await withCommonHeaders(
        request.post("/app/ui/generate").send(data),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createUi(token: string, uiData: UICreateAPIData) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/ui").send(uiData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async restoreUi(
    uiName: string,
    savePointId: string,
    { token }: { token?: string } = {},
  ) {
    try {
      const res = await withCommonHeaders(
        request
          .post(`/app/ui/${uiName}/save/${savePointId}/restore`)
          .send({ uiName, savePointId }),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async saveUi(
    name: string,
    definition: Definition,
    description: string,
    { token }: { token?: string } = {},
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/ui/${name}/save`).send({ definition, description }),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editUi(
    token: string,
    { name, editData }: { name: string; editData: IUIEditForm },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.put(`/app/ui/${name}`).send(editData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async releaseUi(
    uiName: string,
    data: { name: string; description: string },
    { token }: { token?: string } = {},
  ) {
    try {
      const res = await withCommonHeaders(
        request.post(`/app/ui/${uiName}/release`).send(data),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteUI(token: string, { uiName }: { uiName: string }) {
    try {
      const res = await withCommonHeaders(
        request.delete(`/app/ui/${uiName}`),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async publishReleaseUi(
    uiName: string,
    releaseName: string,
    { token }: { token?: string } = {},
  ) {
    try {
      const res = await withCommonHeaders(
        request.post(`/app/ui/${uiName}/release/${releaseName}/publish`),
        token,
      );

      return res.body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllFiles(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/storage/file"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async uploadFile(token: string, fileData: FileForm) {
    try {
      let req = request.post("/app/storage/file");

      req = withAuthHeader(req, token);

      const { body } = await req
        .field("groupName", fileData.groupName)
        .field("typeGroupName", fileData.typeGroupName)
        .field("acl[]", fileData.acl ?? [])
        .attach("file", fileData.file);

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteFile(token: string, { fileName }: { fileName: string }) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`${FILE_STORAGE_PREFIX}${fileName}`),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFileGroups(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/storage/groups"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFileTypes(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/storage/types"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFilesByGroupType(
    token: string,
    { groupType }: { groupType: string },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/storage/groups/${groupType}/files`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getFunctionsName(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/ui/functions/list"),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllRoles(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/roles"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createUserRole(token: string, params: { name: string }) {
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/auth/role/${params.name}`).send(params),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getTableAudit(
    token: string,
    query: { schemaName: string; tableName: string },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/audit/table").query({ ...query }),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAuditTableList(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/audit/table/list"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getUserAudit(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/audit/user-events"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getUser(token: string, { userId }: { userId: string }) {
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/users/integrated/${userId}`),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getAllUsers(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/users/integrated"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async saveUser(token: string, user: UserForm) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/users/integrated").send(user),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editUser(
    token: string,
    params: { userId: string; user: EditUserForm },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.put(`/app/users/integrated/${params.userId}`).send(params.user),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteUser(token: string, { userId }: { userId: string }) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/users/integrated/${userId}`).send(),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editLoginConfig(token: string, config: SettingsConfig) {
    try {
      const { body } = await withCommonHeaders(
        request.patch("/app/auth/config/login").send(config),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getLDAPConfig(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get(LDAP_CONFIG_URL),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteLDAPConfig(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.delete(LDAP_CONFIG_URL),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async saveLDAPConfig(token: string, ldapData: LDAPData) {
    try {
      const { body } = await withCommonHeaders(
        request.put(LDAP_CONFIG_URL).send(ldapData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getQueries(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/admin/model/queries"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getModel(token: string) {
    try {
      const { body } = await withCommonHeaders(
        request.get("/app/admin/model"),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getColumnValues(
    token: string,
    params: {
      schema: string;
      table: string;
      column: string;
    },
  ) {
    const { schema, table, column } = params;
    try {
      const { body } = await withCommonHeaders(
        request.get(
          `/app/admin/model/${schema}/${table}/workflow/column/${column}`,
        ),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getStateWorkflow(
    token: string,
    params: {
      schema: string;
      table: string;
    },
  ) {
    const { schema, table } = params;
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/${schema}/${table}/workflow/graph`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getStateNode(
    token: string,
    params: {
      id: number;
    },
  ) {
    const { id } = params;
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/workflow/state/${id}`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createSimpleQuery(
    token: string,
    params: {
      schema: string;
      table: string;
      data: {
        viewName: string;
        permissions: { grantee: string; privileges: string[] }[];
        i18n: Translation<keyof any>;
      };
    },
  ) {
    const { schema, table, data } = params;
    try {
      const { body } = await withCommonHeaders(
        request.post(`/app/admin/model/query/${schema}/${table}`).send(data),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getStateTransition(
    token: string,
    params: {
      transitionId: string | undefined;
    },
  ) {
    const { transitionId } = params;
    if (!transitionId) {
      return;
    }
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/workflow/transition/${transitionId}`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createCustomQuery(
    token: string,
    data: {
      viewName: string;
      code: string;
      permissions: { grantee: string; privileges: string[] }[];
      i18n: Translation<keyof any>;
      identifyingColumn: string | null;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/model/query/custom").send(data),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async editCustomQuery(
    token: string,
    data: {
      viewName: string;
      code: string;
      // these are not supported yet
      // permissions: { grantee: string; privileges: string[] }[];
      i18n: Translation<"title">;
    },
  ) {
    try {
      const { body } = await withCommonHeaders(
        request.put("/app/admin/model/query/custom").send(data),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createStateTransition(
    token: string,
    stateTransitionParams: CreateStateTransitionParams,
  ) {
    const { schema, table, stateTransitionData } = stateTransitionParams;
    try {
      const { body } = await withCommonHeaders(
        request
          .post(`/app/admin/model/${schema}/${table}/workflow/transition`)
          .send(stateTransitionData),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async createState(token: string, params: CreateStateParams) {
    const { schema, table, stateData } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .post(`/app/admin/model/${schema}/${table}/workflow/state`)
          .send(stateData),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async updateState(token: string, params: UpdateStateParams) {
    const { id, stateData } = params;

    try {
      const { body } = await withCommonHeaders(
        request.put(`/app/admin/model/workflow/state/${id}`).send(stateData),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteState(token: string, params: DeleteStateParams) {
    const { id } = params;
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/model/workflow/state/${id}`).send(),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async updateStateTransition(
    token: string,
    params: UpdateStateTransitionParams,
  ) {
    const { transitionId, schema, table, stateTransitionData } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .put(
            `/app/admin/model/${schema}/${table}/workflow/transition/${transitionId}`,
          )
          .send(stateTransitionData),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async workflowSetup(token: string, params: WorkflowSetupParams) {
    const { schema, table, workflowSetupAPIData } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .post(`/app/admin/model/${schema}/${table}/workflow`)
          .send(workflowSetupAPIData),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async workflowActivationSwitch(
    token: string,
    params: WorkflowActivationParams,
  ) {
    const { schema, table, active } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .put(`/app/admin/model/${schema}/${table}/workflow/toggle`)
          .send({ active }),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async setColumAlias(
    token: string,
    params: {
      schema: string;
      table: string;
      column: string;
    },
  ) {
    const { schema, table, column } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .put(`/app/admin/model/${schema}/${table}/lookup`)
          .send({ column }),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteStateTransition(
    token: string,
    params: DeleteStateTransitionParams,
  ) {
    const { id } = params;
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/model/workflow/transition/${id}`).send(),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getCustomQuery(token: string, params: { viewName: string }) {
    const { viewName } = params;
    try {
      const { body } = await withCommonHeaders(
        request.get(`/app/admin/model/query/${viewName}`),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async testCustomQuery(
    token: string,
    params: {
      data: {
        viewName: string;
        code: string;
      };
    },
  ) {
    const { data } = params;
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/model/query/temp").send(data),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async historyTrackingSwitch(
    token: string,
    params: HistoryTrackingSwitchParams,
  ) {
    const { schema, table, active } = params;
    try {
      const { body } = await withCommonHeaders(
        request
          .put(`/app/admin/model/${schema}/${table}/historyTracker/toggle`)
          .send({ active }),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async getDataPreview(
    token: string,
    params: {
      data: {
        viewName: string;
        code: string;
      };
    },
  ) {
    const { data } = params;
    try {
      const { body } = await withCommonHeaders(
        request.post("/app/admin/model/query/preview").send(data),
        token,
      );

      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }

  public async deleteQuery(token: string, params: DeleteQueryParams) {
    const { name } = params;
    try {
      const { body } = await withCommonHeaders(
        request.delete(`/app/admin/model/query/${name}`).send(),
        token,
      );
      return body;
    } catch (err) {
      throw getResponseError(err);
    }
  }
}
