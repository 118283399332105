import React, {
  MouseEventHandler,
  createRef,
  memo,
  useCallback,
  useEffect,
} from "react";

import clsx from "classnames";

import { FixedSizeList, areEqual } from "react-window";
import { FlowElement, useStore, useZoomPanHelper } from "react-flow-renderer";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@material-ui/core";
import Icon from "@material-ui/icons/Timeline";

import { useWorkflowTranslations } from "../translation";
import { IFixedRow } from "core";
import IconButton from "elementTypes/common/IconButton";
import { useStyles } from "../../components/styles";
import { useDimensions } from "utils/hooks/useDimensions";
import { EmptyData } from "../../components/EmptyData";
import { usePrevious } from "utils/hooks";
import { getListWithIndex, useSelectedState } from "../utils";
import { NodeData } from "./StateViewer/types";
import { CreateNewStateBtn } from "../component";

type Props = {
  list: FlowElement<NodeData>[];
};

const ITEM_SIZE = 44;

export const WorkflowLandingPage = memo<Props>(({ list }) => {
  const listRef = createRef<FixedSizeList>();
  const translation = useWorkflowTranslations();
  const classes = useStyles();

  const { setSelectedElement } = useSelectedState();

  const reactFlowStore = useStore();
  const { setCenter } = useZoomPanHelper();

  const focusState = useCallback(
    (state: string) => () => {
      const { nodes } = reactFlowStore.getState();

      const node = nodes.find((n) => n.data && n.data.label === state);

      if (node) {
        const x = node.__rf.position.x + node.__rf.width / 2;
        const y = node.__rf.position.y + node.__rf.height / 2;
        const zoom = 1.8;

        setCenter(x, y, zoom);
      }
    },
    [reactFlowStore, setCenter],
  );

  const Row = memo<IFixedRow<FlowElement<any>>>(
    ({ data: items, index, style }) => {
      const item = items[index];
      const title = item.data?.label;

      const handleEdit: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        setSelectedElement(item);
      };

      return (
        <Box
          key={item.id}
          className={clsx(classes.row, { [classes.oddRow]: index % 2 })}
          style={style}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={focusState(title)}
        >
          <Box flex={1}>
            <Typography variant="button">{title}</Typography>
          </Box>
          <IconButton
            icon="edit"
            onClick={handleEdit}
            size="small"
            tooltip={translation.editStateTooltip}
          />
        </Box>
      );
    },
    areEqual,
  );

  const { ref: boxRef, ...size } = useDimensions();
  const listHeight = size?.height ?? ITEM_SIZE * Math.min(list.length ?? 0, 10);
  const prevList = usePrevious(list ?? []);

  useEffect(() => {
    if (list.length > prevList.length && listRef.current) {
      const scrollToQuery = getListWithIndex(prevList, list) as FlowElement<
        any
      > & { index: number };
      if (scrollToQuery) {
        listRef.current.scrollToItem(scrollToQuery.index);
      }
    }
  }, [list, prevList, listRef]);

  return (
    <Box
      display="grid"
      gridTemplateRows="1fr 2fr"
      gridGap={8}
      height="100%"
      component="aside"
    >
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translation.workflowLandingPageTitle}
          </Typography>

          <Typography gutterBottom variant="body2">
            {translation.landingPageParagraph1}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {translation.landingPageParagraph2}
          </Typography>

          <Typography variant="body2">
            {translation.landingPageParagraph3}
          </Typography>

          <Typography variant="body2">
            {translation.landingPageParagraph4}
          </Typography>
        </CardContent>
      </Card>
      <Card variant="outlined" className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          disableTypography
          title={
            <Typography variant="h5">{translation.stateListTitle}</Typography>
          }
          avatar={
            <Box display="flex">
              <Icon />
            </Box>
          }
          action={
            <Box mb={-2}>
              <CreateNewStateBtn isIcon />
            </Box>
          }
        />
        <Divider />
        {list.length ? (
          <Box
            {...{ ref: boxRef }}
            height="100%"
            // fix height overflow when scrolling
            minHeight="0"
          >
            <FixedSizeList
              ref={listRef}
              height={listHeight}
              itemCount={list.length}
              itemSize={ITEM_SIZE}
              width="100%"
              itemData={list}
            >
              {Row}
            </FixedSizeList>
          </Box>
        ) : (
          <EmptyData title={translation.emptyStateList} />
        )}
      </Card>
    </Box>
  );
});
