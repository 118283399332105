import React, { memo } from "react";
import { useQueryClient } from "react-query";
import { FlowElement } from "react-flow-renderer";

import { getApiError } from "queries/utils";
import { useSessionContext, useTranslation } from "core/session";
import {
  useDeleteState,
  useStateNode,
  useUpdateState,
} from "queries/admin/modelBuilderData";
import { LoadingComponent } from "layouts/common/Loading";
import { QueryKeys } from "queries/admin";
import { StateForm } from "./StateForm";
import { StateUpdateAPIData } from "./types";
import { UIStateForm } from "./StateForm/types";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { useStateFormTranslations } from "./StateForm/translation";
import { DEFAULT_LANGUAGE_CODE } from "../../../../../../core";
import { useSelectedState } from "../utils";

const EditState = () => {
  const { selectedElement, setSelectedElement } = useSelectedState();

  const { id } = selectedElement as FlowElement;
  const { success, successDelete } = useStateFormTranslations();
  const showSnackbar = useSnackbar();
  const {
    language: { code: languageCode },
  } = useSessionContext();
  const queryClient = useQueryClient();
  const { mutate } = useUpdateState({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: () => {
      showSnackbar(success, "success");
      queryClient.invalidateQueries(QueryKeys.fetchWorkflow);
      queryClient.invalidateQueries(QueryKeys.fetchStateNode);
      setSelectedElement(null);
    },
  });
  const deleteState = useDeleteState({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.fetchWorkflow);
      showSnackbar(successDelete, "success");
      setSelectedElement(null);
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const stateNodeResponse = useStateNode({
    id: parseInt(id, 10),
  });

  const stateNode = stateNodeResponse.data;

  const translation = useTranslation(stateNode?.i18n);

  if (!stateNode) {
    return null;
  }

  const { id: stateNodeId, state, stateChangesFound, acl } = stateNode;

  const { title, longDescription } = translation;

  if (stateNodeResponse.isLoading) {
    return <LoadingComponent />;
  }

  const isEditable = Boolean(acl);

  const node: UIStateForm = {
    id: stateNodeId,
    state,
    title,
    longDescription,
    isEditable,
    acl,
  } as UIStateForm;

  const onSubmit = (formData: Record<string, unknown> | UIStateForm) => {
    const { ...data } = formData as UIStateForm;

    const text = {
      title: data.title.trim(),
      longDescription: data.longDescription?.trim(),
    };

    const payload: StateUpdateAPIData = {
      i18n: {
        [languageCode]: text,
        [DEFAULT_LANGUAGE_CODE]: text,
      },
      acl: data.isEditable ? data.acl ?? [] : null,
    };

    mutate({
      id: parseInt(id, 10),
      stateData: payload,
    });
  };

  const onDelete = () => {
    const stateId = node.id;
    deleteState.mutate({ id: String(stateId) });
  };

  return (
    <StateForm
      key={node.id}
      node={node}
      onSubmit={onSubmit}
      onDelete={onDelete}
      stateChangesFound={stateChangesFound}
    />
  );
};

export default memo(EditState);
