import { buildValue } from "../controlledReduxModule/selectors";
import { DateTimeInputConfig } from "../../types";

export const uncontrolledSelectors = (config: DateTimeInputConfig) => ({
  value: (valueSelector: (state: any) => string | Date | null) => (
    state: any,
  ) => buildValue(valueSelector(state)),
  minDate: (state: any) =>
    config.minDate ? buildValue(config.minDate(state)) : null,
  maxDate: (state: any) =>
    config.maxDate ? buildValue(config.maxDate(state)) : null,
  timezone: (state: any) => (config.timezone ? config.timezone(state) : null),
  formatString: (state: any) => config.formatString?.(state) ?? undefined,
});
