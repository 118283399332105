import React, { memo, useCallback, useMemo } from "react";
import { FixedSizeList, areEqual } from "react-window";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { Section, useObjectViewList } from "core/editor";
import { useSessionContext } from "core/session";
import { getTranslatedText } from "core/utils/element";
import { IFixedRow, IObjectViewField } from "core/types";
import { MuiIcon } from "elementTypes/common/MuiIcon";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { BarChartConfig } from "../../types";
import { useChartEditorTranslation } from "../../translation";
import { useElementEditorContext } from "../../../../core/editor/EditorLayout/components/ElementEditorContext";

type IRow = IFixedRow<IObjectViewField>;

export const SortComponent = memo(() => {
  const {
    elementModel: {
      config: {
        sort = [],
        dataSource: { viewName },
      },
    },
    changeConfigValue,
  } = useElementEditorContext<BarChartConfig>();
  const translation = useChartEditorTranslation();
  const { language } = useSessionContext();
  const { getViewByName } = useObjectViewList();
  const fields = useMemo(() => getViewByName(viewName)?.fields ?? [], [
    getViewByName,
    viewName,
  ]);

  const itemSize = 48;

  const handleChange = useCallback(
    (prop, newValue) => changeConfigValue(prop, newValue),
    [changeConfigValue],
  );

  const handleChangeSort = useCallback(
    (name: string) => (_: any, value: "asc" | "desc" | null) => {
      const existing = sort.find(
        (existingColumn) => existingColumn.fieldName === name,
      );
      const nextSort = value
        ? existing
          ? sort.map((item) =>
              item.fieldName === name
                ? { ...item, asc: value === "asc" }
                : item,
            )
          : [...sort, { fieldName: name, asc: value === "asc", hidden: true }]
        : sort.filter((sorted) => sorted.fieldName !== name);

      handleChange("sort", nextSort);
    },
    [sort, handleChange],
  );

  const Row = memo<IRow>(({ data, index, style }) => {
    const { name, i18n } = data[index];
    const sortedColumn = sort?.find((sorted) => sorted.fieldName === name);

    return (
      <ListItem key={name} divider={true} style={style}>
        <Tooltip title={name} placement="left-start">
          <ListItemText
            primary={getTranslatedText(language, i18n, "title")}
            primaryTypographyProps={{ display: "block", variant: "body1" }}
          />
        </Tooltip>
        <ListItemIcon>
          <ToggleButtonGroup
            exclusive
            value={
              sortedColumn?.asc
                ? "asc"
                : sortedColumn?.asc === false
                ? "desc"
                : undefined
            }
            onChange={handleChangeSort(name)}
            aria-label="Sort"
            size="small"
          >
            <ToggleButton
              value="asc"
              aria-label="asc"
              tooltip={translation.ascendantTooltip}
            >
              <MuiIcon icon="arrow_downward" />
            </ToggleButton>
            <ToggleButton
              value="desc"
              aria-label="desc"
              tooltip={translation.descendantTooltip}
            >
              <MuiIcon icon="arrow_upward" />
            </ToggleButton>
          </ToggleButtonGroup>
        </ListItemIcon>
      </ListItem>
    );
  }, areEqual);

  if (!fields.length) {
    return null;
  }

  return (
    <Section title={translation.sortTitle} defaultOpened={!!sort?.length}>
      <FixedSizeList
        height={itemSize * Math.min(fields.length, 5)}
        itemCount={fields!.length}
        itemSize={itemSize}
        width="100%"
        itemData={fields}
      >
        {Row}
      </FixedSizeList>
    </Section>
  );
});
