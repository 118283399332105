import { createUseTranslation } from "core/session/translation";

const stateTransitionTranslations = {
  en: {
    createStateTransition: "Create State Transition",
    editStateTransition: "Edit State Transition",
    save: "Save",
    cancel: "Cancel",
    permissionsLabelField: "Roles",
    preStateLabelField: "Pre State",
    postStateLabelField: "Post State",
    aclLabelField: "Roles",
    titleLabelField: "Title",
    descriptionLabelField: "Description",
    success: "The changes were saved successfully",
    everybodyToggleTitle: "Everybody",
    selectedToggleTitle: "Selected Roles",
    everybodyTooltip: "Everybody can perform the transition",
    selectedTooltip: "Only selected roles can perform the transition",
    formLabelContent: "Can be performed by",
    successDelete: "Delete Transition successfully",
    deleteTransition: "Delete Transition",
    startState: "Start State",
    endState: "End State",
    fromStateTooltip: "From State",
    toStateTooltip: "To State",
  },
  es: {
    createStateTransition: "Crear transición de estado",
    editStateTransition: "Editar transición de estado",
    save: "Guardar",
    cancel: "Cancelar",
    preStateLabelField: "Estado Anterior",
    postStateLabelField: "Estado Posterior",
    aclLabelField: "Roles",
    titleLabelField: "Título",
    descriptionLabelField: "Descripción",
    success: "Los cambios se guardaron de manera satisfactoria",
  },
};

export const useStateTransitionTranslations = createUseTranslation(
  stateTransitionTranslations,
);
