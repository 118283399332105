import React, { memo, useEffect } from "react";

import { useRoles } from "queries/admin";
import { LoadingComponent } from "../../../../../../../layouts/common/Loading";
import {
  BaseAutocomplete,
  IAutocompleteValue,
  getValueObject,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";
import { useStateTransitionTranslations } from "./translation";
import { getRoleOptions } from "../../../../../../../queries/admin/utils";

interface PermissionFieldsProps {
  value: string[];
  onChange: (acl: string[]) => void;
  disable?: boolean;
}

const PermissionFields = ({
  value = [],
  onChange,
  disable,
}: PermissionFieldsProps) => {
  const translation = useStateTransitionTranslations();
  const { data: roleOptions, isLoading } = useRoles({
    select: getRoleOptions,
  });

  useEffect(() => {
    if (disable && value.length) {
      onChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable, onChange]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!roleOptions) {
    return null;
  }

  const handleChange = (newValue: IAutocompleteValue) => {
    onChange(newValue as string[]);
  };

  const rolesOptionsObject = (
    (value as any[]) || []
  ).map((val: string | number) => getValueObject(roleOptions, val));

  return (
    <BaseAutocomplete
      isMulti={true}
      options={roleOptions}
      valueObject={rolesOptionsObject as SelectOption | SelectOption[]}
      name={translation.permissionsLabelField}
      label={translation.permissionsLabelField}
      onChange={handleChange}
      isLoading={false}
      disabled={disable}
    />
  );
};

export default memo(PermissionFields);
