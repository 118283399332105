import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Provider } from "react-redux";
import { QueryClientProvider } from "react-query";
import CssBaseline from "@material-ui/core/CssBaseline";

import { SnackbarProvider } from "notistack";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import enLocale from "date-fns/locale/en-US";
import { IntlProvider } from "react-intl";

import {
  EditorLayout,
  Layout,
  Notifier,
  buildElementTypeGetter,
  buildLayoutGetter,
  buildStore,
  compileElementTypes,
  createRouter,
} from "./core";
import { SessionProvider } from "./core/session";
import { elementTypes as rawElementTypes } from "./elementTypes";
import { layouts } from "./layouts";
import { buildServices } from "./services";
import { routes as staticRoutes } from "./staticPages";

import "typeface-roboto";

/* TODO:
 * Decide how to handle themes. Maybe this explicit imports should be done by the dynamically chosen Layout.
 * The thing is that the core's Router needs these elements. So they must be either explicitly provided (in
 * which case the `<Router>` component should be rendered by each layout) or provided via a context (in
 * which case the core must provide a ThemeContext and each layout must provide it)
 */

import { RouterError } from "./layouts/common/RouterError";
import { RouterLoading } from "./layouts/common/RouterLoading";
import { RouterNotFound } from "./layouts/common/RouterNotFound";
import {
  SNACKBAR_ANCHOR_ORIGIN,
  SnackbarDefaultAction,
} from "./layouts/common/Snackbar";
import ThemeContainer from "./layouts/common/ThemeContainer";
import { queryClient } from "./queryClient";
import { ElementTypesProvider } from "core/ElementTypesContext";

const elementTypes = compileElementTypes(rawElementTypes);
const getElementType = buildElementTypeGetter(elementTypes);
const getLayout = buildLayoutGetter(layouts);
const services = buildServices();
const { Router, service: routerService } = createRouter(staticRoutes);

const store = buildStore(getElementType, routerService, {
  services,
});
const locale = navigator.language;

const App = memo(() => {
  const [currentLocale, setLocale] = useState(enLocale);

  const fetchLocale = async (loc: string) => {
    try {
      const dynamicLocale = await import("date-fns/locale");
      return dynamicLocale[loc];
    } catch (error) {
      return null;
    }
  };

  const updateLocale = useCallback(async () => {
    try {
      for (const language of navigator.languages) {
        const existingLocale = await fetchLocale(language);
        if (existingLocale) {
          setLocale(existingLocale);
          break;
        }
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    updateLocale();
  }, [updateLocale]);

  const availableElementTypes = useMemo(
    () =>
      Object.keys(elementTypes).reduce((a, n) => {
        const elementType = elementTypes[n];
        return elementType.defaultElement &&
          elementType.editable !== false &&
          elementType.name !== "default_grid"
          ? { ...a, [n]: elementType }
          : a;
      }, {}),
    [],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <SessionProvider>
          <IntlProvider locale={locale}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={currentLocale}
            >
              <ThemeContainer>
                <CssBaseline />
                <SnackbarProvider
                  maxSnack={5}
                  dense={true}
                  anchorOrigin={SNACKBAR_ANCHOR_ORIGIN}
                  preventDuplicate={true}
                  action={SnackbarDefaultAction}
                  data-testid="snackbar"
                >
                  <>
                    <ElementTypesProvider
                      value={{
                        getElementType,
                        getLayout,
                        elementTypes,
                        availableElementTypes,
                      }}
                    >
                      <EditorLayout>
                        <Layout>
                          <Router
                            errorComponent={RouterError}
                            notFoundComponent={RouterNotFound}
                            loadingComponent={RouterLoading}
                          />
                        </Layout>
                      </EditorLayout>
                    </ElementTypesProvider>
                    <Notifier />
                  </>
                </SnackbarProvider>
              </ThemeContainer>
            </MuiPickersUtilsProvider>
          </IntlProvider>
        </SessionProvider>
      </Provider>
    </QueryClientProvider>
  );
});

export default App;
