import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { UseMutationResult } from "react-query";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { selectors as sessionSelectors } from "core/session/reduxModule";
import Button from "elementTypes/common/Button";
import Link from "elementTypes/common/Link";
import { User } from "./types";
import useStyles from "../../styles";
import { Table, TableRow } from "../../common";

import IconButton from "elementTypes/common/IconButton";
import { useDeleteUser, useUsers } from "../../../../queries/admin";
import { getApiError } from "../../../../queries/utils";
import { LoadingComponent } from "../../../../layouts/common/Loading";
import { useSnackbar } from "../../../../utils/hooks/useSnackbar";

interface RowProps {
  user: User;
  deleteUser: UseMutationResult<unknown, unknown, { userId: string }>;
  isCurrentUser: boolean;
}

const Row = memo<RowProps>(({ user, deleteUser, isCurrentUser }) => {
  const onDeleteClick = () => {
    deleteUser.mutate({ userId: user.id });
  };

  return (
    <TableRow rowId={user.id}>
      <Link
        variant="body2"
        title="View user info"
        href={`/admin/users/view/${user.id}`}
      >
        {user.email}
      </Link>
      <Typography>{user.userName}</Typography>
      <Typography>{user.role}</Typography>
      <Typography>{user.language}</Typography>
      <Checkbox disabled checked={user.isAdmin} />
      <Checkbox disabled checked={user.isActive} />
      <>
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            icon="create"
            color="primary"
            href={`/admin/users/edit/${user.id}`}
          />
          <IconButton
            icon="delete_outline"
            color="primary"
            onClick={onDeleteClick}
            disabled={isCurrentUser}
          />
        </Box>
      </>
    </TableRow>
  );
});

const titles = [
  "Email",
  "Username",
  "Role",
  "Language",
  "Admin",
  "Active",
  "Actions",
];

const headers = titles.map((title) => ({
  name: title.toLowerCase(),
  title,
}));

export const UsersPage = memo(() => {
  const additionalInformation = useSelector(
    sessionSelectors.additionalInformation,
  );
  const currentEmail =
    additionalInformation?.type === "integrated"
      ? additionalInformation.email
      : null;
  const showSnackbar = useSnackbar();
  const { data, refetch, isFetching, isLoading, error } = useUsers();

  const deleteUser = useDeleteUser({
    onSuccess: () => {
      showSnackbar("User deleted successfully", "success");
      refetch();
    },
    onError: (er) => {
      const apiError = getApiError(er);
      showSnackbar(`User could not be deleted: ${apiError}`, "error");
    },
  });

  const rows = useMemo(
    () =>
      (data ?? []).map((user) => (
        <Row
          key={user.id}
          user={user}
          deleteUser={deleteUser}
          isCurrentUser={currentEmail === user.email}
        />
      )),
    [data, currentEmail, deleteUser],
  );
  const { horizontallyCenter } = useStyles();

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">Users</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: "inline-flex", justifyContent: "flex-end" }}
      >
        <Button
          color="primary"
          href="/admin/users/create"
          iconLeft="add"
          label="Create"
        />
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <Table
            rows={rows}
            headers={headers}
            onDataReload={refetch}
            loading={isFetching}
            error={error?.message}
          />
        )}
      </Grid>
    </Grid>
  );
});
