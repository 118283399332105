import { createUseTranslation } from "core/session/translation";

export const textInputEditorTranslation = {
  en: {
    startAdornment: "Start Adornment",
    endAdornment: "End Adornment",
    iconLabel: "Icon",
    textLabel: "Text",
    applyTitle: "Apply to",
    styleTitle: "Styling",
    translationLabel: "Translation",
    multilineInputLabel: "Amount of visible lines",
    enableMultiline: "Enable multiline",
    regexError: "Value does not match the required pattern",
  },
};

export const useTextInputEditorTranslation = createUseTranslation(
  textInputEditorTranslation,
);
