import React, { ChangeEvent, MouseEvent, memo, useCallback } from "react";

import TextField from "@material-ui/core/TextField";
import { Box, FormLabel } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { buildCustomExpressionValue } from "core";
import { Section, useElementEditorContext } from "core/editor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { useDateTimeFieldEditorTranslation } from "../translation";
import { UntransformedDateTimeFieldConfig } from "../../types";

enum Format {
  date = "showDatePart",
  time = "showTimePart",
}

export const SetupComponent = memo(() => {
  const {
    elementModel: {
      id,
      config,
      config: { value, showDatePart = true, showTimePart = true, formatString },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeFieldConfig>();
  const translation = useDateTimeFieldEditorTranslation();

  const format =
    showDatePart && showTimePart
      ? [Format.date, Format.time]
      : showDatePart
      ? [Format.date]
      : [Format.time];

  const handleExpressionValueChange = useCallback(
    (newValue: string) => changeConfigValue("value", newValue),
    [changeConfigValue],
  );
  const customInputValue = ({
    value: inputValue,
    onChange: onIdentifierChange,
  }: {
    value: string;
    onChange: (value: string) => void;
  }) => {
    const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
      onIdentifierChange(e.target.value);

    return (
      <TextField
        disabled={!value}
        label={translation.valueLabel}
        value={inputValue}
        onChange={onCustomChange}
        fullWidth={true}
      />
    );
  };

  const handleFormatChange = (
    _event: MouseEvent<HTMLElement>,
    nexValue: [Format.time | Format.date],
  ) => {
    if (nexValue?.length) {
      for (const f of Object.values(Format)) {
        changeConfigValue(f as Format.time | Format.date, nexValue.includes(f));
      }
    }
  };

  const formats = Object.values(Format).map((f) => (
    <ToggleButton key={f} value={f} aria-label={f}>
      {translation[`${f}Label`]}
    </ToggleButton>
  ));

  const handleChangeFormatString = (event: ChangeEvent<HTMLInputElement>) => {
    const nextFormat = event.target.value.trim()
      ? event.target.value
      : undefined;

    changeConfigValue("formatString", nextFormat);
  };

  const handleChangeTimezone = useCallback(
    (newValue: string) => changeConfigValue("timezone", newValue),
    [changeConfigValue],
  );

  return (
    <Section title={translation.valueLabel} wrapped={true}>
      <TableColumnEditor
        id={id}
        value={value}
        onChange={handleExpressionValueChange}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["date", "dateTime", "time"]}
      />
      <CustomExpressionEditor
        value={value as any}
        config={config}
        onChange={handleExpressionValueChange}
        label={translation.valueLabel}
        nonExpressionEditor={customInputValue}
        disableSwitcher={false}
        switcherDisabled={!value}
      />
      <Box my={0.5} gridGap={8} display="flex" alignItems="center">
        <FormLabel>{translation.formatLabel}</FormLabel>
        <ToggleButtonGroup
          size="small"
          value={format}
          onChange={handleFormatChange}
        >
          {formats}
        </ToggleButtonGroup>
      </Box>
      <TextField
        fullWidth
        value={formatString ?? ""}
        label={translation.formatStringLabel}
        onChange={handleChangeFormatString}
        placeholder="MM/dd/yyyy hh:mm a"
        helperText={translation.formatHelperText}
      />

      <CustomExpressionEditor
        label={"Timezone"}
        value={config.timezone ?? buildCustomExpressionValue("null")}
        config={config}
        onChange={handleChangeTimezone}
      />
    </Section>
  );
});
