import React, { ComponentProps, memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useQueryClient } from "react-query";

import { TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { Section, useElementEditorContext } from "core/editor";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import { UntransformedStorageImageConfig } from "../../types";
import { useEditorStorageImageTranslation } from "../translation";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import FileSelect from "./FileSelect";
import { UploadZone } from "./UploadImage";
import { useSaveFile } from "queries/admin/fileData";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { getApiError } from "queries/utils";
import {
  FileForm,
  FileResponse,
} from "staticPages/admin/pages/files/pages/upload/types";

import { QueryKeys } from "queries/admin";
import { selectors as sessionSelectors } from "core/session/reduxModule";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      id,
      config,
      config: { path, fullSizeOnClick, isUsingFileStorage },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedStorageImageConfig>();

  const queryClient = useQueryClient();

  const translation = useEditorStorageImageTranslation();
  const showSnackbar = useSnackbar();

  const uploadFile = useSaveFile({
    onSuccess: (data: FileResponse) => {
      showSnackbar(translation.saveSuccessMessage, "success");
      queryClient.invalidateQueries(QueryKeys.getFilesByGroupType);
      changeConfigValue("isUsingFileStorage", true);
      wrappedChangePath(data.fileName);
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(`${translation.saveErrorMessage} ${msg}`, "error");
    },
  });

  const wrappedChangePath = useCallback(
    (newValue: string) => {
      changeConfigValue("path", newValue);
    },
    [changeConfigValue],
  );

  const handleFullSizeChange = useCallback(
    () => changeConfigValue("fullSizeOnClick", !fullSizeOnClick),
    [changeConfigValue, fullSizeOnClick],
  );

  const handleUsingFileStorage = useCallback(
    () => changeConfigValue("isUsingFileStorage", !isUsingFileStorage),
    [changeConfigValue, isUsingFileStorage],
  );

  const appUser = useSelector(sessionSelectors.ui);
  const uiUser = appUser?.role ?? "";

  const pathNonExpressionEditor: ComponentProps<
    typeof CustomExpressionEditor
  >["nonExpressionEditor"] = useCallback(
    ({ value, onChange }) =>
      isUsingFileStorage ? (
        <FileSelect value={value} onChange={onChange} />
      ) : (
        <TextField
          data-cypress-selector="storage-image-non-expression-path"
          fullWidth={true}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
    [isUsingFileStorage],
  );

  const onChangeStatus = (file: IFileWithMeta) => {
    const data: FileForm = {
      groupName: "private",
      typeGroupName: "image",
      file: file.file,
      acl: [uiUser],
    };
    uploadFile.mutate(data);
  };

  return (
    <Section title={translation.configSectionTitle} wrapped={true}>
      <UploadZone onChange={onChangeStatus} />

      <TableColumnEditor
        id={id}
        value={path}
        onChange={wrappedChangePath}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text"]}
      />
      <CustomExpressionEditor
        value={path}
        config={config}
        onChange={wrappedChangePath}
        label="Path"
        nonExpressionEditor={pathNonExpressionEditor}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(isUsingFileStorage)}
            onChange={handleUsingFileStorage}
          />
        }
        label={translation.isUsingFileStorageLabel}
      />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(fullSizeOnClick)}
            onChange={handleFullSizeChange}
          />
        }
        label={translation.fullSizeLabel}
      />
    </Section>
  );
});
