import React, { memo } from "react";

import { DatePicker, DateTimePicker, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { ConnectedReduxModuleProps } from "core";
import { getDateValue, getTotalTimezoneOffset } from "utils/date";
import { ReduxModule } from "./reduxModule";
import { DateTimeInput } from "./types";
import { ASTERISK_SYMBOL } from "../common/utils";

const DefaultDateTimeInput = memo<
  ConnectedReduxModuleProps<ReduxModule, DateTimeInput>
>(
  ({
    value,
    element: {
      config: { nullable, showDatePart = true, showTimePart = true },
      i18n: { label },
    },
    changeValue,
    disabled,
    errors,
    minDate,
    maxDate,
    timezone,
    required,
    formatString,
  }) => {
    const handleChange = (date: MaterialUiPickersDate | null) => {
      if (date === null) {
        changeValue(null);
      } else {
        if (timezone === null) {
          changeValue(date.toISOString());
        } else {
          const dateWithRespectToTimezone = new Date(
            date.valueOf() - getTotalTimezoneOffset(date, timezone),
          );
          changeValue(dateWithRespectToTimezone.toISOString());
        }
      }
    };

    const errorProps = errors
      ? {
          error: true,
          helperText: errors,
        }
      : {};

    let actualValue = getDateValue(value);

    // `""` and `null` result in the picker not allowing changing the month and year
    // `undefined` works as expected, setting no restrictions
    const minDateOrUndefined = getDateValue(minDate) || undefined;
    const maxDateOrUndefined = getDateValue(maxDate) || undefined;

    if (actualValue !== null && timezone !== null) {
      const dateWithRespectToTimezone = new Date(
        actualValue.valueOf() + getTotalTimezoneOffset(actualValue, timezone),
      );

      actualValue = dateWithRespectToTimezone;
    }

    const inputLabel = (
      <>
        {label}
        {required && ASTERISK_SYMBOL}
      </>
    );

    if (showDatePart && showTimePart) {
      return (
        <DateTimePicker
          value={actualValue}
          label={inputLabel}
          onChange={handleChange}
          disabled={disabled}
          fullWidth={true}
          variant="dialog"
          clearable={nullable}
          minDate={minDateOrUndefined}
          maxDate={maxDateOrUndefined}
          format={formatString}
          {...errorProps}
        />
      );
    }

    if (showDatePart) {
      return (
        <DatePicker
          value={actualValue}
          label={inputLabel}
          onChange={handleChange}
          disabled={disabled}
          fullWidth={true}
          variant="dialog"
          clearable={nullable}
          minDate={minDateOrUndefined}
          maxDate={maxDateOrUndefined}
          format={formatString}
          {...errorProps}
        />
      );
    }

    return (
      <TimePicker
        value={actualValue}
        label={inputLabel}
        onChange={handleChange}
        disabled={disabled}
        fullWidth={true}
        variant="dialog"
        clearable={nullable}
        format={formatString}
        {...errorProps}
      />
    );
  },
);

export default DefaultDateTimeInput;
