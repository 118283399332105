import React, { memo } from "react";
import clsx from "classnames";

import { IElementComponentProps, RouterReduxModuleComponentProps } from "core";
import { getPushArguments } from "core/router";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { InternalLinkButton } from "./types";
import useStyles from "./styles";
import { MapStateToProps } from "./container";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";
import { Colors } from "elementTypes/common/StyledTypography";

type Props = IElementComponentProps<{}, InternalLinkButton> &
  Pick<RouterReduxModuleComponentProps, "push"> & {
    className?: string;
  } & MapStateToProps;

const DefaultInternalLinkButton = memo<Props>(
  ({
    element: {
      i18n: { label },
      config: {
        linkTo: { pageId },
        icon,
        fullWidth,
        size,
        variant,
      },
    },
    color = "default",
    params,
    pages,
    disabled,
    ...rest
  }) => {
    const { root } = useStyles();
    const page = pages[pageId];
    if (!page) {
      return <>{NON_BREAKING_SPACE}</>;
    }
    const pageUrl = getPushArguments(page, params)[0];

    const commonProps = {
      color: Colors[color],
      size,
      disabled,
    };

    return icon ? (
      <IconButton
        icon={icon}
        tooltip={label}
        {...(commonProps as any)}
        href={pageUrl}
      />
    ) : (
      <Button
        label={label}
        fullWidth={fullWidth}
        {...commonProps}
        className={clsx(root, rest.className)}
        variant={variant}
        href={pageUrl}
      />
    );
  },
);

DefaultInternalLinkButton.displayName = "DefaultInternalLinkButton";

export default DefaultInternalLinkButton;
