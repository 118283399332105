import React, { memo } from "react";
import {
  FieldValues,
  FormContext,
  UseFormOptions,
  useForm,
  useFormContext,
} from "react-hook-form";
import Box, { BoxProps } from "@material-ui/core/Box";

export type FormOptions = UseFormOptions<FieldValues>;

type FormProps = {
  children: any;
  onSubmit: (data: any) => void;
  defaultValues?: FieldValues;
  formOptions?: FormOptions;
  className?: string;
  boxProps?: BoxProps;
};

export const HookForm = memo<FormProps>(
  ({ defaultValues, children, onSubmit, className, formOptions, boxProps }) => {
    const methods = useForm({ defaultValues, ...formOptions });

    return (
      <FormContext {...methods}>
        <Box
          {...boxProps}
          className={className}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {children}
        </Box>
      </FormContext>
    );
  },
);

export function useHookFormContext() {
  const context = useFormContext();
  if (context === undefined) {
    throw new Error("useHookFormContext must be used within a FormContext");
  }
  return context;
}
