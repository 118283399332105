import React, { ChangeEvent, memo } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import { useTranslator } from "core/session/translation/createUseTranslation";
import { Translation } from "core/types/i18n";
import { TableMetadataStateChange } from "elementTypes/default_table/types";
import { useActionTranslation } from "../translation";
import { Props as FormProps } from "../container";
import { FormConfig, FormTypes } from "../types";

interface Props {
  handleSave: FormProps["save"];
  handleReset: FormProps["reset"];
  onStateChange: FormProps["setStateFieldValue"];
  goBack: () => void;
  isValid: boolean;
  hasChanges: boolean;
  loading: boolean;
  type: FormConfig["type"];
  allowedStateChanges: TableMetadataStateChange[];
  stateFieldValue: string | null;
  hideSaveAndStayButton?: boolean;
}

export const FormActions = memo<Props>(
  ({
    onStateChange,
    handleReset,
    handleSave: originalHandleSave,
    goBack,
    hasChanges,
    isValid,
    loading,
    type,
    allowedStateChanges,
    stateFieldValue,
    hideSaveAndStayButton,
  }) => {
    const translation = useActionTranslation();
    const { translate } = useTranslator();

    const handleSaveAndRedirect = () => originalHandleSave(true);
    const handleSave = () => originalHandleSave(false);

    const handleStateChange = (e: ChangeEvent<HTMLInputElement>) =>
      onStateChange(e.target.value);

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          xs
          spacing={2}
        >
          <Grid item>
            <Button
              data-testid="default_form_save_and_go_button"
              color="primary"
              variant="contained"
              disabled={!isValid || loading}
              onClick={handleSaveAndRedirect}
            >
              {translation.saveAndGo}
            </Button>
          </Grid>
          {type !== FormTypes.edit && !hideSaveAndStayButton && (
            <Grid item>
              <Button
                data-testid="default_form_save_button"
                color="primary"
                variant="contained"
                disabled={!isValid || loading}
                onClick={handleSave}
              >
                {translation.saveAndStay}
              </Button>
            </Grid>
          )}
          {type === FormTypes.create &&
            stateFieldValue &&
            allowedStateChanges.length > 1 && (
              <Grid item>
                <TextField
                  label={translation.stateFieldNameLabel}
                  select
                  onChange={handleStateChange}
                  value={stateFieldValue}
                  data-testid="state-picker"
                >
                  {allowedStateChanges.map(({ to, i18n }) => (
                    <MenuItem value={`${to}`} key={`${to}`}>
                      {translate(i18n as Translation<"short_desc">).short_desc}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          xs
          spacing={2}
        >
          <Grid item>
            <Button
              data-testid="default_form_reset_button"
              variant="outlined"
              disabled={!hasChanges || loading}
              onClick={handleReset}
            >
              {translation.reset}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              onClick={goBack}
              data-testid="default_form_back_button"
            >
              {translation.back}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  },
);
