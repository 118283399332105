import React, { memo } from "react";

import { ConnectedReduxModuleProps } from "core";
import { ColorInput as ColorInputComponent } from "../common/ColorInput";
import { ReduxModule } from "./reduxModule";
import { ColorInput } from "./types";
import { Box } from "@material-ui/core";
import { ASTERISK_SYMBOL } from "../common/utils";

const DefaultColorInput = memo<
  ConnectedReduxModuleProps<ReduxModule, ColorInput>
>(
  ({
    value,
    element: {
      i18n: { label },
      config: { placement = "start" },
    },
    changeValue,
    disabled,
    required,
  }) => {
    const inputLabel = label && (
      <Box whiteSpace="nowrap" component="span">
        {label}
        {required && ASTERISK_SYMBOL}
      </Box>
    );

    return (
      <ColorInputComponent
        value={value}
        changeValue={changeValue}
        disabled={disabled}
        placement={placement}
        required={required}
        label={inputLabel}
        fullHeight
      />
    );
  },
);

export default DefaultColorInput;
