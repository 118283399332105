import React, {
  ChangeEvent,
  ComponentType,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  cloneElement,
  forwardRef,
  lazy,
  memo,
} from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import { withLazyLoading } from "../../helpers/HOC/LazyLoading";

import { SelectOption } from "../types";
import { MultiAutocomplete, SingleAutocomplete } from "./index";
import { useStyles } from "./styles";

export const DEFAULT_ITEM_HEIGHT = 36;
const MAX_ITEM_HEIGHT = 48;

type ErrorProps = {
  optionsError?: string;
  errors?: any;
};

type BaseMultiProps = {
  allowSameValue?: boolean;
  isMulti?: boolean;
  withCheckbox?: boolean;
};

type ValueProps = {
  valueObject: SelectOption | SelectOption[] | string | null;
};

export type IAutocompleteValue =
  | string[]
  | string
  | boolean
  | number
  | number[]
  | null;

type GenericAutocompleteProps = AutocompleteProps<
  SelectOption,
  boolean,
  boolean,
  boolean
>;

type Variant = TextFieldProps["variant"];
type Size = GenericAutocompleteProps["size"];

export type BaseAutocompleteProps = {
  options: SelectOption[];
  name: string;
  label: string;
  onChange: (value: IAutocompleteValue, reason?: string) => void;
  changeTouched?: (value: boolean) => void;
  onInputChange?: (value: string) => void;
  isLoading: boolean;
  searchInputValue?: string;
  disabled?: boolean;
  isClearable?: boolean;
  autosuggestHighlight?: boolean;
  virtualizedList?: boolean;
  size?: Size;
  variant?: Variant;
  placeholder?: string;
  className?: string;
  customRenderOption?: GenericAutocompleteProps["renderOption"];
  defaultItemSize?: number;
  groupBy?: GenericAutocompleteProps["groupBy"];
  renderGroup?: GenericAutocompleteProps["renderGroup"];
  getOptionDisabled?: (opt: SelectOption) => boolean;
  /**
   * can only be used if isMulti is not `true`
   */
  startAdornment?: ReactNode;
  required?: boolean;
};

export type CommonProps = {
  Listbox: ComponentType<HTMLAttributes<HTMLElement>>;
  renderInput: (param: any) => ReactNode;
  debugMode: boolean;
  getOptionLabel: (opt: SelectOption) => string;
  highlightedOption: (opt: SelectOption, input: string) => ReactNode;
} & Pick<
  BaseAutocompleteProps,
  | "options"
  | "onInputChange"
  | "isLoading"
  | "searchInputValue"
  | "disabled"
  | "getOptionDisabled"
  | "isClearable"
  | "autosuggestHighlight"
  | "virtualizedList"
  | "size"
  | "customRenderOption"
  | "defaultItemSize"
  | "groupBy"
  | "renderGroup"
  | "className"
>;

export type BaseProps = BaseAutocompleteProps &
  ValueProps &
  ErrorProps &
  BaseMultiProps;

const ListboxComponent = withLazyLoading(
  lazy(() => import("./ListboxComponent")),
);

export const BaseAutocomplete = memo<BaseProps>(
  ({
    label,
    variant,
    name,
    placeholder,
    isMulti,
    isLoading,
    valueObject,
    optionsError,
    errors,
    allowSameValue,
    isClearable,
    onInputChange,
    searchInputValue,
    changeTouched,
    customRenderOption,
    defaultItemSize = DEFAULT_ITEM_HEIGHT,
    groupBy,
    renderGroup,
    startAdornment,
    required,
    getOptionDisabled,
    ...rest
  }) => {
    const { inputEndAdornment } = useStyles();
    const itemSize = rest.withCheckbox ? MAX_ITEM_HEIGHT : defaultItemSize;

    const Listbox = forwardRef<HTMLDivElement>((props, ref) => (
      <div ref={ref}>
        <ListboxComponent itemSize={itemSize} {...props} />
      </div>
    )) as ComponentType<HTMLAttributes<HTMLElement>>;

    const renderInput = (params: AutocompleteRenderInputParams) => {
      const handleInputChange = ({
        target: { value: searchValue },
      }: ChangeEvent<HTMLInputElement>) => onInputChange?.(searchValue ?? "");

      const handleTouchedChange = () => changeTouched?.(true);
      const helperText = (
        <>
          {optionsError}
          {errors && (
            <>
              {optionsError && <br />}
              {errors}
            </>
          )}
        </>
      );

      return (
        <TextField
          {...params}
          label={label}
          fullWidth={true}
          name={name}
          placeholder={placeholder}
          variant={variant}
          onChange={handleInputChange}
          onBlur={handleTouchedChange}
          margin="dense"
          error={Boolean(optionsError || errors)}
          helperText={optionsError || errors ? helperText : null}
          required={required}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: isMulti
              ? params.InputProps.startAdornment
              : startAdornment,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params?.InputProps?.endAdornment &&
                  !isLoading &&
                  cloneElement(params.InputProps.endAdornment as ReactElement, {
                    className: `${inputEndAdornment} ${
                      (params?.InputProps?.endAdornment as ReactElement)?.props
                        ?.className
                    }`,
                  })}
              </>
            ),
          }}
        />
      );
    };

    const debugMode = process.env.NODE_ENV === "development";

    const getOptionLabel = (option: SelectOption) => String(option.label);

    const highlightedOption = (option: SelectOption, inputValue: string) => {
      const matches = match(option.label, inputValue);
      const parts = parse(option.label, matches);
      return (
        <>
          {parts.map(({ highlight, text }, index) => (
            <Typography
              key={`${index}-${text}`}
              color={highlight ? "primary" : "initial"}
              component="pre"
              variant={highlight ? "subtitle2" : "body2"}
            >
              {text}
            </Typography>
          ))}
        </>
      );
    };

    const selectComponent = isMulti ? (
      <MultiAutocomplete
        {...rest}
        isLoading={isLoading}
        Listbox={Listbox}
        allowSameValue={allowSameValue}
        value={valueObject as SelectOption[]}
        renderInput={renderInput}
        searchInputValue={onInputChange ? searchInputValue : undefined}
        debugMode={debugMode}
        getOptionLabel={getOptionLabel}
        highlightedOption={highlightedOption}
        customRenderOption={customRenderOption}
        onInputChange={onInputChange}
        getOptionDisabled={getOptionDisabled}
      />
    ) : (
      <SingleAutocomplete
        {...rest}
        isLoading={isLoading}
        Listbox={Listbox}
        value={valueObject as SelectOption}
        renderInput={renderInput}
        searchInputValue={onInputChange ? searchInputValue : undefined}
        debugMode={debugMode}
        getOptionLabel={getOptionLabel}
        highlightedOption={highlightedOption}
        customRenderOption={customRenderOption}
        groupBy={groupBy}
        renderGroup={renderGroup}
        onInputChange={onInputChange}
        getOptionDisabled={getOptionDisabled}
        isClearable={isClearable}
      />
    );
    return selectComponent;
  },
);

BaseAutocomplete.displayName = "BaseAutocomplete";
