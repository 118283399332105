import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { actions as routerActions } from "core/router/reduxModule";
import history from "core/router/reduxModule/history";
import Button from "elementTypes/common/Button";
import IconButton, { Props as IconButtonProps } from "../IconButton";

type BackButtonProps = {
  className?: string;
  isIcon?: boolean;
  href?: string;
};

export const BackButton = memo<BackButtonProps>(
  ({ className, isIcon, href }) => {
    const dispatch = useDispatch();

    function handleBackButtonClick() {
      dispatch(href ? routerActions.push(href) : routerActions.goBack());
    }

    if (history.length <= 1) {
      return null;
    }

    const label = "Back";

    const props = {
      className,
      onClick: handleBackButtonClick,
      color: "secondary",
      ...(isIcon
        ? { icon: "arrow_back", tooltip: label }
        : { iconLeft: "arrow_back", label }),
    };

    return isIcon ? (
      <IconButton
        {...(props as IconButtonProps)}
        icon="arrow_back"
        tooltip={label}
      />
    ) : (
      <Button {...props} iconLeft="arrow_back" label={label} />
    );
  },
);
