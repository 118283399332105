import React, { ChangeEvent, memo, useCallback } from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import {
  Section,
  ViewAutocomplete,
  useElementEditorContext,
} from "core/editor";
import { AdvancedGoogleMapsConfig } from "../types";
import { Typography } from "@material-ui/core";

export const GoogleMapsEditor = memo(() => {
  const {
    elementModel: {
      config: { dataSource, defaultCenter, defaultZoom, key },
    },
    changeConfigValue,
  } = useElementEditorContext<AdvancedGoogleMapsConfig>();

  const changeDataSource = useCallback(
    (newDataSource: AdvancedGoogleMapsConfig["dataSource"]) =>
      changeConfigValue("dataSource", newDataSource),
    [changeConfigValue],
  );

  const { viewName, latColumnName, lngColumnName, placeName } = dataSource;

  const handleViewNameChange = (newViewName: string) =>
    changeDataSource({
      viewName: newViewName,
      latColumnName: "",
      lngColumnName: "",
      placeName: "",
    });

  const handleLatLongColumnNameChange = (columnKey: string, value: any) =>
    changeDataSource({
      ...dataSource,
      [columnKey as keyof typeof dataSource]: value,
    });

  const changeKey = useCallback(
    (newKey: AdvancedGoogleMapsConfig["key"]) =>
      changeConfigValue("key", newKey),
    [changeConfigValue],
  );

  const handleKeyChange = (e: ChangeEvent<HTMLInputElement>) =>
    changeKey(e.target.value);

  const changeDefaultZoom = useCallback(
    (newDefaultZoom: AdvancedGoogleMapsConfig["defaultZoom"]) =>
      changeConfigValue("defaultZoom", newDefaultZoom),
    [changeConfigValue],
  );

  const handleDefaultZoomChange = (e: ChangeEvent<HTMLInputElement>) =>
    changeDefaultZoom(Number(e.target.value));

  const changeDefaultCenter = useCallback(
    (newDefaultCenter: Partial<AdvancedGoogleMapsConfig["defaultCenter"]>) =>
      changeConfigValue("defaultCenter", {
        ...defaultCenter,
        ...newDefaultCenter,
      }),
    [changeConfigValue, defaultCenter],
  );

  const { lng, lat } = defaultCenter;

  const handleDefaultCenterLatChange = (e: ChangeEvent<HTMLInputElement>) =>
    changeDefaultCenter({
      lat: Number(e.target.value),
      lng,
    });

  const handleDefaultCenterLngChange = (e: ChangeEvent<HTMLInputElement>) =>
    changeDefaultCenter({
      lng: Number(e.target.value),
      lat,
    });

  const fields = [
    {
      label: "Longitude Column Name",
      value: lngColumnName,
      name: "lngColumnName",
    },
    {
      label: "Latitude Column Name",
      value: latColumnName,
      name: "latColumnName",
    },
    {
      label: "Place Name",
      value: placeName,
      name: "placeName",
    },
  ];

  return (
    <>
      <Section title="Data Source" wrapped={true}>
        <ViewAutocomplete
          viewValue={viewName}
          viewLabel="Query Name"
          onViewNameChange={handleViewNameChange}
          onViewFieldChange={handleLatLongColumnNameChange}
          fields={fields}
        />
      </Section>
      <Section title="Map" wrapped={true}>
        <TextField
          label="Google Maps API Key"
          value={key}
          onChange={handleKeyChange}
          fullWidth={true}
        />
        <TextField
          label="Default Zoom"
          type="number"
          value={defaultZoom}
          onChange={handleDefaultZoomChange}
          fullWidth={true}
        />
        <Typography align="center">Center coordinates</Typography>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6}>
            <TextField
              label="Longitude"
              type="number"
              value={lng}
              onChange={handleDefaultCenterLngChange}
              fullWidth={true}
            />
          </Grid>
          <Grid item={true} xs={6}>
            <TextField
              label="Latitude"
              type="number"
              value={lat}
              onChange={handleDefaultCenterLatChange}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Section>
    </>
  );
});
