import { createUseTranslation } from "core/session/translation";

const erdTranslations = {
  en: {
    actionCreateNodeTooltip: "Add Object",
    actionEditNodeTooltip: "Edit existing object",
    cancelTitle: "Close",
    columnSelectTooltip: "Select All in the Column",
    createQuerySuccessMsg: "Query Successfully Created",
    editQuerySuccessMsg: "Query Successfully Edited",
    createWorkflowLabel: "Create Workflow",
    defineKeyLookupsTitle: "Default Lookup for",
    disableHistory: "Disable History Tracking",
    enableHistory: "Enable History Tracking",
    foreignKeyTooltip: "Foreign Key",
    generateQueryTitle: "Generate Query",
    auditingEnabled: "Auditing was enabled",
    auditingDisabled: "Auditing was disabled",
    keyLookupSelectColumn: "Default Lookup column",
    createWorklowLabel: "Create Workflow",
    menuGenerateQuery: "Generate Query",
    menuWorkflowSetup: "Workflow",
    menuWorkflowViewer: "Workflow",
    menuKeyLookup: "Default Lookup",
    submitTitle: "Save",
    transitionsToAllLabel: "Transitions to all",
    workflowDialogTitle: "Workflow",
    workflowNone: "None",
    workflowSelectColumn: "Pick the table column that contain the states",
    workflowTooltip: "Workflow configured",
    customCreateQueryTitle: "Create Query",
    customEditQueryTitle: "Edit Query",
    queryStatementLabel: "Statement",
    userColumnTitle: "User",
    rowSelectTooltip: "Select All in the Row",
    basicQueryTabTitle: "Basic Query",
    setLookupColumn: "Default Lookup column set",
    primaryKeyTooltip: "Primary Key",
    notNullTooltip: "Not NULL",
    uniqueTooltip: "Unique",
    aliasTooltip: "Alias",
    queryTitleLabel: "Title",
    queryNameLabel: "Name",
    auditTableTitle: "Auditing",
    auditTableDisable: "Disable",
    auditTableEnable: "Enable",
  },
  es: {
    actionCreateNodeTooltip: "Agregar objeto",
    actionEditNodeTooltip: "Editar objeto asociado",
    cancelTitle: "Cerrar",
    createWorklowLabel: "Crear Workflow",
    menuGenerateQuery: "Generar Consulta",
    menuWorkflowSetup: "Configurar Workflow",
    menuWorkflowViewer: "Visualizador Workflow",
    submitTitle: "Guardar",
    transitionsToAllLabel: "Todas las transisiones posibles",
    workflowDialogTitle: "Workflow",
    workflowNone: "Ninguno",
    workflowSelectColumn: "Hacer click en la columna que contiene los estados",
    setLookupColumn: "Configurar columna Lookup por defecto",
    defineKeyLookupsTitle: "Lookup por defecto para",
    keyLookupSelectColumn: "Columna Lookup por defect",
    disableHistory: "Deshabilitar registro de cambios",
    enableHistory: "Habilitar registro de cambios",
    auditTableTitle: "Auditoria",
    auditTableDisable: "Disable",
    auditTableEnable: "Enable",
  },
};

export const useErdTranslation = createUseTranslation(erdTranslations);
