import React, { createRef, memo, useEffect } from "react";

import clsx from "classnames";

import { Box, Card, CardHeader, Divider, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import ReorderIcon from "@material-ui/icons/Reorder";

import { actions as routerActions } from "core/router/reduxModule";
import { FixedSizeList, areEqual } from "react-window";
import { getApiError } from "queries/utils";
import { IFixedRow } from "core";
import { QueryKeys } from "queries/admin";
import { QueryObject } from "./types";
import { useDataBaseTranslation } from "../translation";
import { useDeleteQuery } from "../../../../../queries/admin";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { useStyles } from "./styles";
import { useTranslation } from "core/session";
import IconButton from "elementTypes/common/IconButton";
import { useDimensions } from "../../../../../utils/hooks/useDimensions";
import { usePrevious } from "../../../../../utils/hooks";
import { EmptyData } from "./EmptyData";
import { getListWithIndex } from "../workflow/utils";

interface IProps {
  queries: QueryObject[];
}

const ITEM_SIZE = 44;

export const QueriesPanel = memo<IProps>(({ queries }) => {
  const listRef = createRef<FixedSizeList>();
  const classes = useStyles();
  const { successDelete } = useDataBaseTranslation();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();

  const handleOpenCustomQuery = () => {
    dispatch(routerActions.push("/admin/database/customQuery"));
  };

  const { mutate } = useDeleteQuery({
    onSuccess: () => {
      showSnackbar(successDelete, "success");
      queryClient.invalidateQueries(QueryKeys.fetchQueries);
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const handleDelete = (name: string) => {
    mutate({ name });
  };

  const translation = useDataBaseTranslation();

  const Row = memo<IFixedRow<QueryObject>>(({ data: items, index, style }) => {
    const item = items[index];
    const { title } = useTranslation(item.i18n);
    const onDelete = () => handleDelete(item.name);
    const onEdit = () => {
      dispatch(routerActions.push(`/admin/database/customQuery/${item.name}`));
    };
    return (
      <Box
        key={item.id}
        // We are using clsx instead of CSS :nth-child because there is probably a bug within the
        // virtualization of :nth-child, which makes the list flaky by scrolling -> Ticket CN-451 for more Info.
        className={clsx(classes.row, { [classes.oddRow]: index % 2 })}
        style={style}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flex={1}>
          <Typography>{title}</Typography>
        </Box>
        {!item.autogenerated && (
          <IconButton
            icon="edit"
            onClick={onEdit}
            size="small"
            tooltip="Edit Query"
          />
        )}
        <IconButton
          icon="delete_outline"
          onClick={onDelete}
          size="small"
          tooltip="Delete Query"
        />
      </Box>
    );
  }, areEqual);

  const { ref: boxRef, ...size } = useDimensions();
  const listHeight =
    size?.height ?? ITEM_SIZE * Math.min(queries.length ?? 0, 10);
  const prevQueries = usePrevious(queries ?? []);

  useEffect(() => {
    if (queries.length > prevQueries.length && listRef.current) {
      const scrollToQuery = getListWithIndex(
        prevQueries,
        queries,
      ) as QueryObject & { index: number };
      if (scrollToQuery) {
        listRef.current.scrollToItem(scrollToQuery.index);
      }
    }
  }, [queries, prevQueries, listRef]);

  return (
    <Card variant="outlined" className={clsx(classes.cardHeight, classes.card)}>
      <CardHeader
        disableTypography
        avatar={
          <Box display="flex">
            <ReorderIcon />
          </Box>
        }
        title={<Typography variant="h6">{translation.queries}</Typography>}
        classes={{
          root: classes.cardHeader,
          action: classes.cardHeaderAction,
        }}
        action={
          <IconButton
            onClick={handleOpenCustomQuery}
            icon="add"
            tooltip={translation.createQuery}
          />
        }
      />
      <Divider />
      {queries.length ? (
        <Box
          {...{ ref: boxRef }}
          height="100%"
          // fix height overflow when scrolling
          minHeight="0"
        >
          <FixedSizeList
            ref={listRef}
            height={listHeight}
            itemCount={queries.length}
            itemSize={ITEM_SIZE}
            width="100%"
            itemData={queries}
          >
            {Row}
          </FixedSizeList>
        </Box>
      ) : (
        <EmptyData title={"No queries found"} />
      )}
    </Card>
  );
});
