import React, { ChangeEvent, memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import { ColorSelector } from "core/editor/common";
import { Section, useElementEditorContext } from "core/editor";
import { UntransformedClipboardCopyButtonConfig } from "../../types";
import { useClipboardCopyButtonEditorTranslation } from "../translation";

enum Variants {
  contained = "contained",
  outlined = "outlined",
}

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { variant = Variants.outlined },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedClipboardCopyButtonConfig>();
  const translation = useClipboardCopyButtonEditorTranslation();

  const handleColorChange = (nextValue: string) => {
    changeConfigValue("color", nextValue);
  };

  const handleVariantChange = (_e: ChangeEvent, value: string) => {
    changeConfigValue("variant", value);
  };

  const variants = Object.keys(Variants).map((itemVariant) => (
    <FormControlLabel
      key={itemVariant}
      control={<Radio color="primary" />}
      label={translation[`${itemVariant}Label`]}
      value={itemVariant}
    />
  ));

  return (
    <Section title={translation.stylingSectionTitle} wrapped={true}>
      <ColorSelector config={config} onChange={handleColorChange} />
      <FormLabel>{translation.variantInputLabel}</FormLabel>
      <RadioGroup row={true} value={variant} onChange={handleVariantChange}>
        {variants}
      </RadioGroup>
    </Section>
  );
});
