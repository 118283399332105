import React, { memo } from "react";

import { Edge } from "react-flow-renderer";
import { useSelector } from "react-redux";

import { EdgeData } from "./StateViewer/types";
import { getApiError } from "queries/utils";
import { QueryKeys } from "queries/admin";
import { StateTransitionForm } from "./StateTransitionForm";
import { UIStateTransitionForm } from "./StateTransitionForm/types";
import { useQueryClient } from "react-query";
import { useSessionContext, useTranslation } from "core/session";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { selectors as routerSelectors } from "core/router/reduxModule";
import {
  useDeleteStateTransition,
  useStateTransition,
  useUpdateStateTransition,
} from "../../../../../../queries/admin";
import { useStateTransitionTranslations } from "./StateTransitionForm/translation";
import { LoadingComponent } from "../../../../../../layouts/common/Loading";
import { useSelectedState } from "../utils";

const EditStateTransition = () => {
  const { selectedElement, setSelectedElement } = useSelectedState();

  const element = selectedElement as Edge<EdgeData>;
  const { id: transitionId } = element;
  const stateTransitionResponse = useStateTransition({
    transitionId,
  });
  const { success, successDelete } = useStateTransitionTranslations();
  const showSnackbar = useSnackbar();
  const location = useSelector(routerSelectors.location);
  const queryClient = useQueryClient();
  const {
    language: { code: languageCode },
  } = useSessionContext();
  const { mutate } = useUpdateStateTransition({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: () => {
      showSnackbar(success, "success");
      queryClient.invalidateQueries(QueryKeys.fetchWorkflow);
      queryClient.invalidateQueries(QueryKeys.fetchStateTransition);
      setSelectedElement(null);
    },
  });

  const deleteTransition = useDeleteStateTransition({
    onSuccess: () => {
      showSnackbar(successDelete, "success");
      queryClient.invalidateQueries(QueryKeys.fetchWorkflow);
      setSelectedElement(null);
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const { title, shortDescription } = useTranslation(
    stateTransitionResponse?.data?.i18n,
  );

  if (stateTransitionResponse.isError) {
    // TODO handle errors correctly
    return null;
  }

  if (stateTransitionResponse.isLoading) {
    return <LoadingComponent />;
  }

  const {
    id: stateTransitionId,
    preState,
    postState,
    acl,
  } = stateTransitionResponse.data;

  const edge: UIStateTransitionForm = {
    id: stateTransitionId,
    preState,
    postState,
    acl,
    title,
    shortDescription,
  } as UIStateTransitionForm;

  const onSubmit = (data: Record<string, unknown>) => {
    const {
      queries: { schema, table },
    } = location;
    const translated = {
      [languageCode]: {
        title: data.title,
        shortDescription: data.shortDescription,
      },
    };
    const stateTransitionData = {
      acl: (data.acl ?? []) as string[],
      i18n: translated,
    };
    mutate({
      transitionId: stateTransitionId,
      schema,
      table,
      stateTransitionData,
    });
  };

  const onDeleteTransition = () => {
    deleteTransition.mutate({ id: stateTransitionId });
  };

  return (
    <StateTransitionForm
      key={edge.id}
      edge={edge}
      onSubmit={onSubmit}
      onDelete={onDeleteTransition}
    />
  );
};

export default memo(EditStateTransition);
