import React, { memo } from "react";

import { Theme, createStyles, makeStyles } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel, {
  FormControlLabelProps,
} from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { usePlacementEditorTranslation } from "./translation";

const placementOptions = ["start", "top", "bottom", "end"];

type LabelPlacement = Exclude<
  FormControlLabelProps["labelPlacement"],
  undefined
>;

type Props = {
  value: FormControlLabelProps["labelPlacement"];
  onChange: (value: LabelPlacement) => void;
};

export const PlacementSelector = memo<Props>(({ value = "end", onChange }) => {
  const translation = usePlacementEditorTranslation();
  const classes = useStyles();

  const options = placementOptions.map((option) => (
    <FormControlLabel
      key={option}
      value={option}
      control={<Radio color="primary" />}
      label={translation[`${option}Label`]}
      labelPlacement={option as LabelPlacement}
    />
  ));

  const handleChange = (_: any, newValue: string) =>
    onChange(newValue as LabelPlacement);

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend" className={classes.label}>
        {translation.title}
      </FormLabel>
      <RadioGroup
        row
        className={classes.group}
        aria-label="position"
        name="position"
        value={value}
        onChange={handleChange}
      >
        {options}
      </RadioGroup>
    </FormControl>
  );
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(0.5),
    },
    group: {
      justifyContent: "space-between",
      flexWrap: "nowrap",
      paddingBottom: theme.spacing(),
    },
  }),
);
