import { createUseTranslation } from "core/session/translation";

export const rolePagesTranslation = {
  en: {
    pageTitle: "Roles",
    createBtnLabel: "Create",
    createPageTitle: "Create Role",
    inputNameLabel: "Name",
    successMsg: "Role * has ben create",
  },
};

export const useRolePagesTranslation = createUseTranslation(
  rolePagesTranslation,
);
