import React, {
  ChangeEvent,
  MouseEvent,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DatePicker,
  DatePickerProps,
  DateTimePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import TextField from "@material-ui/core/TextField";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
  useParentForm,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { ConfigComponent, TranslationComponent } from "./components";
import { UntransformedDateTimeInputConfig } from "../types";
import { useDateTimeInputEditorTranslation } from "./translation";
import { getDateValue } from "utils/date";
import { Box, FormLabel } from "@material-ui/core";

enum Format {
  date = "showDatePart",
  time = "showTimePart",
}

export const DateTimeInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const translation = useDateTimeInputEditorTranslation();
  const {
    elementModel: {
      config: {
        dataSource,
        defaultValue,
        showDatePart = true,
        showTimePart = true,
        maxDate,
        minDate,
        nullable,
        formatString,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeInputConfig>();
  const {
    fieldPath: [fieldName],
  } = dataSource ?? { fieldPath: [] };
  const { getParentViewField } = useParentForm();

  const format =
    showDatePart && showTimePart
      ? [Format.date, Format.time]
      : showDatePart
      ? [Format.date]
      : [Format.time];

  const isTimeOnly = useMemo(
    () => getParentViewField(fieldName as string)?.generalType.type === "time",
    [fieldName, getParentViewField],
  );

  useEffect(() => {
    if (isTimeOnly && showDatePart) {
      changeConfigValue(Format.date, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeOnly]);

  const changeLanguage = (l: Language) => setLang(l);

  const handleDefaultValueInputChange = (date: MaterialUiPickersDate | null) =>
    changeConfigValue("defaultValue", date ? date.toISOString() : null);

  const handleFormatChange = (
    _event: MouseEvent<HTMLElement>,
    value: [Format.time | Format.date],
  ) => {
    if (value?.length) {
      for (const f of Object.values(Format)) {
        changeConfigValue(f as Format.time | Format.date, value.includes(f));
      }
    }
  };

  const formats = Object.values(Format).map((f) => (
    <ToggleButton key={f} value={f} aria-label={f}>
      {translation[`${f}Label`]}
    </ToggleButton>
  ));

  const handleChangeFormatString = (event: ChangeEvent<HTMLInputElement>) => {
    const nextFormat = event.target.value.trim()
      ? event.target.value
      : undefined;

    changeConfigValue("formatString", nextFormat);
  };

  const dateProps: DatePickerProps = {
    value: defaultValue ? defaultValue : null,
    label: editorTranslation.defaultValueInputLabel,
    onChange: handleDefaultValueInputChange,
    disabled: Boolean(dataSource),
    fullWidth: true,
    variant: "dialog",
    minDate: getDateValue(minDate),
    maxDate: getDateValue(maxDate),
    clearable: nullable,
  };

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["date", "dateTime", "time"]}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        {showTimePart ? (
          <DateTimePicker {...dateProps} />
        ) : (
          <DatePicker {...dateProps} />
        )}
        <Box my={0.5} gridGap={8} display="flex" alignItems="center">
          <FormLabel>{translation.formatLabel}</FormLabel>
          <ToggleButtonGroup
            size="small"
            value={format}
            onChange={handleFormatChange}
          >
            {formats}
          </ToggleButtonGroup>
        </Box>
        <TextField
          fullWidth
          value={formatString ?? ""}
          label={translation.formatStringLabel}
          onChange={handleChangeFormatString}
          placeholder="MM/dd/yyyy hh:mm a"
          helperText={translation.formatHelperText}
        />
      </FormInputConfigEditorComponent>
      <ConfigComponent />
    </>
  );
});
