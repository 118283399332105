import React, { memo, useMemo } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Button from "elementTypes/common/Button";
import useStyles from "../../styles";
import { Table, TableRow } from "../../common";
import { useLoginConfig } from "../../../../queries/admin";
import { SettingsConfig } from "./types";

type Row = {
  name: keyof SettingsConfig;
  config: SettingsConfig;
};

const Row = memo<Row>(({ name, config }) => (
  <TableRow rowId={name}>
    <Typography>{name}</Typography>
    <Typography>{config[name]}</Typography>
  </TableRow>
));

const titles = ["Name", "Value"];
const headers = titles.map((title) => ({
  name: title.toLowerCase(),
  title,
}));

export const SettingsPage = memo(() => {
  const {
    data: config,
    isLoading: loading,
    error,
    refetch,
    isFetching,
  } = useLoginConfig();

  const rows = useMemo(
    () =>
      config
        ? [
            <Row key={"logoPath"} name={"logoPath"} config={config} />,
            <Row
              key={"identifierInputTitle"}
              name={"identifierInputTitle"}
              config={config}
            />,
          ]
        : [],
    [config],
  );
  const { horizontallyCenter } = useStyles();

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Settings</Typography>
          <Button
            color="primary"
            label="Edit"
            iconLeft="edit"
            href={"/admin/settings/edit"}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Table
          rows={rows}
          headers={headers}
          onDataReload={refetch}
          loading={loading || isFetching}
          error={error?.message}
        />
      </Grid>
    </Grid>
  );
});
