import React from "react";
import { Box } from "@material-ui/core";

interface SpacerProps {
  vertical?: number;
  horizontal?: number;
}

export const Spacer = ({ vertical, horizontal }: SpacerProps) => (
  <Box marginTop={vertical} marginLeft={horizontal} />
);
