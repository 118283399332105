import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { useIsMounted } from "./useIsMounted";

interface DebouncedStateOptions<Value, TransformerValue> {
  /**
   * a value to watch for changes
   * when this value changed, update the internal value to defaultValue
   *
   * This allows reusing the hook
   */
  identifier?: any;

  /**
   * the debounce delay
   */
  delay?: number;

  /**
   * a function, that transforms some value + the current value to the new value
   * use case:
   * - value is an object, the new value is a Partial<Value>, meaning
   *   currentValue has to be spread for fallback values
   *
   * @example
   *
   * const [value, setValue] = useState({foo: "bar", foo2: "bar2"});
   *
   * const transformValue = (newValue: Partial<typeof value>, currentValue: Value) =>
   *   ({...currentValue, newValue});
   *
   * const [cachedValue, cachedHandleChange] = useDebouncedState(
   *   value,
   *   setValue,
   *   { transformer: transformValue }
   * );
   *
   * // no need to spread `value` here - this is done in the transformer
   * cacheHandleChange({foo: "baz"})
   */
  transformer?: (newValue: TransformerValue, currentValue: Value) => Value;
}

/**
 * This hooks purpose is to enhance the performance of setting values.
 * It works in the following scenario:
 *
 * - value and handleChange method comes from outside (props, context)
 * - you want to update the value a lot of times (text input)
 * - the outside value doesn't have to be in sync all the time
 * - locally, you always need the current value
 *
 * How it works
 *
 * - the value is stored in a local state
 * - the handleChange function is debounced
 * - the exposed handleChange function calls the states setValue and the debounced handleChange function
 *
 *
 * @param defaultValue the default value
 * @param callback the callback function to debounce
 * @param {DebouncedStateOptions} options
 *
 * @example
 *
 * const [cachedValue, cachedHandleChange] = useDebouncedState(
 *   props.value,
 *   props.handleChange,
 *   { identifier: props.id, delay: 500 }
 * );
 */
export function useDebouncedState<Value, TransformerValue = Value>(
  defaultValue: Value,
  callback: (newValue: Value) => void,
  {
    delay = 1000,
    identifier,
    transformer,
  }: DebouncedStateOptions<Value, TransformerValue> = {},
) {
  const [value, setValue] = useState(defaultValue);

  const identifierRef = useRef(identifier);

  useEffect(() => {
    // if identifier changed, update value to new defaultValue
    if (identifierRef.current !== identifier) {
      setValue(defaultValue);
      identifierRef.current = identifier;
    }
  }, [identifier, setValue, defaultValue]);

  const debouncedCallback = useMemo(() => debounce(callback, delay), [
    callback,
    delay,
  ]);

  // set internal value and call debounced callback
  const handleValueChange = useCallback(
    (newValue: TransformerValue) => {
      const transformedValue = (transformer?.(newValue, value) ??
        newValue) as Value;
      setValue(transformedValue);
      debouncedCallback(transformedValue);
    },
    [debouncedCallback, value, transformer],
  );

  return [value, handleValueChange, setValue] as const;
}

/**
 * debounce object changes
 *
 * a similar object is kept as state, which is changed instantaneously on
 * `onChange` callback call
 * this is the object that is actually returned
 *
 * another object `callbackData` is kept as ref, which keeps the data that still
 * needs to be synchronized to the real object (by calling the callback)
 *
 * this is used to set multiple properties at once, which is useful if you call
 * onChange multiple times with different properties. This can happen if one
 * property influences another property (e.g. resetting to default value)
 */
export function useDebouncedStateWithKeys<
  Value extends Record<string, unknown>
>(
  defaultValue: Value,
  callback: (key: string, newValue: any) => void,
): {
  value: Value;
  onChange: (key: string, newValue: any) => void;
} {
  const [value, setValue] = useState(defaultValue);
  // keep the object properties that still need to be set
  const callbackData = useRef<Partial<Value>>({});

  const isMounted = useIsMounted();

  const handleCallback = useCallback(() => {
    // for each saved property in callbackData, call the callback function
    Object.entries(callbackData.current).forEach(([key, newValue]) => {
      callback(key, newValue);
    });
    // reset the callbackData
    callbackData.current = {};
  }, [callback]);

  const memoizedCallback = useMemo(() => debounce(handleCallback, 512), [
    handleCallback,
  ]);

  const handleValueChange = useCallback(
    (key: keyof Value, newValue: any) => {
      if (isMounted()) {
        setValue((nextValue) => ({ ...nextValue, [key]: newValue }));
      }
      // save data for later callback calling
      callbackData.current[key] = newValue;
      memoizedCallback();
    },
    [isMounted, memoizedCallback],
  );

  return { value, onChange: handleValueChange };
}
