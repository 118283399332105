import { FilesPageComponent as FPComponent } from "./component";

import { UploadFilesPageRoute } from "./pages";

export const route = {
  Component: FPComponent,
  auth: true,
  isAdmin: true,
  routes: {
    "/admin/files/upload": UploadFilesPageRoute,
  },
};
