import React, { memo } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import SyncIcon from "@material-ui/icons/Sync";

import { actions } from "../reduxModule";

import useStyles from "../styles";
import { useSettingsTranslation } from "../translation";
import BackButton from "../../../elementTypes/common/BackButton";

export interface IUpdateProfileForm {
  password: string;
  passwordRepeated: string;
}

const UserProfile = memo(() => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isValid, dirty },
    reset,
    getValues,
    errors,
  } = useForm<IUpdateProfileForm>({
    mode: "onChange",
  });
  const { userProfileRootPaper } = useStyles();
  const dispatch = useDispatch();
  const {
    changePasswordTitle,
    newPasswordLabel,
    repeatPasswordLabel,
    repeatPasswordNotEqual,
  } = useSettingsTranslation();

  const updateProfile = (value: IUpdateProfileForm) => {
    dispatch(actions.updateProfile({ password: value.password }));
    reset();
  };

  return (
    <>
      <Box display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon />
        <Typography component="h4" variant="h4">
          {changePasswordTitle}
        </Typography>
      </Box>
      <Paper variant="outlined" className={userProfileRootPaper}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <form autoComplete="off" onSubmit={handleSubmit(updateProfile)}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    label={newPasswordLabel}
                    type="password"
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="passwordRepeated"
                    label={repeatPasswordLabel}
                    type="password"
                    fullWidth
                    inputRef={register({
                      required: true,
                      validate: (repeatedPassword: string) =>
                        getValues().password === repeatedPassword,
                    })}
                    error={!!errors.passwordRepeated}
                    helperText={
                      errors.passwordRepeated && repeatPasswordNotEqual
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    disabled={isSubmitting || !isValid || !dirty}
                    endIcon={<SyncIcon />}
                    type="submit"
                  >
                    Change
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
});

export default UserProfile;
