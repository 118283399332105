import React, { ReactElement, Ref, forwardRef, memo, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import { TransitionProps } from "@material-ui/core/transitions";
import Skeleton from "@material-ui/lab/Skeleton";
import { ImgProps, useImage } from "react-image";
import clsx from "classnames";

import { IElementComponentProps } from "core";
import { MapStateToProps } from "./container";
import { useStyles } from "./style";
import { StorageImage } from "./types";
import { useStorageImageTranslation } from "./translation";
import IconButton from "../common/IconButton";
import { FILE_STORAGE_PREFIX } from "services/api/constants";

type IProps = IElementComponentProps<Record<string, unknown>, StorageImage> &
  MapStateToProps;

const StorageImageComponent = memo<IProps>(
  ({
    path: nullablePath,
    element: {
      config: {
        variant = "none",
        isWrapped = false,
        fullSizeOnClick = false,
        isUsingFileStorage = false,
      },
    },
  }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const translation = useStorageImageTranslation();
    const { root, ...imageClasses } = useStyles();
    const imageClass = imageClasses[variant];
    const handleImageClick = () => {
      if (fullSizeOnClick) {
        setOpenDialog(true);
      }
    };
    const handleDialogClose = () => setOpenDialog(false);
    let src = nullablePath ?? "";

    if (src === "") {
      return null;
    }

    if (isUsingFileStorage) {
      src = FILE_STORAGE_PREFIX + src;
    }

    const imgComponent = (
      <ImageComponent
        key={src}
        src={src}
        className={imageClass}
        onClick={handleImageClick}
      />
    );

    return (
      <>
        {isWrapped ? (
          <Paper variant="outlined" className={root}>
            {imgComponent}
          </Paper>
        ) : (
          <div className={root}>{imgComponent}</div>
        )}

        <Dialog
          fullScreen
          open={openDialog}
          onClose={handleDialogClose}
          TransitionComponent={Transition}
        >
          <AppBar position="relative">
            <Toolbar variant="dense">
              <Box flexGrow={1} />
              <IconButton
                edge="end"
                icon="close"
                tooltip={translation.closeText}
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
              />
            </Toolbar>
          </AppBar>
          <ImageComponent
            key={src}
            src={src}
            className={imageClasses.fullScreenImage}
          />
        </Dialog>
      </>
    );
  },
);

const ImageComponent = memo<ImgProps>(({ alt, className, src, onClick }) => {
  const { src: imgSrc, error, isLoading } = useImage({
    srcList: src,
    useSuspense: false,
  });

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (error) {
    return null;
  }

  return <img alt={alt} src={imgSrc} className={className} onClick={onClick} />;
});

const Transition = forwardRef(
  (props: TransitionProps & { children?: ReactElement }, ref: Ref<unknown>) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

const LoaderComponent = memo(() => {
  const { stretch, skeletonWrapper } = useStyles();

  return (
    <div
      className={clsx(stretch, skeletonWrapper)}
      data-cypress-selector="loader-component"
    >
      <Skeleton variant="rect" animation="wave" width="100%" height="100%" />
    </div>
  );
});

export default StorageImageComponent;
