import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  createStyles({
    root: {
      scrollSnapType: "x mandatory",
      overflowX: "auto",
      overflowY: "hidden",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        width: 0,
        background: "#f0f0f0",
        opacity: 0,
      },
    },
    child: {
      scrollSnapAlign: "start",
    },
  }),
);
