import React, { ChangeEvent, memo, useCallback, useEffect } from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  LanguageSection,
  Section,
  TranslationEditor,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session";
import { TextInputConfig } from "../../types";
import { useTextInputEditorTranslation } from "../translation";
import { CodeEditor } from "../../../common/CodeEditor";

export const ConfigEditor = memo(() => {
  const {
    elementModel: {
      i18n,
      config: { dataSource, multiline, defaultValue, regexPattern },
    },
    changeConfigValue,
    changeTranslation,
  } = useElementEditorContext<TextInputConfig>();

  useEffect(() => {
    if (dataSource && regexPattern) {
      changeConfigValue("regexPattern", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const { language } = useSessionContext();

  const {
    enableMultiline,
    multilineInputLabel,
  } = useTextInputEditorTranslation();

  const handleChangeMultiline = useCallback(
    (newMultilineValue: TextInputConfig["multiline"]) =>
      changeConfigValue("multiline", newMultilineValue),
    [changeConfigValue],
  );

  const handleMultilineInputChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) =>
    handleChangeMultiline(parseInt(newValue, 10));

  const handleDefaultValueInputChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) =>
    changeConfigValue("defaultValue", newValue);

  const handleRegexValueInputChange = (
    _editor: any,
    _data: any,
    nextPattern: string,
  ) => changeConfigValue("regexPattern", nextPattern);

  const multilineEnabled = multiline !== undefined;

  const toggleMultiline = () =>
    handleChangeMultiline(multiline ? undefined : 1);

  const {
    configTitle,
    defaultValueInputLabel,
    regexInputLabel,
  } = useEditorTranslation();

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={language}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text"]}
      />
      <Section title={configTitle} wrapped={true}>
        <FormControlLabel
          control={
            <Switch checked={multilineEnabled} onChange={toggleMultiline} />
          }
          label={enableMultiline}
        />
        <TextField
          disabled={!multilineEnabled}
          value={multiline ?? 0}
          name="multiline"
          type="number"
          label={multilineInputLabel}
          fullWidth={true}
          onChange={handleMultilineInputChange}
        />
      </Section>
      <LanguageSection>
        <TranslationEditor
          translationKey="label"
          i18n={i18n}
          changeTranslation={changeTranslation}
          language={language}
        />
      </LanguageSection>
      <FormInputConfigEditorComponent>
        <TextField
          disabled={Boolean(dataSource)}
          value={defaultValue ?? ""}
          name="defaultValue"
          label={defaultValueInputLabel}
          fullWidth={true}
          onChange={handleDefaultValueInputChange}
        />
        {!dataSource && (
          <CodeEditor
            value={regexPattern ?? ""}
            label={regexInputLabel}
            onChange={handleRegexValueInputChange}
            language="text/x-regex"
            theme="default"
            onAutoComplete={false}
            stylesAsMuiInput
          />
        )}
      </FormInputConfigEditorComponent>
    </>
  );
});
