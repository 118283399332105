import React, { ChangeEvent, memo, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { StylingComponent, TranslationComponent } from "./components";
import { UntransformedBoolInputConfig } from "../types";

export const BoolInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const {
    elementModel: {
      config: { dataSource, defaultValue },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedBoolInputConfig>();

  const changeLanguage = (l: Language) => setLang(l);
  const handleDefaultValueInputChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    changeConfigValue("defaultValue", checked);
  };

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["boolean"]}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        <FormControlLabel
          control={
            <Switch
              checked={defaultValue ?? false}
              onChange={handleDefaultValueInputChange}
              disabled={Boolean(dataSource)}
            />
          }
          label={editorTranslation.defaultValueInputLabel}
        />
      </FormInputConfigEditorComponent>
      <StylingComponent />
    </>
  );
});
