import React, { memo } from "react";
import classNames from "classnames";

import Dropzone, {
  IDropzoneProps,
  IFileWithMeta,
  ILayoutProps,
} from "react-dropzone-uploader";

import Grid from "@material-ui/core/Grid";
import MuiButton from "@material-ui/core/Button";

import { MuiIcon } from "elementTypes/common/MuiIcon";
import { useStyles } from "../../style";
import { useEditorStorageImageTranslation } from "../translation";

type Props = {
  onChange: (file: IFileWithMeta) => void;
};

const Layout = memo<ILayoutProps>(
  ({
    input,
    dropzoneProps,
    files,
    extra: { maxFiles, active: isDragging },
  }) => {
    const { dropButton, activeBorder } = useStyles();
    const translation = useEditorStorageImageTranslation();

    return (
      <Grid item={true} xs={12} container justify="center">
        <MuiButton
          {...dropzoneProps}
          className={classNames(dropzoneProps.className, dropButton, {
            [activeBorder]: isDragging,
          })}
          component="div"
          variant="outlined"
          color="primary"
          startIcon={<MuiIcon icon={"cloud_upload"} />}
        >
          {translation.selectFileButton}
          {files.length < maxFiles && input}
        </MuiButton>
      </Grid>
    );
  },
);

export const UploadZone = memo<Props>(({ onChange }) => {
  const onChangeStatus: IDropzoneProps["onChangeStatus"] = (file, status) => {
    switch (status) {
      case "done":
        onChange(file);
        file.remove();
        break;
      default:
        break;
    }
  };

  return (
    <Dropzone
      onChangeStatus={onChangeStatus}
      LayoutComponent={Layout}
      inputContent={null}
      maxFiles={1}
      multiple={false}
      accept={"image/*"}
    />
  );
});
