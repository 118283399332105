import React, { memo, useState } from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { selectors as routerSelectors } from "core/router/reduxModule";

import { actions as routerActions } from "core/router/reduxModule";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { getApiError } from "queries/utils";
import {
  QueryKeys,
  useCreateCustomQuery,
  useCustomQuery,
  useEditCustomQuery,
} from "queries/admin";
import { DEFAULT_LANGUAGE_CODE, Translation } from "core";
import BackButton from "elementTypes/common/BackButton";
import { useErdTranslation } from "../erd/translation";
import { CustomQueryForm } from "./customQueryForm";
import { PermissionProvider, Permissions } from "../permissionComponent";
import { CustomQueryResponse, UICustomQueryForm } from "./types";
import { LoadingComponent } from "layouts/common/Loading";
import { useSessionContext } from "core/session";
import { getTranslatedText } from "core/utils/element";

const DATABASE_URL = "/admin/database";

export const CustomQuery = memo(() => {
  const translation = useErdTranslation();

  const [permissions, setPermissions] = useState<Permissions>({});

  const { language } = useSessionContext();

  const { queryName: viewName } = useSelector(routerSelectors.params);
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const showError = (error: unknown) => {
    const msg = getApiError(error);
    showSnackbar(msg, "error");
  };

  const handleProcessSuccess = (msg: string) => {
    showSnackbar(msg, "success");
    queryClient.invalidateQueries(QueryKeys.fetchQueries);
    dispatch(routerActions.push(DATABASE_URL));
  };

  const customQueryData = useCustomQuery(
    { viewName },
    {
      enabled: !!viewName,
      onSuccess: (data: CustomQueryResponse) => {
        const nextPermissions = data.permissions.reduce(
          (res, permission) => ({
            ...res,
            [permission.grantee]: permission.privileges,
          }),
          {} as Record<string, string[]>,
        );
        setPermissions(nextPermissions);
      },
    },
  );

  const createQuery = useCreateCustomQuery({
    onSuccess: () => {
      handleProcessSuccess(translation.createQuerySuccessMsg);
    },
    onError: showError,
    mutationKey: "CreateCustomQuery",
  });

  const editQuery = useEditCustomQuery({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.fetchCustomQuery);
      handleProcessSuccess(translation.editQuerySuccessMsg);
    },
    onError: showError,
    mutationKey: "EditCustomQuery",
  });

  const query: UICustomQueryForm | undefined = customQueryData.data
    ? {
        name: customQueryData.data.viewName,
        title: getTranslatedText(
          language,
          customQueryData.data.i18n,
          "title",
        ) as string,
        code: customQueryData.data.code,
        identifyingColumn: customQueryData.data?.identifyingColumn,
      }
    : undefined;

  const handleSave = (dataWithWrongType: Record<string, unknown>) => {
    const data = dataWithWrongType as {
      name: string;
      title: string;
      code: string;
      identifyingColumn?: string;
    };

    const title = data.title.trim();

    const i18n = {
      [DEFAULT_LANGUAGE_CODE]: {
        title,
      },
      [language.code]: {
        title,
      },
    } as Translation<"title">;

    customQueryData.data
      ? editQuery.mutate({
          viewName: customQueryData.data.viewName,
          code: data.code,
          i18n,
          // this is not supported yet
          // permissions: Object.entries(permissions).map(
          //   ([grantee, privileges]) => ({
          //     grantee,
          //     privileges,
          //   }),
          // ),
        })
      : createQuery.mutate({
          viewName: data.name,
          code: data.code,
          permissions: Object.entries(permissions).map(
            ([grantee, privileges]) => ({
              grantee,
              privileges,
            }),
          ),
          identifyingColumn: data.identifyingColumn ?? null,
          i18n,
        });
  };

  return (
    <Box width="100%" minHeight="100%">
      <Card variant="outlined">
        <Box display="flex" alignItems="center" p={1}>
          <BackButton isIcon={true} href={DATABASE_URL} />
          <Typography variant="h5">
            {viewName
              ? translation.customEditQueryTitle
              : translation.customCreateQueryTitle}
          </Typography>
        </Box>
      </Card>
      <PermissionProvider
        value={{
          permissions,
          setPermissions,
          isEditMode: Boolean(customQueryData.data),
        }}
      >
        {viewName ? (
          customQueryData.isSuccess && query ? (
            <CustomQueryForm onSave={handleSave} data={query} />
          ) : (
            <LoadingComponent />
          )
        ) : (
          <CustomQueryForm onSave={handleSave} />
        )}
      </PermissionProvider>
    </Box>
  );
});
