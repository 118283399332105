import React, { memo, useCallback, useMemo } from "react";

import clsx from "classnames";

import { useStore, useZoomPanHelper } from "react-flow-renderer";

import { Box, Card, CardHeader, Divider, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import TableChartIcon from "@material-ui/icons/TableChart";

import { EmptyData } from "./EmptyData";
import { Entities } from "./types";

import { useDataBaseTranslation } from "../translation";
import { useStyles } from "./styles";
import prettyBytes from "pretty-bytes";

interface IProps {
  entities: Entities;
}

export const EntitiesPanel = memo<IProps>(({ entities }) => {
  const translation = useDataBaseTranslation();
  const classes = useStyles();

  const reactFlowStore = useStore();
  const { setCenter } = useZoomPanHelper();

  const focusSchema = useCallback(
    (schema: string) => () => {
      const { nodes } = reactFlowStore.getState();

      const node = nodes.find((n) => n.data.schema === schema);

      if (node) {
        const x = node.__rf.position.x + node.__rf.width / 2;
        const y = node.__rf.position.y + node.__rf.height / 2;
        const zoom = 0.5;

        setCenter(x, y, zoom);
      }
    },
    [reactFlowStore, setCenter],
  );

  const focusTable = useCallback(
    (schema: string, table: string) => () => {
      const { nodes } = reactFlowStore.getState();

      const node = nodes.find(
        (n) => n.data.schemaName === schema && n.data.tableName === table,
      );

      if (node) {
        const x = node.__rf.position.x + node.__rf.width / 2;
        const y = node.__rf.position.y + node.__rf.height / 2;
        const zoom = 0.8;

        setCenter(x, y, zoom);
      }
    },
    [reactFlowStore, setCenter],
  );

  const items = useMemo(
    () =>
      Object.keys(entities).map((schema) => {
        const tableCount = entities[schema].length;
        const schemaTitle = (
          <div className={classes.treeItemTitle}>
            <Typography>{schema}</Typography>
            <Typography className={classes.tableCountTitle}>
              {tableCount}{" "}
              {tableCount === 1
                ? translation.treeItemSchemaTableSingular
                : translation.treeItemSchemaTablePlural}
            </Typography>
          </div>
        );

        return (
          <TreeItem
            key={schema}
            nodeId={schema}
            label={schemaTitle}
            onClick={focusSchema(schema)}
          >
            {entities[schema].map((table) => (
              <TreeItem
                key={table.name}
                nodeId={table.name}
                label={
                  <div className={classes.treeItemTitle}>
                    <Typography>{table.name}</Typography>
                    <Typography className={classes.tableSizeTitle}>
                      {prettyBytes(table.tableSize)}
                    </Typography>
                  </div>
                }
                onClick={focusTable(schema, table.name)}
              />
            ))}
          </TreeItem>
        );
      }),
    [
      classes.tableCountTitle,
      classes.tableSizeTitle,
      classes.treeItemTitle,
      entities,
      focusSchema,
      focusTable,
      translation.treeItemSchemaTablePlural,
      translation.treeItemSchemaTableSingular,
    ],
  );

  return (
    <Card className={clsx(classes.cardHeight, classes.card)} variant="outlined">
      <CardHeader
        disableTypography
        avatar={
          <Box display="flex">
            <TableChartIcon />
          </Box>
        }
        classes={{
          root: classes.cardHeader,
        }}
        title={<Typography variant="h6">{translation.entities}</Typography>}
      />
      <Divider />
      {items.length ? (
        <Box className={classes.treeStyle}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {items}
          </TreeView>
        </Box>
      ) : (
        <EmptyData title="No entities found" />
      )}
    </Card>
  );
});
