import React, { lazy, memo, useCallback, useEffect, useMemo } from "react";

import { buildCustomExpressionValue } from "core";
import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
  useObjectViewList,
} from "core/editor";
import { IField } from "core/editor/common/ViewAutocomplete/components";
import { FormTypes, UntransformedFormConfig } from "../../types";
import { withLazyLoading } from "../../../helpers/HOC/LazyLoading";
import { useEditorFormTranslation } from "../translation";

const CustomExpressionEditor = withLazyLoading(
  lazy(() => import("core/editor/common/CustomExpressionEditor")),
  true,
);

export const DataSource = memo(() => {
  const {
    elementModel: {
      config,
      config: { dataSource, type },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedFormConfig>();
  const { getViewByName } = useObjectViewList();
  const hasIdentifier = useMemo(() => type !== "create", [type]);

  const {
    dataSourceTitle,
    identifierNameLabel,
    viewLabel,
    identifierValueLabel,
  } = useEditorTranslation();
  const { stateFieldNameLabel } = useEditorFormTranslation();

  const changeDataSource = useCallback(
    (newDataSource: UntransformedFormConfig["dataSource"]) =>
      changeConfigValue("dataSource", newDataSource),
    [changeConfigValue],
  );

  const {
    viewName = "",
    identifierName = "",
    identifierValue = "",
    stateFieldName = "",
    multiReference,
  } = dataSource;

  const getDefaultIdentifier = useCallback(
    (nextViewName: string) =>
      getViewByName(nextViewName)?.identifyingField?.name,
    [getViewByName],
  );

  const handleViewNameChange = (newViewName: string) =>
    changeDataSource({
      viewName: newViewName,
      identifierName: hasIdentifier
        ? getDefaultIdentifier(newViewName)
        : undefined,
      identifierValue: hasIdentifier
        ? buildCustomExpressionValue(null)
        : undefined,
    });

  const handleFieldChange = (
    fieldName: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) => {
    // reset multiReference if identifier is unset
    const multiReferenceConfig =
      fieldName === "identifier" && !fieldValue ? undefined : multiReference;

    changeDataSource({
      ...dataSource,
      [fieldName]: fieldValue as string,
      multiReference: multiReferenceConfig,
    });
  };
  const handleIdentifierValueChange = (newValue: any) =>
    changeDataSource({
      ...dataSource,
      identifierValue: newValue,
    });

  const setDefaultIdentifierName = useCallback(
    () =>
      !!viewName.length &&
      changeDataSource({
        ...dataSource,
        viewName,
        identifierName: getDefaultIdentifier(viewName),
        identifierValue: buildCustomExpressionValue(null),
      }),
    [changeDataSource, dataSource, getDefaultIdentifier, viewName],
  );

  useEffect(() => {
    if (hasIdentifier && !identifierName?.length) {
      setDefaultIdentifierName();
    }
  }, [hasIdentifier, type, identifierName, setDefaultIdentifierName]);

  const fields: IField[] = [
    {
      label: identifierNameLabel,
      value: identifierName ?? "",
      name: "identifierName",
      isClearable: type === FormTypes.create,
    },
  ];

  if (type === FormTypes.create) {
    fields.push({
      label: stateFieldNameLabel,
      value: stateFieldName,
      name: "stateFieldName",
      isClearable: true,
    });
  }

  return (
    <Section title={dataSourceTitle} wrapped={true}>
      <ViewAutocomplete
        viewValue={viewName ?? ""}
        viewLabel={viewLabel ?? ""}
        onViewNameChange={handleViewNameChange}
        onViewFieldChange={handleFieldChange}
        fields={fields}
      />
      {/* Disable for "create" type. Issue #747 */}
      {hasIdentifier && (
        <CustomExpressionEditor
          label={identifierValueLabel}
          value={identifierValue ?? ""}
          config={config}
          onChange={handleIdentifierValueChange}
          disableSwitcher
        />
      )}
    </Section>
  );
});
