import { createTypeSafeContext } from "utils/createTypeSafeContext";

import { IPage, Translation } from "../types";
import { ISelectedElement } from "./reduxModule";

type DraggableParams = {
  i: string;
  w: number;
  h: number;
} | null;

interface IEditorContext {
  editModeOn: boolean;
  changeConfigValue: (
    selected: ISelectedElement,
    key: string,
    value: any,
  ) => void;
  changePositionValue: (
    selected: ISelectedElement,
    key: string,
    value: any,
  ) => void;
  changeTranslationValue: (
    selected: ISelectedElement,
    value: Translation<string>,
  ) => void;
  setDraggableElement: (params: DraggableParams) => void;
  unselectElement: (page: IPage) => void;
}

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  IEditorContext
>();

export const useEditorContext = useTypeSafeContext;
export const EditorProvider = Provider;
export const EditorConsumer = Consumer;
