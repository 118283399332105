import React, {
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";

import dagre from "dagre";
import ReactFlow, {
  ArrowHeadType,
  Connection,
  ConnectionMode,
  Controls,
  Edge,
  Elements,
  FlowElement,
  Node,
  OnLoadParams,
  Position,
  isEdge,
  isNode,
} from "react-flow-renderer";
import { Box } from "@material-ui/core";

import { LayoutOptions } from "../../../component";
import { MiniMap } from "../../../components/MiniMap";
import { EdgeData, NodeData, State } from "./types";
import { StateNode } from "./StateNode";
import StartNode from "./StartNode";
import EndNode from "./EndNode";
import Transition from "./Transition";
import ConnectionLine from "./ConnectionLine";
import { useSelectedState } from "../../utils";

const edgeTypes = {
  transition: Transition,
};

const getLayoutedElements = (
  elements: Elements<NodeData | EdgeData>,
  directionValue: LayoutOptions,
) => {
  const isHorizontal = directionValue === LayoutOptions.horizontal;
  const dagreGraph = new dagre.graphlib.Graph({
    compound: true,
    directed: true,
  });
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: directionValue });

  elements.forEach((el, index: number) => {
    if (isNode(el)) {
      // TODO: remove hardcoded width and height
      dagreGraph.setNode(el.id, {
        width: isHorizontal ? 300 : index % 2 === 0 ? 100 : 600,
        height: isHorizontal ? (index % 2 === 0 ? 100 : 600) : 300,
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    let flowElement = { ...el };
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);

      const targetPosition = isHorizontal ? Position.Left : Position.Top;
      const sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

      const position = {
        x: nodeWithPosition.x,
        y: nodeWithPosition.y,
      };

      flowElement = {
        ...flowElement,
        position,
        sourcePosition,
        targetPosition,
      };
    } else if (isEdge(el)) {
      flowElement = {
        ...flowElement,
        arrowHeadType: ArrowHeadType.ArrowClosed,
      };
    }
    return flowElement;
  });
};

const nodeTypes = {
  state: StateNode,
  start: StartNode,
  end: EndNode,
};
interface IProps {
  directionValue: LayoutOptions;
  sourceContent: Elements<any>;
  getStateById: (id: string) => State | undefined;
}

const WorkflowViewer = memo<IProps>(
  ({ directionValue, sourceContent, getStateById }) => {
    const { setSelectedElement } = useSelectedState();

    const [elements, setElements] = useState<Elements>([]);
    const [reactFlowInstance, setReactFlowInstance] = useState<
      OnLoadParams | undefined
    >(undefined);

    const onLayout = useCallback(() => {
      const layoutedElements = getLayoutedElements(
        sourceContent,
        directionValue,
      );
      setElements(layoutedElements);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceContent, directionValue]);

    const handleConnect = (params: Connection | Edge) =>
      setSelectedElement({
        ...params,
        type: "transition",
        ...(params.source && {
          sourceName: getStateById(params.source)?.name,
        }),
        ...(params.target && {
          targetName: getStateById(params.target)?.name,
        }),
      } as FlowElement);

    const handleLoad = (instance: OnLoadParams) => {
      setReactFlowInstance(instance);
      onLayout();
    };

    useEffect(() => {
      onLayout();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceContent, directionValue]);

    useEffect(() => {
      reactFlowInstance?.fitView();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements]);

    const handleElementClick = (
      _ev: MouseEvent<any>,
      element: Edge<EdgeData> | Node<NodeData>,
    ) => {
      let nextElement = { ...element };

      if (isEdge(element)) {
        nextElement = {
          ...nextElement,
          ...((nextElement as Edge).source && {
            sourceName: getStateById((nextElement as Edge).source)?.name,
          }),
          ...((nextElement as Edge).target && {
            targetName: getStateById((nextElement as Edge).target)?.name,
          }),
        };
      }

      setSelectedElement(nextElement);
    };

    return (
      <Box
        flex="1"
        position="relative"
        overflow="hidden"
        width="100% !important"
        height="100% !important"
        bgcolor="background.paper"
        border="1px solid"
        borderColor="divider"
        borderRadius="borderRadius"
      >
        <ReactFlow
          style={{ overflow: "visible" }}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          connectionLineComponent={ConnectionLine}
          elements={elements}
          onLoad={handleLoad}
          onConnect={handleConnect}
          onElementClick={handleElementClick}
          connectionMode={ConnectionMode.Loose}
          elementsSelectable={true}
          selectNodesOnDrag={false}
        >
          <MiniMap />
          <Controls showInteractive={false} />
        </ReactFlow>
      </Box>
    );
  },
);

export default WorkflowViewer;
