import React, { FC, memo } from "react";

import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { Box } from "@material-ui/core";

import { useStyles } from "./style";
import { Handle, NodeProps, Position } from "react-flow-renderer";

const EndNode: FC<NodeProps<any>> = ({ targetPosition = Position.Bottom }) => {
  const { handleClass } = useStyles();

  return (
    <>
      <Box
        p={0.5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor="grey.600"
        borderRadius="40px"
        color="white"
        bgcolor="background.paper"
      >
        <Box
          p={0.75}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="40px"
          fontWeight={700}
          color="white"
          bgcolor="grey.600"
        >
          <DeleteIcon color="inherit" fontSize="small" />
        </Box>
      </Box>
      <Handle type="target" position={targetPosition} className={handleClass} />
    </>
  );
};

export default memo(EndNode);
