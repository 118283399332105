import React, { ChangeEvent, memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";

import {
  FormInputConfigEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { UntransformedNumberInputConfig } from "../../types";
import { useNumberInputEditorTranslation } from "../translation";
import { clampFractionValues } from "elementTypes/common/clampFractionValues";

export const InputComponent = memo(() => {
  const {
    elementModel: {
      config: {
        dataSource,
        defaultValue,
        precision,
        prefix,
        suffix,
        format,
        thousandSeparator = false,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedNumberInputConfig>();
  const editorTranslation = useEditorTranslation();
  const translation = useNumberInputEditorTranslation();

  const handleStringInputChange = (
    configName: keyof UntransformedNumberInputConfig,
  ) => ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) =>
    changeConfigValue(configName, newValue ? newValue : undefined);

  const handleDefaultValueChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) =>
    changeConfigValue("defaultValue", Number(newValue));

  const handlePrecisionChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => {
    if (newValue === "") {
      changeConfigValue("precision", undefined);
    } else {
      changeConfigValue(
        "precision",
        clampFractionValues(parseInt(newValue, 10)),
      );
    }
  };

  const handleThousandSeparatorChange = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => changeConfigValue("thousandSeparator", checked);

  return (
    <FormInputConfigEditorComponent>
      <TextField
        disabled={Boolean(dataSource)}
        value={defaultValue}
        name="defaultValue"
        type="number"
        label={editorTranslation.defaultValueInputLabel}
        fullWidth={true}
        onChange={handleDefaultValueChange}
      />
      <TextField
        value={precision}
        name="precision"
        type="number"
        label={translation.precisionInputLabel}
        fullWidth={true}
        onChange={handlePrecisionChange}
      />
      <TextField
        value={prefix}
        name="prefix"
        label={translation.prefixInputLabel}
        fullWidth={true}
        onChange={handleStringInputChange("prefix")}
      />
      <TextField
        value={suffix}
        name="suffix"
        label={translation.suffixInputLabel}
        fullWidth={true}
        onChange={handleStringInputChange("suffix")}
      />
      <TextField
        value={format}
        name="format"
        label={translation.formatInputLabel}
        fullWidth={true}
        onChange={handleStringInputChange("format")}
        helperText={translation.formatHelperText}
      />
      <FormControlLabel
        control={
          <Switch
            value={thousandSeparator}
            onChange={handleThousandSeparatorChange}
          />
        }
        label={translation.thousandSeparatorInputLabel}
        name="thousandSeparator"
      />
    </FormInputConfigEditorComponent>
  );
});
