import { IDefaultElement } from "core";
import { UntransformedDefaultModalDialogConfig } from "../types";
import { buildCustomExpressionValue } from "core";

export const defaultElement: IDefaultElement<UntransformedDefaultModalDialogConfig> = {
  config: {
    openButtonDisabled: buildCustomExpressionValue("false"),
  },
  i18n: {
    en: {
      modalTitle: "Title",
      openButtonLabel: "Open",
    },
  },
  children: {
    content: {
      elements: [],
    },
  },
};
