import React, { ChangeEvent, ComponentProps, memo, useCallback } from "react";

import { Section, useElementEditorContext } from "core/editor";
import { GeoJSONInputConfig } from "../../types";
import { useGeoJSONInputEditorTranslation } from "../translation";
import { FormControlLabel, Switch, TextField } from "@material-ui/core";
import { DEFAULT_CONFIG } from "elementTypes/default_geojson_input/constants";

export const Modes = memo(() => {
  const {
    modeTitle,
    defaultGeoJsonValueTitle,
    defaultGeoJsonValue,
    coordinatesTitle,
    zoom,
    latitude,
    longitude,
    deleteLabel,
    dragLabel,
    lineLabel,
    polygonLabel,
    rectangleLabel,
    tileLayerUrlLabel,
    tileLayerUrlDescription,
    maximumFeaturesLabel,
    maximumFeaturesDescription,
  } = useGeoJSONInputEditorTranslation();

  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<GeoJSONInputConfig>();

  const handleSwitchValueChange = useCallback(
    (
      key: keyof GeoJSONInputConfig,
    ): ComponentProps<typeof Switch>["onChange"] => (_event, checked) => {
      changeConfigValue(key, checked);
    },
    [changeConfigValue],
  );

  const handleTileLayerUrlChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("tileLayerUrl", e.target.value || null),
    [changeConfigValue],
  );

  const handleDefaultGeoJSONChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue(
        "geoJSONdefaultValue",
        e.target.value?.trim() ? e.target.value : undefined,
      ),
    [changeConfigValue],
  );

  const handleLatitudeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("latitude", parseFloat(e.target.value) || 0),
    [changeConfigValue],
  );

  const handleLongitudeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("longitude", parseFloat(e.target.value) || 0),
    [changeConfigValue],
  );

  const handleZoomChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue("zoom", parseInt(e.target.value, 10) || 0),
    [changeConfigValue],
  );

  const handleMaximumFeatureChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      changeConfigValue(
        "maximumFeatures",
        e.target.value ? parseInt(e.target.value, 10) : null,
      ),
    [changeConfigValue],
  );

  return (
    <div>
      <Section title={defaultGeoJsonValueTitle} wrapped={true}>
        <TextField
          fullWidth
          value={
            config.geoJSONdefaultValue ?? DEFAULT_CONFIG.geoJSONdefaultValue
          }
          onChange={handleDefaultGeoJSONChange}
          label={defaultGeoJsonValue}
          multiline
          rowsMax={5}
        />
      </Section>
      <Section title={coordinatesTitle} wrapped={true}>
        <TextField
          fullWidth
          value={config.latitude ?? DEFAULT_CONFIG.latitude}
          onChange={handleLatitudeChange}
          label={latitude}
          type="number"
        />
        <TextField
          fullWidth
          value={config.longitude ?? DEFAULT_CONFIG.longitude}
          onChange={handleLongitudeChange}
          label={longitude}
          type="number"
        />
        <TextField
          fullWidth
          value={config.zoom ?? DEFAULT_CONFIG.zoom}
          onChange={handleZoomChange}
          label={zoom}
          type="number"
        />
      </Section>
      <Section title={modeTitle} wrapped={true}>
        <TextField
          fullWidth
          value={config.tileLayerUrl ?? ""}
          onChange={handleTileLayerUrlChange}
          label={tileLayerUrlLabel}
          helperText={tileLayerUrlDescription}
        />
        <TextField
          fullWidth
          value={config.maximumFeatures}
          type="number"
          onChange={handleMaximumFeatureChange}
          label={maximumFeaturesLabel}
          helperText={maximumFeaturesDescription}
        />

        <FormControlLabel
          control={
            <Switch
              checked={config.allowDelete ?? DEFAULT_CONFIG.allowDelete}
              onChange={handleSwitchValueChange("allowDelete")}
            />
          }
          label={deleteLabel}
        />
        <FormControlLabel
          control={
            <Switch
              checked={config.allowDrag ?? DEFAULT_CONFIG.allowDrag}
              onChange={handleSwitchValueChange("allowDrag")}
            />
          }
          label={dragLabel}
        />
        <FormControlLabel
          control={
            <Switch
              checked={config.allowLine ?? DEFAULT_CONFIG.allowLine}
              onChange={handleSwitchValueChange("allowLine")}
            />
          }
          label={lineLabel}
        />
        <FormControlLabel
          control={
            <Switch
              checked={config.allowPolygon ?? DEFAULT_CONFIG.allowPolygon}
              onChange={handleSwitchValueChange("allowPolygon")}
            />
          }
          label={polygonLabel}
        />
        <FormControlLabel
          control={
            <Switch
              checked={config.allowRectangle ?? DEFAULT_CONFIG.allowRectangle}
              onChange={handleSwitchValueChange("allowRectangle")}
            />
          }
          label={rectangleLabel}
        />
      </Section>
    </div>
  );
});
