import React, { memo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { LANGUAGES } from "core/types/i18n";
import { actions as routerActions } from "core/router/reduxModule";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import BackButton from "elementTypes/common/BackButton";
import { UserForm } from "./types";
import useStyles from "./styles";

import { Autocomplete } from "elementTypes/common/Autocomplete";
import { useRoles, useSaveUser } from "../../../../../../queries/admin";
import { getRoleOptions } from "../../../../../../queries/admin/utils";
import { useSessionContext } from "../../../../../../core/session";
import { useSnackbar } from "../../../../../../utils/hooks/useSnackbar";
import { getApiError } from "../../../../../../queries/utils";

export const CreateUsersPage = memo(() => {
  const showSnackbar = useSnackbar();
  const dispatch = useDispatch();
  const { root, footer, dividerFooter } = useStyles();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isValid },
    control,
  } = useForm<UserForm>({ mode: "onChange" });

  const { language } = useSessionContext();

  const { data: roleOptions, refetch } = useRoles({
    select: getRoleOptions,
  });

  const saveUser = useSaveUser({
    onSuccess: () => {
      showSnackbar("User created successfully", "success");
      dispatch(routerActions.push("/admin/users"));
    },
    onError: (er) => {
      const apiError = getApiError(er);
      showSnackbar(`User was not created: ${apiError}`, "error");
    },
  });

  const submit = (data: UserForm) => {
    saveUser.mutate(data);
  };

  const handleLoad = () => refetch();

  const startAdornment = (
    <IconButton
      size="small"
      icon="refresh"
      tooltip="Refresh"
      onClick={handleLoad}
    />
  );

  const langs = LANGUAGES.map((lang) => (
    <MenuItem key={lang.code} value={lang.code}>
      {lang.label}
    </MenuItem>
  ));

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon href="/admin/users" />
        <Typography variant="h5">Create User</Typography>
      </Box>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="Email"
              type="email"
              fullWidth={true}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="userName"
              label="Username"
              fullWidth={true}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              label="Password"
              type="password"
              fullWidth={true}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <Autocomplete
                  options={roleOptions}
                  startAdornment={startAdornment}
                />
              }
              label="Role"
              name="role"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel shrink={true} id="language">
              Language
            </InputLabel>
            <Controller
              as={
                <Select value={language.code} fullWidth={true}>
                  {langs}
                </Select>
              }
              name="language"
              rules={{ required: true }}
              control={control}
              defaultValue={language.code}
            />
          </Grid>
          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              disabled={isSubmitting || !isValid}
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Create"
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
