import React, { memo, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { actions as routerActions } from "core/router/reduxModule";
import { Controller, useForm } from "react-hook-form";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  Select,
  Typography,
} from "@material-ui/core";

import { IObjectView } from "core";
import { useTranslator } from "core/session/translation/createUseTranslation";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { IUIGenerateForm } from "./types";
import useStyles from "./styles";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";
import BackButton from "elementTypes/common/BackButton";
import { useRoles } from "queries/admin";
import { useGenerateApp, useObjectView } from "queries/admin/appData";
import { getApiError } from "queries/utils";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { useCommonStaticPagesTranslation } from "../../translation";

export const GenerateAppsPage = memo(() => {
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const translation = useCommonStaticPagesTranslation();

  const rolesList = useRoles();

  const { root, footer, dividerFooter } = useStyles();
  const {
    handleSubmit,
    register,
    control,
    errors,
    watch,
    formState: { isSubmitting, isValid },
    setValue,
  } = useForm<IUIGenerateForm>({
    mode: "onChange",
    defaultValues: { role: "", name: "", description: "", objectViews: [] },
  });

  const role = watch("role");

  const viewList = useObjectView(role);

  const objectViews = watch("objectViews");

  useEffect(() => {
    if (role) {
      setValue("objectViews", []);
    }
  }, [role, setValue]);

  const generateApp = useGenerateApp({
    onSuccess: () => {
      showSnackbar(translation.createAppMessage, "success");
      dispatch(routerActions.goBack());
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const submit = (data: IUIGenerateForm) => {
    generateApp.mutate(data);
  };

  const handleRefreshClick = () => rolesList.refetch();

  const handleRefreshObjectViewsClick = () => viewList.refetch();

  const allSelected = viewList.data?.every((v: IObjectView) =>
    objectViews.includes(v.id),
  );

  const handleSelectAllObjectViewsClick = () =>
    setValue(
      "objectViews",
      allSelected ? [] : viewList.data?.map((v: IObjectView) => v.id) ?? [],
    );

  const { translate } = useTranslator();

  const isDisabled = viewList.isFetching || viewList.isLoading;

  const items = useMemo(
    () =>
      viewList.data?.length ? (
        viewList.data?.map(({ id, name, i18n }: IObjectView) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={objectViews.includes(id)} />
            <Typography color="primary">{translate(i18n).title}</Typography>
            {NON_BREAKING_SPACE}({name})
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>
          <Typography variant="button">{translation.noDataLabel}</Typography>
        </MenuItem>
      ),
    [viewList.data, objectViews, translation, translate],
  );

  const rolesListItems = useMemo(
    () =>
      rolesList.data?.map(({ name }) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      )),
    [rolesList.data],
  );

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon href="/admin/apps" />
        <Typography variant="h5">{translation.generateAppTitle}</Typography>
      </Box>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Name"
              error={Boolean(errors?.name)}
              fullWidth={true}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              fullWidth={true}
              error={Boolean(errors?.description)}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  error={Boolean(errors?.role)}
                  disabled={!rolesList.data}
                  select={!!rolesList.data}
                  label="Owner"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        size="small"
                        icon="refresh"
                        tooltip="Refresh"
                        onClick={handleRefreshClick}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {rolesListItems}
                </TextField>
              }
              name="role"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              disabled={isDisabled}
              error={Boolean(errors?.objectViews)}
            >
              <InputLabel>Queries</InputLabel>
              <Controller
                as={
                  <Select
                    margin="dense"
                    multiple
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },

                      getContentAnchorEl: null,
                    }}
                    renderValue={(selected) => (
                      <Box display="flex" flexWrap="wrap">
                        {(selected as string[]).map((value) => {
                          const view = viewList.data?.find(
                            (v: IObjectView) => v.id === value,
                          );
                          return (
                            view && (
                              <Box m={0.25} key={value}>
                                <Chip
                                  label={view.name}
                                  variant="outlined"
                                  size="small"
                                />
                              </Box>
                            )
                          );
                        })}
                      </Box>
                    )}
                    input={
                      <Input
                        fullWidth
                        startAdornment={
                          <>
                            <IconButton
                              size="small"
                              icon="refresh"
                              tooltip="Refresh"
                              onClick={handleRefreshObjectViewsClick}
                              disabled={isDisabled}
                            />
                            <IconButton
                              size="small"
                              icon={allSelected ? "clear" : "check"}
                              tooltip="Select / Unselect all"
                              onClick={handleSelectAllObjectViewsClick}
                              disabled={isDisabled || !viewList.data}
                            />
                          </>
                        }
                      />
                    }
                  >
                    {items}
                  </Select>
                }
                name="objectViews"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              disabled={
                isSubmitting ||
                !isValid ||
                rolesList.isLoading ||
                rolesList.isFetching ||
                viewList.isLoading
              }
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Generate"
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
