import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    checkboxMargin: {
      marginRight: theme.spacing(-0.5),
    },
    displayFlex: {
      display: "flex",
      alignItems: "center",
    },
    fixedList: {
      width: "100%",
      padding: 0,
      maxHeight: "auto",
    },
    listBoxItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "start",
    },
  }),
);

export const useListStyles = makeStyles((theme: Theme) =>
  createStyles({
    listbox: {
      "& ul": {
        padding: 0,
        margin: 0,
      },
      "& [class*='MuiAutocomplete-option']": {
        right: 0,
        width: "auto",
        boxSizing: "border-box",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
      "& [class*='MuiListItemText-root']": {
        width: "100%",
      },
      "& span[class*='MuiTypography-root']": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  }),
);
