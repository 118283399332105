import { selectorScoper } from "core/utils/redux";

import { IState } from "./types";
import { constants } from "./constants";

/**
 * TODO:
 * "staticPages" should be removed from here. Static redux modules should be factory functions that take the path
 */
const scopeSelector = selectorScoper<IState>([
  "staticPages",
  constants.MODULE_NAME,
]);

export const selectors = {
  config: (state: any) => scopeSelector(state).config,
  error: (state: any) => scopeSelector(state).error,
  loading: (state: any) => scopeSelector(state).loading,
};
