import { createUseTranslation } from "core/session/translation";

export const editorTranslation = {
  en: {
    viewLabel: "Source View",
    valueColumnHelperText: "Type and press enter to add value",
    titleLabel: "Title",
    optionTitle: "Option",
    showBackgroundLabel: "Show Background",
    sortTitle: "Sort",
    ascendantTooltip: "Sort Ascending",
    descendantTooltip: "Sort Descending",
  },
};

export const useCustomChartEditorTranslation = createUseTranslation(
  editorTranslation,
);
