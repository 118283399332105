import React from "react";

import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const PanelSkeleton = () => (
  <Paper elevation={1} style={{ height: "50%" }}>
    <Grid container style={{ padding: 15 }} alignItems="center">
      <Grid item xs={2}>
        <Skeleton width={20} height={20} variant="rect" />
      </Grid>
      <Grid item xs={10}>
        <Skeleton width="90%" height={30}>
          <Typography>.</Typography>
        </Skeleton>
      </Grid>
    </Grid>
    <Box pt={1} pb={2} display="flex" flexDirection="column">
      <Skeleton style={{ marginLeft: 35 }} width="80%" height={30} />
      <Skeleton style={{ marginLeft: 35, marginRight: 35 }} height={30} />
      <Skeleton style={{ marginLeft: 35 }} width="80%" height={30} />
    </Box>
  </Paper>
);
