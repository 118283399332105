import React, { memo } from "react";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";
import { PieChart } from "./types";

import { IOptionEchartProps } from "../common/Echarts";
import { IEchartsContainerSingleProps } from "../common/Echarts/EchartsContainer";
import EchartsContainerOneSerie from "../common/Echarts/EchartsContainerOneSerie";
import { LoadingComponent } from "layouts/common/Loading";
import { AlertBox } from "elementTypes/common/AlertBox";

type Props = ConnectedReduxModuleProps<ReduxModule, PieChart>;

const EchartsPieChart = memo<Props>(
  ({
    element: {
      config: { hideLabel, hideLegend, isDonut, dataSource, showBackground },
      i18n: { title },
    },
    error,
    data,
  }) => {
    const propsEchartsContainer: Omit<
      IEchartsContainerSingleProps,
      "data" | "dataSource"
    > = {
      type: "pie",
      title,
      defaultOptions: {
        title: {
          x: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          show: !hideLegend,
        },
        series: [
          {
            avoidLabelOverlap: true,
            label: {
              show: !hideLabel,
            },
            radius: ["0%", "70%"],
            ...(Boolean(isDonut) && {
              // have same max radius similar to the pie chart
              radius: ["50%", "70%"],
            }),
          },
        ],
      } as IOptionEchartProps,
      showBackground,
    };

    if (!dataSource.viewName) {
      return (
        <AlertBox title="Config error" message={"Query Name is required"} />
      );
    }

    if (error) {
      return <AlertBox title="Error" message={error} />;
    }

    if (!data) {
      return <LoadingComponent />;
    }

    return (
      <EchartsContainerOneSerie
        {...propsEchartsContainer}
        dataSource={dataSource}
        data={data}
      />
    );
  },
);

export default EchartsPieChart;
