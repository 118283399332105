import { UseMutationOptions, UseQueryOptions } from "react-query";

import AdminService from "services/admin";
import {
  CustomQueryResponse,
  DataPreviewResponse,
  TestCustomQueryResponse,
} from "staticPages/admin/pages/modelBuilder/customQuery/types";
import { StateResponsePayload } from "../../staticPages/admin/pages/modelBuilder/workflow/components/types";
import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "../utils";
import { QueryKeys } from "./queryKeys";
import {
  CreateStateParams,
  CreateStateTransitionParams,
  DeleteStateParams,
  DeleteStateTransitionParams,
  UpdateStateParams,
  UpdateStateTransitionParams,
  WorkflowActivationParams,
} from "staticPages/admin/pages/modelBuilder/workflow/types";
import {
  DataModel,
  HistoryTrackingSwitchParams,
  WorkflowSetupParams,
} from "staticPages/admin/pages/modelBuilder/erd/types";
import { DeleteQueryParams } from "staticPages/admin/pages/modelBuilder/components/types";
import { Workflow } from "staticPages/admin/pages/modelBuilder/workflow/components/StateViewer/types";

const services = AdminService.getInstance();

export type ColumnValuesArguments = Parameters<
  typeof services.getColumnValues
>[1];

type WorkflowArguments = Parameters<typeof services.getStateWorkflow>[1];
type StateNodeArguments = Parameters<typeof services.getStateNode>[1];
type SimpleQueryArguments = Parameters<typeof services.createSimpleQuery>[1];
type CustomQueryArguments = Parameters<typeof services.createCustomQuery>[1];
type EditCustomQueryArguments = Parameters<typeof services.editCustomQuery>[1];
type GetCustomQueryArguments = Parameters<typeof services.getCustomQuery>[1];
export type stateTransitionArguments = Parameters<
  typeof services.getStateTransition
>[1];
type DataPreviewArguments = Parameters<typeof services.getDataPreview>[1];
type ColumnAliasArguments = Parameters<typeof services.setColumAlias>[1];
type TestCustomQueryArguments = Parameters<typeof services.testCustomQuery>[1];

export const fetchQueries: Query<ReturnType<typeof services.getQueries>> = {
  queryKey: QueryKeys.fetchQueries,
  queryFn: services.getQueries,
};

export const fetchModel: Query<DataModel> = {
  queryKey: QueryKeys.fetchModel,
  queryFn: services.getModel,
};

export const fetchColumnValues: Query<
  Record<string, unknown>[],
  ColumnValuesArguments
> = {
  queryKey: QueryKeys.fetchColumnValues,
  queryFn: services.getColumnValues,
};

export const fetchStateWorkflow: Query<Workflow, WorkflowArguments> = {
  queryKey: QueryKeys.fetchWorkflow,
  queryFn: services.getStateWorkflow,
};

export const fetchStateNode: Query<StateResponsePayload, StateNodeArguments> = {
  queryKey: QueryKeys.fetchStateNode,
  queryFn: services.getStateNode,
};

export const fetchDataPreview: Query<
  DataPreviewResponse,
  DataPreviewArguments
> = {
  queryKey: QueryKeys.fetchDataPreview,
  queryFn: services.getDataPreview,
};

export const fetchStateTransition: Query<
  ReturnType<typeof services.getStateTransition>,
  stateTransitionArguments
> = {
  queryKey: QueryKeys.fetchStateTransition,
  queryFn: services.getStateTransition,
};

export const fetchCustomQuery: Query<
  CustomQueryResponse,
  GetCustomQueryArguments
> = {
  queryKey: QueryKeys.fetchCustomQuery,
  queryFn: services.getCustomQuery,
};

export const useModel = () => useAuthenticatedQuery(fetchModel);
export const useQueries = () => useAuthenticatedQuery(fetchQueries);
export const useColumnValues = (
  params: ColumnValuesArguments,
  config?: UseQueryOptions<
    Record<string, unknown>[],
    Error,
    Record<string, unknown>[]
  >,
) => useAuthenticatedQuery(fetchColumnValues, params, config);

export const useDataPreview = (
  options?: UseMutationOptions<
    DataPreviewResponse,
    unknown,
    DataPreviewArguments
  >,
) => useAuthenticatedMutation(services.getDataPreview, options);

export const useWorkFlow = (
  params: WorkflowArguments,
  config?: UseQueryOptions<Workflow, Error>,
) => useAuthenticatedQuery(fetchStateWorkflow, params, config);

export const useStateNode = (params: StateNodeArguments) =>
  useAuthenticatedQuery(fetchStateNode, params);

export const useCustomQuery = (
  params: GetCustomQueryArguments,
  config?: UseQueryOptions<CustomQueryResponse, Error>,
) => useAuthenticatedQuery(fetchCustomQuery, params, config);

export const useCreateSimpleQuery = (
  options?: UseMutationOptions<unknown, unknown, SimpleQueryArguments>,
) => useAuthenticatedMutation(services.createSimpleQuery, options);

export const useCreateCustomQuery = (
  options?: UseMutationOptions<unknown, unknown, CustomQueryArguments>,
) => useAuthenticatedMutation(services.createCustomQuery, options);

export const useEditCustomQuery = (
  options?: UseMutationOptions<unknown, unknown, EditCustomQueryArguments>,
) => useAuthenticatedMutation(services.editCustomQuery, options);

export const useStateTransition = (
  params: stateTransitionArguments,
  config?: UseQueryOptions<any, Error>,
) => useAuthenticatedQuery(fetchStateTransition, params, config);

export const useCreateState = (
  options?: UseMutationOptions<unknown, unknown, CreateStateParams>,
) => useAuthenticatedMutation(services.createState, options);

export const useUpdateState = (
  options?: UseMutationOptions<unknown, unknown, UpdateStateParams>,
) => useAuthenticatedMutation(services.updateState, options);

export const useDeleteState = (
  options?: UseMutationOptions<unknown, unknown, DeleteStateParams>,
) => useAuthenticatedMutation(services.deleteState, options);

export const useCreateStateTransition = (
  options?: UseMutationOptions<unknown, unknown, CreateStateTransitionParams>,
) => useAuthenticatedMutation(services.createStateTransition, options);

export const useUpdateStateTransition = (
  options?: UseMutationOptions<unknown, unknown, UpdateStateTransitionParams>,
) => useAuthenticatedMutation(services.updateStateTransition, options);

export const useWorkflowSetup = (
  options?: UseMutationOptions<unknown, unknown, WorkflowSetupParams>,
) => useAuthenticatedMutation(services.workflowSetup, options);

export const useWorkflowActivationSwitch = (
  options?: UseMutationOptions<unknown, unknown, WorkflowActivationParams>,
) => useAuthenticatedMutation(services.workflowActivationSwitch, options);

export const useColumnAliasQuery = (
  options?: UseMutationOptions<unknown, unknown, ColumnAliasArguments>,
) => useAuthenticatedMutation(services.setColumAlias, options);

export const useDeleteStateTransition = (
  options?: UseMutationOptions<unknown, unknown, DeleteStateTransitionParams>,
) => useAuthenticatedMutation(services.deleteStateTransition, options);

export const useTestCustomQuery = (
  options?: UseMutationOptions<
    TestCustomQueryResponse,
    unknown,
    TestCustomQueryArguments
  >,
) => useAuthenticatedMutation(services.testCustomQuery, options);

export const useHistoryTrackingSwitch = (
  options?: UseMutationOptions<unknown, unknown, HistoryTrackingSwitchParams>,
) => useAuthenticatedMutation(services.historyTrackingSwitch, options);

export const useDeleteQuery = (
  options?: UseMutationOptions<unknown, unknown, DeleteQueryParams>,
) => useAuthenticatedMutation(services.deleteQuery, options);
