/**
 * TODO:
 * Refactor thoughts...
 *
 * MobX is simple and fast.
 * But what about dynamic behaviour in trees, for example, there's data for a table, and each row must have encapsulated
 * behaviour. So it might seem we need MST, because we'll end up building it anyways, except we don't fall for the OOP
 * trap, and don't mix data with behavior (actually we don't fall in the pseudo-OOP trap of considering objects data
 * structures)
 * So if we follow that separation, we end up with more of a FP style. In the case of the table rows, it's just data.
 * And the behavior will be applied as a function to the corresponding row.
 * We don't need trees for elements, because we already have them in the json definition, we can just flatten them.
 *
 * The tree only exists in the view layer. But what about context regarding behavior? Since the context only make sense
 * in regards to the tree, it's React's (the View's) responsibility to handle that. Since the behavior is decoupled from
 * the view, it accepts all parameters it must accept, and a React component just calls a piece of behavior providing
 * the needed data and params, which it can extract from it's context.
 */

import { ReducersMapObject, Store, combineReducers } from "redux";
import { Saga, SagaMiddleware, Task } from "redux-saga";
import { all } from "redux-saga/effects";

import { IMPLEMENTED_INTERFACES, SET_MODULE_GETTER } from "./constants";
import * as editor from "./editor/reduxModule";
import { getElementInstanceId, instantiateElement } from "./instantiateElement";
import * as router from "./router/reduxModule";
import { Type } from "./runtime-typing";
import * as session from "./session/reduxModule";
import {
  GetElementType,
  GetModule,
  IElement,
  IElementModel,
  IElementProps,
  IElementType,
  IGetModuleOptions,
  IImplementedElementInterfaces,
  IReduxModule,
  IReduxModuleContext,
  Selector,
  SelectorsMap,
  TypeFactory,
} from "./types";
import { isElementInstance } from "./utils/element";
import { IStaticRouteConfig } from "./router/types";
import {
  AnyFluxStandardAction,
  NonInitialReducer,
  createAction,
  createActionTypeScoper,
} from "./utils/redux";
import { SessionReduxModule } from ".";

interface IMountedElement {
  element: IElement;
  module: IReduxModule | null;
  childrenReduxModuleContext: IReduxModuleContext;
  getModule: GetModule;
  actions: {
    showElement: () => AnyFluxStandardAction;
    hideElement: () => AnyFluxStandardAction;
  };
  unmount: () => void;
  hidden: boolean;
}

export const ELEMENTS_KEY = "elements";

// Private action to force newly mounted reducers to return their initial state.
const ELEMENT_INIT = "@@reducerManager/ELEMENT_INIT";
const ELEMENT_INIT_ACTION = { type: ELEMENT_INIT };
const STATIC_MOUNTED = "@@reducerManager/STATIC_MOUNTED";
const STATIC_MOUNTED_ACTION = { type: STATIC_MOUNTED };

export type ReduxModule = {
  MODULE_NAME: string;
  reducer?: (state: any, action: AnyFluxStandardAction) => any;
  saga?: Saga;
};

const INITIAL_MODULES: [ReduxModule, SessionReduxModule, ReduxModule] = [
  router,
  session,
  editor,
];

const emptyElementsReducer: NonInitialReducer<any> = (state = {}) => state;
const emptyStaticPagesReducer: NonInitialReducer<any> = (state = {}) => state;

/*
 * This reducerManager pattern is based on https://redux.js.org/recipes/code-splitting#using-a-reducer-manager.
 *
 * This manager exposes a `reduce` prop to be used as the main redux reducer, and a `saga` prop to be used
 * as the main saga. It provides functions to mount and unmount elements to the store.
 */
export function createReducerManager(getElementType: GetElementType) {
  const initialReducers = getInitialModulesReducers();
  const mountedElements: Record<string, IMountedElement> = {};
  const dynamicReducers: Record<string, NonInitialReducer<any>> = {};

  let initialized = false;
  // Reference to the store will be set later because of circular dependency
  let _store: Store;

  // Reference to the saga middleware will be set later because of circular dependency
  let _sagaMiddleware: SagaMiddleware;

  let combinedElementsReducer = emptyElementsReducer;
  let combinedStaticPagesReducer = emptyStaticPagesReducer;
  let keysToRemove: string[] = [];
  let staticPageKeysToRemove: string[] = [];

  function refreshElementsReducer() {
    combinedElementsReducer = Object.keys(dynamicReducers).length
      ? (combineReducers(dynamicReducers) as NonInitialReducer<any>)
      : emptyElementsReducer;
  }

  function refreshStaticPagesReducer() {
    combinedStaticPagesReducer = Object.keys(dynamicReducers).length
      ? (combineReducers(dynamicReducers) as NonInitialReducer<any>)
      : emptyStaticPagesReducer;
  }

  /*
   * A reducer that removes from the state the keys pending deletion and then calls the combined elements reducer.
   */
  const elementsReducer = (state: any, action: AnyFluxStandardAction) => {
    // If any reducers have been removed, clean up their state first
    if (keysToRemove.length > 0) {
      state = { ...state };
      for (const key of keysToRemove) {
        delete state[key];
      }
      keysToRemove = [];
    }

    // Delegate to the combined reducer
    return combinedElementsReducer(state, action);
  };

  const staticPagesReducer = (state: any, action: AnyFluxStandardAction) => {
    // If any reducers have been removed, clean up their state first
    if (staticPageKeysToRemove.length > 0) {
      state = { ...state };
      for (const key of staticPageKeysToRemove) {
        delete state[key];
      }
      staticPageKeysToRemove = [];
    }

    // Delegate to the combined reducer
    return combinedStaticPagesReducer(state, action);
  };

  function searchForModule(idMaps: Array<Record<string, string>>, id: string) {
    let realId: string;
    for (let i = idMaps.length - 1; i >= 0; i--) {
      realId = idMaps[i][id];
      if (realId !== undefined) {
        return (mountedElements[realId] || {}).module;
      }
    }
    return undefined;
  }

  function createModuleGetter(idMaps: Array<Record<string, string>>) {
    return function getModuleInScope<M extends IReduxModule>(
      id: number | string,
      opts: IGetModuleOptions = {},
    ): IGetModuleOptions["allowNull"] extends true ? M | null : M {
      const stringId = id.toString();
      let module: IReduxModule | null | undefined = (
        mountedElements[
          opts.location ? `${opts.location.key}.${stringId}` : stringId
        ] || {}
      ).module;
      if (module === undefined) {
        module = searchForModule(idMaps, stringId);
      }
      if (module === null) {
        if (!opts.allowNull) {
          throw new Error(`Element "${id}" has no redux module.`);
        }
      } else if (!module) {
        throw new Error(`Module with id "${id}" not present in store.`);
      }
      return module as IGetModuleOptions["allowNull"] extends true
        ? M | null
        : M;
    };
  }

  const { location: locationSelector } = router.selectors;

  return {
    /**
     * Since there is a circular dependency between the store, the saga middleware, and this manager,
     * the references to the store and saga middleware are set after creating the manager.
     */
    initialize: (store: Store, sagaMiddleware: SagaMiddleware) => {
      if (initialized) {
        throw new Error("Reducer Manager already initialized");
      }
      initialized = true;
      _store = store;
      _sagaMiddleware = sagaMiddleware;
    },

    /**
     * Mount an elements reducer and saga and return an object containing a function to unmount the element, the
     * created module and the context for the element's children.
     */
    mountElement: (
      elementModel: IElementModel,
      reduxModuleContext: IReduxModuleContext,
      props: IElementProps,
      dynamic: boolean,
    ) => {
      if (!initialized) {
        throw new Error(
          "Reducer Manager not initialized. Call `.initialize()`.",
        );
      }

      if (isElementInstance(elementModel)) {
        throw new Error(
          `Cannot mount an instantiated element.\n${JSON.stringify(
            elementModel,
          )}`,
        );
      }

      const { context, scope: contextScope, idMaps } = reduxModuleContext;
      const location = locationSelector(_store.getState());
      const scope = contextScope
        ? location.key
          ? `${location.key}.${contextScope}`
          : contextScope
        : location.key;

      const id = getElementInstanceId(elementModel, props, scope, dynamic);
      let mounted = mountedElements[id];

      if (mounted) {
        /**
         * In case the element was unmounted and immediately re-mounted we remove its id from the list of
         * state keys to be removed.
         * This check used to be before the if-else, but another bug made us move it here. If this bug returns
         * we can try putting it before `if (mounted)`, and fixing the other bug somehow. The other bug was the
         * following:
         * We set a filter or order to a Table in editing mode. Then we change the viewSource config of the table.
         * That makes the filter or sort be obsolete, since the view changed. Since the config changed, the element
         * should be completely re-mounted and it's state cleared. But this line prevents the state from being cleared,
         * leaving the old state with a filter for a non-existing column, thus breaking the table.
         */
        keysToRemove = keysToRemove.filter((k) => k !== id);

        /**
         * TODO:
         * Previously this code was as follows:
         *
         * if (mounted.hidden || true) {
         *   mounted.hidden = false;
         *   _store.dispatch(mounted.actions.showElement());
         * } else {
         *   throw new Error(
         *     `Element with id ${id} already mounted. This is probably because a duplicated element on screen doesn't have the necessary prop "dynamic: true"`,
         *   );
         * }
         *
         * That code caused an error when doing the following actions:
         *  .load a table page
         *  .click on EDIT LAYOUT
         *  .change the url of the CREATE button to an invalid url
         *  .click the button (you navigate to a not found page)
         *  .click the browser back button
         *  .the elements throw the "already mounted" error above
         *
         * This happens because the root Element, the grid, is being unmounted and re-mounted by some reason (my
         * best guess is a swallowed exception somewhere), BUT IT'S CHILDREN ARE NOT BEING UNMOUNTED. React by
         * some reason re-mounts elements that were not unmounted, thus, the same element is mounted twice and
         * the code above throws.
         */
        mounted.hidden = false;
        _store.dispatch(mounted.actions.showElement());
      } else {
        let currentIdMaps: Array<Record<string, string>>;
        let currentIdMap: Record<string, string>;
        if (dynamic) {
          /**
           * We spread the last map to optimize the search.
           * Since the order of rendering of react elements is arbitrary, we can't be certain that all elements in
           * the ancestor scope are mounted. That's why all ancestor maps must be passed the `createModuleGetter`
           * and it must perform a search, but if we spread the last known map and let `createModuleGetter` run a
           * reverse search we will make the search faster.
           *
           * introduced in https://github.com/agstrauss/cypex-gui-refactor/commit/5a8fd5e08cfdfe029ab8b7f83cb8a305db7877c9#diff-a1c059ad5fbe0d4f6dea0236264471ba09f1f42bcb42220fa7e1505d4c608e6aR146
           */
          currentIdMap = { ...idMaps[idMaps.length - 1] };
          currentIdMaps = [...idMaps, currentIdMap];
        } else {
          currentIdMaps = idMaps;
          currentIdMap = idMaps[idMaps.length - 1];
        }

        const getModule = createModuleGetter(currentIdMaps);
        const elementType = getElementType(elementModel);

        const element = instantiateElement(
          elementModel,
          elementType,
          id,
          getModule,
          session.selectors.currentLanguage,
          props,
          scope,
          location,
        );

        currentIdMap[element.originalId] = element.id;

        let childrenContext = context;
        let module: IReduxModule;

        const scopeActionType = createActionTypeScoper(id);
        const types = {
          ELEMENT_SHOW: scopeActionType("ELEMENT_SHOW"),
          ELEMENT_HIDE: scopeActionType("ELEMENT_HIDE"),
        };
        const actions = {
          showElement: createAction(types.ELEMENT_SHOW),
          hideElement: createAction(types.ELEMENT_HIDE),
        };

        if (elementType.reduxModuleFactory) {
          module = elementType.reduxModuleFactory({
            element,
            context,
            getModule,
            lifecycleTypes: types,
            path: [ELEMENTS_KEY, element.id],
          });

          if (module.reducer && !dynamicReducers[element.id]) {
            dynamicReducers[element.id] = module.reducer;
            refreshElementsReducer();
          }

          if (module.context) {
            childrenContext = { ...context };
            /*
             * We allow the context returned by `reduxModuleFactory` to be a single context provider or an array
             * of providers, so if it's a single provider we cast it to an array.
             */
            const contextProviders = Array.isArray(module.context)
              ? module.context
              : [module.context];
            for (const contextProvider of contextProviders) {
              childrenContext[contextProvider.context.id] = contextProvider;
            }
          }

          if (module.selectors) {
            // TODO: See if this should only run in react dev mode, not in production
            module.selectors = getTypeCheckedSelectors(
              elementType,
              elementModel,
              module.selectors,
            );
          }

          if (module.interfaces) {
            /*
             * We allow the interfaces returned by `reduxModuleFactory` to be a single interface implementation or an
             * array of implementations, so if it's a single implementation we cast it to an array.
             */
            const interfaces = Array.isArray(module.interfaces)
              ? module.interfaces
              : [module.interfaces];
            const indexedImplementations: IImplementedElementInterfaces = {};
            for (const implementation of interfaces) {
              implementation.interface[SET_MODULE_GETTER](getModule);
              indexedImplementations[
                implementation.interface.id
              ] = implementation;
            }

            module = {
              ...module,
              [IMPLEMENTED_INTERFACES]: indexedImplementations,
            };
          }
        } else {
          module = {};
        }

        const childrenReduxModuleContext: IReduxModuleContext = {
          getModule,
          context: childrenContext,
          scope: dynamic ? element.id : reduxModuleContext.scope,
          idMaps: currentIdMaps,
        };

        mounted = {
          element,
          module,
          childrenReduxModuleContext,
          getModule,
          actions,
          hidden: false,
          unmount: () => {
            /**
             * TODO:
             * Right now all element's states are being cached on location change for ever. In the future, we will
             * have to clear the cache eventually.
             */

            /**
             * If the current location is different that the original, it means the element is being unmounted
             * because of a location change, so we cache it's state and mark it as hidden. If the location is
             * the same, it means the element is actually being removed, so we unmount it's redux module.
             */
            const currentLocation = locationSelector(_store.getState());
            if (location.key !== currentLocation.key) {
              mountedElements[element.id].hidden = true;
              _store.dispatch(actions.hideElement());
            } else {
              keysToRemove.push(element.id);
              delete mountedElements[element.id];
              delete dynamicReducers[element.id];

              refreshElementsReducer();

              if (sagaTask) {
                sagaTask.cancel();
              }
            }
          },
        };

        mountedElements[element.id] = mounted;

        // dispatch empty action to initialize state via reducers
        _store.dispatch(ELEMENT_INIT_ACTION);

        let sagaTask: Task | undefined;
        if (module.saga) {
          sagaTask = _sagaMiddleware.run(module.saga);
        }
      }

      return mounted;
    },

    /**
     * Mount an static page reducer and saga and return an object containing a function to unmount the element
     */
    mountStaticPage: (page: IStaticRouteConfig) => {
      if (!initialized) {
        throw new Error(
          "Reducer Manager not initialized. Call `.initialize()`.",
        );
      }

      if (page.reduxModule?.reducer) {
        staticPageKeysToRemove = staticPageKeysToRemove.filter(
          (k) => k !== page.reduxModule?.constants["MODULE_NAME"],
        );

        dynamicReducers[page.reduxModule.constants["MODULE_NAME"]] =
          page.reduxModule.reducer;

        refreshStaticPagesReducer();
      }

      let sagaTask: Task | undefined;

      const mounted = {
        page,
        unmount: () => {
          if (page.reduxModule?.reducer) {
            staticPageKeysToRemove.push(
              page.reduxModule.constants["MODULE_NAME"],
            );

            delete dynamicReducers[page.reduxModule.constants["MODULE_NAME"]];

            refreshStaticPagesReducer();
          }

          if (sagaTask) {
            sagaTask.cancel();
          }
        },
      };

      if (page.reduxModule?.saga) {
        sagaTask = _sagaMiddleware.run(page.reduxModule.saga);
      }

      _store.dispatch(STATIC_MOUNTED_ACTION);

      return mounted;
    },

    /*
     * The root reducer function exposed by this object.
     * This will be passed to the store.
     */
    reduce: combineReducers({
      ...initialReducers,
      [ELEMENTS_KEY]: elementsReducer,
      staticPages: staticPagesReducer,
    }),

    /*
     * The root saga exposed by this object.
     * This will be passed to `sagaMiddleware.run`.
     */
    saga: function* mainSaga() {
      const allModules = [...INITIAL_MODULES];
      const sagas = Object.keys(allModules).reduce((s, k) => {
        const saga = allModules[k].saga;
        return saga ? [...s, saga()] : s;
      }, [] as any[]);

      yield all(sagas);
    },
  };
}

/*
 * Merge the initialModules' reducers with the extraReducers checking for duplicated keys and return the merged map.
 */
function getInitialModulesReducers() {
  return [...INITIAL_MODULES].reduce((r, { reducer, MODULE_NAME }) => {
    if (MODULE_NAME in r) {
      throw new Error(`Duplicated module name "${MODULE_NAME}"`);
    }
    return reducer ? { ...r, [MODULE_NAME]: reducer } : r;
  }, {} as ReducersMapObject<any, AnyFluxStandardAction>);
}

function getTypeCheckedSelectors(
  { name, selectorTypes = {} }: IElementType,
  { config }: IElementModel,
  selectors: SelectorsMap,
) {
  const missingSelectors = new Set(Object.keys(selectorTypes));
  const typedSelectors: SelectorsMap = {};
  for (const key of Object.keys(selectors)) {
    const type = selectorTypes[key];
    if (type) {
      typedSelectors[key] = getTypeCheckedSelector(
        name,
        type,
        key,
        selectors[key],
        config,
      );
      missingSelectors.delete(key);
    } else {
      typedSelectors[key] = selectors[key];
    }
  }
  if (missingSelectors.size) {
    throw new Error(
      `The following selectors are missing in type "${name}": ${Array.from(
        missingSelectors,
      ).join(", ")}`,
    );
  }
  return typedSelectors;
}

const getTypeCheckedSelector = (
  elementTypeName: string,
  typeOrFactory: Type | TypeFactory,
  key: string,
  selector: Selector<any>,
  config: IElementModel["config"],
) => {
  const typeFactory =
    typeof typeOrFactory === "function" ? typeOrFactory : () => typeOrFactory;
  return (state: any) => {
    const value = selector(state);
    const editModeOn = editor.selectors.editModeOn(state);
    if (editModeOn) {
      const result = typeFactory({ config, state }).validate(value);
      if (result !== true) {
        throw new Error(
          `Invalid value for selector "${key}" of element type "${elementTypeName}": ${result.message}`,
        );
      }
    }
    return value;
  };
};

export type ReducerManager = ReturnType<typeof createReducerManager>;
