import * as t from "io-ts";
import {
  DEFAULT_LANGUAGE_CODE,
  IElement,
  IElementArrayChild,
  IElementSingleChild,
  TypeFactory,
  UntransformedConfig,
  customExpression,
} from "core/types";
import { selectors } from "core/editor/reduxModule";

import { Type, buildObjectViewType, types } from "core/runtime-typing";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";
import { FormInputConfig } from "../common";
/**
 * TODO:
 * Maybe move the filter typing to a `common` folder, since it's accessed by more than one element.
 */
import { FixedFilterGroup } from "../default_table/toolsPanel";
import { SelectorTypes } from "core/types/element";

export const OptionI18n = t.type({
  label: t.string,
});

export const TranslatedSelectOption = t.intersection([
  t.type({
    value: t.union([t.string, t.number, t.boolean]),
    i18n: t.type({ [DEFAULT_LANGUAGE_CODE]: OptionI18n }),
  }),
  t.record(t.string, t.any),
]);

export const SelectOption = t.intersection([
  t.type({
    value: t.union([t.string, t.number, t.boolean]),
    label: t.string,
  }),
  t.record(t.string, t.any),
]);

export type SelectOption = t.TypeOf<typeof SelectOption>;
export type TranslatedSelectOption = t.TypeOf<typeof TranslatedSelectOption>;

const getDataRowType = ({
  isArray = false,
  isNullable = false,
} = {}): TypeFactory<AutocompleteInputConfig> => ({ config, state }) => {
  let type: Type;
  const viewName = config.reference?.viewName;
  const viewList = selectors.viewList(state);
  if (!viewList) {
    // view list still loading, do not show any typing errors
    type = types.any();
  } else if (!viewName) {
    type = types.optional(types.null(), "no source view set");
  } else {
    const view = viewList.find((v) => v.name === viewName);
    if (!view) {
      throw new Error(`Invalid view ${viewName}`);
    }
    type = buildObjectViewType(view, "The data for the row");
    if (isArray) {
      type = types.array(type, "the data rows");
    }
    if (isNullable) {
      type = types.nullable(type, type.description);
    }
  }
  return type;
};

const valueObjectSelector: TypeFactory<AutocompleteInputConfig> = ({
  config,
}) => {
  const option = types.interface({
    value: types.optional(
      types.nullable(
        types.union([types.string(), types.number(), types.boolean()]),
      ),
    ),
    label: types.optional(types.string()),
  });
  if (config.isMulti) {
    return types.array(option);
  } else {
    return types.nullable(option);
  }
};

const rawValueObjectSelector: TypeFactory<AutocompleteInputConfig> = ({
  config,
  state,
}) =>
  config.reference
    ? getDataRowType({ isArray: config.isMulti, isNullable: true })({
        config,
        state,
      })
    : types.null(
        "no raw values available, because data does not come from view",
      );

const valueSelector: TypeFactory<AutocompleteInputConfig> = ({
  config: { isMulti },
}) =>
  isMulti
    ? types.nullable(
        types.union([
          types.array(types.string()),
          types.array(types.number()),
          types.array(types.boolean()),
        ]),
        "the current values",
      )
    : types.nullable(
        types.union([types.string(), types.number(), types.boolean()]),
        "the current value",
      );

export const autocompleteInputSelectors: SelectorTypes<AutocompleteInputConfig> = {
  value: valueSelector,
  searchInputValue: types.nullable(types.string(), "the current search text"),
  options: types.nullable(
    types.array(
      types.interface({
        value: types.nullable(
          types.union([types.string(), types.number(), types.boolean()]),
        ),
        label: types.string(),
      }),
    ),
    "the available options",
  ),
  loadingOptions: types.boolean(),
  optionsError: types.nullable(types.string()),
  errors: types.any(),
  touched: types.boolean(),
  valueObject: valueObjectSelector,
  rawOptions: getDataRowType({ isArray: true, isNullable: true }),
  rawValueObject: rawValueObjectSelector,
  disabled: types.boolean(),
};

export const AutocompleteInputConfig = t.intersection([
  WithOptionalFieldDataSourceConfig(),
  FormInputConfig,
  t.union([
    // either `reference` or `options` as data source, but not both
    t.type({
      reference: t.type({
        columnName: t.string,
        columnLabel: t.string,
        viewName: t.string,
      }),
      options: t.undefined,
    }),
    t.type({
      reference: t.undefined,
      options: t.array(t.union([TranslatedSelectOption, t.null])),
    }),
  ]),
  t.union([
    // either `allowSameValue` or `withCheckbox`, both doesn't make sense
    t.partial({
      allowSameValue: t.union([t.undefined, t.boolean]),
      withCheckbox: t.union([t.undefined, t.literal(false)]),
    }),
    t.partial({
      allowSameValue: t.union([t.undefined, t.literal(false)]),
      withCheckbox: t.union([t.undefined, t.boolean]),
    }),
  ]),
  t.partial({
    isMulti: t.boolean,
    isClearable: t.boolean,
    filter: customExpression(t.union([t.null, FixedFilterGroup])),
    fullTextSearch: t.boolean,
    fetchDelay: t.number,
    autosuggestHighlight: t.boolean,
    virtualizedList: t.boolean,
    firstOptionSelected: t.boolean,
    size: t.keyof({
      small: null,
      medium: null,
    }),
    chipVariant: t.keyof({
      default: null,
      outlined: null,
    }),
  }),
]);

export type AutocompleteInputConfig = t.TypeOf<typeof AutocompleteInputConfig>;

export type UntransformedAutocompleteInputConfig = UntransformedConfig<
  AutocompleteInputConfig
>;

export const AutocompleteInputTranslationKeys = ["label"] as const;

export type AutocompleteInputTranslationKeys = typeof AutocompleteInputTranslationKeys[number];

export type AutocompleteInput = IElement<
  AutocompleteInputConfig,
  Record<string, IElementSingleChild | IElementArrayChild>,
  AutocompleteInputTranslationKeys
>;
