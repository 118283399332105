import React, { memo, useMemo } from "react";

import defaultsDeep from "lodash/defaultsDeep";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import * as locales from "@material-ui/core/locale";

import defaultTheme from "./defaultThemeDefinition";

import { IAppMetadata, Language } from "core";

export type IThemeContainerProps = {
  appMetadata: IAppMetadata | null | undefined;
  language: Language;
};

const ThemeContainer = memo<IThemeContainerProps>(
  ({ appMetadata, children, language }) => {
    const {
      definition: {
        layout: {
          definition: { theme: themeOptions = {} },
        },
      },
    } = appMetadata?.release ?? { definition: { layout: { definition: {} } } };

    const themeDefinition = useMemo(
      () => defaultsDeep({}, themeOptions, defaultTheme(themeOptions)),
      [themeOptions],
    );

    const theme = useMemo(
      () => createTheme(themeDefinition, locales[language.locale]),
      [themeDefinition, language.locale],
    );

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
  },
);

ThemeContainer.displayName = "ThemeContainer";

export default ThemeContainer;
