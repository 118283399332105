import { alpha, createStyles, makeStyles } from "@material-ui/core/styles";
import { path } from "ramda";
import { Colors, ContrastColors } from "../StyledTypography";

interface Props {
  color?: string;
  variant?: string;
}

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: (props: Props) => {
      if (!props.color) {
        return {};
      }
      const color: string | unknown = path(
        props.color.split("."),
        theme.palette,
      );

      if (color === undefined) {
        return {};
      }

      const namespace = theme.palette[props.color.split(".")[0]];

      // we don't have the "from"
      const colorMapping = Object.entries(Colors).find(
        ([_from, to]) => props.color === to,
      );

      if (!colorMapping) {
        return {};
      }

      const contrastColor: string | undefined = path(
        ContrastColors[colorMapping[0]].split("."),
        theme.palette,
      );

      if (!contrastColor) {
        return {};
      }

      // careful, the namespaces "text" and common do not have the properties accessed below
      // e.g. contrastText, main, dark
      // that's why we need to fallback via "??"

      // contained styles
      // https://github.com/mui-org/material-ui/blob/f285808fbf8728b0cc0435e65a76dbc691be3643/packages/material-ui/src/Button/Button.js#L138
      const containedRoot = {
        color: contrastColor,
        backgroundColor: namespace.main ?? color,
        "&:hover": {
          backgroundColor: namespace.dark ?? color,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: namespace.main ?? color,
          },
        },
      };

      // outlined styles
      // https://github.com/mui-org/material-ui/blob/f285808fbf8728b0cc0435e65a76dbc691be3643/packages/material-ui/src/Button/Button.js#L80
      const outlinedRoot = {
        color: namespace.main ?? color,
        border: `1px solid ${alpha(namespace.main ?? color, 0.5)}`,
        "&:hover": {
          border: `1px solid ${namespace.main ?? color}`,
          backgroundColor: alpha(
            namespace.main ?? color,
            theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
      };

      return props.variant === "contained" ? containedRoot : outlinedRoot;
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }),
);
