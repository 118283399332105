import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Button from "elementTypes/common/Button";
import { actions as routerActions } from "core/router/reduxModule";
import { EditUserForm } from "../types";
import useStyles from "../styles";
import { User } from "../../../types";
import { useEditUser } from "../../../../../../../queries/admin";
import { useSnackbar } from "../../../../../../../utils/hooks/useSnackbar";
import { getApiError } from "../../../../../../../queries/utils";

export const Form = memo<{ user: User; roleOptions: any[] }>(
  ({ roleOptions, user }) => {
    const showSnackbar = useSnackbar();
    const dispatch = useDispatch();
    const { formPaper, footer, dividerFooter } = useStyles();
    const {
      handleSubmit,
      register,
      formState: { isSubmitting, isValid, dirty },
      control,
    } = useForm<EditUserForm>({
      mode: "onChange",
      defaultValues: {
        isActive: user!.isActive,
        password: "",
        role: user!.role,
      },
    });

    const editUser = useEditUser({
      onSuccess: () => {
        showSnackbar("User edited successfully", "success");
        dispatch(routerActions.push("/admin/users"));
      },
      onError: (er) =>
        showSnackbar(`User was not edited: ${getApiError(er)}`, "error"),
    });

    const submit = (data: EditUserForm) => {
      const userData: EditUserForm = {};

      if (data.isActive !== user!.isActive) {
        userData.isActive = data.isActive;
      }

      if (data.role !== user!.role) {
        userData.role = data.role;
      }

      if (data.password) {
        userData.password = data.password;
      }

      editUser.mutate({ userId: user!.id, user: userData });
    };

    const defaultValue = roleOptions.find(
      (option) => option.value === user!.role,
    );

    return (
      <form autoComplete="off" onSubmit={handleSubmit(submit)}>
        <Paper variant="outlined" className={formPaper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                defaultValue={defaultValue}
                options={roleOptions}
                getOptionLabel={(option: { label: string; value: string }) =>
                  option.label
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Role"
                    name="role"
                    inputRef={register({})}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="password"
                label="Password"
                type="password"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={register({})}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <FormControlLabel control={<Checkbox />} label={"Active"} />
                }
                name="isActive"
                control={control}
              />
            </Grid>
            <Grid item xs={12} className={footer}>
              <Divider className={dividerFooter} />
              <Button
                color="secondary"
                disabled={isSubmitting || !isValid || !dirty}
                processing={isSubmitting}
                iconRight="forward"
                type="submit"
                label="Edit"
              />
            </Grid>
          </Grid>
        </Paper>
      </form>
    );
  },
);
