import { createTypeSafeContext } from "utils/createTypeSafeContext";
import { ILocation } from "./reduxModule";

interface IRouterContext {
  location: ILocation | null;
}

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  IRouterContext
>();

export const useRouterContext = useTypeSafeContext;
export const RouterContextProvider = Provider;
export const RouterContextConsumer = Consumer;
