import React, { memo, useCallback } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { Section, useElementEditorContext } from "core/editor";
import { useEditorTranslation } from "../../translation";
import { FormInputConfig } from "elementTypes/common";
import { useParentForm } from "../../hooks/useParentForm";
import { JSONSchema6 } from "json-schema";
import { WithOptionalFieldDataSourceConfig } from "../../../../elementInterfaces";

export const FormInputConfigEditorComponent = memo(({ children }) => {
  const {
    elementModel: {
      config: { disabled, nullable, dataSource },
    },
    changeConfigValue,
  } = useElementEditorContext<
    FormInputConfig & WithOptionalFieldDataSourceConfig
  >();
  const editorTranslation = useEditorTranslation();

  const handleDisabledChange = useCallback(
    (newValue: FormInputConfig["disabled"]) =>
      changeConfigValue("disabled", newValue),
    [changeConfigValue],
  );
  const handleNullableChange = useCallback(
    (newValue: FormInputConfig["nullable"]) =>
      changeConfigValue("nullable", newValue),
    [changeConfigValue],
  );

  const handleDisabledInputChange = () => handleDisabledChange(!disabled);
  const handleNullableInputChange = () => handleNullableChange(!nullable);

  const { parentJSONSchema, parentIsReadOnly } = useParentForm();
  const requiredFields = (parentJSONSchema as JSONSchema6)?.required ?? [];

  // since we don't have nesting fields validation so far,
  // take the first field in the path
  const fieldName = dataSource?.fieldPath?.[0]?.toString() ?? "";
  const isRequiredInForm = requiredFields.includes(fieldName);

  const isNullable = nullable === false;

  return (
    <Section title={editorTranslation.inputConfigTitle} wrapped={true}>
      <>{children}</>
      <FormControlLabel
        control={
          <Switch
            checked={parentIsReadOnly || Boolean(disabled)}
            onChange={handleDisabledInputChange}
          />
        }
        label={editorTranslation.disabledLabel}
        disabled={parentIsReadOnly}
      />
      {(!dataSource?.elementId || isRequiredInForm) && (
        <FormControlLabel
          control={
            <Switch
              checked={isRequiredInForm ? false : !isNullable}
              onChange={handleNullableInputChange}
            />
          }
          label={editorTranslation.nullableLabel}
          disabled={isRequiredInForm}
        />
      )}
    </Section>
  );
});
