import * as t from "io-ts";
import {
  IElement,
  SelectorTypes,
  UntransformedConfig,
  customExpression,
} from "core/types";
import { selectors } from "core/editor/reduxModule";
import { Type, buildObjectViewType, types } from "core/runtime-typing";

export const DataDisplayConfig = t.intersection([
  t.type({
    dataSource: t.intersection([
      t.type({
        viewName: t.string,
      }),
      t.partial({
        identifierName: t.string,
        // should only the first row or the whole array be available
        setAllData: t.boolean,
      }),
    ]),
    // a format expression, e.g. `@@expression: element.data.value + " USD"`
    format: customExpression(t.any),
  }),
  t.partial({
    // identifier value, used to build query filter, e.g. `@@expression: location.query.identifier`
    identifier: customExpression(t.any),
    // an icon identifier, e.g. `flight_takeoff`
    icon: t.string,
    // the color to use, e.g. `success`, `info`, `error`, `warning`
    color: customExpression(t.string),
    // where to place the bar, null if bar is disabled
    barPosition: t.union([
      t.null,
      t.keyof({
        top: null,
        right: null,
        bottom: null,
        left: null,
      }),
    ]),
  }),
]);

export const dataDisplaySelectors: SelectorTypes<DataDisplayConfig> = {
  loading: types.boolean(
    "Indicates if the data is in process of being loaded from the server",
  ),
  error: types.nullable(types.string()),
  formattedData: types.optional(
    types.union([types.string(), types.number(), types.anyRecord()]),
  ),
  identifier: types.optional(types.union([types.number(), types.string()])),
  color: types.optional(types.string()),
  data: ({ config, state }) => {
    let type: Type;
    const viewName = config.dataSource.viewName;
    const viewList = selectors.viewList(state);
    if (!viewList) {
      // view list still loading, do not show any typing errors
      type = types.any();
    } else if (!viewName) {
      type = types.optional(types.null(), "no source view set");
    } else {
      const view = viewList.find((v) => v.name === viewName);
      if (!view) {
        throw new Error(`Invalid view ${viewName}`);
      }
      type = buildObjectViewType(view);
      if (config.dataSource.setAllData) {
        type = types.array(type);
      }
    }
    return types.nullable(type);
  },
};

export type DataDisplayConfig = t.TypeOf<typeof DataDisplayConfig>;

export type UntransformedDataDisplayConfig = UntransformedConfig<
  DataDisplayConfig
>;

export const DataDisplayTranslationKeys = ["label"] as const;

export type DataDisplayTranslationKeys = typeof DataDisplayTranslationKeys[number];

export type DataDisplay = IElement<
  DataDisplayConfig,
  {},
  DataDisplayTranslationKeys
>;
