import React, { memo } from "react";

import { getArrow } from "perfect-arrows";

interface ButtonEdgeProps {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
}

function ConnectionLine({
  sourceX,
  sourceY,
  targetX,
  targetY,
}: ButtonEdgeProps) {
  const arrow = getArrow(sourceX, sourceY, targetX, targetY);

  const [sx, sy, cx, cy, ex, ey, ae] = arrow;
  const endAngleAsDegrees = ae * (180 / Math.PI);

  return (
    <>
      <path
        style={{ strokeWidth: 2 }}
        className="react-flow__edge-path"
        d={`M${sx},${sy} Q${cx},${cy} ${ex},${ey}`}
        fill="none"
      />
      <polygon
        points="0,-6 12,0, 0,6"
        transform={`translate(${ex},${ey}) rotate(${endAngleAsDegrees})`}
      />
    </>
  );
}

export default memo(ConnectionLine);
