import { UseQueryOptions } from "react-query";
import AdminService from "services/admin";
import { FunctionNameList } from "./types";
import { Query, useAuthenticatedQuery } from "../utils";
import { QueryKeys } from "./queryKeys";

const getOptions = (list: FunctionNameList[]) =>
  list.map((item) => ({
    label: item.name,
    value: item.name,
    argnames: item.argnames?.filter(Boolean),
  }));

const services = AdminService.getInstance();

export const fetchApps: Query<ReturnType<typeof services.getAllUi>> = {
  queryKey: QueryKeys.fetchApps,
  queryFn: services.getAllUi,
};

export const fetchFunctions: Query<FunctionNameList[]> = {
  queryKey: QueryKeys.fetchFunctions,
  queryFn: services.getFunctionsName,
};

type SelectorOptions = { label: string; value: string; argnames?: string[] }[];

export const useApps = () => useAuthenticatedQuery(fetchApps);

export const useFunctions = (
  options?: UseQueryOptions<FunctionNameList[], Error, SelectorOptions>,
) => useAuthenticatedQuery(fetchFunctions, undefined, options);

export const useFunctionOptions = () => useFunctions({ select: getOptions });
