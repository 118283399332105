import React, { memo } from "react";
import { Box, Divider, Paper, TextField, Typography } from "@material-ui/core";
import { Controller } from "react-hook-form";

import {
  HookForm,
  useHookFormContext,
} from "../../../../../../elementTypes/common/HookForm";
import BackButton from "../../../../../../elementTypes/common/BackButton";
import Button from "../../../../../../elementTypes/common/Button";
import { useCreateUserRole } from "../../../../../../queries/admin";
import { getApiError } from "../../../../../../queries/utils";
import { useRoute, useSnackbar } from "../../../../../../utils/hooks";
import { useHookFormError } from "../../../../../../utils/hooks/useHookFormError";
import { useRolePagesTranslation } from "../../translation";

const ROLES_URL = "/admin/roles";

export const CreateRolePage = memo(() => {
  const showSnackbar = useSnackbar();
  const route = useRoute();

  const create = useCreateUserRole({
    onSuccess: (newRole) => {
      showSnackbar(
        translation.successMsg.replace("*", `"${newRole.name}"`),
        "success",
      );
      route("push", ROLES_URL);
    },
    onError: (apiError) => {
      showSnackbar(getApiError(apiError), "error");
    },
  });

  const handleSubmit = (newRole: Record<string, unknown>) => {
    create.mutate(newRole as { name: string });
  };

  const translation = useRolePagesTranslation();

  return (
    <HookForm onSubmit={handleSubmit} formOptions={{ mode: "onChange" }}>
      <Box display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon href={ROLES_URL} />
        <Typography variant="h5">{translation.createPageTitle}</Typography>
      </Box>
      <FormContent />
    </HookForm>
  );
});

const FormContent = memo(() => {
  const {
    control,
    formState: { isSubmitting },
    errors,
  } = useHookFormContext();

  const handleValidate = (val: string) => !!val.trim();

  const getErrorMessage = useHookFormError();

  const translation = useRolePagesTranslation();

  return (
    <Paper variant="outlined">
      <Box p={1}>
        <Controller
          as={<TextField autoFocus />}
          name="name"
          control={control}
          rules={{
            required: true,
            validate: handleValidate,
          }}
          fullWidth
          label={translation.inputNameLabel}
          defaultValue=""
          error={Boolean(errors?.name)}
          helperText={getErrorMessage(errors.name)}
        />
      </Box>
      <Divider />
      <Box p={1}>
        <Button
          color="secondary"
          disabled={isSubmitting}
          processing={isSubmitting}
          iconRight="forward"
          type="submit"
          label={translation.createBtnLabel}
        />
      </Box>
    </Paper>
  );
});
