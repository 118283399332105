import React, { memo } from "react";

import { UserProfile } from "./components";
import useStyles from "./styles";

export const SettingsPage = memo(() => {
  const { root } = useStyles();

  return (
    <div className={root}>
      <UserProfile />
    </div>
  );
});
