import { call, put, select } from "redux-saga/effects";

import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import {
  DEFAULT_APP_URL,
  actions as routerActions,
} from "core/router/reduxModule";
import { actions } from "./actions";

import * as ApiService from "services/api";
import { LoginConfig } from "services/api";
import { getServerError } from "../../../core/utils/api";

function* bootstrap() {
  const token: string = yield select(sessionSelectors.token);
  const isLoggedIn: boolean = yield select(sessionSelectors.isLoggedIn);

  try {
    if (isLoggedIn) {
      // TODO check if `next` is set and only if not, redirect to default app url
      // necessary if you have multiple tabs open, all of them are redirected to "login"
      // , you sign in into one tab and refresh the others
      yield put(routerActions.replace(DEFAULT_APP_URL));

      return;
    }

    const config: LoginConfig = yield call(ApiService.getLoginConfig, token);
    yield put(actions.loadLoginConfigSuccess(config));
  } catch (error) {
    yield put(actions.loadLoginConfigError(getServerError(error)));
    yield put(
      sessionActions.enqueueSnackbar({
        message: "Login config could not be retrieved",
        options: {
          variant: "error",
        },
      }),
    );
  }
}

export function* saga() {
  yield bootstrap();
}
