import { OptionsObject, VariantType } from "notistack";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { actions as sessionActions } from "../../core/session/reduxModule";

export const useSnackbar = () => {
  const dispatch = useDispatch();

  return useCallback(
    (message: string, variant: VariantType, options?: OptionsObject) => {
      dispatch(
        sessionActions.enqueueSnackbar({
          message,
          options: {
            variant,
            ...(options ?? {}),
          },
        }),
      );
    },
    [dispatch],
  );
};
