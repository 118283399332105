import React, { memo, useState } from "react";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { AdvancedComponent, TranslationComponent } from "./components";
import { UntransformedJsonInputConfigConfig } from "../types";
import { JsonView } from "../../common/JsonView";

export const JsonInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const {
    elementModel: {
      config: { dataSource, defaultValue },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedJsonInputConfigConfig>();

  const changeLanguage = (l: Language) => setLang(l);
  const changeDefaultInputValue = (value: any) =>
    changeConfigValue("defaultValue", value);

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["json"]}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        <JsonView
          disabled={Boolean(dataSource)}
          value={defaultValue ?? {}}
          onValueChange={changeDefaultInputValue}
          label={editorTranslation.defaultValueInputLabel}
        />
      </FormInputConfigEditorComponent>
      <AdvancedComponent />
    </>
  );
});
