import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";

import { format as formatDate } from "date-fns";

import { stringToDateObject } from "utils/string";

import { DateTimeInput } from "../../types";
import { createSelector } from "reselect";
import { memoize } from "lodash";

export function buildValue(value: string | Date | null) {
  if (typeof value === "string") {
    return stringToDateObject(value);
  }
  return value;
}

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: DateTimeInput,
) {
  const selectValue = dataSource.createFieldValueSelector(fieldPath, {
    defaultValue: null,
  });
  const value = (state: any): Date | null => {
    const date = selectValue(state);

    return typeof date === "string" ? stringToDateObject(date) : date;
  };

  const disabled = () =>
    dataSource.isReadOnly || Boolean(element.config.disabled);

  const errors = dataSource.createFieldErrorSelector(fieldPath);
  const minDate = (state: any) =>
    element.config.minDate
      ? stringToDateObject(element.config.minDate(state))
      : null;
  const maxDate = (state: any) =>
    element.config.maxDate
      ? stringToDateObject(element.config.maxDate(state))
      : null;

  const timezone = (state: any) =>
    element.config.timezone ? element.config.timezone(state) : null;

  const format = createSelector([value], (v) =>
    memoize((formatString: string) =>
      v === null ? null : formatDate(v, formatString),
    ),
  );

  const required = () =>
    dataSource.createFieldRequiredSelector(fieldPath) ??
    element.config.nullable === false;

  const formatString = (state: any) =>
    element.config.formatString?.(state) ?? undefined;

  return {
    value,
    minDate,
    maxDate,
    disabled,
    errors,
    format,
    timezone,
    required,
    formatString,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
