import { Edge, Elements, Node } from "react-flow-renderer";

import { EdgeData, NodeData, State, StateChange, Workflow } from "./types";

const toNode = (s: State, stateChanges: StateChange[]) => {
  const isEditable = (state: State) =>
    !stateChanges
      .filter((sc) => sc.source === null || sc.target === null)
      .find((e) => e.source === state.id || e.target === state.id);

  return {
    id: `${s.id}`,
    data: {
      id: s.id,
      label: s.name,
      selfStateChangeId: s.selfStateChangeId,
      isEditable: isEditable(s),
    },
    type: "state",
    position: { x: 0, y: 0 },
  } as Node<NodeData>;
};

const toEdge = (sc: StateChange) =>
  ({
    id: `${sc.id}`,
    source: sc.source === null ? "start" : `${sc.source}`,
    target: sc.target === null ? "end" : `${sc.target}`,
    animated: false,
    isHidden: false,
    type: "transition",
    data: {
      i18n: sc.i18n,
    },
  } as Edge<EdgeData>);

export const workflowToReactFlowElements = (workflow: Workflow) => {
  const elements: Elements = [
    {
      id: "start",
      type: "start",
      position: { x: 0, y: 0 },
    } as Node,
    {
      id: "end",
      type: "end",
      position: { x: window.innerWidth * 0.8, y: 0 },
    } as Node,
  ];
  const { states, stateChanges } = workflow;

  states.forEach((s: State) => {
    elements.push(toNode(s, stateChanges));
  });

  stateChanges.forEach(
    (sc: StateChange) => sc.source !== sc.target && elements.push(toEdge(sc)),
  );

  return elements;
};
