import { createTypeSafeContext } from "utils/createTypeSafeContext";

export type Permissions = Record<string, string[]>;

interface PermissionContext {
  permissions: Permissions;
  setPermissions: (permissions: Permissions) => void;
  // remove when editing permissions will be implemented
  isEditMode?: boolean;
}

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  PermissionContext
>();

export const usePermissionContext = useTypeSafeContext;
export const PermissionProvider = Provider;
export const PermissionConsumer = Consumer;
