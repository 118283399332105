import React, { FC, memo, useMemo } from "react";

import { Box, Tooltip, Typography } from "@material-ui/core";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import Card from "@material-ui/core/Card";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

import IconButton from "../../../../../elementTypes/common/IconButton";
import { Column, NodeData } from "./types";
import { useStyles } from "./styles";
import { useTableNodeMenuContext } from "./tableNodeMenu";
import { StyledTypography } from "elementTypes/common/StyledTypography";
import { sortColumns } from "./utils";
import { useErdTranslation } from "./translation";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";

export const TABLE_WIDTH = 400;
export const MAX_COLUMNS_QUANTITY = 10;
export const TABLE_HEADER_HEIGHT = 48;
export const TABLE_BODY_ROW_HEIGHT = 40;

const TableNode: FC<NodeProps<NodeData>> = ({ data, id }) => {
  const { lookupLabelColumn, columns, tableName, schemaName } = data;

  const translation = useErdTranslation();
  const { node, nodeItem, handleClass } = useStyles();

  // if there are more than `MAX_COLUMNS_QUANTITY - 1` use the last column space
  // to display information that more columns exist
  const hasMoreColumns = columns.length >= MAX_COLUMNS_QUANTITY;
  const sortedColumns = useMemo(
    () =>
      sortColumns(columns).slice(
        0,
        hasMoreColumns ? MAX_COLUMNS_QUANTITY - 1 : MAX_COLUMNS_QUANTITY,
      ),
    [columns, hasMoreColumns],
  );

  const { onMenuOpen } = useTableNodeMenuContext();

  const items = sortedColumns.map((column: Column, index: number) => (
    <Box
      display="grid"
      className={nodeItem}
      key={`${column.name}-${index}`}
      alignItems="center"
      gridGap={8}
      gridTemplateColumns="max-content 1fr max-content"
      position="relative"
      height={TABLE_BODY_ROW_HEIGHT}
    >
      <Handle
        type="source"
        isConnectable={false}
        id={`${id}.${column.name}`}
        position={Position.Right}
        className={handleClass}
      />
      <Handle
        type="target"
        isConnectable={false}
        id={`${id}.${column.name}`}
        position={Position.Left}
        className={handleClass}
      />
      <div style={{ lineHeight: 1 }}>
        {column.primaryKey && (
          <Tooltip title={translation.primaryKeyTooltip}>
            <VpnKeyIcon fontSize="small" />
          </Tooltip>
        )}
        {column.foreignKey && (
          <Tooltip
            title={`${translation.foreignKeyTooltip}: ${column.foreignKey}`}
          >
            <CallReceivedIcon fontSize="small" />
          </Tooltip>
        )}
        {column.workflowConfigured && (
          <Tooltip title={translation.workflowTooltip}>
            <GroupWorkIcon fontSize="small" />
          </Tooltip>
        )}
        {column.name === lookupLabelColumn && (
          <Tooltip title={"Default Lookup column"}>
            <RemoveRedEyeIcon fontSize="small" />
          </Tooltip>
        )}
      </div>
      <Box display="flex" flex={1} gridGap="8" alignItems="center">
        <Typography>{column.name}</Typography>
        {!column.primaryKey && column.unique && (
          <Tooltip title={translation.uniqueTooltip}>
            <FingerprintIcon fontSize="small" />
          </Tooltip>
        )}
      </Box>
      <Box display="inline-flex">
        <StyledTypography
          typographyProps={{
            variant: "overline",
          }}
          text={column.type}
        />
        {!column.nullable || column.primaryKey ? (
          <StyledTypography
            typographyProps={{
              variant: "overline",
            }}
            text="*"
            tooltip={translation.notNullTooltip}
          />
        ) : (
          <StyledTypography
            typographyProps={{
              variant: "overline",
            }}
            text={NON_BREAKING_SPACE}
          />
        )}
      </Box>
    </Box>
  ));

  return (
    <Card variant="outlined" classes={{ root: node }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={TABLE_HEADER_HEIGHT}
      >
        {/* 48px - IconButton width */}
        <Box px={1} maxWidth="calc(100% - 48px)">
          <Tooltip title={`${schemaName}.${tableName}`}>
            <Typography variant="h5" noWrap>
              {tableName}
            </Typography>
          </Tooltip>
        </Box>
        <IconButton
          icon="MoreVert"
          onClick={onMenuOpen(data)}
          color="inherit"
        />
      </Box>
      {items}
      {hasMoreColumns && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={nodeItem}
          height={TABLE_BODY_ROW_HEIGHT}
        >
          <Typography>
            +{columns.length - MAX_COLUMNS_QUANTITY + 1} more columns
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default memo(TableNode);
