import React, { FC } from "react";

import InputAdornment from "@material-ui/core/InputAdornment";

import { IInputAdornment } from ".";
import { MuiIcon } from "../MuiIcon";

const EndInputAdornment: FC<IInputAdornment> = ({ icon, text }) => (
  <InputAdornment position="end">
    {icon ? <MuiIcon icon={icon} /> : text}
  </InputAdornment>
);

export default EndInputAdornment;
