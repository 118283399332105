import { Colors } from "elementTypes/common/StyledTypography";
import React, { memo } from "react";

import Button from "../common/Button";
import { CallButtonProps } from "./container";
import useStyles from "./styles";

const DefaultCallButton = memo<CallButtonProps>(
  ({
    element: {
      i18n,
      config: { variant },
    },
    disabled,
    icon,
    loading,
    load,
    color = "default",
  }) => {
    const { root } = useStyles();

    return (
      <Button
        onClick={load}
        label={i18n.label}
        className={root}
        iconRight={icon}
        processing={loading}
        disabled={loading || disabled}
        fullWidth
        color={Colors[color]}
        variant={variant}
      />
    );
  },
);

export default DefaultCallButton;
