import { selectorScoper } from "../../utils/redux";

import { MODULE_NAME } from "./constants";
import { IState } from "./types";

export const scopeSelector = selectorScoper<IState>([MODULE_NAME]);

export const baseSelectors = {
  newPages: (state: any) => scopeSelector(state).newPages,

  updatedElements: (state: any) => scopeSelector(state).updatedElements,

  updatedLayoutDefinition: (state: any) =>
    scopeSelector(state).updatedLayoutDefinition,
  updatedMenu: (state: any) => scopeSelector(state).updatedMenu,
};
