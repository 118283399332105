import React, { memo, useEffect, useMemo } from "react";
import MUITable from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import sortBy from "lodash/sortBy";

import { ToolsPanel } from "./toolsPanel";
import { DEFAULT_CELL_ALIGN, useStyles } from "./style";
import { ITableContext, TableContextProvider } from "./TableContext";
import { CellAlignment } from "./types";
import { Info, TableBody, TableFooter, TableHead } from "./components";
import { useTableTranslation } from "./translation";
import { Props } from "./container";
import { LoadingComponent } from "layouts/common/Loading";
import { emptySelectedRow } from "./reduxModule/reducer";
import { TableHeaderCell } from "../default_table_header_cell/types";

const emptyArray: any[] = [];

const DefaultTable = memo<Props>(
  ({
    applyFilter,
    changeFilter,
    data,
    element,
    nextFilter,
    changeOffset,
    params,
    nextPageAvailable,
    changeLimit,
    load,
    orderIndexed,
    getColOrder,
    changeOrder,
    error,
    loading,
    searchInputValue,
    changeSearchValue,
    metadata,
    startPolling,
    stopPolling,
    selectRow,
    selected,
    references: referencesData,
    hidden,
  }) => {
    const {
      children: {
        header: { elements: headerElements },
        body: { elements: bodyElements },
      },
      config: {
        filter: { fields },
        simpleFilter,
        interval,
        canSelectRow,
        dataSource: { references: referencesSource },
      },
    } = element;

    const sortedHeaderElements = useMemo(
      () =>
        sortBy(headerElements as TableHeaderCell[], [
          "position",
          "column",
        ]).filter((_e, i) => !hidden?.[i]),
      [headerElements, hidden],
    );

    const sortedBodyElements = useMemo(
      () =>
        sortBy(bodyElements, ["position", "column"]).filter(
          (_e, i) => !hidden?.[i],
        ),
      [bodyElements, hidden],
    );

    const bodyElementsAlignments = useMemo(
      () =>
        sortedHeaderElements.map<CellAlignment>(
          (el) => el.config.align || DEFAULT_CELL_ALIGN,
        ),
      [sortedHeaderElements],
    );

    const { identifierName, stateColumnName } = element.config.dataSource;
    const { root, tableWrapper } = useStyles();

    const { textNoData } = useTableTranslation();

    useEffect(() => {
      if (interval) {
        startPolling();
      }

      return () => {
        if (interval) {
          stopPolling();
        }
      };
    }, [interval, startPolling, stopPolling]);

    const tableContext: ITableContext = useMemo(
      () => ({
        orderIndexed,
        getColOrder,
        changeOrder,
        metadata,
        disableSort: !data?.length,
        identifyingColumnName: identifierName,
        stateColumnName,
        changeFilter,
        error,
      }),
      [
        changeFilter,
        changeOrder,
        data,
        error,
        getColOrder,
        identifierName,
        metadata,
        orderIndexed,
        stateColumnName,
      ],
    );

    return (
      <TableContextProvider value={tableContext}>
        <Paper className={root} variant="outlined">
          <ToolsPanel
            filter={nextFilter}
            fields={fields}
            simpleFilter={simpleFilter}
            applyFilter={applyFilter}
            searchInputValue={searchInputValue}
            changeSearchValue={changeSearchValue}
            loading={loading}
            data={data}
          />
          <section className={tableWrapper}>
            <MUITable stickyHeader>
              <TableHead elements={sortedHeaderElements} />
              <TableBody
                data={data || emptyArray}
                elements={sortedBodyElements}
                alignments={bodyElementsAlignments}
                identifierName={identifierName}
                error={error}
                loading={loading}
                canSelectRow={canSelectRow}
                selectRow={selectRow}
                selected={canSelectRow ? selected : emptySelectedRow}
                referencesData={referencesData}
                referencesSource={referencesSource}
              />
            </MUITable>
            {loading && <LoadingComponent />}
            {(error || !(data && !!data.length)) && !loading && (
              <Info
                error={!!error}
                msg={error || textNoData}
                icon={"error_outline"}
                data-test-selector="table-info"
                data-is-error={!!error}
              />
            )}
          </section>
          <TableFooter
            params={params}
            data={data || emptyArray}
            nextPageAvailable={nextPageAvailable}
            onRefresh={load}
            onPageSizeChange={changeLimit}
            onChangePage={changeOffset}
            rowsPerPageOptions={element.config.rowsPerPage}
            loading={loading}
          />
        </Paper>
      </TableContextProvider>
    );
  },
);

DefaultTable.displayName = "DefaultTable";

export default DefaultTable;
