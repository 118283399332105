import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      padding: ({ disableSpacing }: { disableSpacing?: boolean }) =>
        disableSpacing ? 0 : theme.spacing(2),
      display: "flex",
      flexDirection: "column",
    },
    body: {
      flexGrow: 1,
      overflowY: "auto",
      padding: theme.spacing(),
    },
    divider: {
      margin: `${theme.spacing()}px 0`,
    },
  }),
);

export default useStyles;
