import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

type ErrorMessage = {
  error: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(),
    },
  }),
);

export const ErrorMessage = memo<ErrorMessage>(({ error }) => {
  const { paper } = useStyles();

  return (
    <Paper variant="outlined" className={paper}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h5">Error</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="error">{error}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
});
