import { makeStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      width: "100%",
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    button: {
      width: "100%",
      height: "100%",
    },
  }),
);

export default useStyles;
