import { useCallback } from "react";
import {
  FlowElement,
  useStoreActions,
  useStoreState,
} from "react-flow-renderer";

export const getListWithIndex = <Entity extends { id: any }>(
  prevList: Entity[],
  list: Entity[],
) => {
  return list.reduce(
    (res, item, index) =>
      prevList.some((i) => i.id === item.id) ? res : { ...item, index },
    {},
  );
};

export const useSelectedState = () => {
  const selectedElement = useStoreState((store) => store.selectedElements)?.[0];
  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements,
  );

  const setSelectedElement = useCallback(
    (element: FlowElement | null) => {
      setSelectedElements(element ?? []);
    },
    [setSelectedElements],
  );

  return {
    selectedElement,
    setSelectedElement,
  };
};
