import Component from "./container";
import { TableEditor, defaultElement, editorMetadata } from "./editor";
import { reduxModuleFactory } from "./reduxModule";
import { TableChildren, TableConfig, tableSelectors } from "./types";
import { IRawElementType } from "core";

const elementType: IRawElementType = {
  reduxModuleFactory,
  name: "default_table",
  component: Component,
  configType: TableConfig,
  childrenType: TableChildren,
  selectorTypes: tableSelectors,
  editorComponent: TableEditor,
  editorMetadata,
  defaultElement,
};

export default elementType;

export * from "./reduxModule/types";
export { useTableContext } from "./TableContext";
