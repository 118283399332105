import React, { memo, useMemo, useState } from "react";

import { Box, Card, Divider, Typography } from "@material-ui/core";
import { Elements, FlowElement, ReactFlowProvider } from "react-flow-renderer";

import { useSelector } from "react-redux";

import BackButton from "elementTypes/common/BackButton";
import { WorkflowViewer } from "./components";
import { LayoutOptions } from "../component";
import { ToggleLayoutPanel } from "../components/ToggleLayoutPanel";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { workflowToReactFlowElements } from "./components/StateViewer/utils";
import { useWorkFlow } from "queries/admin/modelBuilderData";
import { useWorkflowTranslations } from "./translation";
import Button from "../../../../../elementTypes/common/Button";
import { EnforceWorkflow } from "./components";
import { LoadingComponent } from "../../../../../layouts/common/Loading";
import { StyledSchemaTableName } from "../components/styledSchemaTableName";
import { RightPanel } from "./components";
import IconButton from "../../../../../elementTypes/common/IconButton";
import { useSelectedState } from "./utils";

export const WorkflowPageComponent = memo(() => {
  const translation = useWorkflowTranslations();
  const [direction, setDirection] = useState<LayoutOptions>(
    LayoutOptions.horizontal,
  );

  const location = useSelector(routerSelectors.location);
  const {
    queries: { schema, table },
  } = location;

  const workflowResponse = useWorkFlow(
    {
      schema,
      table,
    },
    {
      enabled: !!schema && !!table,
    },
  );

  const workflow = workflowResponse?.data;

  const getStateById = (id: string) =>
    workflow?.states?.find((state) => String(state.id) === id);

  const elements = (useMemo(
    () => workflow && workflowToReactFlowElements(workflow),
    [workflow],
  ) ?? []) as Elements;

  const stateList = elements.filter((element) => element.type === "state");

  if (workflowResponse.isLoading) {
    return <LoadingComponent />;
  }

  const onChangeLayout = (_event: any, nextValue: LayoutOptions) =>
    nextValue && setDirection(nextValue);

  return (
    <ReactFlowProvider>
      <Box
        width="100%"
        height="100%"
        maxHeight="100%"
        display="grid"
        gridGap={8}
        gridTemplateColumns="3fr minmax(300px, 1fr)"
        gridTemplateRows="min-content 1fr"
        p={0.5}
      >
        <Box gridColumn="1 / span 2">
          <Card variant="outlined">
            <Box display="flex" alignItems="center" p={1}>
              <BackButton isIcon={true} href="/admin/database" />
              <Box display="flex" flexDirection="column">
                <Typography variant="h5">
                  {translation.workflowTitle}
                </Typography>
                <StyledSchemaTableName schemaName={schema} tableName={table} />
              </Box>
              <Box
                flex="1"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gridGap={8}
              >
                <ToggleLayoutPanel
                  onChange={onChangeLayout}
                  value={direction}
                />
                <Divider orientation="vertical" flexItem={true} />
                <EnforceWorkflow value={!!workflow?.workflowActivated} />
                <CreateNewStateBtn />
              </Box>
            </Box>
          </Card>
        </Box>
        <Box gridColumn="1 / 1" overflow="auto">
          <WorkflowViewer
            directionValue={direction}
            sourceContent={elements}
            getStateById={getStateById}
          />
        </Box>
        <Box
          gridColumn="2 / 2"
          gridRow="2"
          display="flex"
          flexDirection="column"
          gridGap={8}
          overflow="auto"
          height="100%"
        >
          <RightPanel list={stateList} />
        </Box>
      </Box>
    </ReactFlowProvider>
  );
});

export const CreateNewStateBtn = memo<{ isIcon?: boolean }>(({ isIcon }) => {
  const translation = useWorkflowTranslations();
  const { setSelectedElement } = useSelectedState();

  const handleClick = () =>
    setSelectedElement({ type: "state" } as FlowElement);

  return isIcon ? (
    <IconButton
      icon="add"
      onClick={handleClick}
      color="primary"
      tooltip={translation.newState}
    />
  ) : (
    <Button
      label={translation.newState}
      onClick={handleClick}
      iconRight="add"
      color="primary"
    />
  );
});
