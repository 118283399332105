import React, { MouseEvent, memo, useState } from "react";

import clsx from "classnames";

import { ColorResult, SketchPicker } from "react-color";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel, {
  FormControlLabelProps,
} from "@material-ui/core/FormControlLabel";
import Popover from "@material-ui/core/Popover";

import { useStyles } from "./style";

export type Placement = FormControlLabelProps["labelPlacement"];

type Props = {
  value: string;
  changeValue: (value: string | null) => void;
  label?: string | JSX.Element;
  disabled?: boolean;
  placement?: Placement;
  required?: boolean;
  fullHeight?: boolean;
};

export const ColorInput = memo<Props>(
  ({
    value,
    changeValue,
    label,
    disabled,
    placement = "end",
    required,
    fullHeight,
  }) => {
    const color = value || "transparent";
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

    // always save the currently selected color in localColor, even while still selecting
    // when finished selecting, actually call changeValue and reset the localColor
    const [localColor, setLocalColor] = useState<string | null>(null);
    const classes = useStyles({
      isLabel: Boolean(label),
      bgColor: color,
      placement,
    });

    const handleClick = (event: MouseEvent<any>) =>
      setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleChange = ({ hex }: ColorResult) => {
      const nextVal = hex === "transparent" && !required ? null : hex;
      if (nextVal !== value) {
        changeValue(nextVal);
        setLocalColor(null);
      }
    };

    const handleLocalChange = ({ hex }: ColorResult) => {
      if (hex !== value) {
        setLocalColor(hex);
      }
    };

    const component = (
      <button
        onClick={handleClick}
        className={clsx(classes.colorControl, {
          [classes.fullHeight]: fullHeight,
        })}
      />
    );
    const open = Boolean(anchorEl);

    return (
      <>
        <FormControl
          required={required}
          fullWidth={true}
          data-disabled={disabled}
          className={clsx({ [classes.fullHeight]: fullHeight })}
        >
          <FormControlLabel
            className={clsx(classes.labelControl, {
              [classes.fullHeight]: fullHeight,
            })}
            control={component}
            disabled={disabled}
            label={label}
            labelPlacement={placement}
          />
        </FormControl>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className="color-input-popover"
        >
          <SketchPicker
            color={localColor ?? color}
            onChangeComplete={handleChange}
            onChange={handleLocalChange}
          />
        </Popover>
      </>
    );
  },
);
