import React, { ChangeEvent, memo } from "react";

import TextField from "@material-ui/core/TextField";
import isEmpty from "lodash/isEmpty";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";
import { TextInput } from "./types";
import {
  EndInputAdornment,
  StartInputAdornment,
} from "../common/InputAdornment";

const DefaultTextInput = memo<
  ConnectedReduxModuleProps<ReduxModule, TextInput>
>(
  ({
    value,
    element,
    changeValue,
    changeTouched,
    errors,
    disabled,
    required,
  }) => {
    const {
      config: {
        startAdornment,
        startAdornmentIcon,
        endAdornment,
        endAdornmentIcon,
        multiline,
        regexPattern,
      },
    } = element;

    const hasStartAdornment = startAdornment || startAdornmentIcon;
    const getStartAdornment = () => (
      <StartInputAdornment icon={startAdornmentIcon} text={startAdornment} />
    );

    const hasEndAdornment = endAdornment || endAdornmentIcon;
    const getEndAdornment = () => (
      <EndInputAdornment icon={endAdornmentIcon} text={endAdornment} />
    );

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
      changeValue(e.target.value ? e.target.value : null);
    };

    const handleTouchedChange = () => {
      changeTouched(true);
    };

    return (
      <TextField
        data-testid="default_text_input"
        label={element.i18n.label}
        fullWidth={true}
        value={value || ""}
        onChange={handleValueChange}
        disabled={disabled}
        onBlur={handleTouchedChange}
        error={Boolean(errors)}
        helperText={!isEmpty(errors) && errors}
        multiline={multiline ? true : false}
        rows={multiline}
        InputProps={{
          startAdornment: hasStartAdornment ? getStartAdornment() : undefined,
          endAdornment: hasEndAdornment ? getEndAdornment() : undefined,
        }}
        inputProps={{
          ...(regexPattern && {
            pattern: regexPattern,
          }),
        }}
        required={required}
      />
    );
  },
);

DefaultTextInput.displayName = "DefaultTextInput";

export default DefaultTextInput;
