import React, { ReactNode, memo } from "react";
import Alert, { AlertProps } from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Box, { BoxProps } from "@material-ui/core/Box";

export type AlertBoxProps = {
  message: string | ReactNode;
  alertTitle?: string;
  color?: AlertProps["severity"];
  boxProps?: BoxProps;
} & AlertProps;

export const AlertBox = memo<AlertBoxProps>(
  ({
    message,
    alertTitle,
    color = "error",
    variant = "outlined",
    boxProps = {},
    ...rest
  }) => (
    <Box {...boxProps}>
      <Alert severity={color} variant={variant} {...rest}>
        {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
        {message}
      </Alert>
    </Box>
  ),
);
