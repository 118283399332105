import {
  QueryFunctionContext,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";
import { useSelector } from "react-redux";

import { selectors as sessionSelectors } from "core/session/reduxModule";

type Params = Record<string, unknown>;
type QueryFnWithoutParams<QueryResult> = (
  token: string,
) => QueryResult | Promise<QueryResult>;
type QueryFnWithParams<QueryResult, QueryFunctionParameters> = (
  token: string,
  params: QueryFunctionParameters,
) => QueryResult | Promise<QueryResult>;

export type Query<QueryResult, QueryFunctionParameters = Params> = {
  queryKey: string;
  queryFn:
    | QueryFnWithParams<QueryResult, QueryFunctionParameters>
    | QueryFnWithoutParams<QueryResult>;
};

export const useToken = () => useSelector(sessionSelectors.token);

export const useAuthenticatedQuery = <
  QueryResult,
  QueryFunctionParameters extends Params,
  QuerySelectResult = QueryResult
>(
  { queryKey, queryFn }: Query<QueryResult, QueryFunctionParameters>,
  params?: QueryFunctionParameters,
  config?: UseQueryOptions<QueryResult, Error, QuerySelectResult>,
) => {
  const token = useToken() ?? "";

  // if (!token) {
  //  this should never happen
  // }

  const result = useQuery(
    [queryKey, { ...params }],
    ({ queryKey: [, args] }: QueryFunctionContext) => {
      return queryFn(token, args as QueryFunctionParameters);
    },
    config,
  );

  return result;
};

export const useAuthenticatedMutation = <
  QueryResult,
  QueryFunctionParameters extends Params
>(
  queryFn:
    | QueryFnWithParams<QueryResult, QueryFunctionParameters>
    | QueryFnWithoutParams<QueryResult>,
  options?: UseMutationOptions<QueryResult, unknown, QueryFunctionParameters>,
) => {
  const token = useToken() ?? "";

  // if (!token) {
  //  this should never happen
  // }

  const mutationFn = async (args: QueryFunctionParameters) =>
    await queryFn(token, args);

  const mutation = useMutation(mutationFn, options);

  return mutation;
};

export const getApiError = (error: unknown) =>
  typeof error === "string"
    ? error.toString()
    : (error as Record<string, string>)?.message;
