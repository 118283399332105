import React, { ChangeEvent, memo, useCallback } from "react";
import TextField from "@material-ui/core/TextField";

import { Language, Translation } from "../../../types";
import { useLabelEditorTranslation } from "./translation";
import { useLanguageSectionContext } from "../LanguageSection";

type TranslationEditorProps = {
  translationKey: string;
  i18n: Translation<TranslationEditorProps["translationKey"]>;
  changeTranslation: (value: Translation<string>) => void;
  language?: Language;
  label?: string;
};

// TODO use LanguageSection as wrapper wherever this is used
export const TranslationEditor = memo<TranslationEditorProps>(
  ({ i18n, changeTranslation, translationKey, language, label }) => {
    const { label: defaultLabel } = useLabelEditorTranslation();
    const { lang: defaultLanguage } = useLanguageSectionContext();
    const lang = language ?? defaultLanguage;

    const value = i18n[lang.code]?.[translationKey] ?? "";
    const { code } = lang;

    const handleInputChange = useCallback(
      ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) =>
        changeTranslation({
          ...i18n,
          [code]: {
            ...i18n[code],
            [translationKey]: newValue,
          },
        }),
      [changeTranslation, code, i18n, translationKey],
    );

    return (
      <TextField
        value={value}
        name={translationKey}
        label={label ?? defaultLabel}
        fullWidth={true}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
    );
  },
);
