import React, { ChangeEvent, memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  getPageElementsByType,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";

import { selectors as editorSelectors } from "core/editor/reduxModule";
import { selectors as routerSelectors } from "core/router/reduxModule";

import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";
import IconButton from "elementTypes/common/IconButton";
import { Spacer } from "elementTypes/common/Spacer";

import {
  CallButtonOnSuccessAction,
  UntransformedCallButtonConfig,
} from "../../types";

import { useEntryStyles, useStyles } from "./OnSuccessConfig.style";

interface OnSuccessEntryProps {
  entry: CallButtonOnSuccessAction;
  options: SelectOption[];
  onChange: (entry: CallButtonOnSuccessAction) => void;
  onDelete: () => void;
  divided?: boolean;
}

const OnSuccessConfigEntry = memo(
  ({ entry, options, onChange, onDelete, divided }: OnSuccessEntryProps) => {
    const editorTranslation = useEditorTranslation();
    const classes = useEntryStyles();

    const [searchValue, setSearchValue] = useState("");

    const handleElementIdInputChange = (value: IAutocompleteValue) => {
      setSearchValue("");
      onChange({
        ...entry,
        elementId: value as string,
      });
    };

    const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange({
        type: e.target.value as CallButtonOnSuccessAction["type"],
        elementId: "",
      });
    };

    const valueObject = options.find(
      (option) => option.value === entry.elementId,
    );

    return (
      <Box display="flex" flexDirection="column">
        <div className={classes.firstLine}>
          <TextField
            select
            value={entry.type}
            onChange={handleTypeChange}
            label="Type"
          >
            <MenuItem value="reload_table">Refresh table</MenuItem>
            <MenuItem value="save_form">Save form</MenuItem>
            <MenuItem value="refresh_chart">Refresh chart</MenuItem>
          </TextField>
          <Spacer horizontal={1} />
          <IconButton
            icon="delete_outlined"
            color="error.main"
            onClick={onDelete}
          />
        </div>
        <BaseAutocomplete
          options={options}
          onChange={handleElementIdInputChange}
          valueObject={valueObject ?? null}
          name="elementId"
          label={editorTranslation.elementIdLabel}
          isLoading={false}
          searchInputValue={searchValue}
          onInputChange={setSearchValue}
          virtualizedList={true}
        />
        {divided && (
          <Box py={0.5}>
            <Divider />
          </Box>
        )}
      </Box>
    );
  },
);

export const OnSuccessConfigEditor = memo(() => {
  const {
    elementModel: {
      config: { onSuccess },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCallButtonConfig>();
  const classes = useStyles();

  const updatedElements = useSelector(editorSelectors.updatedElements);
  const page = useSelector(routerSelectors.page);

  const rootElement = page!.element;

  const tableOptions = useMemo(() => {
    const tables = getPageElementsByType(
      rootElement,
      updatedElements,
      "default_table",
    );
    return tables.map((element) => ({
      value: element.id,
      label: element.id,
    }));
  }, [rootElement, updatedElements]);

  const formOptions = useMemo(() => {
    const forms = getPageElementsByType(
      rootElement,
      updatedElements,
      "default_form",
    );
    return forms.map((element) => ({
      value: element.id,
      label: element.id,
    }));
  }, [rootElement, updatedElements]);

  const chartOptions = useMemo(() => {
    const charts = getPageElementsByType(rootElement, updatedElements, [
      "echarts_custom",
      "echarts_pie_chart",
      "echarts_line_chart",
      "echarts_bar_chart",
    ]);
    return charts.map((element) => ({
      value: element.id,
      label: element.id,
    }));
  }, [rootElement, updatedElements]);

  const handleOnSuccessChange = (
    newValue: UntransformedCallButtonConfig["onSuccess"],
  ) => changeConfigValue("onSuccess", newValue);

  const handleOnSuccessEntryChange = (index: number) => (
    entry: CallButtonOnSuccessAction,
  ) => {
    if (onSuccess) {
      const newOnSuccess = [...onSuccess];
      newOnSuccess[index] = entry;
      handleOnSuccessChange(newOnSuccess);
    }
  };

  const handleDelete = (index: number) => () => {
    if (onSuccess) {
      const newOnSuccess = [...onSuccess];
      newOnSuccess.splice(index, 1);
      handleOnSuccessChange(newOnSuccess);
    }
  };

  const handleAdd = () => {
    const newOnSuccess = onSuccess ? [...onSuccess] : [];
    newOnSuccess.push({ type: "reload_table", elementId: "" });
    handleOnSuccessChange(newOnSuccess);
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h6">Success actions</Typography>
        <IconButton icon="add" onClick={handleAdd} />
      </div>
      {onSuccess?.length ? (
        onSuccess.map((successAction, index) => (
          <OnSuccessConfigEntry
            key={index}
            onChange={handleOnSuccessEntryChange(index)}
            onDelete={handleDelete(index)}
            entry={successAction}
            divided={index !== onSuccess.length - 1}
            options={
              {
                reload_table: tableOptions,
                save_form: formOptions,
                refresh_chart: chartOptions,
              }[successAction.type]
            }
          />
        ))
      ) : (
        <Typography variant="subtitle1">No actions configured</Typography>
      )}
    </>
  );
});
