import React, { ChangeEvent, memo, useMemo } from "react";

import TextField from "@material-ui/core/TextField";

import { IPage } from "../../../types";
import { mapStateToProps } from "./container";
import { PageAutocomplete } from "..";
import { getTranslatedText } from "../../../utils/element";
import { useSessionContext } from "../../../session";
import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "../CustomExpressionEditor";

type Props = ReturnType<typeof mapStateToProps> & {
  pageId: string | null;
  params?: any;
  config: Record<string, any>;
  onChange: (page: IPage, params: any) => void;
  label?: string;
  onDelete?: () => void;
};

type ParamInputProps = {
  label: string;
  value: string;
  config: Record<string, any>;
  onChange: (label: string, value: string) => void;
};

const ParamInput = memo<ParamInputProps>(
  ({ config, label, value, onChange }) => {
    const handleChange = (nextValue: string) => onChange(label, nextValue);

    const customInput = ({
      value: paramValue,
      onChange: onParamChange,
    }: NonExpressionEditorProps) => {
      const onCustomChange = (e: ChangeEvent<HTMLInputElement>) =>
        onParamChange(e.target.value);

      return (
        <TextField
          label={label}
          value={paramValue}
          onChange={onCustomChange}
          fullWidth={true}
        />
      );
    };

    return (
      <CustomExpressionEditor
        label={label}
        config={config}
        value={value}
        onChange={handleChange}
        nonExpressionEditor={customInput}
      />
    );
  },
);

export const PageSelector = memo<Props>(
  ({ config, onChange, onDelete, pages, pageId, params, label = "" }) => {
    const { language } = useSessionContext();

    const selectedPage = pageId && pages[pageId];

    const options = useMemo(
      () =>
        Object.keys(pages).map((innerPageId) => {
          const { id, url, i18n } = pages[innerPageId];
          return {
            value: id,
            label: getTranslatedText(language, i18n, "label") ?? url,
            url,
          };
        }),
      [pages, language],
    );

    const handlePageIdChange = (newPageId: string) => {
      const page = pages[newPageId];
      if (page) {
        onChange(page, {});
      } else if (newPageId === "") {
        onDelete?.();
      }
    };

    let pageParams: any;

    if (selectedPage && !!Object.keys(selectedPage.params).length) {
      const handleParamChange = (k: string, value: string) =>
        onChange(selectedPage, {
          ...(params ?? {}),
          [k]: value,
        });

      pageParams = Object.keys(selectedPage.params).map((k) => (
        <ParamInput
          key={k}
          label={k}
          config={config}
          value={params[k] || ""}
          onChange={handleParamChange}
        />
      ));
    } else {
      pageParams = null;
    }

    return (
      <>
        <PageAutocomplete
          value={pageId}
          options={options}
          onChange={handlePageIdChange}
          label={label}
          clearable={!!onDelete}
        />
        {pageParams}
      </>
    );
  },
);
