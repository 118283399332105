import React, { FC, memo } from "react";
import { Controller } from "react-hook-form";

import {
  Box,
  Card,
  Chip,
  Divider,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowForward";

import { UICreateStateTransitionForm } from "./types";
import { useStateTransitionTranslations } from "./translation";
import {
  HookForm,
  useHookFormContext,
} from "../../../../../../../elementTypes/common/HookForm";
import { useHookFormError } from "../../../../../../../utils/hooks/useHookFormError";
import { PermissionComponent } from "./PermissionComponent";
import IconButton from "elementTypes/common/IconButton";
import Button from "elementTypes/common/Button";
import { useSelectedState } from "../../utils";

interface StateTransitionFormProps {
  onSubmit: (data: Record<string, unknown>) => void;
  onDelete?: () => void;
  edge?: UICreateStateTransitionForm;
}

export const StateTransitionForm: FC<StateTransitionFormProps> = ({
  edge,
  onSubmit,
  onDelete,
}) => {
  return (
    <HookForm
      onSubmit={onSubmit}
      boxProps={{ bgcolor: "background.paper", height: "100%" }}
    >
      <FormContent data={edge} onDelete={onDelete} />
    </HookForm>
  );
};

function TransitionState(props: { title: string; tooltip: string }) {
  // TODO use state translation
  return (
    <Tooltip title={props.tooltip}>
      <Chip
        label={props.title.toUpperCase()}
        variant="outlined"
        color={"default"}
        style={{ borderRadius: 4 }}
      />
    </Tooltip>
  );
}

const FormContent = memo<{
  data?: UICreateStateTransitionForm;
  onDelete?: () => void;
}>(({ data, onDelete }) => {
  const { control, errors } = useHookFormContext();

  const { setSelectedElement } = useSelectedState();

  const handleClose = () => setSelectedElement(null);

  const getErrorMessage = useHookFormError();

  const {
    cancel,
    createStateTransition,
    deleteTransition,
    editStateTransition,
    titleLabelField,
    descriptionLabelField,
    save,
    startState,
    endState,
    fromStateTooltip,
    toStateTooltip,
  } = useStateTransitionTranslations();

  const title = data ? editStateTransition : createStateTransition;

  const replaceNullForPreState = (preState: string | undefined) => {
    return !preState || preState === null ? startState : preState;
  };

  const replaceNullForPostState = (postState: string | undefined) => {
    return !postState || postState === null ? endState : postState;
  };

  return (
    <Card
      variant="outlined"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      component="aside"
    >
      <Box display="flex" justifyContent="space-between" py={1}>
        <Box display="flex" alignItems="center" gridGap={8}>
          <IconButton
            onClick={handleClose}
            tooltip={cancel}
            icon="arrow_back"
          />
          <Typography variant="h5">{title}</Typography>
        </Box>
        {data && (
          <IconButton
            onClick={onDelete}
            icon="delete_outlined"
            tooltip={deleteTransition}
          />
        )}
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        width="100%"
      >
        <Box
          py={1}
          px={1.5}
          display="flex"
          flexDirection="column"
          flex={1}
          gridGap={16}
        >
          {data && (
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={5}
                container
                justifyContent="center"
                alignItems="center"
              >
                <TransitionState
                  tooltip={fromStateTooltip}
                  title={replaceNullForPreState(data?.preState)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                container
                justifyContent="center"
                alignItems="center"
              >
                <ArrowRight />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                container
                justifyContent="center"
                alignItems="center"
              >
                <TransitionState
                  tooltip={toStateTooltip}
                  title={replaceNullForPostState(data?.postState)}
                />
              </Grid>
            </Grid>
          )}
          <FormControl fullWidth>
            <Controller
              as={
                <TextField
                  label={titleLabelField}
                  fullWidth={true}
                  autoComplete={"off"}
                  helperText={getErrorMessage(errors.title)}
                />
              }
              defaultValue={data?.title ?? ""}
              error={Boolean(errors?.title)}
              control={control}
              name="title"
              rules={{
                required: true,
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              as={
                <TextField
                  label={descriptionLabelField}
                  fullWidth={true}
                  autoComplete={"off"}
                  helperText={getErrorMessage(errors.shortDescription)}
                />
              }
              error={Boolean(errors?.shortDescription)}
              control={control}
              name="shortDescription"
              defaultValue={data?.shortDescription ?? ""}
              rules={{
                required: true,
              }}
            />
          </FormControl>
          <Box mt={1}>
            <PermissionComponent node={data} control={control} />
          </Box>
        </Box>
        <Box p={1} display="flex" justifyContent="flex-end">
          <Button label={save} type="submit" color="primary" />
        </Box>
      </Box>
    </Card>
  );
});
