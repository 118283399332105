import { connect } from "react-redux";

import { selectors as routerSelectors } from "../../router/reduxModule";
import { actions, selectors } from "../reduxModule";
import { EditorLayout as EEComponent } from "./component";

export const mapStateToProps = (state: any) => ({
  editModeOn: selectors.editModeOn(state),
  draggableElementParams: selectors.draggableElementParams(state),
  creatingPage: routerSelectors.creatingPage(state),
  isLayoutChanged: selectors.isLayoutChanged(state),
});

export const EditorLayout = connect(mapStateToProps, actions)(EEComponent);
