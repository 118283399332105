import CodeMirror, { Editor, Hints, Pos, ShowHintOptions } from "codemirror";
import { keys } from "ramda";

export type ErdHintOptionTable = string;

export interface ErdHintOption {
  [schemaName: string]: ErdHintOptionTable[];
}

/**
 * only display hints if the statement contains the keyword "FROM"
 */
const shouldShowHint = (value: string) => value.toUpperCase().includes("FROM");

const getHints = (word: string, source: Record<string, string[]>): string[] => {
  const [schema, table, dummy] = word.split(".");
  return table !== undefined
    ? dummy !== undefined
      ? []
      : source[schema] || []
    : keys(source);
};

// for a more sophisticated version including columns and aliases, look into:
// https://codemirror.net/addon/hint/sql-hint.js
// be aware that support for schemas might not be working fine:
// https://github.com/codemirror/CodeMirror/issues/2323

const WORD_SEPARATOR = /\s|,/;

const erdHint = (
  editor: Editor,
  options: ShowHintOptions & { hintList: ErdHintOption },
): Hints => {
  const cursor = editor.getCursor();
  const curLine = editor.getLine(cursor.line);
  const value = editor.getValue().trim();
  const startToken = editor.getTokenAt(cursor);

  /**
   * the last word; valid word separators are whitespace and ","
   * @example
   * worksp
   * @example
   * workspace
   * @example
   * workspace.t_em
   * @example
   * workspace.t_employee
   */
  const lastWord = curLine
    .substr(0, startToken.end)
    .split(WORD_SEPARATOR)
    .slice(-1)[0];

  /**
   * the current word (e.g. schema or table name)
   * @example
   * worksp
   * @example
   * workspace
   * @example
   * t_em
   * @example
   * t_employee
   */
  const searchText = lastWord.split(".").slice(-1)[0];

  const hints = shouldShowHint(value)
    ? getHints(lastWord, options.hintList) ?? []
    : [];
  const result: Hints = {
    list: (!value
      ? []
      : hints.filter((item) => item.startsWith(searchText))
    ).sort(),
    from: Pos(
      cursor.line,
      // if no value has been written, do not delete the previous character
      searchText ? startToken.start : startToken.start + 1,
    ),
    to: Pos(cursor.line, startToken.end),
  };

  return result;
};

CodeMirror.registerHelper("hint", "sql", erdHint);
