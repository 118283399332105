import React, { ChangeEvent, memo } from "react";
import { Controller } from "react-hook-form";
import { FormControl, TextField } from "@material-ui/core";

import { useHookFormContext } from "elementTypes/common/HookForm";

import { NodeData } from "../types";
import { useHookFormError } from "utils/hooks/useHookFormError";
import { humanize } from "utils/string";
import { useErdTranslation } from "../translation";
import {
  PermissionTable,
  handleNameValidate,
  handleTitleValidate,
  titleToName,
} from "../queryBuilder";

type Props = {
  nodeData: NodeData;
};

export const BasicQuery = memo<Props>(({ nodeData }) => {
  const { control, errors, setValue } = useHookFormContext();

  const getErrorMessage = useHookFormError();

  // We have to type it like an array because of the useHookForm
  const handleTitleChange = ([ev]: ChangeEvent<HTMLInputElement>[]) => {
    const nextName = titleToName(ev.target.value);
    setValue("name", nextName);

    return ev.target.value;
  };

  const defaultTitle = humanize(nodeData.tableName);

  const translation = useErdTranslation();

  return (
    <>
      <FormControl fullWidth>
        <Controller
          as={
            <TextField
              margin="dense"
              label={translation.queryTitleLabel}
              helperText={getErrorMessage(errors.title)}
              autoFocus
            />
          }
          error={Boolean(errors.title)}
          name="title"
          control={control}
          defaultValue={defaultTitle}
          rules={{
            required: true,
            validate: handleTitleValidate,
          }}
          onChange={handleTitleChange}
        />
      </FormControl>
      <FormControl fullWidth>
        <Controller
          as={
            <TextField
              margin="dense"
              label={translation.queryNameLabel}
              helperText={getErrorMessage(errors.name)}
            />
          }
          error={Boolean(errors.name)}
          name="name"
          control={control}
          disabled
          defaultValue={nodeData.tableName}
          rules={{
            required: true,
            validate: handleNameValidate,
          }}
        />
      </FormControl>
      <PermissionTable />
    </>
  );
});
