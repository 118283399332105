import React, { memo, useEffect, useMemo, useState } from "react";

import ListItemText from "@material-ui/core/ListItemText";

import {
  BaseAutocomplete,
  BaseAutocompleteProps,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";

type Props = {
  options: BaseAutocompleteProps["options"];
  onChange?: (pageValue: string) => void;
  value?: string | null;
  label?: string;
  error?: string;
  disabled?: boolean;
  clearable?: boolean;
};

export type Option = SelectOption;

export const PageAutocomplete = memo<Props>(
  ({ onChange, options, value, label = "", clearable, error, ...rest }) => {
    const valueObject = useMemo(() => options.find((o) => o.value === value), [
      options,
      value,
    ]);

    const [searchInputValue, setInputValue] = useState<string>("");

    useEffect(() => {
      !!searchInputValue.length && setInputValue("");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueObject]);

    const handleChange = (pageValue: IAutocompleteValue) =>
      onChange?.(pageValue as string);

    const handleInputChange = (newValue: string) => setInputValue(newValue);

    const customRenderOption = (option: SelectOption) => (
      <ListItemText
        primary={option.label}
        secondary={option.url}
        secondaryTypographyProps={{ variant: "caption", display: "block" }}
      />
    );

    return (
      <BaseAutocomplete
        options={options}
        onChange={handleChange}
        valueObject={valueObject ?? null}
        name="pages"
        label={label}
        isLoading={false}
        searchInputValue={searchInputValue}
        onInputChange={handleInputChange}
        customRenderOption={customRenderOption}
        defaultItemSize={48}
        virtualizedList={true}
        optionsError={error}
        isClearable={clearable}
        {...rest}
      />
    );
  },
);
