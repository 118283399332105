import { EventChannel, eventChannel } from "redux-saga";
import {
  all,
  call,
  getContext,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";

import { withLeadingSlash } from "core/router/reduxModule/utils";
import { AllServices } from "../../buildStore";
import {
  ADMIN_URL,
  DEFAULT_APP_URL,
  LOGIN_URL,
} from "../../router/reduxModule";
import history from "../../router/reduxModule/history";
import { parseQueries } from "../../router/reduxModule/utils";
import { IAppMetadata, IUi, LANGUAGES, Language } from "../../types";
import { Notification } from "../Notifier/types";
import { getTranslatedTextSaga } from "../translation/createUseTranslation";
import { sessionTranslation } from "../translations";
import { actions, types } from "./actions";
import { LANGUAGE_STORAGE_KEY, TOKEN_STORAGE_KEY } from "./constants";
import { selectors } from "./selectors";
import { Actions } from "./types";

import { actions as routerActions } from "../../router/reduxModule";

import { routes as staticPagesRoutes } from "staticPages";
import { AuthMeResponse } from "services/api";
import { stringify } from "query-string";
import { getServerError } from "../../utils/api";

function* bootstrap() {
  try {
    const services: AllServices = yield getContext("services");
    const token: string | null = yield call(
      services.storage.getItem,
      TOKEN_STORAGE_KEY,
    );
    if (token) {
      // save the token in the state and retrieve user information
      yield put(actions.loadUser({ token }));
      yield take(types.USER_SUCCESS);
    }
    yield loadAppMetadata();
  } catch (error) {
    return error;
  }
}

function* loadAppMetadata(redirect?: string) {
  const services: AllServices = yield getContext("services");
  const token: ReturnType<typeof selectors.token> = yield select(
    selectors.token,
  );
  const isAdmin: ReturnType<typeof selectors.isAdmin> = yield select(
    selectors.isAdmin,
  );
  const isLoggedIn: ReturnType<typeof selectors.isLoggedIn> = yield select(
    selectors.isLoggedIn,
  );

  try {
    const { search, pathname: historyPathname } = history.location;
    const pathname = historyPathname.split("/");
    const uiList: IUi[] = yield call(
      services.api.loadUIList,
      token as Parameters<typeof services.api.loadUIList>[0],
    );

    /**
     * the UI that should be loaded
     */
    let ui: IUi;

    /**
     * TODO:
     * This dependency on the internals of the router module is a bit ugly, but right now the router module
     * does not put the location in redux until the appMetadata is loaded, so the only way to get the ui
     * from the url is by hand. Maybe we could extract this part to an exported util of the router module.
     */

    if (search) {
      const { ui: uiName } = parseQueries(search);

      ui = uiName
        ? uiList.find((u) => u.name === uiName) || uiList[0]
        : uiList[0];
    } else {
      ui = uiList[0];
    }

    yield put(actions.setUiList(uiList));

    if (!ui) {
      // no UI available, check for static pages or redirect to special pages

      // TODO clear current UI

      // TODO create custom page if user has no UIs
      const staticRoute = staticPagesRoutes[`/${pathname[1]}`];
      // if the current page is allowed to be accessed by the user
      // the user is allowed if:
      // - the user is an admin and the page is an admin only page
      // - the user is logged in and the page is an auth only page
      // - the page is not a auth or admin only page
      // TODO check if page is subpage and check the subpage permissions
      if (
        !redirect &&
        staticRoute &&
        ((!staticRoute.isAdmin && (!staticRoute.auth || isLoggedIn)) ||
          (isAdmin && staticRoute?.isAdmin))
      ) {
        const queries = parseQueries(history.location.search);

        // trigger saving the current location to the state and page loading
        yield put(
          routerActions.push(`${historyPathname}?${stringify(queries)}`),
        );
      } else {
        if (isAdmin) {
          yield put(
            routerActions.push(redirect ?? withLeadingSlash(ADMIN_URL)),
          );
        } else {
          if (isLoggedIn) {
            yield put(routerActions.push("/no-apps-available"));
          } else if (redirect) {
            yield put(routerActions.push(redirect));
          } else {
            // If no UI is available and isn't logged in, redirect to /login. (issue #461)
            const { next, ui: uiSearchParam, ...restQueries } = parseQueries(
              history.location.search,
            );

            // TODO this is not perfect
            // the `next` param from the location we're redirecting from is lost
            const queries = {
              ...restQueries,
              next: [LOGIN_URL, "/", ""].includes(history.location.pathname)
                ? next
                : history.location.pathname,
              ui: uiSearchParam,
            };
            yield put(routerActions.push(`${LOGIN_URL}?${stringify(queries)}`));
          }
        }
      }
    } else {
      yield put(actions.loadAppMetadata(ui, redirect));
    }
    yield put(actions.loadAppMetadataFinished());
  } catch (error) {
    yield put(actions.loadAppMetadataError(getServerError(error)));
  }
}

/**
 * Load the app metadata of the current UI.
 */
function* loadAppMetadataSaga(action: ReturnType<Actions["loadAppMetadata"]>) {
  const services: AllServices = yield getContext("services");
  const token: ReturnType<typeof selectors.token> = yield select(
    selectors.token,
  );
  const isAdmin: ReturnType<typeof selectors.isAdmin> = yield select(
    selectors.isAdmin,
  );

  try {
    const appMetadata: IAppMetadata = yield call(
      services.api.loadAppMetadata,
      token as Parameters<typeof services.api.loadAppMetadata>[0],
      { uiName: action.payload.ui.name, latest: isAdmin },
    );
    yield put(
      actions.loadAppMetadataSuccess(
        action.payload.ui,
        appMetadata,
        action.payload.redirect,
      ),
    );
  } catch (error) {
    yield put(actions.loadAppMetadataError(getServerError(error)));
  }
}

function* loadAppMetadataErrorSaga() {
  const services: AllServices = yield getContext("services");

  yield call(services.loader.hide);

  const { next, ui } = parseQueries(history.location.search);

  const maintenanceUrl = "/maintenance";

  const routeParams = {
    next:
      next ??
      (history.location.pathname !== maintenanceUrl
        ? history.location.pathname
        : undefined),
    ui,
  };

  const route = stringify(routeParams);

  yield put(routerActions.push(`${maintenanceUrl}?${route}`));
}

/**
 * Call the api service login, dispatch the success or error action and store the token with the storage service.
 */
function* loginSaga(action: ReturnType<typeof actions["login"]>) {
  const isLoggedIn: ReturnType<typeof selectors.isLoggedIn> = yield select(
    selectors.isLoggedIn,
  );
  if (isLoggedIn) {
    return;
  }

  const services: AllServices = yield getContext("services");

  try {
    const token: string = yield call(services.api.login, action.payload);

    /**
     * TODO:
     * The following code was modified in the branch issue-461 but is not
     * related to the branch. Must it be deleted?
     */
    // const { language } = yield call(services.api.getUser, token);
    //
    // yield call(services.storage.setItem, TOKEN_STORAGE_KEY, token);
    // yield call(services.storage.setItem, LANGUAGE_STORAGE_KEY, language);
    // yield put(
    //   actions.changeLanguage(
    //     LANGUAGES.find((l: Language) => l.code === language)!,
    //     false,
    //   ),
    // );

    yield put(actions.loginSuccess({ token }));

    yield put(
      actions.enqueueSnackbar({
        message: yield call(
          getTranslatedTextSaga,
          sessionTranslation,
          "loggedIn",
        ),
        options: {
          variant: "success",
        },
      }),
    );
  } catch (error) {
    const msg = getServerError(error);
    yield put(actions.loginError(msg));
    yield put(
      actions.enqueueSnackbar({
        message: msg,
        options: {
          variant: "error",
        },
      }),
    );
  }
}

/**
 * After a successful login, load the UI list. If only one UI is available select it as the default, else navigate
 * to the UI selection route.
 */
function* loginSuccessSaga() {
  const token: ReturnType<typeof selectors.token> = yield select(
    selectors.token,
  );

  if (!token) {
    throw Error("token not set, must not happen");
  }

  // TODO see if we can use the router selector here
  const { next, ui, ...rest } = parseQueries(history.location.search);
  let nextRoute = DEFAULT_APP_URL;
  const queries = { ...rest };

  const user: { isAdmin: boolean } | null = yield userGetSaga(
    actions.loadUser({ token }),
  );

  const hasNext = next && next !== "/";

  if (hasNext) {
    nextRoute = next;

    if (ui) {
      queries.ui = ui;
    }
  } else if (user?.isAdmin && !ui) {
    nextRoute = withLeadingSlash(ADMIN_URL);
  }

  const nextUrl = `${nextRoute}?${stringify(queries)}`;

  yield loadAppMetadata(nextUrl);
}

/**
 * Clear the token from the storage and reload app metadata.
 */
function* logoutSaga(action: ReturnType<typeof actions["logout"]>) {
  const services: AllServices = yield getContext("services");

  yield call(services.storage.removeItem, TOKEN_STORAGE_KEY);

  // TODO set redirect in `loadAppMetadata` instead of all this redirecting logic
  const uiList: ReturnType<typeof selectors.uiList> = yield select(
    selectors.uiList,
  );
  const publicUi = !!uiList && uiList?.length > 0;
  let redirect = action.payload;

  if (!redirect) {
    if (publicUi) {
      redirect = DEFAULT_APP_URL;
    } else {
      redirect = LOGIN_URL;
    }
  }

  yield loadAppMetadata(redirect);
  yield put(
    actions.enqueueSnackbar({
      message: yield call(
        getTranslatedTextSaga,
        sessionTranslation,
        "loggedOut",
      ),
      options: {
        variant: "info",
      },
    }),
  );
}

/**
 * Logout when the token expires
 */
function* tokenExpiredSaga() {
  yield put(actions.logout());
}

/**
 * Create a channel to connect the api service tokenExpired event to sagas.
 */
function* createTokenExpiredChannel() {
  const services: AllServices = yield getContext("services");
  return eventChannel((emitter) =>
    services.api.onTokenExpired(() => emitter({})),
  );
}

function* uiChangeSaga(action: ReturnType<Actions["changeUi"]>) {
  yield put(actions.loadAppMetadata(action.payload, DEFAULT_APP_URL));
}

function* snackbarEnqueueSaga(action: ReturnType<Actions["enqueueSnackbar"]>) {
  const notifications: ReturnType<typeof selectors.notifications> = yield select(
    selectors.notifications,
  );

  const newNotifications = [
    ...notifications,
    {
      ...action.payload,
    },
  ];

  yield put(actions.setSnackbarList(newNotifications));
}

function* snackbarCloseSaga(action: ReturnType<Actions["closeSnackbar"]>) {
  const notifications: ReturnType<typeof selectors.notifications> = yield select(
    selectors.notifications,
  );

  const newNotifications = notifications.map((notification: Notification) =>
    action.payload.dismissAll || notification.key === action.payload.key
      ? { ...notification, dismissed: true }
      : { ...notification },
  );

  yield put(actions.setSnackbarList(newNotifications));
}

function* snackbarRemoveSaga(action: ReturnType<Actions["removeSnackbar"]>) {
  const notifications: ReturnType<typeof selectors.notifications> = yield select(
    selectors.notifications,
  );

  const newNotifications = notifications.filter(
    (notification: Notification) => notification.key !== action.payload,
  );

  yield put(actions.setSnackbarList(newNotifications));
}

function* languageChangeSaga(
  action: ReturnType<typeof actions["changeLanguage"]>,
) {
  const token: ReturnType<typeof selectors.token> = yield select(
    selectors.token,
  );
  const {
    payload: { lang, sync },
  } = action;
  if (token) {
    const services: AllServices = yield getContext("services");
    yield call(services.storage.setItem, LANGUAGE_STORAGE_KEY, lang.code);
    if (sync) {
      yield call(services.api.updateUser, token, { language: lang.code });
    }
  }
}

function* userGetSaga(
  action: ReturnType<
    typeof actions["loadUser"] | typeof actions["loginSuccess"]
  >,
) {
  const token = action.payload.token;

  const services: AllServices = yield getContext("services");
  try {
    const {
      language,
      isAdmin,
      additionalInformation,
    }: AuthMeResponse = yield call(services.api.getUser, token);

    yield call(services.storage.setItem, TOKEN_STORAGE_KEY, token);
    yield call(services.storage.setItem, LANGUAGE_STORAGE_KEY, language);
    yield put(
      actions.changeLanguage(
        LANGUAGES.find((l: Language) => l.code === language)!,
        false,
      ),
    );

    yield put(actions.loadUserSuccess({ isAdmin, additionalInformation }));
    return { isAdmin };
  } catch (e) {
    yield put(actions.loadUserError(getServerError(e)));
    yield call(services.storage.removeItem, TOKEN_STORAGE_KEY);
    yield loadAppMetadataErrorSaga();
    return null;
  }
}

export function* saga() {
  const tokenExpiredChannel: EventChannel<unknown> = yield createTokenExpiredChannel();

  yield all([
    takeLatest(tokenExpiredChannel, tokenExpiredSaga),
    takeLatest(types.APP_METADATA_LOAD, loadAppMetadataSaga),
    takeLatest(types.APP_METADATA_LOAD_ERROR, loadAppMetadataErrorSaga),
    takeLatest(types.LOGIN, loginSaga),
    takeLatest(types.LOGIN_SUCCESS, loginSuccessSaga),
    takeLatest(types.LOGOUT, logoutSaga),
    takeLatest(types.UI_CHANGE, uiChangeSaga),
    takeLatest(types.SNACKBAR_ENQUEUE, snackbarEnqueueSaga),
    takeLatest(types.SNACKBAR_CLOSE, snackbarCloseSaga),
    takeLatest(types.SNACKBAR_REMOVE, snackbarRemoveSaga),
    takeLatest(types.LANGUAGE_CHANGE, languageChangeSaga),
    takeLatest(types.USER_LOAD, userGetSaga),
    takeLatest(types.BOOTSTRAP, bootstrap),
  ]);

  yield bootstrap();
}
