import { createContext, useContext } from "react";

import { actions as editorActions } from "core/editor/reduxModule";
import {
  IElementType,
  IObjectView,
  IObjectViewField,
  Language,
} from "core/types";
import { ViewFieldToElement } from "core/editor";
import { ColumnDetails } from "./Columns";
import { IGenerateColumn } from "./ColumnEditor";

type ColumnsContextType = {
  columnDetails: ColumnDetails | null;
  language: Language;
  elementTypes: Record<string, IElementType<any>>;
  currentView: IObjectView | undefined;
  hidden: any[];
  getElementTypeFromField: (
    field: IObjectViewField,
    index?: number,
  ) => ViewFieldToElement;
  updateChildren: (
    ...params: Parameters<typeof editorActions.updateElementChildren>
  ) => void;
  setColumnDetails: (details: ColumnDetails | null) => void;
  deleteColumn: (index: number) => void;
  generateColumn: (newProps: IGenerateColumn) => void;
};
const ColumnsContext = createContext<ColumnsContextType | undefined>(undefined);

export function useColumnsContext() {
  const context = useContext(ColumnsContext);

  if (!context) {
    throw new Error(
      "useColumnsContext must be used within a ColumnsContext.Provider",
    );
  }
  return context;
}

export const ColumnsProvider = ColumnsContext.Provider;
