import React, { memo } from "react";

import CustomExpressionEditor, {
  NonExpressionEditorProps,
} from "core/editor/common/CustomExpressionEditor";
import { useColorSelectorTranslation } from "./translation";
import ColorSelect, { IColor, colors as defaultColors } from "../ColorSelect";
import { allowedValuesToggleModeHandler } from "../CustomExpressionEditor/component";

type Props = {
  config: Record<string, any>;
  onChange: (value: string) => void;
  configKey?: string;
  colors?: IColor[];
  defaultValue?: IColor;
  label?: string;
};

export const ColorSelector = memo<Props>(
  ({
    config,
    configKey = "color",
    onChange,
    colors,
    defaultValue = "default",
    label,
  }) => {
    const { textColorLabel } = useColorSelectorTranslation();

    const customColorSelect = ({
      value: textValue,
      onChange: onColorChange,
    }: NonExpressionEditorProps) => (
      <ColorSelect
        value={textValue}
        onChange={onColorChange}
        defaultValue={defaultValue}
        colorItems={colors ?? defaultColors}
      />
    );

    return (
      <CustomExpressionEditor
        label={label ?? textColorLabel}
        value={config[configKey]}
        config={config}
        onChange={onChange}
        nonExpressionEditor={customColorSelect}
        onToggleMode={allowedValuesToggleModeHandler(colors ?? defaultColors)(
          config[configKey],
          onChange,
          defaultValue,
        )}
      />
    );
  },
);
