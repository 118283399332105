import React, { ChangeEvent, memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import Radio from "@material-ui/core/Radio";
import { RadioGroup } from "@material-ui/core";

import {
  ColorSelector,
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";
import { UntransformedExternalLinkFieldConfig } from "../../types";
import { useExternalLinkFieldEditorTranslation } from "../translation";

enum Variants {
  contained = "contained",
  outlined = "outlined",
}

export const Display = memo(() => {
  const {
    displayConfigTitle,
    isButtonLabel,
  } = useExternalLinkFieldEditorTranslation();

  const {
    elementModel: {
      config: { isButton = false, icon, variant = "outlined" },
      config,
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedExternalLinkFieldConfig>();

  const toggleIsButton = () => changeConfigValue("isButton", !isButton);

  const changeIcon = (newIcon: UntransformedExternalLinkFieldConfig["icon"]) =>
    changeConfigValue("icon", newIcon);

  const handleColorChange = (newColor: string) =>
    changeConfigValue("color", newColor);

  const handleVariantChange = (_e: ChangeEvent, value: string) => {
    changeConfigValue("variant", value);
  };

  const variants = Object.keys(Variants).map((itemVariant) => (
    <FormControlLabel
      key={itemVariant}
      control={<Radio color="primary" />}
      label={itemVariant}
      value={itemVariant}
    />
  ));

  return (
    <Section title={displayConfigTitle} wrapped={true}>
      <ColorSelector config={config} onChange={handleColorChange} />
      <FormControlLabel
        control={<Switch checked={isButton} onChange={toggleIsButton} />}
        label={isButtonLabel}
      />

      {isButton && (
        <>
          <IconAutocomplete label="Icon" value={icon} onChange={changeIcon} />

          <RadioGroup row={true} value={variant} onChange={handleVariantChange}>
            {variants}
          </RadioGroup>
        </>
      )}
    </Section>
  );
});
