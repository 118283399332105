import { createTypeSafeContext } from "utils/createTypeSafeContext";

type JsonSchemaContextType = {
  onCreate: (
    name: string,
    value: Record<string, unknown>,
    newProp: { name: string; type: string | string[] },
  ) => void;
  onDelete: (name: string, values: Record<string, unknown>) => void;
};

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  JsonSchemaContextType
>();

export const useJsonSchemaContext = useTypeSafeContext;
export const JsonSchemaProvider = Provider;
export const JsonSchemaConsumer = Consumer;
