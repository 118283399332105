import React, { ChangeEvent, ComponentProps, memo, useCallback } from "react";

import { isBoolean } from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { buildCustomExpressionValue, getExpression } from "core";
import {
  IconAutocomplete,
  Section,
  useElementEditorContext,
} from "core/editor";
import { UntransformedCallButtonConfig } from "../../types";
import { useCallButtonEditorTranslation } from "../translation";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";

export const DisplayComponent = memo(() => {
  const {
    displaySectionTitle,
    showNotificationLabel,
    disabledLabel,
  } = useCallButtonEditorTranslation();
  const {
    elementModel: {
      config,
      config: { icon, showNotification, disabled },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCallButtonConfig>();

  const changeIcon = (newIcon: UntransformedCallButtonConfig["icon"]) =>
    changeConfigValue("icon", newIcon);

  const handleShowNotificationInputChange = () =>
    changeConfigValue("showNotification", !showNotification);

  const wrappedChangePath = useCallback(
    (newValue: string) => {
      changeConfigValue("disabled", newValue);
    },
    [changeConfigValue],
  );

  const disabledNonExpressionEditor: ComponentProps<
    typeof CustomExpressionEditor
  >["nonExpressionEditor"] = useCallback(
    ({ value, onChange }) => {
      const handleChange = (
        _event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
      ) => onChange(checked);

      return (
        <FormControlLabel
          control={
            <Switch
              checked={isBoolean(value) ? value : value === "true"}
              onChange={handleChange}
            />
          }
          label={disabledLabel}
        />
      );
    },
    [disabledLabel],
  );

  const handleToggleMode = (isExpression: boolean) => {
    const nextVal = isExpression
      ? getExpression(String(disabled)) === "true"
      : buildCustomExpressionValue(String(disabled));
    changeConfigValue("disabled", nextVal);
  };

  return (
    <Section title={displaySectionTitle} wrapped={true}>
      <IconAutocomplete label="Icon" value={icon} onChange={changeIcon} />
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(showNotification)}
            onChange={handleShowNotificationInputChange}
          />
        }
        label={showNotificationLabel}
      />
      <CustomExpressionEditor
        value={String(disabled)}
        config={config}
        label={disabledLabel}
        onChange={wrappedChangePath}
        onToggleMode={handleToggleMode}
        nonExpressionEditor={disabledNonExpressionEditor}
      />
    </Section>
  );
});
