import { createTypeSafeContext } from "utils/createTypeSafeContext";
import { Align } from ".";

export interface ITableContext {
  alignment: Align[];
}

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  ITableContext
>();

export const useTableContext = useTypeSafeContext;
export const TableContextProvider = Provider;
export const TableContextConsumer = Consumer;
