import React, { memo, useState } from "react";
import { Editor, EditorChange } from "codemirror";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { ConfigComponent } from "./components/Config";
import { TranslationComponent } from "./components/Translation";
import { UntransformedCodeInputConfig } from "../types";
import { CodeEditor } from "../../common/CodeEditor";

export const useStyles = makeStyles(() =>
  createStyles({
    defaultValueInput: {
      height: "200px",
      width: "100%",
    },
  }),
);

export const CodeInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const classes = useStyles();
  const {
    elementModel: {
      config: { dataSource, defaultValue, language: codeLanguage },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedCodeInputConfig>();

  const changeLanguage = (l: Language) => setLang(l);
  const handleDefaultValueInputChange = (
    _editor: Editor,
    _data: EditorChange,
    newValue: string,
  ) => changeConfigValue("defaultValue", newValue || null);

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text"]}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        <div className={classes.defaultValueInput}>
          <CodeEditor
            value={defaultValue ?? ""}
            label={editorTranslation.defaultValueInputLabel}
            onChange={handleDefaultValueInputChange}
            options={{ readOnly: Boolean(dataSource) }}
            language={codeLanguage}
            onAutoComplete={false}
            stylesAsMuiInput
          />
        </div>
      </FormInputConfigEditorComponent>
      <ConfigComponent />
    </>
  );
});
