import { createUseTranslation } from "core/session/translation";

export const commonStaticPagesTranslation = {
  en: {
    SaveButton: "Save",
    DeleteButton: "Delete",
    deletedMessage: "File was deleted successfully",
    saveErrorMessage: "File was not uploaded. Error:",
    saveSuccessMessage: "File successfully uploaded",
    tableNameLabel: "Name",
    tableFileTypeLabel: "File Type",
    tableUploadLabel: "Uploaded",
    tableTypeGroupLabel: "Type Group",
    tableAccessGroupLabel: "Access Group",
    tableActionsLabel: "Actions",
  },
};

export const useCommonStaticPagesTranslation = createUseTranslation(
  commonStaticPagesTranslation,
);
