import React, { memo } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { useElementEditorContext } from "core/editor";
import { FormConfig } from "../../types";
import { useEditorFormTranslation } from "../translation";

export const DisableSpacing = memo(() => {
  const {
    elementModel: {
      config: { disableSpacing = false },
    },
    changeConfigValue,
  } = useElementEditorContext<FormConfig>();
  const { disableSpacingLabel } = useEditorFormTranslation();

  const handleDisableSpacingInputChange = () =>
    changeConfigValue("disableSpacing", !disableSpacing);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={disableSpacing}
          onChange={handleDisableSpacingInputChange}
        />
      }
      label={disableSpacingLabel}
    />
  );
});
