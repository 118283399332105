import React, { memo } from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from "react-number-format";

import { ConnectedReduxModuleProps } from "core";
import { ReduxModule } from "./reduxModule";
import { NumberInput } from "./types";
import {
  EndInputAdornment,
  StartInputAdornment,
} from "../common/InputAdornment";

const DefaultNumberInput = memo<
  ConnectedReduxModuleProps<ReduxModule, NumberInput>
>(
  ({
    value,
    element: {
      i18n: { label },
      config: {
        startAdornment,
        startAdornmentIcon,
        endAdornment,
        endAdornmentIcon,
        precision,
        prefix,
        suffix,
        thousandSeparator,
        format,
      },
    },
    changeValue,
    changeTouched,
    disabled,
    errors,
    required,
  }) => {
    const hasStartAdornment = startAdornment || startAdornmentIcon;
    const getStartAdornment = () => (
      <StartInputAdornment icon={startAdornmentIcon} text={startAdornment} />
    );

    const hasEndAdornment = endAdornment || endAdornmentIcon;
    const getEndAdornment = () => (
      <EndInputAdornment icon={endAdornmentIcon} text={endAdornment} />
    );

    const handleValueChange = (values: NumberFormatValues) => {
      changeValue(values.value);
    };

    const handleTouchedChange = () => {
      changeTouched(true);
    };

    return (
      <TextField
        fullWidth={true}
        label={label}
        disabled={disabled}
        helperText={errors}
        error={Boolean(errors)}
        onBlur={handleTouchedChange}
        required={required}
        InputProps={{
          startAdornment: hasStartAdornment && getStartAdornment(),
          endAdornment: hasEndAdornment && getEndAdornment(),
          inputComponent: NumberFormatCustom as any,
          inputProps: {
            component: NumberFormat,
            value: value ?? "",
            prefix,
            suffix,
            thousandSeparator,
            format,
            onValueChange: handleValueChange,
            decimalScale: precision,
          },
        }}
      />
    );
  },
);

interface NumberFormatCustomProps extends NumberFormatProps {
  inputRef: (instance: NumberFormat | null) => void;
  onValueChange: (values: NumberFormatValues) => void;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, component, onValueChange, ...rest } = props;

  function handleValueChange(values: NumberFormatValues) {
    onValueChange(values);
  }

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      allowEmptyFormatting={true}
      mask="_"
      onValueChange={handleValueChange}
    />
  );
}

export default DefaultNumberInput;
