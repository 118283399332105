import React, {
  ChangeEvent,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";

import { Section, useElementEditorContext } from "core/editor";
import { UntransformedSliderInputConfig } from "../../types";
import { useSliderInputEditorTranslation } from "../translation";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";
import { useStyles } from "./styles";
import { Marks } from "./Marks";

const valueLabelDisplayOptions = ["on", "off", "auto"];
const trackOptions = ["normal", "inverted", false];
const valueLabelDisplayAutocompleteOptions = valueLabelDisplayOptions.map(
  (valueLabelDisplayOption) => ({
    value: valueLabelDisplayOption,
    label: valueLabelDisplayOption,
  }),
);
const trackAutocompleteOptions = trackOptions.map((trackOption) => ({
  value: trackOption,
  label: String(trackOption),
}));

export const AdvancedComponent = memo(() => {
  const {
    elementModel: {
      config: { min, max, marks, step, track, valueLabelDisplay },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedSliderInputConfig>();
  const translation = useSliderInputEditorTranslation();
  const [
    searchValueLabelDisplayInputValue,
    setSearchValueLabelDisplayInputValue,
  ] = useState<string>("");
  const [searchTrackInputValue, setSearchTrackInputValue] = useState<string>(
    "",
  );
  const classes = useStyles();

  const handleChange = useCallback(
    (prop, newValue) => changeConfigValue(prop, newValue),
    [changeConfigValue],
  );

  const selectedValueLabelDisplayValue = useMemo(
    () =>
      valueLabelDisplayAutocompleteOptions.find(
        (o: SelectOption) => o.value === valueLabelDisplay,
      ),
    [valueLabelDisplay],
  );
  const selectedTrackValue = useMemo(
    () => trackAutocompleteOptions.find((o: SelectOption) => o.value === track),
    [track],
  );

  const handleNumberInputChange = (
    numberInputName: "min" | "max" | "step",
  ) => ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => {
    const value = Number(newValue);
    handleChange(numberInputName, value);
  };
  const handleValueLabelDisplayInputChange = (value: IAutocompleteValue) => {
    setSearchValueLabelDisplayInputValue("");
    handleChange("valueLabelDisplay", value || (undefined as any));
  };
  const handleTrackInputChange = (value: IAutocompleteValue) => {
    setSearchTrackInputValue("");
    handleChange("track", value || (undefined as any));
  };

  const handleMarksInputChange = () => handleChange("marks", !marks);

  return (
    <>
      <Section title={translation.advancedTitle} wrapped={true}>
        <TextField
          value={min}
          name="min"
          type="number"
          label={translation.minInputLabel}
          fullWidth={true}
          onChange={handleNumberInputChange("min")}
        />
        <TextField
          className={classes.topMargin}
          value={max}
          name="max"
          type="number"
          label={translation.maxInputLabel}
          fullWidth={true}
          onChange={handleNumberInputChange("max")}
        />
        <TextField
          className={classes.topMargin}
          value={step}
          name="step"
          type="number"
          label={translation.stepInputLabel}
          fullWidth={true}
          onChange={handleNumberInputChange("step")}
        />
        <BaseAutocomplete
          options={valueLabelDisplayAutocompleteOptions}
          onChange={handleValueLabelDisplayInputChange}
          valueObject={selectedValueLabelDisplayValue ?? null}
          name="valueLabelDisplay"
          label={translation.valueLabelDisplayInputLabel}
          isLoading={false}
          searchInputValue={searchValueLabelDisplayInputValue}
          onInputChange={setSearchValueLabelDisplayInputValue}
          virtualizedList={true}
          isClearable={true}
        />
        <BaseAutocomplete
          options={trackAutocompleteOptions}
          onChange={handleTrackInputChange}
          valueObject={selectedTrackValue ?? null}
          name="track"
          label={translation.trackInputLabel}
          isLoading={false}
          searchInputValue={searchTrackInputValue}
          onInputChange={setSearchTrackInputValue}
          virtualizedList={true}
          isClearable={true}
        />
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(marks)}
              onChange={handleMarksInputChange}
            />
          }
          label={translation.marksLabel}
        />
      </Section>
      {Boolean(marks) && <Marks />}
    </>
  );
});
