import { createUseTranslation } from "core/session/translation";

const CQTranslations = {
  en: {
    queryTitleLabel: "Title",
    queryNameLabel: "Name",
    queryIdentifyingLabel: "Identifier Column",
    queryEditorInfo: "Start writing your query",
    queryEditorErrorTitle: "Error details",
    queryEditorViewExists: "Query {{viewName}} already exist",
    queryEditorLoading: "Validating",
    createButton: "Create",
    dataGridContent: "Run the query to see results",
    queryEditorErrorDetails: "Details",
    queryEditorLine: "Line",
    queryEditorLineErrorPrefix: "Error",
    queryEditorLineDetailsPrefix: "Details",
    queryEditorRun: "Run",
    permissionTableTitle: "Permission Table",
    updateButton: "Update",
  },
  es: {
    queryEditorInfo: "Escriba la consulta SQL",
    queryEditorErrorTitle: "Detalle de errores",
    queryEditorViewExists: "La consulta {{viewName}} ya existe",
    queryEditorLoading: "Validando",
    queryEditorErrorDetails: "Detalles",
    queryEditorLine: "Linea",
    queryEditorLineErrorPrefix: "Error",
    queryEditorLineDetailsPrefix: "Detales",
    queryEditorRun: "Ejecutar",
  },
};

export const useCustomQueryTranslation = createUseTranslation(CQTranslations);
