import { IReduxModuleFactoryArgs } from "core";
import { Form } from "../types";
import { buildActions } from "./actions";
import { buildDataSourceInterface } from "./dataSource";
import { buildReducer } from "./reducer";
import { buildSaga } from "./saga";
import { buildSelectors } from "./selectors";

export function reduxModuleFactory({
  path,
  element,
  lifecycleTypes,
}: IReduxModuleFactoryArgs<Form>) {
  const scope = path.join(".");
  const { actions, types } = buildActions(scope);
  const reducer = buildReducer(types);
  const selectors = buildSelectors(path, element);
  const saga = buildSaga(element, lifecycleTypes, actions, types, selectors);
  const dataSource = buildDataSourceInterface(
    actions,
    types,
    selectors,
    element,
  );

  return {
    actions,
    reducer,
    saga,
    selectors,
    interfaces: dataSource,
  };
}
