import React, { FC } from "react";

import { createStyles, makeStyles } from "@material-ui/core";

import { TCssGridConfig } from "core";

const setEnd = (end: number) => `span ${end}`;

const useStyles = makeStyles(
  createStyles<"root", TCssGridConfig>({
    root: {
      gridColumnStart: ({ column }) => column,
      gridColumnEnd: ({ width }) => setEnd(width),
      gridRowStart: ({ row }) => row,
      gridRowEnd: ({ height }) => setEnd(height),
    },
  }),
);

const GridCell: FC<TCssGridConfig> = ({ children, ...rest }) => {
  const { root } = useStyles(rest);
  return <div className={root}>{children}</div>;
};

GridCell.displayName = "GridCell";

export default GridCell;
