import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    codeClass: {
      minHeight: "350px",

      "& .CodeMirror": {
        minHeight: "100%",
        height: "unset",
      },
    },
    alertOverflow: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  }),
);
