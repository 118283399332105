import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";

import defaultTheme from "./defaultThemeDefinition";
import { selectors as sessionSelectors } from "../../../../session/reduxModule";
import { CssBaseline } from "@material-ui/core";

export const EditorThemeProvider = memo(({ children }) => {
  const language = useSelector(sessionSelectors.currentLanguage);

  const theme = useMemo(
    () => createTheme(defaultTheme, locales[language.locale]),
    [language.locale],
  );

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
});
