import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Box, Card, CardContent, Divider } from "@material-ui/core";

import useStyles from "./styles";
import LoginForm from "./LoginForm";
import verticalLogoPath from "../common/verticalLogo.svg";
import { AppBar } from "layouts/common/AppBar";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { selectors as loginSelectors } from "./reduxModule";
import { LoadingComponent } from "layouts/common/Loading";

export const LoginPage = memo(() => {
  const config = useSelector(loginSelectors.config);
  const loading = useSelector(loginSelectors.loading);

  const loggingIn = useSelector(sessionSelectors.loggingIn);

  const dispatch = useDispatch();

  const { root, logo } = useStyles();

  const handleSubmit = (values: any) => dispatch(sessionActions.login(values));

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <AppBar
        label=""
        menuWidth=""
        handleDrawerChange={() => null}
        open={false}
        toolbarVariant="dense"
        headerButtonsColor={"inherit"}
        hidden
      />
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className={root}>
          <Card variant="outlined">
            <Box
              py={3}
              display="flex"
              alignContent="center"
              justifyContent="center"
            >
              <img
                alt="logo"
                src={config?.logoPath ?? verticalLogoPath}
                className={logo}
              />
            </Box>
            <Divider />
            <CardContent>
              <LoginForm
                onSubmit={handleSubmit}
                loggingIn={loggingIn}
                identifierTitle={config?.identifierInputTitle}
              />
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
});
