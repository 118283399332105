import React, {
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { humanize } from "utils/string";
import { usePrevious } from "utils/hooks";
import {
  Section,
  ViewFieldToElement,
  useEditorTranslation,
  useObjectViewList,
  viewFieldToElementType,
} from "core/editor";
import { actions as editorActions } from "core/editor/reduxModule";
import { useElementTypesContext } from "core/ElementTypesContext";
import { useSessionContext } from "core/session";
import { selectors as routerSelectors } from "core/router/reduxModule";
import {
  DEFAULT_LANGUAGE_CODE,
  IElementModel,
  IElementType,
  IObjectViewField,
  Language,
  TElementModelWithPosition,
} from "core/types";
import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { TableHeaderCell } from "elementTypes/default_table_header_cell/types";
import Popover from "elementTypes/common/Popover";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { CellAlignment, UntransformedTableConfig } from "../../../types";
import { useTableEditorTranslation } from "../../../translation";
import { ColumnsProvider } from "./ColumnsContext";
import { Columns } from "./Columns";
import { ColumnDialog } from "./ColumnDialog";

export type IColumn = TableHeaderCell & {
  position: {
    column: number;
  };
};

export type IGenerateColumn = ViewFieldToElement & {
  fieldName?: string;
  sortable?: boolean;
  label?: string;
  align?: CellAlignment;
  width?: string;
};

export type ColumnDetails =
  | (Omit<IColumn, "i18n"> & {
      label: string;
      index: number;
      isNew?: boolean;
      isHidden?: string | null;
    })
  | ReturnType<typeof newColumnParams>;
export type ExtendedColumnConfig = ReturnType<typeof getHeaderConfig>;

type Props = {
  elementModel: IElementModel<UntransformedTableConfig>;
  changeConfigValue: (key: keyof UntransformedTableConfig, value: any) => void;
};

export const ColumnEditor = memo<Props>(
  ({
    elementModel,
    elementModel: {
      config: {
        dataSource: { viewName },
        hidden: initiallyHidden,
      },
      children,
    },
    changeConfigValue,
  }) => {
    const dispatch = useDispatch();
    const {
      addColumnTooltip,
      columnsTitle,
      generateColumnsTooltip,
      generateConfirmation,
    } = useTableEditorTranslation();
    const { cancelButton } = useEditorTranslation();
    const { elementTypes: allElementTypes } = useElementTypesContext();
    const page = useSelector(routerSelectors.page);
    const { language } = useSessionContext();
    const { getViewByName } = useObjectViewList();
    const [columnDetails, setColumnDetails] = useState<ColumnDetails | null>(
      null,
    );

    const [lang, setLang] = useState<Language>(language);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const {
      header: { elements: columns },
      body: { elements: bodyColumns },
    } = children as any;
    const currentView = getViewByName(viewName);
    const prevViewname = usePrevious(viewName);
    const hidden = initiallyHidden ?? getDefaultHidden((columns ?? []).length);

    const columnsExist = !!columns.length;
    const elementTypes: Record<string, IElementType<any>> = Object.keys(
      allElementTypes,
    ).reduce((result, name) => {
      const elementType = allElementTypes[name];

      return elementType.defaultElement
        ? { ...result, [name]: elementType }
        : result;
    }, {});

    const createElement = useCallback(
      (...params: Parameters<typeof editorActions.createElement>) => {
        dispatch(editorActions.createElement(...params));
      },
      [dispatch],
    );

    const updateChildren = useCallback(
      (...params: Parameters<typeof editorActions.updateElementChildren>) => {
        dispatch(editorActions.updateElementChildren(...params));
      },
      [dispatch],
    );

    const handlePopupClose = useCallback(() => setAnchorEl(null), []);

    function getDefaultHidden(colNumber: number) {
      return new Array(colNumber).fill(null);
    }

    const generateColumn = useCallback(
      (newProps: IGenerateColumn) => {
        const position = newProps.position ?? {
          column: 1,
          row: 1,
          width: 1,
          height: 1,
        };

        // create header column
        const defaultElement = getHeaderConfig(
          {
            ...newProps,
            label: newProps.label ?? humanize(newProps.name),
            fieldName: newProps.fieldName ?? newProps.name,
            sortable: newProps.sortable ?? true,
          },
          lang,
          position.column,
        );

        createElement(
          elementTypes,
          elementTypes.default_table_header_cell,
          page!,
          position,
          elementModel,
          defaultElement,
          generateColumnName(newProps.name),
          "header",
          true,
        );

        // create body column
        createElement(
          elementTypes,
          elementTypes[newProps.type],
          page!,
          position,
          elementModel,
          !!Object.keys(newProps.config).length || newProps.i18n
            ? {
                config: newProps.config,
                i18n: newProps.i18n,
              }
            : undefined,
          `table_body_${newProps.name}`,
          "body",
          true,
        );
      },
      [elementTypes, elementModel, createElement, lang, page],
    );

    function deleteColumn(index: number) {
      updateChildren(
        elementModel,
        bodyColumns.filter(
          (_: TElementModelWithPosition, i: number) => i !== index,
        ),
        page!,
        "body",
      );
      updateChildren(
        elementModel,
        columns.filter((_: IColumn, i: number) => i !== index),
        page!,
        "header",
      );
      changeConfigValue("hidden", [
        ...hidden.filter((_, hiddenIndex) => hiddenIndex !== index),
      ]);
    }

    const getElementTypeFromField = useCallback(
      (field: IObjectViewField, index = 0): ViewFieldToElement =>
        viewFieldToElementType[field.generalType.type]["field"]({
          field,
          parentElement: elementModel,
          parentType: "default_table",
          position: {
            column: index + 1,
            row: 1,
            width: 1,
            height: 1,
          },
        }),
      [elementModel],
    );

    const generateDefaultColumns = useCallback(() => {
      updateChildren(elementModel, [], page!, "header");
      updateChildren(elementModel, [], page!, "body");

      currentView?.fields.map((field, index) => {
        const element = getElementTypeFromField(field, index);
        generateColumn(element);
      });

      changeConfigValue(
        "hidden",
        getDefaultHidden((currentView?.fields ?? []).length),
      );
      handlePopupClose();
    }, [
      currentView,
      elementModel,
      generateColumn,
      getElementTypeFromField,
      page,
      handlePopupClose,
      updateChildren,
      changeConfigValue,
    ]);

    function changeLanguage(newLanguage: Language) {
      setLang(newLanguage);
    }

    function setNewParams() {
      setColumnDetails(newColumnParams(getLastOrder(columns)));
    }

    function onGenerateClick(e: MouseEvent<HTMLButtonElement>) {
      columnsExist ? setAnchorEl(e.currentTarget) : generateDefaultColumns();
    }

    // generate columns on initial viewName set
    useEffect(() => {
      if (viewName.length && !columns.length) {
        generateDefaultColumns();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (viewName.length && viewName !== prevViewname) {
        generateDefaultColumns();
      }
    }, [viewName, prevViewname, generateDefaultColumns]);

    return (
      <ColumnsProvider
        value={{
          columnDetails,
          language: lang,
          elementTypes,
          currentView,
          hidden,
          getElementTypeFromField,
          updateChildren,
          setColumnDetails,
          deleteColumn,
          generateColumn,
        }}
      >
        <Section
          title={columnsTitle}
          classes={{ root: "editor-table-section-columns" }}
          headerAction={
            <Box display="flex" alignItems="center">
              <IconButton
                icon="filter_none"
                onClick={onGenerateClick}
                color="primary"
                tooltip={generateColumnsTooltip}
              />
              <IconButton
                icon="add"
                onClick={setNewParams}
                color="primary"
                tooltip={addColumnTooltip}
              />
            </Box>
          }
          cardActions={
            columnsExist ? (
              <LanguageSwitch
                language={lang}
                changeLanguage={changeLanguage}
                colorVariant="dark"
                fullWidth={true}
              />
            ) : undefined
          }
        >
          <Columns />
        </Section>
        <ColumnDialog />
        <Popover
          anchorEl={anchorEl}
          onClose={handlePopupClose}
          actionsAlign="center"
          actions={
            <>
              <Button
                id="generate-columns-pop-up-confirm"
                label={generateColumnsTooltip}
                color="secondary"
                onClick={generateDefaultColumns}
              />
              <Button
                label={cancelButton}
                onClick={handlePopupClose}
                id="generate-columns-pop-up-cancel"
              />
            </>
          }
        >
          <Typography component="span">{generateConfirmation}</Typography>
        </Popover>
      </ColumnsProvider>
    );
  },
);

function getLastOrder(arr: IColumn[]) {
  return arr!.length ? Math.max(...arr.map((el) => el.position.column)) + 1 : 1;
}

function newColumnParams(order: number) {
  return {
    name: "",
    label: "",
    config: {
      dataSource: {
        fieldName: "",
        sortable: false,
      },
      align: "center" as CellAlignment,
    },
    position: {
      column: order,
      row: 1,
      width: 1,
      height: 1,
    },
    isNew: true,
    index: order - 1,
    isHidden: null as null | string,
  };
}

function getHeaderConfig(
  data: Record<string, any>,
  lang: Language,
  order: number,
) {
  return {
    name: data.name,
    i18n: {
      [DEFAULT_LANGUAGE_CODE]: { label: data.label },
      [lang.code]: { label: data.label },
    },
    type: "default_table_header_cell",
    config: {
      dataSource: {
        fieldName: data.fieldName || "",
        sortable: data.sortable,
      },
      align: data.align,
      ...(!(data.width === "auto") && { width: "1px" }),
    },
    position: {
      column: order,
      height: 1,
      width: 1,
      row: 1,
    },
  };
}

export function generateColumnName(name: string) {
  const value = name
    .replace("table_header_column_", "")
    .replace(/[^a-zA-Z0-9-_]/g, "_")
    .replace(/[-_]+$/g, "")
    .replace(/[_]+/g, "_")
    .toLowerCase();

  return `table_header_column_${value}`;
}
