import React, {
  ComponentProps,
  MouseEvent,
  ReactNode,
  memo,
  useEffect,
  useState,
} from "react";
import Draggable from "react-draggable";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Button from "../../../common/Button";
import IconButton from "../../../common/IconButton";

import {
  FormOptions,
  HookForm,
  useHookFormContext,
} from "../../../common/HookForm";
import { DialogWrapperProvider } from "./DialogWrapperContext";
import { useDialogTranslation } from "./translation";

import { useStyles } from "./style";

type OwnProps = {
  paperClass?: string;
  actionsClass?: string;
  title: ReactNode;
  formOptions?: FormOptions;
  draggable?: boolean;
  disableBackdropClick?: boolean;
  // if DialogContent is a form
  isForm?: boolean;
  // if form dynamically changed
  hasChanges?: boolean;
};

type DialogButtons = {
  cancelTitle: string;
  submitTitle?: string;
  submitDisabled?: boolean;
  processing?: boolean;
  submitButtonProps?: ComponentProps<typeof Button>;
};

type DialogContentText = {
  contentText?: string | ReactNode;
};
type HookFormSubmit = {
  handleSubmit: (data: { [k: string]: any }) => void;
};

type DefaultSubmit = {
  handleSubmit?: (e: MouseEvent) => void;
};

type DialogEvents = {
  handleClose: (
    event: Record<string, unknown>,
    reason?: "backdropClick" | "escapeKeyDown",
  ) => void;
} & (HookFormSubmit | DefaultSubmit);

export type DialogWrapperProps = Omit<DialogProps, "title"> &
  DialogButtons &
  DialogEvents &
  OwnProps &
  DialogContentText & {
    subActions?: ReactNode;
  };

/* eslint-disable quotes */
export const PaperComponent = (props: PaperProps) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
  >
    <Paper {...props} />
  </Draggable>
);

export const DialogWrapper = memo<DialogWrapperProps>(
  ({
    open,
    children,
    submitDisabled,
    submitTitle,
    cancelTitle,
    contentText,
    title,
    paperClass,
    actionsClass,
    isForm = false,
    formOptions,
    keepMounted = true,
    subActions,
    draggable,
    processing,
    hasChanges,
    disableBackdropClick,
    handleClose,
    handleSubmit,
    submitButtonProps,
    ...rest
  }) => {
    const {
      root,
      content,
      formClass,
      handlerIcon,
      displayCenter,
    } = useStyles();
    const { moveTooltip } = useDialogTranslation();

    const handleDialogClose = (
      event: Record<string, unknown>,
      reason: "backdropClick" | "escapeKeyDown",
    ) => {
      if (!(reason === "backdropClick" && disableBackdropClick)) {
        handleClose(event, reason);
      }
    };

    const contentDialog = (
      <>
        <DialogTitle
          id="dialog-title"
          disableTypography={true}
          className={displayCenter}
        >
          {draggable && (
            <>
              <IconButton
                icon="zoom_out_map"
                id="draggable-dialog-title"
                tooltip={moveTooltip}
                className={handlerIcon}
                disableRipple={true}
                placement="top"
              />
            </>
          )}
          <Typography variant="h4">{title}</Typography>
        </DialogTitle>
        <DialogContent className={content} id="dialog-content">
          {contentText && (
            <DialogContentText
              component={typeof contentText === "string" ? "p" : "div"}
            >
              {contentText}
            </DialogContentText>
          )}
          <DialogWrapperProvider value={{ onSubmit: handleSubmit! }}>
            {children}
          </DialogWrapperProvider>
        </DialogContent>
        <DialogActions className={actionsClass} id="dialog-actions">
          <Button label={cancelTitle} onClick={handleClose} color="secondary" />
          {subActions}
          {submitTitle &&
            (isForm ? (
              <SubmitButton
                label={submitTitle}
                disabled={submitDisabled}
                processing={processing}
                hasChanges={hasChanges}
                buttonProps={submitButtonProps}
              />
            ) : (
              <Button
                label={submitTitle}
                onClick={handleSubmit}
                disabled={submitDisabled}
                processing={processing}
                color="primary"
                {...submitButtonProps}
              />
            ))}
        </DialogActions>
      </>
    );

    return (
      <Dialog
        {...rest}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby={draggable ? "draggable-dialog-title" : "dialog-title"}
        className={root}
        classes={{ paperScrollPaper: paperClass }}
        keepMounted={keepMounted}
        PaperComponent={draggable ? PaperComponent : undefined}
        TransitionProps={{
          mountOnEnter: true,
          timeout: 0,
        }}
      >
        {isForm && handleSubmit ? (
          <HookForm
            className={formClass}
            onSubmit={handleSubmit}
            formOptions={formOptions}
          >
            {contentDialog}
          </HookForm>
        ) : (
          contentDialog
        )}
      </Dialog>
    );
  },
);

type SubmitProps = {
  label: string;
  disabled?: boolean;
  processing?: boolean;
  hasChanges?: boolean;
  buttonProps?: ComponentProps<typeof Button>;
};

const SubmitButton = memo<SubmitProps>(
  ({ label, disabled, processing, hasChanges, buttonProps }) => {
    const {
      formState: { isSubmitting, dirty },
    } = useHookFormContext();

    const [isDisabled, setDisabled] = useState<boolean>(disabled || false);
    /*
     * Will set `isDisabled` to false after a user interacted with any of the inputs.
     */
    useEffect(() => {
      if (!dirty !== isDisabled) {
        if (disabled === false && !dirty) {
          setDisabled(false);
        } else {
          setDisabled(!dirty);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);

    /*
     * During form submitting will set `isDisabled` to true.
     */

    useEffect(() => {
      setDisabled(isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    /*
     * Update `isDisabled` value only if disabled prop is defined
     * and the prop has been changed.
     */
    useEffect(() => {
      if (disabled !== undefined && disabled !== isDisabled) {
        setDisabled(disabled);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    return (
      <Button
        label={label}
        disabled={hasChanges ? false : isDisabled}
        processing={processing}
        color="primary"
        type="submit"
        {...buttonProps}
      />
    );
  },
);
