import React, { MouseEvent as ReactMouseEvent, memo } from "react";
import clsx from "classnames";
import TRow from "@material-ui/core/TableRow";

import { IElement } from "core";
import { CellAlignment } from "../types";
import { TableCell } from "../components";
import { useBodyStyles } from "../style";

export type RowProps = {
  identifier: string | number;
  elements: IElement[];
  alignments: CellAlignment[];
  row: Record<string, unknown>;
  references: Record<string, unknown> | null;
  selected: boolean;
  canSelectRow?: boolean;
  selectRow: (identifier: string | number, row: RowProps["row"]) => void;
};

export const TableRow = memo<RowProps>(
  ({
    identifier,
    elements,
    alignments,
    row,
    references,
    canSelectRow,
    selectRow,
    selected,
  }) => {
    const { rowClass } = useBodyStyles();
    const onRowClick = (
      _event: ReactMouseEvent<HTMLTableRowElement, MouseEvent>,
    ) => {
      if (canSelectRow) {
        selectRow(identifier, row);
      }
    };

    return (
      <TRow
        tabIndex={-1}
        className={clsx(rowClass, { selected }, { canSelectRow })}
        onClick={onRowClick}
      >
        {elements.map((col, i) => (
          <TableCell
            key={`${col.id}-${col.name}`}
            col={col}
            row={row}
            references={references}
            identifier={identifier}
            alignment={alignments[i]}
          />
        ))}
      </TRow>
    );
  },
);

TableRow.displayName = "TableRow";
