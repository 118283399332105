import React, { NamedExoticComponent, memo } from "react";
import { Box, BoxProps } from "@material-ui/core";
import { DataGrid, DataGridProps, GridColDef } from "@material-ui/data-grid";

import { GeneralType } from "core";
import { useStyles } from "./styles";

export type HighlightedRow = string[];

export type HighlightedRows = Record<string, HighlightedRow>;

type DataGridTableProps = DataGridProps & {
  columns: GridColumn[] | CustomColumnProps[];
  boxProps?: BoxProps;
};

type CustomEditProps = {
  editComponent?: NamedExoticComponent<any>;
  editProps?: Record<string, unknown>;
};

type CustomCellProps = {
  component?: NamedExoticComponent<any>;
  props?: Record<string, unknown>;
};

export type CustomColumnProps = CustomEditProps &
  CustomCellProps & {
    generalType?: GeneralType;
    index?: number;
    nullable?: boolean;
  };

export type GridColumn = GridColDef & CustomColumnProps;

export const DataGridTable = memo<DataGridTableProps>(
  ({ rows, columns, boxProps, ...rest }) => {
    const classes = useStyles();

    return (
      <Box mt={2} className={classes.root} {...boxProps}>
        <DataGrid rows={rows} columns={columns} autoHeight {...rest} />
      </Box>
    );
  },
);
