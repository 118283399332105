import React, { ChangeEvent, ReactNode, memo, useMemo } from "react";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import MuiTabs from "@material-ui/core/Tabs";

import { ConnectedReduxModuleProps, Element } from "core";
import { ReduxModule } from "./reduxModule";
import { useStyles } from "./style";
import { Tabs } from "./types";

type Props = ConnectedReduxModuleProps<ReduxModule, Tabs>;

type TabPanelProps = {
  index: number;
  value: number;
  className: string;
  children?: ReactNode;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Paper square={true} variant="outlined" className={other.className}>
          {children}
        </Paper>
      )}
    </div>
  );
}

const DefaultTabs = memo<Props>(
  ({ select, indexSelected, element: { config, children } }) => {
    const {
      fullSize,
      tabsClass,
      tabRoot,
      tabButtonContainer,
      tabContentContainer,
    } = useStyles();

    const onChange = (
      _event: ChangeEvent<Record<string, unknown>>,
      value: number,
    ) => select(value);

    const tabsProps = {
      ...(config.variant === "standard" && { centered: true }),
      ...(config.variant === "scrollable" && {
        scrollButtons: "auto" as "auto" | "desktop" | "on" | "off",
      }),
    };

    const labels = useMemo(
      () =>
        config.labels.map((label, index) => (
          <Tab key={index} label={label} data-testid="test-tab" />
        )),
      [config.labels],
    );

    const panels = useMemo(
      () =>
        children.content.elements.map((element, i) => (
          <TabPanel
            value={indexSelected}
            index={i}
            key={i}
            className={clsx(fullSize, tabContentContainer)}
          >
            <Element key={element.id} element={element} />
          </TabPanel>
        )),
      [children.content.elements, indexSelected, fullSize, tabContentContainer],
    );

    return (
      <div className={clsx(tabRoot, fullSize)}>
        <Paper className={tabButtonContainer} square={true} variant="outlined">
          <MuiTabs
            value={indexSelected}
            onChange={onChange}
            indicatorColor="primary"
            textColor="primary"
            variant={config.variant}
            {...tabsProps}
          >
            {labels}
          </MuiTabs>
        </Paper>
        <div className={clsx(tabsClass, fullSize)}>{panels}</div>
      </div>
    );
  },
);

export default DefaultTabs;
