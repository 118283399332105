import { MouseEvent } from "react";
import { createTypeSafeContext } from "utils/createTypeSafeContext";

type IDialogWrapperContext = {
  onSubmit: (e: MouseEvent) => void;
};

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  IDialogWrapperContext
>();

export const useDialogContext = useTypeSafeContext;
export const DialogWrapperProvider = Provider;
export const DialogWrapperConsumer = Consumer;
