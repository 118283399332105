import { v4 as uuid } from "uuid";
import * as R from "ramda";

import {
  DEFAULT_LANGUAGE_CODE,
  Language,
  Translation,
} from "../../../../types";
import { ItemDetails } from "./components";

export type IData = { [k: string]: any };

export const reorderList = (
  list: ItemDetails[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((item: ItemDetails, index: number) => ({
    ...item,
    order: index + 1,
  }));
};

export const orderMenu = (menu: ItemDetails[]): ItemDetails[] =>
  menu
    .sort((a, b) => a.order - b.order)
    .map((item, index) => ({
      ...item,
      order: index,
      ...(!!item.menu.length && {
        menu: orderMenu(item.menu),
      }),
    }));

export const createI18n = (
  language: Language,
  label?: string,
): Translation<"label" | "icon"> => ({
  [DEFAULT_LANGUAGE_CODE]: {
    label: label ?? "",
    icon: "",
  },
  [language.code]: {
    label: label ?? "",
    icon: "",
  },
});

export const createNewEntry = (
  order: number,
  language: Language,
  label?: string,
): ItemDetails => ({
  id: uuid().split("-")[0],
  i18n: createI18n(language, label),
  order,
  pageId: "",
  menu: [],
  externalLink: null,
});

export const getMenuPath = (p: number[]) =>
  p.reduce((f, i) => [...f, "menu", i], [] as Array<string | number>);

export const convertValuesToData = (values: IData) => {
  let data = {};
  for (const key in values) {
    const [path, fieldName] = key.split(".");
    const value = values[key];
    // eslint-disable-next-line
    const newKey = path.replace(/[\[\]\'\']/g, "");
    data = R.assocPath([...newKey.split(","), fieldName], value, data);
  }
  return data as IData;
};

export const exposeItemValue = (
  menuItem: ItemDetails,
  data: IData,
  language: Language,
): ItemDetails => {
  if (!(data && !!Object.keys(data).length)) {
    return menuItem;
  }
  const nextItem: ItemDetails = R.clone(menuItem);

  const getTranslation = (param: string) =>
    data?.[param] ?? nextItem.i18n[language.code]?.[param];

  return {
    ...nextItem,
    i18n: {
      ...nextItem.i18n,
      [language.code]: {
        ...nextItem.i18n?.[language.code],
        ...(!data.pageId && {
          label: getTranslation("label"),
        }),
        icon: getTranslation("icon"),
      },
    } as Translation<"label" | "icon">,
    ...(data.pageId
      ? {
          menu: [],
          pageId: data.pageId,
        }
      : {
          pageId: "",
          menu: nextItem.menu.map((subItem, index) =>
            exposeItemValue(subItem, data[index], language),
          ),
        }),
  };
};
