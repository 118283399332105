import Ajv, { Options } from "ajv";
import { JSONSchema6 } from "json-schema";

import cloneDeep from "lodash/cloneDeep";
import mapValues from "lodash/mapValues";
import { assocPath, path as getPath } from "ramda";

import {
  IApiError,
  IElement,
  IElementArrayChild,
  IElementSingleChild,
} from "core/types";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces/FormDataSource";
import { Form } from "../types";

export function createDefaultData(
  element: Form,
  data: Record<string, unknown> = {},
) {
  for (const child of element.children.content.elements) {
    addElementToData(element.id, child, data);
  }

  // create data for multiReference config
  const { multiReference } = element.config.dataSource;
  if (multiReference) {
    Object.entries(multiReference).forEach(
      ([formFieldName, _referenceConfig]) => {
        data[formFieldName] = [];
      },
    );
  }

  return data;
}

function addElementToData(
  formId: string,
  element: IElement<WithOptionalFieldDataSourceConfig, any>,
  data: any,
) {
  const { dataSource } = element.config;

  if (dataSource && dataSource.elementId === formId) {
    const { fieldPath } = dataSource;

    /**
     * TODO:
     * Decide how to get the defaultValue. The dataSource config can include the type.
     */
    // const valueFactory = defaultValues[type];
    // if (!valueFactory) {
    //   throw new Error(
    //     `Field type "${type}" not supported in form. (field "${name}")`,
    //   );
    // }

    /**
     * TODO:
     * Can nested fields exist, or does the subForm take care of them?
     */
    data[fieldPath[fieldPath.length - 1]] = null;
  }

  const { children } = element;
  let c: IElementSingleChild | IElementArrayChild;
  for (const k of Object.keys(children)) {
    c = children[k];
    for (const child of (c as IElementArrayChild).elements || [
      (c as IElementSingleChild).element,
    ]) {
      addElementToData(formId, child, data);
    }
  }
}

// const defaultValues = {
//   text: () => null,
// };

interface IValidationProps {
  values: any;
  schema: JSONSchema6;
  options?: Options;
}

export const getValidationData = ({
  schema,
  values,
  options,
}: IValidationProps) => {
  const ajvOptions = {
    // allErrors means to go through the whole schema and return all errors
    allErrors: true,
    // enable support of the `nullable` keyword
    // additionally to the type `null`
    nullable: true,
    ...options,
  };
  const ajv = new Ajv({ ...ajvOptions });
  const validate = ajv.compile(schema);
  const newValues = cloneDeep(values);

  return {
    isValid: validate(newValues),
    values: newValues,
    errors: validate.errors,
  };
};

export const isNullable = (
  name: string,
  schema: JSONSchema6,
  identifierName?: string,
) =>
  identifierName === name
    ? false
    : !(schema.required && schema.required.includes(name));

export const getSchema = (jsonSchema?: JSONSchema6, identifier?: string) => {
  let schema: JSONSchema6 | null = null;
  if (jsonSchema) {
    schema = jsonSchema;

    if (schema.properties) {
      const newProps = mapValues(
        schema.properties,
        (val: any, key: string) => ({
          ...val,
          nullable: isNullable(key, schema as JSONSchema6, identifier),
        }),
      );

      schema = { ...schema, properties: newProps };
    }
  }
  return schema;
};

export const validateChange = (currentSchema: JSONSchema6) =>
  handleValidate(currentSchema, {});

const handleValidate = (currentSchema: JSONSchema6, options: Ajv.Options) => {
  if (currentSchema) {
    // Full list of the Avj options: https://ajv.js.org/#options
    const opts = {
      ...options,
    };

    return (obj: Record<string, unknown>) => {
      const { isValid, errors: validationErrors } = getValidationData({
        options: opts,
        schema: currentSchema,
        values: obj,
      });
      let errors = {};
      if (validationErrors) {
        for (const e of validationErrors) {
          let path =
            e.dataPath?.split(".")?.filter((key) => !!key.length) ?? [];
          let msg = e.message;

          if (e.keyword === "required") {
            path = [...path, e.params["missingProperty"]];
            msg = "Required";
          } else if (
            e.keyword === "type" &&
            !getPath(path, obj) &&
            path.some((fieldName) =>
              currentSchema?.required?.includes(fieldName),
            )
          ) {
            // if input value is required and has `null` value
            // avj identify it as type error but should be required
            msg = "Required";
          }

          errors = assocPath(path, msg, errors);
        }
      }
      return !isValid ? errors : {};
    };
  } else {
    // no json-schema found - always return an empty error object
    return () => ({});
  }
};

export const getErrors = (apiError: IApiError) => {
  const fieldPath = apiError.details?.match(/Key \((".*"|[^)]+)\)/)?.slice(1);

  switch (apiError.code) {
    case "23505":
      return fieldPath
        ? fieldPath.map((f) => ({
            fieldPath: f,
            description: "uniqueViolation",
          }))
        : null;
    default:
      return null;
  }
};
