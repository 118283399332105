import React, { BaseSyntheticEvent, memo, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useForm } from "react-hook-form";

import { useLoginFormTranslation } from "./translation";
import { IUserTypeForm, IUserTypeFormData } from "./types";
import Button from "elementTypes/common/Button";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { InputAdornment } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: "10px",
    },
    item: {
      marginBottom: "10px",
    },
    buttonContainer: {
      display: "flex",
    },
  }),
);

const LoginForm = memo<IUserTypeForm>(
  ({ loggingIn, onSubmit, identifierTitle }) => {
    const { root, item, buttonContainer } = useStyles();
    const { register, handleSubmit, errors } = useForm<IUserTypeFormData>();
    const translations = useLoginFormTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const onClick = () => setShowPassword(!showPassword);

    const handleFormSubmit = (data: any, e?: BaseSyntheticEvent<any>) => {
      e?.preventDefault();

      onSubmit(data);
    };

    return (
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container={true} spacing={2} className={root}>
          <Grid item={true} xs={12} className={item}>
            <TextField
              name="identifier"
              label={identifierTitle ?? "Username / Email"}
              required={true}
              fullWidth={true}
              inputRef={register({
                required: translations.requiredInput,
              })}
            />
            {errors.identifier && (
              <Typography variant="caption" color="error">
                {errors.identifier.message}
              </Typography>
            )}
          </Grid>
          <Grid item={true} xs={12} className={item}>
            <TextField
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              required={true}
              fullWidth={true}
              inputRef={register({
                required: translations.requiredInput,
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={onClick}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <Typography variant="caption" color="error">
                {errors.password.message}
              </Typography>
            )}
          </Grid>
          <Grid
            container={true}
            item={true}
            xs={12}
            justifyContent="flex-end"
            className={buttonContainer}
          >
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              processing={loggingIn}
              disabled={loggingIn}
              iconRight="person"
            >
              {translations.buttonLogin}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  },
);

export default LoginForm;
