import { IReduxModuleFactoryArgs } from "core";
import { TextInput } from "../types";
import { controlledReduxModuleFactory } from "./controlledReduxModule";
import { uncontrolledReduxModuleFactory } from "./uncontrolledReduxModule";

export function reduxModuleFactory({
  path,
  element,
}: IReduxModuleFactoryArgs<TextInput>) {
  return element.config.dataSource
    ? controlledReduxModuleFactory(element)
    : uncontrolledReduxModuleFactory({
        path,
        element,
      } as IReduxModuleFactoryArgs<TextInput>);
}

export type ReduxModule = ReturnType<typeof reduxModuleFactory>;
