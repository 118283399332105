import React, { memo, useCallback } from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";

import {
  Section,
  ViewAutocomplete,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { defaultElement } from "../defaultElement";
import { BarChartConfig } from "../../types";
import { useChartEditorTranslation } from "../../translation";

export const SetupComponent = memo(() => {
  const {
    elementModel: {
      config: {
        dataSource,
        hideLegend,
        hideLabel,
        showBackground = false,
        isStacked = false,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<BarChartConfig>();

  const { viewName, columns } = dataSource;

  const {
    labelLabel,
    labelShowLabel,
    legendShowLabel,
    valueLabel,
    viewLabel,
    valueColumnHelperText,
    showBackgroundLabel,
    isStackedLabel,
  } = useChartEditorTranslation();

  const changeDataSource = useCallback(
    (newDataSource: Partial<BarChartConfig["dataSource"]>) =>
      changeConfigValue("dataSource", { ...dataSource, ...newDataSource }),
    [changeConfigValue, dataSource],
  );

  const handleViewNameChange = (newViewName: string) =>
    changeDataSource({
      viewName: newViewName,
      columns: defaultElement.config?.dataSource.columns,
    });

  const handleFieldChange = (
    fieldName: string,
    fieldValue: string[] | string | boolean | number | number[] | null,
  ) =>
    changeDataSource({
      columns: { ...columns, [fieldName]: fieldValue },
    });

  const toggleLegend = useCallback(
    () => changeConfigValue("hideLegend", !hideLegend),
    [changeConfigValue, hideLegend],
  );

  const toggleLabel = useCallback(
    () => changeConfigValue("hideLabel", !hideLabel),
    [changeConfigValue, hideLabel],
  );

  const toggleShowBackground = useCallback(
    () => changeConfigValue("showBackground", !showBackground),
    [changeConfigValue, showBackground],
  );

  const handleIsStackedChange = () =>
    changeConfigValue("isStacked", !isStacked);

  const fields = [
    {
      label: labelLabel,
      value: columns.labelColumnName,
      name: "labelColumnName",
    },
    {
      label: valueLabel,
      value: columns.valueColumnNames,
      name: "valueColumnNames",
    },
  ];
  const { configTitle } = useEditorTranslation();

  return (
    <Section title={configTitle} wrapped={true}>
      <ViewAutocomplete
        viewValue={viewName}
        viewLabel={viewLabel}
        onViewNameChange={handleViewNameChange}
        onViewFieldChange={handleFieldChange}
        fields={fields}
      />
      <FormHelperText>{valueColumnHelperText}</FormHelperText>
      <FormControlLabel
        control={<Switch checked={!hideLegend} onChange={toggleLegend} />}
        label={legendShowLabel}
      />
      <FormControlLabel
        control={<Switch checked={!hideLabel} onChange={toggleLabel} />}
        label={labelShowLabel}
      />
      <FormControlLabel
        control={
          <Switch checked={showBackground} onChange={toggleShowBackground} />
        }
        label={showBackgroundLabel}
      />
      <FormControlLabel
        control={
          <Switch checked={isStacked} onChange={handleIsStackedChange} />
        }
        label={isStackedLabel}
      />
    </Section>
  );
});
