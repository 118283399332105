import React, { memo } from "react";

import { Filter } from "elementTypes/common/Echarts/editor";
import {
  DataSourceComponent,
  OptionComponent,
  SortComponent,
  TranslationComponent,
} from "./components";

export const EchartsCustomChartEditor = memo(() => (
  <>
    <DataSourceComponent />
    <TranslationComponent />
    <SortComponent />
    <Filter />
    <OptionComponent />
  </>
));
