import React, { FC, memo } from "react";

import { Box, Typography } from "@material-ui/core";
import { NodeProps } from "react-flow-renderer";
import { NodeData } from "./types";
import { ElementSchemaNode } from "./component";

const SchemaNode: FC<NodeProps<NodeData>> = ({ data }) => {
  const { schema, width, height } = (data as unknown) as ElementSchemaNode;

  return (
    <Box
      width={width}
      height={height}
      border="2px dashed"
      borderColor="divider"
    >
      <Typography
        variant="subtitle1"
        style={{ transform: "translateY(-100%)" }}
      >
        {schema}
      </Typography>
    </Box>
  );
};

export default memo(SchemaNode);
