import React, { memo } from "react";
import Button from "elementTypes/common/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import { Props } from "./container";
import useStyles from "./styles";

import { Colors } from "elementTypes/common/StyledTypography";

const DefaultExternalLinkField = memo<Props>(
  ({
    element: {
      config: { isButton, icon, variant },
      i18n: { label: i18nLabel },
    },
    color = "default",
    link,
  }) => {
    const classes = useStyles();
    const label = i18nLabel ? i18nLabel : link ?? "";
    const target = "_blank";

    if (!link) {
      return null;
    }

    if (isButton) {
      return (
        <Button
          href={link}
          external
          target={target}
          iconRight={icon}
          rel="noopener noreferrer"
          color={Colors[color]}
          variant={variant}
          className={classes.button}
          label={label}
        />
      );
    }

    return (
      <Link href={link} target={target} rel="noopener noreferrer">
        <Typography component="span" className={classes.text}>
          {label}
        </Typography>
      </Link>
    );
  },
);

DefaultExternalLinkField.displayName = "DefaultExternalLinkField";

export default DefaultExternalLinkField;
