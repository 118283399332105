import React, { ChangeEvent, memo, useCallback } from "react";

import { TextField } from "@material-ui/core";

import {
  Section,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor";
import { UntransformedArrayTextFieldConfig } from "../types";
import { StylingComponent } from "./Styling";
import { TableColumnEditor } from "core/editor/common/TableColumnEditor/TableColumnEditor";
import { useArrayTextFieldEditorTranslation } from "./translation";

export const ArrayTextFieldEditor = memo(() => {
  const {
    elementModel: {
      id,
      config: { values, maxLength },
      config,
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedArrayTextFieldConfig>();
  const { configTitle } = useEditorTranslation();
  const translation = useArrayTextFieldEditorTranslation();

  const wrappedChangeValue = useCallback(
    (newValue: string) => changeConfigValue("values", newValue),
    [changeConfigValue],
  );

  const handleMaxLength = (e: ChangeEvent<HTMLInputElement>) =>
    changeConfigValue(
      "maxLength",
      Number(e.target.value) ? parseInt(e.target.value, 10) : undefined,
    );

  return (
    <>
      <Section title={configTitle} wrapped={true}>
        <TableColumnEditor
          id={id}
          value={values}
          onChange={wrappedChangeValue}
          allowedDataTypeIsArray={true}
          allowedDataTypes={["text"]}
        />
        <CustomExpressionEditor
          config={config}
          value={values}
          disableSwitcher={true}
          onChange={wrappedChangeValue}
        />
        <TextField
          label={translation.maxLengthLabel}
          type="number"
          value={maxLength}
          onChange={handleMaxLength}
          fullWidth={true}
        />
      </Section>
      <StylingComponent />
    </>
  );
});
