import * as t from "io-ts";
import { IElement, UntransformedConfig, customExpression } from "core/types";

export const ArrayTextFieldConfig = t.intersection([
  t.type({
    values: customExpression(t.union([t.array(t.string), t.null])),
  }),
  t.partial({
    maxLength: t.union([t.number, t.undefined]),
    color: t.keyof({
      primary: null,
      secondary: null,
      default: null,
    }),
    variant: t.keyof({
      default: null,
      outlined: null,
    }),
    size: t.keyof({
      small: null,
      medium: null,
    }),
  }),
]);

export type ArrayTextFieldConfig = t.TypeOf<typeof ArrayTextFieldConfig>;

export type UntransformedArrayTextFieldConfig = UntransformedConfig<
  ArrayTextFieldConfig
>;

export type ArrayTextField = IElement<ArrayTextFieldConfig>;
