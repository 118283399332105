import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  getElementById,
  getNearestParentElement,
  useElementEditorContext,
  useObjectViewList,
} from "../..";
import { selectors as editorSelectors } from "../reduxModule";
import { selectors as routerSelectors } from "../../router/reduxModule";
import { IObjectView, IObjectViewField } from "core";

import { WithOptionalFieldDataSourceConfig } from "../../../elementInterfaces/FormDataSource";
import { Form, FormConfig } from "elementTypes/default_form/types";

type ParentForm = {
  getParentViewField: (fieldName: string) => IObjectViewField | undefined;
  parentIsReadOnly: boolean;
  parentView?: IObjectView | null;
  parentDataSource?: FormConfig["dataSource"];
  parentJSONSchema?: FormConfig["jsonSchema"];
};

export const useParentForm = (): ParentForm => {
  const {
    elementModel: {
      id,
      config: { dataSource },
    },
  } = useElementEditorContext<WithOptionalFieldDataSourceConfig>();
  const { getViewByName } = useObjectViewList();
  const updatedElements = useSelector(editorSelectors.updatedElements);
  const page = useSelector(routerSelectors.page);

  const rootElement = page!.element;
  const parentForm = useMemo(() => {
    const currentForm = dataSource
      ? (getElementById(
          rootElement,
          updatedElements,
          dataSource?.elementId as string,
        ) as Form | null)
      : null;
    const nearestForm = getNearestParentElement(
      rootElement,
      updatedElements,
      id,
      "default_form",
    ) as Form | null;

    return currentForm ?? nearestForm;
  }, [id, rootElement, updatedElements, dataSource]);

  const parentView = useMemo(
    () => parentForm && getViewByName(parentForm.config.dataSource.viewName),
    [getViewByName, parentForm],
  );

  const getParentViewField = useCallback(
    (fieldName: string) =>
      parentView?.fields?.find((field) => field.name === fieldName),
    [parentView],
  );

  return {
    parentView,
    parentDataSource: parentForm?.config.dataSource,
    parentJSONSchema: parentForm?.config.jsonSchema,
    parentIsReadOnly: parentForm?.config.type === "detail",
    getParentViewField,
  };
};
