import { IStaticRouteConfig } from "core/router/types";

import { AuditsPage } from "./pages/tables/component";

import { TablesAuditPageRoute, UsersAuditPageRoute } from "./pages";

export const route: IStaticRouteConfig = {
  Component: AuditsPage,
  auth: true,
  isAdmin: true,
  routes: {
    "/admin/audits/tables": TablesAuditPageRoute,
    "/admin/audits/users": UsersAuditPageRoute,
  },
};
