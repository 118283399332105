import React, { ChangeEvent, memo, useCallback } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import { Section, useElementEditorContext } from "core/editor";
import {
  ArrayTextFieldConfig,
  UntransformedArrayTextFieldConfig,
} from "../types";
import { useArrayTextFieldEditorTranslation } from "./translation";

enum Colors {
  primary = "primary",
  secondary = "secondary",
  default = "default",
}
enum Sizes {
  medium = "medium",
  small = "small",
}
enum Variants {
  default = "default",
  outlined = "outlined",
}

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: {
        color = Colors.default,
        variant = Variants.default,
        size = Sizes.medium,
      },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedArrayTextFieldConfig>();
  const translation = useArrayTextFieldEditorTranslation();

  const handleColorChange = useCallback(
    (newValue: ArrayTextFieldConfig["color"]) =>
      changeConfigValue("color", newValue),
    [changeConfigValue],
  );
  const handleVariantChange = useCallback(
    (newValue: ArrayTextFieldConfig["variant"]) =>
      changeConfigValue("variant", newValue),
    [changeConfigValue],
  );
  const handleSizeChange = useCallback(
    (newValue: ArrayTextFieldConfig["size"]) =>
      changeConfigValue("size", newValue),
    [changeConfigValue],
  );

  const colors = Object.values(Colors).map((itemColor) => (
    <FormControlLabel
      key={itemColor}
      control={<Radio color="primary" />}
      label={translation[`${itemColor}Label`]}
      value={itemColor}
    />
  ));
  const sizes = Object.values(Sizes).map((itemSize) => (
    <FormControlLabel
      key={itemSize}
      control={<Radio color="primary" />}
      label={translation[`${itemSize}Label`]}
      value={itemSize}
    />
  ));
  const variants = Object.values(Variants).map((itemVariant) => (
    <FormControlLabel
      key={itemVariant}
      control={<Radio color="primary" />}
      label={translation[`${itemVariant}Label`]}
      value={itemVariant}
    />
  ));

  const handleChange = (type: "color" | "size" | "variant") => (
    _e: ChangeEvent,
    value: string,
  ) => {
    switch (type) {
      case "color":
        handleColorChange(value as "primary" | "secondary");
        break;
      case "variant":
        handleVariantChange(value as "default" | "outlined");
        break;
      default:
        handleSizeChange(value as "small" | "medium");
    }
  };

  return (
    <Section title={translation.stylingSectionTitle} wrapped={true}>
      <FormLabel>{translation.colorInputLabel}</FormLabel>
      <RadioGroup row={true} value={color} onChange={handleChange("color")}>
        {colors}
      </RadioGroup>
      <FormLabel>{translation.sizeInputLabel}</FormLabel>
      <RadioGroup row={true} value={size} onChange={handleChange("size")}>
        {sizes}
      </RadioGroup>
      <FormLabel>{translation.variantInputLabel}</FormLabel>
      <RadioGroup row={true} value={variant} onChange={handleChange("variant")}>
        {variants}
      </RadioGroup>
    </Section>
  );
});
