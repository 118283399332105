import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { getTranslatedTextSaga } from "core/session/translation/createUseTranslation";
import { editorTranslation } from "core/editor";

import { DataDisplay } from "../types";
import { Actions, Selectors, Types } from "./types";
import { createWatcherSaga } from "core/utils/saga";
import { getServerError } from "core/utils/api";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: DataDisplay,
  selectors: Selectors,
) {
  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);

    const { dataSource } = element.config;

    if (!dataSource.viewName?.length) {
      // Inside editor mode if element has just been created viewName can be empty
      // set error to warn user of viewName necessity
      const msg: string = yield call(
        getTranslatedTextSaga,
        editorTranslation,
        "viewNameError",
      );
      yield put(actions.loadError(msg));
      return;
    }

    try {
      const identifierValue: unknown = yield select(selectors.identifier);

      const metadata = {
        order: dataSource.identifierName
          ? `${dataSource.identifierName}.desc`
          : null,
        offset: 0,
        limit: dataSource.setAllData ? 10 : 1,
      };
      if (dataSource.identifierName && identifierValue !== undefined) {
        metadata[dataSource.identifierName] = `eq.${identifierValue}`;
      }

      const data: Record<string, unknown>[] = yield call(
        services.api.loadViewData,
        token,
        dataSource.viewName,
        metadata,
      );
      yield put(
        actions.loadSuccess((dataSource.setAllData ? data : data[0]) ?? null),
      );
    } catch (error) {
      yield put(actions.loadError(getServerError(error)));
    }
  }

  function* callLoad() {
    yield put(actions.load());
  }

  return function* mainSaga() {
    yield all([
      takeLatest(types.LOAD, loadSaga),
      createWatcherSaga(selectors.identifier, {
        onChange: callLoad,
      }),
    ]);

    yield put(actions.load());
  };
}
