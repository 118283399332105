import { selectorScoper } from "core/utils/redux";

import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";
import { FileInput } from "../types";

import { IReducerState } from "./types";

export function buildSelectors(
  path: string[],
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: FileInput,
) {
  const scopeSelector = selectorScoper<IReducerState>(path);

  const value = (state: any) =>
    dataSource.createFieldValueSelector(fieldPath, {
      defaultValue: null,
    })(state);

  const errors = (state: any) =>
    dataSource.createFieldErrorSelector(fieldPath)(state);

  const touched = (state: any) =>
    dataSource.createFieldTouchedSelector(fieldPath)(state);

  const loading = (state: any) => scopeSelector(state).loading;
  const metadata = (state: any) => scopeSelector(state).metadata;
  const file = (state: any) => scopeSelector(state).file;
  const uploadError = (state: any) => scopeSelector(state).error;
  const metadataError = (state: any) => scopeSelector(state).metadataError;

  const required = () =>
    dataSource.createFieldRequiredSelector(fieldPath) ??
    element.config.nullable === false;

  return {
    value,
    errors,
    touched,
    loading,
    metadata,
    file,
    uploadError,
    metadataError,
    required,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
