import React, { memo } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";

import Link from "elementTypes/common/Link";
import useStyles from "./styles";
import { Props } from "./container";
import { getPushArguments } from "core/router/urlService";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";

import { AlertBox } from "elementTypes/common/AlertBox";
import { useInternalLinkFieldTranslation } from "./translation";

const DefaultInternalLinkField = memo<Props>(
  ({
    label,
    linkTo: { pageId, params },
    loading,
    element: {
      config: { isButton },
    },
    pages,
  }) => {
    const classes = useStyles();
    const translation = useInternalLinkFieldTranslation();

    if (!pageId) {
      return (
        <AlertBox
          title={translation.configErrorTitle}
          message={translation.linkNotSet}
        />
      );
    }

    const page = pages[pageId];

    if (!page) {
      return (
        <AlertBox
          title={translation.configErrorTitle}
          message={translation.pageNotFound}
        />
      );
    }

    if (loading) {
      return <LinearProgress variant="indeterminate" />;
    }

    return label ? (
      <Link
        title={String(label)}
        className={classes.link}
        underline="always"
        href={getPushArguments(page, params)[0]}
        component={isButton ? "button" : undefined}
      >
        {label}
      </Link>
    ) : (
      <>{NON_BREAKING_SPACE}</>
    );
  },
);

DefaultInternalLinkField.displayName = "DefaultInternalLinkField";

export default DefaultInternalLinkField;
