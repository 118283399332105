import React, { FC, memo } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import { Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { useStyles } from "./style";

const StartNode: FC<NodeProps<any>> = ({
  sourcePosition = Position.Bottom,
}) => {
  const { handleClass } = useStyles();

  return (
    <>
      <Box
        p={1.25}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="40px"
        fontWeight={700}
        color="white"
        bgcolor="grey.600"
      >
        <AddIcon color="inherit" fontSize="small" />
      </Box>
      <Handle type="source" position={sourcePosition} className={handleClass} />
    </>
  );
};

export default memo(StartNode);
