import { handleActions } from "core/utils/redux";
import { IState, Types } from "./types";

const INITIAL_STATE: IState = {
  loading: false,
  error: null,
  data: [],
  selected: null,
};

export function buildReducer(types: Types) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.LOAD]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [types.LOAD_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload.data,
    }),
    [types.LOAD_ERROR]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error,
    }),
    [types.SELECT_MARKER]: (state, action) => ({
      ...state,
      selected: action.payload.marker,
    }),
  });
}
