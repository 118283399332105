import { createUseTranslation } from "core/session/translation";

const workflowTranslations = {
  en: {
    workflowLandingPageTitle: "Configure how your data flows",
    workflowTitle: "Workflow",
    workflowDescTitle: "Information about Workflow",
    exit: "Exit",
    newState: "New State",
    editStateTooltip: "Edit State",
    emptyStateList: "No State found",
    stateListTitle: "States",
    landingPageParagraph1:
      "A workflow represents how users can interact with the data. Data can be in different states.",
    landingPageParagraph2:
      "Connections between states are called transitions. They represent how your data can change.",
    landingPageParagraph3:
      "Drag from one state to another to create a transition.",
    landingPageParagraph4:
      "Select a state or transition to display more information.",
  },
  es: {
    workflowTitle: "Workflow",
    exit: "Salir",
    newState: "Nuevo estado",
  },
};

export const useWorkflowTranslations = createUseTranslation(
  workflowTranslations,
);
