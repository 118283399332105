import { IStaticRouteConfig } from "core";
import { route as WorkflowPageRoute } from "./workflow";
import { route as CustomQueryPageRoute } from "./customQuery";
import { DatabasePageComponent } from "./component";

export const route: IStaticRouteConfig = {
  Component: DatabasePageComponent,
  auth: true,
  isAdmin: true,
  routes: {
    "/admin/database/workflow": WorkflowPageRoute,
    "/admin/database/customQuery": CustomQueryPageRoute,
    "/admin/database/customQuery/:queryName": CustomQueryPageRoute,
  },
};
