import { IDefaultElement, buildCustomExpressionValue } from "core";
import { UntransformedArrayTextFieldConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedArrayTextFieldConfig> = {
  config: {
    // eslint-disable-next-line quotes
    values: buildCustomExpressionValue('["🚀", "🎉"]'),
    maxLength: undefined,
  },
};
