import React, { ChangeEvent, memo } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import { ConnectedReduxModuleProps } from "core";
import { ASTERISK_SYMBOL, getColumnName } from "../common/utils";
import { Switcher } from "./components";
import { ReduxModule } from "./reduxModule";
import { useStyles } from "./style";
import { BoolInput } from "./types";

export const DefaultBoolInput = memo<
  ConnectedReduxModuleProps<ReduxModule, BoolInput>
>(
  ({
    value,
    element: {
      i18n: { label, labelRight, labelLeft },
      config: { dataSource, placement, isSwitch },
    },
    changeValue,
    disabled,
    errors,
    required,
  }) => {
    const handleValueChange = ({
      target: { checked },
    }: ChangeEvent<HTMLInputElement>) => {
      const isNullable = isSwitch ? false : !required;
      const shouldSetNull = isNullable && value === false && checked;

      if (shouldSetNull) {
        changeValue(null);
      } else {
        changeValue(checked);
      }
    };

    const name = getColumnName(dataSource);
    const { labelControl } = useStyles();

    /* If the input has `config.nullable` = true and value equal to null, the component appears indeterminate.
     * This does not set the native input element to indeterminate due to inconsistent behavior across browsers.
     * However, we set a data-indeterminate attribute on the input.
     * This will work only for the Checkbox component.
     */
    const indeterminate = Boolean(
      (value === null || value === undefined) && !required,
    );
    const component = isSwitch ? (
      <Switcher
        value={value || false}
        name={name}
        labelRight={labelRight}
        labelLeft={labelLeft}
        onChange={handleValueChange}
      />
    ) : (
      <Checkbox
        checked={value || false}
        value={name}
        name={name}
        datatype={"bool"}
        onChange={handleValueChange}
        indeterminate={indeterminate}
      />
    );

    return (
      <FormControl required={required} error={Boolean(errors)}>
        <FormControlLabel
          className={isSwitch && labelLeft ? "" : labelControl}
          control={component}
          disabled={disabled}
          label={
            label ? (
              <>
                {label}
                {required && ASTERISK_SYMBOL}
              </>
            ) : undefined
          }
          labelPlacement={placement}
        />
        {errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    );
  },
);

export default DefaultBoolInput;
