import React, { memo } from "react";

import { Section, useEditorTranslation } from "core/editor";
import { LinkTo } from "./LinkTo";
import { TypeConfig } from "./TypeConfig";
import { HideSaveAndStayButton } from "./HideAndStayButton";
import { DisableSpacing } from "./DisableSpacing";
import { FormConfig, FormTypes } from "../../types";

export const Config = memo<{ type: FormConfig["type"] }>(({ type }) => {
  const { configTitle } = useEditorTranslation();

  return (
    <Section title={configTitle} wrapped={true}>
      <TypeConfig />
      {type !== FormTypes.detail && (
        <>
          <LinkTo />
          {type !== FormTypes.edit && <HideSaveAndStayButton />}
        </>
      )}
      <DisableSpacing />
    </Section>
  );
});
