import { createUseTranslation } from "core/session/translation";

const workflowActivateTranslations = {
  en: {
    activateLabel: "Activate / Deactivate Workflow",
    helperText:
      "Be aware that enforcing the workflow will restrict the changes that can be performed on your data",
    activationSuccess: "The workflow is now active",
    deactivationSuccess: "The workflow is now inactive",
  },
  es: {
    activateLabel: "Activar/Desactivar Workflow",
    helperText: "Solamente active el workflow is ha terminado de editar",
    activationSuccess: "The workflow is now active",
    deactivationSuccess: "The workflow is now inactive",
  },
};

export const useWorkflowActivateTranslations = createUseTranslation(
  workflowActivateTranslations,
);
