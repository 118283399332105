import { createUseTranslation } from "core/session/translation";

export const commonStaticPagesTranslation = {
  en: {
    urlInputLabel: "URL",
    bindDnInputLabel: "Bind dn",
    bindpwdInputLabel: "Bind password",
    baseDnInputLabel: "Base dn",
    searchAttributeInputLabel: "Search Attribute",
    defaultRoleInputLabel: "Default Role",
    roleAttributeInputLabel: "Role Attribute",
    staticRoleLabel: "Default Role",
    postgresRoleInLdapLabel: "Postgres role in LDAP attribute",
    ldapRoleToPostgresLabel: "Map LDAP role to postgres role",
    createLDAPConfigLabel: "No LDAP authentication configured!",
    dialogTitle: "Delete Configuration",
    dialogContent: "Are you sure want to delete current configuration?",
    SaveButton: "Save",
    DeleteButton: "Delete",
    DeleteDialogButton: "Delete LDAP",
    CancelButton: "Cancel",
    validationMessageRequired: "Required",
    validationMessageUrl: "URL is invalid",
    validationMessageLdapRole: "LDAP Role must be unique",
    validationMessageLdapRoleRequired: "LDAP Role is required",
    tableTitle: "Role Mapping",
    ldapRoleColumnTitle: "LDAP Role",
    postgresRoleColumnTitle: "Postgres Role",
    actionColumnTitle: "Actions",
    addTooltip: "Add New Row",
    deleteTooltip: "Remove Row",
    ldapTitle: "LDAP Configuration",
    deletedMessage: "LDAP Config deleted successfully",
    saveErrorMessage: "LDAP Config was not saved. Error:",
    saveSuccessMessage: "LDAP Config saved successfully",
  },
};

export const useCommonStaticPagesTranslation = createUseTranslation(
  commonStaticPagesTranslation,
);
