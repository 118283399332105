import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList } from "react-window";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";

import { TElementWithPosition } from "core/types/element";
import { useElementEditorContext } from "core/editor";
import { actions as editorActions } from "core/editor/reduxModule";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { TabsChildren, UntransformedTabsConfig } from "../../types";
import { TabConfig } from "./TabConfig";
import { TabRow } from "./TabRow";
import { useTabsContentContext } from "./TabsContext";

function reorderList<T>(list: T[], startIndex: number, endIndex: number) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const reorderElementList = (
  list: TElementWithPosition<any, any, any>[],
  startIndex: number,
  endIndex: number,
) => {
  const result = reorderList(list, startIndex, endIndex);

  return result.map((item, index) => ({
    ...item,
    position: {
      ...item.position,
      row: index + 1,
    },
  }));
};

export const TabsContent = memo(() => {
  const itemSize = 48;
  const {
    elementModel,
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<UntransformedTabsConfig>();
  const dispatch = useDispatch();
  const page = useSelector(routerSelectors.page);
  const { handleTabButtonClick } = useTabsContentContext();

  const {
    content: { elements: tabs },
  } = elementModel.children as TabsChildren;

  const updateChildren = useCallback(
    (...params: Parameters<typeof editorActions.updateElementChildren>) => {
      dispatch(editorActions.updateElementChildren(...params));
    },
    [dispatch],
  );

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || source.index === destination.index) {
      return;
    }

    changeConfigValue(
      "labels",
      reorderList(config.labels, source.index, destination.index),
    );

    updateChildren(
      elementModel,
      reorderElementList(tabs, source.index, destination.index),
      page!,
      "content",
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, snapshot, { source: { index } }) => {
          const name = config.labels[index];
          const defaultTab = index === config.default;
          const { config: tabConfig } = tabs[index];

          return (
            <TabConfig
              config={tabConfig}
              provided={provided}
              snapshot={snapshot}
              onButtonClick={handleTabButtonClick}
              index={index}
              name={name}
              isNew={false}
              defaultTab={defaultTab}
            />
          );
        }}
      >
        {(provided) => (
          <FixedSizeList
            height={itemSize * Math.min(tabs.length, 5)}
            itemCount={tabs.length}
            itemSize={itemSize}
            width="100%"
            outerRef={provided.innerRef}
            itemData={tabs as any}
          >
            {TabRow}
          </FixedSizeList>
        )}
      </Droppable>
    </DragDropContext>
  );
});
