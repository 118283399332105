import React, { MouseEvent, memo } from "react";
import { FormControl, FormLabel, Tooltip } from "@material-ui/core";
import { ToggleButtonGroup } from "@material-ui/lab";

import { PageSelector, useElementEditorContext } from "core/editor";
import { IPage } from "core/types";
import { ToggleButton } from "../../../common/ToggleButton";
import { Types, UntransformedFormConfig, legacy2LinkTo } from "../../types";
import { useEditorFormTranslation } from "../translation";
import useStyles from "../styles";

export const LinkTo = memo(() => {
  const { elementModel, changeConfigValue } = useElementEditorContext<
    UntransformedFormConfig
  >();
  const translation = useEditorFormTranslation();
  const classes = useStyles();

  const linkTo = legacy2LinkTo(
    elementModel.config.linkTo,
  ) as UntransformedFormConfig["linkTo"];

  const handlePageChange = (p: IPage, params: any) => {
    // TODO: allow changeConfigValue("linkTo.pageId")
    changeConfigValue("linkTo", {
      ...linkTo,
      pageId: p.id,
      params,
    });
  };

  const types = Object.entries(Types).map(([key, type]) => (
    <ToggleButton key={type} value={type}>
      <Tooltip title={translation[`${key}Tooltip`]}>
        <span>{translation[`${key}Label`]}</span>
      </Tooltip>
    </ToggleButton>
  ));

  const handleLinkToTypeChange = (
    _: MouseEvent<HTMLElement>,
    value: Types.stay | Types.navigateToPage | Types.goBack,
  ) => {
    if (value) {
      changeConfigValue("linkTo", {
        type: value,
      });
    }
  };

  const value = linkTo.type;

  return (
    <>
      <FormControl fullWidth={true}>
        <FormLabel component="p">{translation.linkToTitleLabel}</FormLabel>
        <ToggleButtonGroup
          className={classes.toggleButtons}
          size="small"
          value={value}
          exclusive={true}
          onChange={handleLinkToTypeChange}
        >
          {types}
        </ToggleButtonGroup>
      </FormControl>
      {linkTo.type === Types.navigateToPage && (
        <PageSelector
          pageId={linkTo.pageId}
          params={linkTo.params}
          config={elementModel.config}
          onChange={handlePageChange}
          label={translation.linkToLabel}
        />
      )}
    </>
  );
});
