import { createSelector } from "reselect";
import isEqual from "lodash/isEqual";

import { selectorScoper } from "core/utils/redux";
import { IState } from "./types";
import { Form } from "../types";

export function buildSelectors(path: string[], element: Form) {
  const scopeSelector = selectorScoper<IState>(path);
  const { identifierValue } = element.config.dataSource;
  const validation = element.config.validation;

  const loadState = (state: any) => scopeSelector(state).load;
  const saveState = (state: any) => scopeSelector(state).save;
  const data = (state: any) => scopeSelector(state).data;
  const errors = (state: any) => scopeSelector(state).errors;
  const touched = (state: any) => scopeSelector(state).touched;
  const originalData = (state: any) => scopeSelector(state).originalData;
  const failedData = (state: any) => scopeSelector(state).failedData;
  const allowedStateChanges = (state: any) =>
    scopeSelector(state).allowedStateChanges;
  const stateFieldValue = (state: any) => scopeSelector(state).stateFieldValue;
  const saveAttemptsSinceReset = (state: any) =>
    scopeSelector(state).saveAttemptsSinceReset;

  const validExpression = (state: any) => validation?.(state);

  const hasChanges = createSelector(
    [data, originalData],
    (d, o) => !d || !isEqual(d, o),
  );

  // validExpression must be true or not set
  // no errors or no errors on touched fields
  const isValid = createSelector(
    [errors, validExpression, touched, saveAttemptsSinceReset],
    (err, validExpr, touchedFields, saveAttempts) =>
      ((!Object.keys(err).some((field) => touchedFields[field]) &&
        saveAttempts === 0) ||
        !Object.keys(err).length) &&
      validExpr !== false,
  );

  const identifier = (state: any) =>
    identifierValue ? identifierValue(state) : undefined;

  const defaultData = (state: any) => {
    const configDefaultData = element.config.defaultData;
    if (!configDefaultData) {
      return null;
    }
    return Object.keys(configDefaultData).reduce((acc, k) => {
      const value = configDefaultData[k];
      return {
        ...acc,
        [k]: typeof value === "function" ? value(state) : value,
      };
    }, {});
  };

  return {
    loadState,
    saveState,
    data,
    errors,
    touched,
    originalData,
    defaultData,
    hasChanges,
    isValid,
    identifier,
    failedData,
    allowedStateChanges,
    stateFieldValue,
    saveAttemptsSinceReset,
  };
}
