import React, { memo, useMemo, useState } from "react";

import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";

import { MuiIcon } from "elementTypes/common/MuiIcon";
import Link from "elementTypes/common/Link";
import useStyles from "../../styles";

type MenuItem = {
  name: string;
  title: string;
  path: string;
  icon?: string;
  menu?: MenuItem[];
};

type Props = {
  menuOpen: boolean;
  menuItemSelected: string;
  nested?: boolean;
} & MenuItem;

export const itemTitle = (text: string, className?: string) => (
  <ListItemText primary={text} className={className} />
);

export const MenuItem = memo<Props>(
  ({ menu, title, nested, path, icon, menuOpen, menuItemSelected }) => {
    const { nested: nestedClass, menuIcon, itemTextClass } = useStyles();
    const [collapsed, toggleCollapse] = useState<boolean>(false);
    const hasMenu = !!menu?.length;
    const nestedMenu = useMemo(
      () =>
        menu?.map((item) => (
          <MenuItem
            key={item.name}
            {...item}
            title={item.title}
            nested={true}
            menuOpen={menuOpen}
            menuItemSelected={menuItemSelected}
          />
        )),
      [menu, menuOpen, menuItemSelected],
    );

    const handleListItemClick = () => {
      if (hasMenu) {
        toggleCollapse((prevCollapsed) => !prevCollapsed);
      }
    };

    const listIcon = useMemo(
      () =>
        icon ? (
          <ListItemIcon className={!menuOpen ? menuIcon : ""}>
            <MuiIcon icon={icon} fontSize="medium" />
          </ListItemIcon>
        ) : null,
      [menuOpen, menuIcon, icon],
    );
    const isSelected = menuItemSelected === path;
    const itemBtn = (
      <Link href={!hasMenu ? path : undefined} underline="none" color="inherit">
        <ListItem
          button={true}
          className={nested && menuOpen ? nestedClass : ""}
          onClick={handleListItemClick}
          selected={isSelected}
        >
          {listIcon}
          {menuOpen && itemTitle(title, itemTextClass)}
          {hasMenu && (
            <MuiIcon icon={collapsed ? "expand_less" : "expand_more"} />
          )}
        </ListItem>
      </Link>
    );

    return (
      <>
        {menuOpen ? (
          itemBtn
        ) : (
          <Tooltip title={title} placement="right">
            {itemBtn}
          </Tooltip>
        )}
        {hasMenu && (
          <Collapse in={collapsed} timeout="auto" unmountOnExit={true}>
            <List component="div" disablePadding={true}>
              {nestedMenu}
            </List>
          </Collapse>
        )}
      </>
    );
  },
);
