import React, { ChangeEvent, memo } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay,
  GridRowData,
} from "@material-ui/data-grid";

import QueryEditor from "./queryEditor";
import { useCustomQueryTranslation } from "./translation";
import { useHookFormError } from "utils/hooks/useHookFormError";
import {
  PermissionTable,
  handleNameValidate,
  handleTitleValidate,
  titleToName,
} from "../erd/queryBuilder";
import { ColumnsData, UICustomQueryForm } from "./types";
import Button from "elementTypes/common/Button";
import { useDataPreview } from "queries/admin";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { getApiError } from "queries/utils";
import { LoadingComponent } from "../../../../../layouts/common/Loading";
import { JsonView } from "elementTypes/common/JsonView";
import { GeneralType } from "../../../../../core/types/app";
import { ArrayField } from "../../../../../elementTypes/common/ArrayField";

type CustomQueryProps = {
  onSave: (data: UICustomQueryForm) => void;
  data?: UICustomQueryForm;
};

const jsonCell = (params: GridCellParams) => (
  <JsonView
    value={params.value as Record<string, unknown> | null}
    popup={true}
    embedded={true}
  />
);

export const textArrayCell = (params: GridCellParams) => (
  <ArrayField
    values={params.value as GeneralType["type"][]}
    variant="outlined"
  />
);

const boolCell = (params: GridCellParams) => (
  <Box display="flex" justifyContent="center" alignContent="center">
    <Checkbox checked={Boolean(params.value)} disabled />
  </Box>
);

const renderCellOverride = (generalType: GeneralType) => {
  if (generalType.isArray) {
    return generalType.type === "json" ? jsonCell : textArrayCell;
  } else {
    switch (generalType.type) {
      case "json":
        return jsonCell;
      case "boolean":
        return boolCell;
      default:
        return null;
    }
  }
};

export const CustomQueryForm = memo<CustomQueryProps>(({ data, onSave }) => {
  const translation = useCustomQueryTranslation();

  const {
    control,
    errors,
    watch,
    setValue,
    handleSubmit,
    clearError,
    getValues,
    setError,
  } = useForm<UICustomQueryForm>();

  const isEditMode = !!data;

  const getErrorMessage = useHookFormError();

  const showSnackbar = useSnackbar();

  const code = watch("code") ?? data?.code;

  const dataPreviewQuery = useDataPreview({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const tableData = dataPreviewQuery.data;

  const { isLoading } = dataPreviewQuery;

  const handleDataPreviewQuery = () => {
    const updated = {
      data: {
        // TODO remove this on the server side
        viewName: "fresh",
        code,
      },
    };
    dataPreviewQuery.mutate(updated);
  };

  // We have to type it like an array because of the useHookForm
  const handleTitleChange = ([ev]: ChangeEvent<HTMLInputElement>[]) => {
    if (!isEditMode) {
      const nextName = titleToName(ev.target.value);
      setValue("name", nextName);
    }

    return ev.target.value;
  };

  const handleStatementValidate = (value: string) => {
    return !!value.trim();
  };

  const columns: GridColDef[] =
    tableData?.columns?.map((column: ColumnsData) => ({
      field: column.name,
      headerName: column.name,
      type: column.type,
      minWidth: 180,
      flex: 1,
      renderCell: renderCellOverride(column.generalType),
    })) ?? [];

  const rows =
    (tableData?.data ?? []).map(
      (row: Record<string, unknown>, index: number) => ({
        id: index,
        ...row,
      }),
    ) ?? ([] as GridRowData[]);

  return (
    <Box
      width="100%"
      height="100%"
      bgcolor="background.paper"
      border="1px solid"
      borderColor="divider"
      borderRadius="borderRadius"
      p={1}
      mt={1}
    >
      <form
        style={{
          width: "100%",
          height: "100%",
        }}
        onSubmit={handleSubmit(onSave)}
      >
        <Box display="flex" gridGap={8} justifyContent="space-between">
          <FormControl fullWidth>
            <Controller
              as={
                <TextField
                  label={translation.queryTitleLabel}
                  helperText={getErrorMessage(errors.title)}
                  autoFocus
                />
              }
              error={Boolean(errors.title)}
              name="title"
              control={control}
              rules={{
                required: true,
                validate: handleTitleValidate,
              }}
              onChange={handleTitleChange}
              autoComplete="off"
              defaultValue={data?.title ?? ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              as={
                <TextField
                  label={translation.queryNameLabel}
                  helperText={getErrorMessage(errors.name)}
                />
              }
              error={Boolean(errors.name)}
              name="name"
              control={control}
              defaultValue={data?.name ?? ""}
              disabled
              rules={{
                required: true,
                validate: handleNameValidate,
              }}
            />
          </FormControl>
        </Box>
        <BoxDivider />
        <Box
          display="grid"
          gridGap={16}
          gridTemplateColumns="repeat(2, 1fr)"
          width="100%"
          height="100%"
        >
          <Controller
            as={<QueryEditor />}
            name="code"
            setError={setError}
            clearError={clearError}
            getValues={getValues}
            error={errors.code}
            control={control}
            handleDataPreview={handleDataPreviewQuery}
            defaultValue={data?.code ?? ""}
            rules={{
              required: true,
              validate: handleStatementValidate,
            }}
          />
          <Box>
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <PreviewTable rows={rows} columns={columns} />
            )}
          </Box>
        </Box>
        <BoxDivider />
        <Box
          mt={1}
          display="grid"
          gridGap={16}
          gridTemplateColumns="repeat(2, 1fr)"
        >
          <FormControl fullWidth>
            <Controller
              as={
                <TextField
                  label={translation.queryIdentifyingLabel}
                  helperText={getErrorMessage(errors.identifyingColumn)}
                />
              }
              error={Boolean(errors.identifyingColumn)}
              name="identifyingColumn"
              control={control}
              defaultValue={data?.identifyingColumn ?? ""}
              disabled={Boolean(data)}
            />
          </FormControl>
        </Box>
        <BoxDivider />
        <Box p={1}>
          <Typography variant="h4">
            {translation.permissionTableTitle}
          </Typography>
        </Box>
        <Box p={1}>
          <PermissionTable />
        </Box>
        <Box textAlign="right" p={1}>
          <Button
            type="submit"
            label={
              isEditMode ? translation.updateButton : translation.createButton
            }
            color="primary"
            size="large"
            disabled={!code ? true : Boolean(errors.code)}
          />
        </Box>
      </form>
    </Box>
  );
});

const BoxDivider = () => (
  <Box py={2}>
    <Divider />
  </Box>
);

const PreviewTable = memo<{ rows: GridRowData[]; columns: GridColDef[] }>(
  ({ rows, columns }) => {
    const translation = useCustomQueryTranslation();

    const components = {
      NoRowsOverlay: columns.length
        ? undefined
        : () => <GridOverlay>{translation.dataGridContent}</GridOverlay>,
    };
    return (
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={components}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
        />
      </div>
    );
  },
);
