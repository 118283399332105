import React, { memo } from "react";

import Box from "@material-ui/core/Box";
import { createUseTranslation } from "core/session/translation";
import { StyledTypography } from "../../../../../elementTypes/common/StyledTypography";

export const StyledSchemaTableName = memo<{
  schemaName: string;
  tableName: string;
}>(({ schemaName, tableName }) => {
  const translation = useTranslation();
  return (
    <Box display="inline-flex">
      <StyledTypography
        fitContent={true}
        typographyProps={{ variant: "h6" }}
        text={schemaName}
        tooltip={translation.schemeTooltip}
        color="primary"
      />
      <StyledTypography
        fitContent={true}
        typographyProps={{ variant: "h6" }}
        text={`.${tableName}`}
        tooltip={translation.tableTooltip}
        color="primary"
      />
    </Box>
  );
});

const translations = {
  en: {
    schemeTooltip: "Schema Name",
    tableTooltip: "Table Name",
  },
};

export const useTranslation = createUseTranslation(translations);
