export const menu = [
  {
    name: "home",
    title: "Home",
    path: "/admin",
    icon: "dashboard",
  },
  {
    name: "roles",
    title: "Roles",
    path: "/admin/roles",
    icon: "people_outline",
  },
  {
    name: "database",
    title: "Database",
    path: "/admin/database",
    icon: "storage",
  },
  {
    name: "applications",
    title: "Applications",
    path: "/admin/apps",
    icon: "apps",
  },
  {
    name: "authentication",
    title: "Authentication",
    path: "",
    icon: "lock_open",
    menu: [
      {
        name: "users",
        title: "Users",
        path: "/admin/users",
        icon: "people",
      },
      {
        name: "settings",
        title: "Login Settings",
        path: "/admin/settings",
        icon: "settings",
      },
      {
        name: "ldap-configuration",
        title: "LDAP Configuration",
        path: "/admin/ldap",
        icon: "lock_open",
      },
    ],
  },
  {
    name: "audit",
    title: "Audit",
    path: "",
    icon: "security",
    menu: [
      {
        name: "audit-tables",
        title: "Tables",
        path: "/admin/audits/tables",
        icon: "table_chart",
      },
      {
        name: "audit-users",
        title: "Users",
        path: "/admin/audits/users",
        icon: "people",
      },
    ],
  },
  {
    name: "fileManagement",
    title: "File Management",
    path: "/admin/files",
    icon: "folder",
  },
];
