import React, { memo, useMemo, useState } from "react";

import { Box, Card, Divider, Typography } from "@material-ui/core";
import { Elements, ReactFlowProvider } from "react-flow-renderer";
import { Helmet } from "react-helmet";
import { useQueryClient } from "react-query";

import { DataModel } from "./erd/types";
import { Entities, QueryObject } from "./components/types";
import { EntitiesPanel } from "./components/EntitiesPanel";
import { ErdChart } from "./erd";
import { ToggleLayoutPanel } from "./components/ToggleLayoutPanel";
import {
  modelToReactFlowElements,
  tablesToEntitiesBySchema,
} from "./erd/utils";
import { PanelSkeleton } from "./components/PanelSkeleton";
import { QueriesPanel } from "./components/QueriesPanel";
import { useDataBaseTranslation } from "./translation";
import { QueryKeys, useModel, useQueries } from "../../../../queries/admin";
import { useSessionContext } from "../../../../core/session";
import { getTranslatedText } from "../../../../core/utils/element";
import { Language, Translation } from "../../../../core";
import { ToggleButton } from "elementTypes/common/ToggleButton";
import { MuiIcon } from "elementTypes/common/MuiIcon";

export enum LayoutOptions {
  horizontal = "LR",
  vertical = "TB",
}

const getTitle = (i18n: Translation<"title">, language: Language) =>
  getTranslatedText(language, i18n, "title") ?? "";

export const DatabasePageComponent = memo(() => {
  const [direction, setDirection] = useState<LayoutOptions>(
    LayoutOptions.horizontal,
  );
  const queryClient = useQueryClient();
  const { language } = useSessionContext();
  const queries = useQueries();
  const model = useModel();
  const queriesList = useMemo(() => (queries.data ?? []) as QueryObject[], [
    queries.data,
  ]);

  const sortedlist = useMemo(
    () =>
      queriesList.sort((a, b) =>
        getTitle(a.i18n, language)?.localeCompare(getTitle(b.i18n, language)),
      ),
    [queriesList, language],
  );

  const dataModel = useMemo(
    () =>
      (model.data ?? {
        tables: [],
        constraints: [],
      }) as DataModel,
    [model.data],
  );
  const translation = useDataBaseTranslation();

  const [elements, tablesList] =
    useMemo(
      () =>
        dataModel && [
          modelToReactFlowElements(dataModel) as Elements,
          tablesToEntitiesBySchema(dataModel.tables) as Entities,
        ],
      [dataModel],
    ) ?? [];

  const handleLayoutChange = (_event: any, nextValue: LayoutOptions) => {
    if (nextValue) {
      setDirection(nextValue);
    }
  };

  const handleModelRefresh = () => {
    queryClient.invalidateQueries(QueryKeys.fetchModel);
  };

  return (
    <>
      <Helmet>
        <title>{translation.databasePageTitle}</title>
      </Helmet>
      <ReactFlowProvider>
        <Box
          width="100%"
          minHeight="100%"
          height="100%"
          display="grid"
          gridGap={8}
          gridTemplateColumns="3fr 1fr"
          gridTemplateRows="min-content 1fr"
          p={0.5}
        >
          <Box gridColumn="1 / span 2">
            <Card variant="outlined">
              <Box display="flex" alignItems="center" p={1}>
                <Typography variant="h5">
                  {translation.databaseOverviewTitle}
                </Typography>
                <Box
                  flex="1"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gridGap={8}
                >
                  <ToggleLayoutPanel
                    onChange={handleLayoutChange}
                    value={direction}
                  />
                  <Divider orientation="vertical" flexItem={true} />
                  <ToggleButton
                    tooltip={translation.refreshModelTooltip}
                    size="small"
                    onChange={handleModelRefresh}
                    value="refresh"
                  >
                    <MuiIcon icon="Autorenew" fontSize="large" />
                  </ToggleButton>
                </Box>
              </Box>
            </Card>
          </Box>
          <Box gridColumn="1 / 1">
            <ErdChart directionValue={direction} sourceContent={elements} />
          </Box>
          <Box
            gridColumn="2 / 2"
            gridRow="2"
            display="flex"
            flexDirection="column"
            gridGap={8}
            overflow="auto"
          >
            {model.isLoading ? (
              <PanelSkeleton />
            ) : (
              <EntitiesPanel entities={tablesList} />
            )}
            {queries.isLoading ? (
              <PanelSkeleton />
            ) : (
              <QueriesPanel queries={sortedlist} />
            )}
          </Box>
        </Box>
      </ReactFlowProvider>
    </>
  );
});
