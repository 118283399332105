import React, { memo, useState } from "react";

import { Section, useElementEditorContext } from "core/editor";
import { useSessionContext } from "core/session";
import { Language } from "core/types";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { MarkdownFieldConfig } from "../types";
import { AdvancedMode } from "./components";
import { useStyles } from "./style";
import { Alignment, LanguageEditor } from "../../common/LanguageEditor";
import { FormControlLabel, Switch } from "@material-ui/core";

export const MarkdownFieldEditor = memo(() => {
  const {
    elementModel,
    changeConfigValue,
    changeTranslation,
  } = useElementEditorContext<MarkdownFieldConfig>();

  const classes = useStyles();

  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);

  const handleChange = (newValue: string) =>
    changeConfigValue("text", newValue);

  const changeLanguage = (l: Language) => setLang(l);

  const setLangTranslation = (key: any) => (value: any) => {
    const newVal = { ...elementModel.i18n, [lang.code]: { [key]: value } };
    changeTranslation(newVal);
  };

  const onAlignmentChange = (alignment: Alignment) =>
    changeConfigValue("align", alignment);

  const translation = elementModel.i18n[lang.code] as { text?: string };

  const htmlAllowed = elementModel.config.htmlAllowed;

  const toggleHtmlAllowed = () =>
    changeConfigValue("htmlAllowed", !htmlAllowed);

  return (
    <Section
      title="Text"
      classes={{ content: classes.textContent }}
      wrapped={true}
      cardActions={
        <LanguageSwitch
          language={lang}
          changeLanguage={changeLanguage}
          colorVariant="dark"
          fullWidth={true}
        />
      }
    >
      <LanguageEditor
        value={translation?.text ?? ""}
        onChange={setLangTranslation("text")}
        onAlignmentChange={onAlignmentChange}
      />
      <AdvancedMode
        value={elementModel.config.text as any}
        config={elementModel.config}
        onChange={handleChange}
      />
      <FormControlLabel
        control={
          <Switch checked={!!htmlAllowed} onChange={toggleHtmlAllowed} />
        }
        label={"HTML Allowed"}
      />
    </Section>
  );
});
