import { UseMutationOptions } from "react-query";
import { IAppMetadata, IObjectView, IUi } from "core";
import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "queries/utils";

import AdminService from "services/admin";
import { loadAppMetadata, loadUIList, loadViewList } from "services/api";
import { UICreateAPIData } from "staticPages/admin/pages/apps/pages/create/types";
import { IUIEditForm } from "staticPages/admin/pages/apps/pages/edit/types";
import { IUIGenerateForm } from "staticPages/admin/pages/apps/pages/generate/types";
import { QueryKeys } from ".";

const services = AdminService.getInstance();

export const fetchUIList: Query<IUi[]> = {
  queryKey: QueryKeys.fetchUIList,
  queryFn: loadUIList,
};

export const fetchObjectViews: Query<IObjectView[], { role: string }> = {
  queryKey: QueryKeys.fetchObjectViews,
  queryFn: loadViewList,
};

export const useDeleteApp = (
  options: UseMutationOptions<unknown, unknown, { uiName: string }>,
) => useAuthenticatedMutation(services.deleteUI, options);

export const useExportApp = (
  options: UseMutationOptions<IAppMetadata, unknown, { uiName: string }>,
) => useAuthenticatedMutation(loadAppMetadata, options);

export const useCreateApp = (
  options: UseMutationOptions<unknown, unknown, UICreateAPIData>,
) => useAuthenticatedMutation(services.createUi, options);

export const useGenerateApp = (
  options: UseMutationOptions<unknown, unknown, IUIGenerateForm>,
) => useAuthenticatedMutation(services.generateUi, options);

export const useObjectView = (role: string) =>
  useAuthenticatedQuery(fetchObjectViews, { role });

export const useEditApp = (
  options: UseMutationOptions<
    { name: string; editData: IUIEditForm },
    unknown,
    { name: string; editData: IUIEditForm }
  >,
) => useAuthenticatedMutation(services.editUi, options);

export const useUIList = () => useAuthenticatedQuery(fetchUIList);
