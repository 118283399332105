import * as t from "io-ts";
import { IElement, UntransformedConfig, customExpression } from "core/types";

export const DateTimeFieldConfig = t.intersection([
  t.type({
    value: customExpression(t.union([t.string, t.null])),
  }),
  t.partial({
    isRelative: t.boolean,
    showDatePart: t.boolean,
    showTimePart: t.boolean,
    timezone: customExpression(t.union([t.number, t.null])),
    formatString: customExpression(t.string),
  }),
]);

export type DateTimeFieldConfig = t.TypeOf<typeof DateTimeFieldConfig>;

export type UntransformedDateTimeFieldConfig = UntransformedConfig<
  DateTimeFieldConfig
>;

export type DateTimeField = IElement<DateTimeFieldConfig>;
