import React, { memo } from "react";

import Typography from "@material-ui/core/Typography";

import { AlertBox } from "../../../../../elementTypes/common/AlertBox";

export const AuditTableSetup = memo(() => {
  return (
    <>
      <AlertBox
        message={
          <>
            <Typography>
              Auditing automatically keeps track of all data modifications
              happening on the table.
            </Typography>
            <Typography>
              This includes the operations{" "}
              <Typography
                style={{ fontWeight: 700, fontFamily: "monospace" }}
                component="span"
              >
                INSERT
              </Typography>
              {", "}
              <Typography
                style={{ fontWeight: 700, fontFamily: "monospace" }}
                component="span"
              >
                UPDATE
              </Typography>
              {", "}
              <Typography
                style={{ fontWeight: 700, fontFamily: "monospace" }}
                component="span"
              >
                DELETE
              </Typography>
              {" and "}
              <Typography
                style={{ fontWeight: 700, fontFamily: "monospace" }}
                component="span"
              >
                TRUNCATE
              </Typography>
              .
            </Typography>
            <Typography>
              Historical data can be viewed in the Audit section. It is saved at{" "}
              <Typography
                style={{ fontWeight: 500, fontFamily: "monospace" }}
                component="span"
              >
                cypex.t_history
              </Typography>
              .
            </Typography>
            <Typography>
              <Typography style={{ fontWeight: 700 }} component="span">
                Note:
              </Typography>{" "}
              Enabling auditing can impact performance.
            </Typography>
          </>
        }
        color="info"
        boxProps={{ mt: 2 }}
      />
    </>
  );
});
