import React, { ChangeEvent, memo, useCallback, useMemo } from "react";
import TextField from "@material-ui/core/TextField";

import { Section, useElementEditorContext } from "core/editor";
import { useFileInputEditorTranslation } from "../translation";
import { FileInputConfig } from "../../types";
import { useStyles } from "../styles";
import {
  useFileGroupOptions,
  useFileTypeOptions,
} from "queries/admin/fileData";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { SelectOption } from "elementTypes/default_autocomplete_input/types";

enum Fields {
  accessGroupName = "accessGroupName",
  typeGroupName = "typeGroupName",
  accept = "accept",
}

const getSelectedOption = (data: SelectOption[], value: string) =>
  data?.find((o: SelectOption) => o.value === value) ?? null;

export const AdvancedComponent = memo(() => {
  const {
    elementModel: { config },
    changeConfigValue,
  } = useElementEditorContext<FileInputConfig>();
  const translation = useFileInputEditorTranslation();
  const classes = useStyles();

  const fileOptions = useFileTypeOptions();

  const selectedType = useMemo(
    () => getSelectedOption(fileOptions.data ?? [], config.typeGroupName),
    [config.typeGroupName, fileOptions.data],
  );

  const groupOptions = useFileGroupOptions();

  const selectedGroup = useMemo(
    () => getSelectedOption(groupOptions.data ?? [], config.accessGroupName),
    [config.accessGroupName, groupOptions.data],
  );

  const handleChange = useCallback(
    (prop, newValue) => changeConfigValue(prop, newValue),
    [changeConfigValue],
  );

  const handleInputAcceptChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => handleChange(Fields.accept, value);

  const handleAutocompleteChange = (inputName: Fields) => (
    value: IAutocompleteValue,
  ) => handleChange(inputName, value);

  return (
    <Section title={translation.advancedTitle} wrapped={true}>
      <BaseAutocomplete
        options={groupOptions.data ?? []}
        onChange={handleAutocompleteChange(Fields.accessGroupName)}
        valueObject={selectedGroup}
        className={classes.topMargin}
        name={Fields.accessGroupName}
        label={translation[`${Fields.accessGroupName}InputLabel`]}
        isLoading={groupOptions.isLoading}
      />
      <BaseAutocomplete
        options={fileOptions.data ?? []}
        onChange={handleAutocompleteChange(Fields.typeGroupName)}
        valueObject={selectedType}
        className={classes.topMargin}
        name={Fields.typeGroupName}
        label={translation[`${Fields.typeGroupName}InputLabel`]}
        isLoading={fileOptions.isLoading}
      />
      <TextField
        value={config[Fields.accept] ?? ""}
        name={Fields.accept}
        label={translation[`${Fields.accept}InputLabel`]}
        fullWidth={true}
        onChange={handleInputAcceptChange}
      />
    </Section>
  );
});
