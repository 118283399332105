import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import GitInfo from "react-git-info/macro";

import { format } from "date-fns";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import useStyles from "./styles";
import IconButton from "elementTypes/common/IconButton";
import Typography from "@material-ui/core/Typography";
import { Dialog, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import { useTranslation } from "core/session";

export const HelpButton = memo((props) => {
  const classes = useStyles(props);

  const applicationInformation = useSelector(sessionSelectors.ui);
  const releaseInformation = useSelector(sessionSelectors.appMetadata);
  const gitInfo = GitInfo();
  const gitInfoDate = format(new Date(gitInfo.commit.date), "yyyy-MM-dd");

  const [openDialog, setOpenDialog] = useState(false);

  const handleMenu = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  const label = useTranslation(applicationInformation?.i18n).label;

  if (!applicationInformation) {
    return null;
  }
  if (!releaseInformation) {
    return null;
  }

  const releaseDate = format(
    new Date(releaseInformation.release.createdAt),
    "yyyy-MM-dd hh:mm",
  );

  let cypexVersion = null;

  if (gitInfo.tags.length) {
    cypexVersion = (
      <>
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          Version
        </Typography>
        <Typography variant="subtitle1">{gitInfo.tags[0]}</Typography>
      </>
    );
  }

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleMenu}
        icon="help_outline"
        aria-label="about cypex and current version"
        aria-controls="appBar"
        aria-haspopup="true"
        fontSize="large"
        data-testid="about-open-button"
      />
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="About"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="about-dialog"
          disableTypography
          className={classes.dialogTitle}
        >
          <Typography variant="h4" style={{ fontWeight: 450 }}>
            About
          </Typography>
          <IconButton
            color={"inherit"}
            onClick={handleClose}
            icon={"close"}
            aria-label="close dialog"
            aria-controls="appBar"
            aria-haspopup="true"
            fontSize="large"
            data-testid="close-dialog"
            style={{ margin: "10px" }}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogRoot} dividers>
          <div className={classes.appInfoContainer}>
            <div className={classes.appInfo}>
              <div>
                <Typography
                  variant="h5"
                  color={"inherit"}
                  data-testid="dialog-application-name"
                >
                  {label}
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="left"
                  color={"inherit"}
                  className={classes.descTypography}
                >
                  {applicationInformation.description}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="subtitle1"
                  align="right"
                  style={{ color: "grey" }}
                >
                  {releaseInformation.release.name}
                </Typography>
                <Typography
                  variant="h6"
                  color={"inherit"}
                  style={{ fontWeight: 450 }}
                >
                  {releaseDate}
                </Typography>
              </div>
            </div>
            <Typography variant="h6" align="left" color={"inherit"}>
              Changelog
            </Typography>
            <Typography variant="subtitle1" align="left" color={"inherit"}>
              {releaseInformation.release.description}
            </Typography>
          </div>
          <Divider variant="fullWidth" />
          <div className={classes.cypexContainer}>
            <Typography variant="h5" align="left" color={"inherit"}>
              CYPEX
            </Typography>
            <div className={classes.cypexInfo}>
              {cypexVersion}

              <Typography variant="h6" style={{ fontWeight: 600 }}>
                Commit
              </Typography>
              <Typography variant="subtitle1">
                {gitInfo.commit.shortHash}
              </Typography>

              <Typography variant="h6" style={{ fontWeight: 600 }}>
                Date
              </Typography>
              <Typography variant="subtitle1">{gitInfoDate}</Typography>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});
