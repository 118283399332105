import React, { MouseEvent, memo, useState } from "react";
import { Box, FormLabel } from "@material-ui/core";
import { Control, Controller, FieldValues } from "react-hook-form";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { ToggleButton } from "../../../../../../../elementTypes/common/ToggleButton";
import { UICreateStateForm } from "../StateForm/types";
import PermissionFields from "./PermissionFields";
import { useStateTransitionTranslations } from "./translation";
import { UICreateStateTransitionForm } from "./types";

enum RadioPermissions {
  everybody = "everybody",
  selected = "selected",
}

interface PermissionProps {
  node?: UICreateStateForm | UICreateStateTransitionForm;
  control: Control<FieldValues>;
}

export const PermissionComponent = memo<PermissionProps>(
  ({ node, control }) => {
    const translation = useStateTransitionTranslations();
    const defaultValue = (node?.acl ?? []).length
      ? RadioPermissions.selected
      : RadioPermissions.everybody;
    const [permissionType, setPermissionType] = useState<RadioPermissions>(
      defaultValue,
    );

    const handleChange = (_: MouseEvent<HTMLElement>, value: string | null) =>
      value && setPermissionType(value as RadioPermissions);

    return (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <FormLabel component="p">{translation.formLabelContent}</FormLabel>
          <ToggleButtonGroup
            value={permissionType}
            exclusive
            onChange={handleChange}
            size="small"
          >
            <ToggleButton
              tooltip={translation.everybodyTooltip}
              value={RadioPermissions.everybody}
            >
              {translation.everybodyToggleTitle}
            </ToggleButton>
            <ToggleButton
              tooltip={translation.selectedTooltip}
              value={RadioPermissions.selected}
            >
              {translation.selectedToggleTitle}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Controller
          as={(inputProps: any) => (
            <PermissionFields
              disable={permissionType === RadioPermissions.everybody}
              {...inputProps}
              boxProps={{ mt: 1 }}
            />
          )}
          control={control}
          name="acl"
          defaultValue={node?.acl ?? []}
        />
      </>
    );
  },
);
