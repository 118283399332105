import React, { memo } from "react";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import CustomExpressionEditor from "core/editor/common/CustomExpressionEditor/component";
import { NonExpressionEditorProps } from "core/editor/common/CustomExpressionEditor/types";
import { useElementEditorContext } from "core/editor/EditorLayout/components/ElementEditorContext";
import { Section } from "core/editor/common/Section";
import { UntransformedDateTimeInputConfig } from "../../types";
import { useDateTimeInputEditorTranslation } from "../translation";
import { getDateValue } from "utils/date";
import { buildCustomExpressionValue } from "core";

export const ConfigComponent = memo(() => {
  const {
    elementModel: {
      config,
      config: { maxDate, minDate },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedDateTimeInputConfig>();
  const translation = useDateTimeInputEditorTranslation();

  const customSelectDate = (dateConfig: "minDate" | "maxDate") => ({
    value: dateValue,
    onChange: onConfigChange,
  }: NonExpressionEditorProps) => {
    const handleChange = (date: MaterialUiPickersDate | null) =>
      onConfigChange(date ? date.toISOString() : "");

    return (
      <DatePicker
        name={dateConfig}
        value={getDateValue(dateValue)}
        onChange={handleChange}
        fullWidth={true}
        variant="dialog"
        clearable
      />
    );
  };

  const handleChange = (dateConfig: "minDate" | "maxDate") => (
    value: string,
  ) => {
    changeConfigValue(dateConfig, value);
  };

  const handleChangeTimezone = (value: string) => {
    changeConfigValue("timezone", value);
  };

  return (
    <Section title={translation.configTitle} wrapped={true}>
      <CustomExpressionEditor
        label={translation.minDateLabel}
        value={minDate ?? ""}
        config={config}
        onChange={handleChange("minDate")}
        nonExpressionEditor={customSelectDate("minDate")}
      />
      <CustomExpressionEditor
        label={translation.maxDateLabel}
        value={maxDate ?? ""}
        config={config}
        onChange={handleChange("maxDate")}
        nonExpressionEditor={customSelectDate("maxDate")}
      />
      <CustomExpressionEditor
        label={"Timezone"}
        value={config.timezone ?? buildCustomExpressionValue("null")}
        config={config}
        onChange={handleChangeTimezone}
      />
    </Section>
  );
});
