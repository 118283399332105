import {
  FieldPath,
  FormDataSourceImplementation,
} from "../../../../elementInterfaces";
import { GeoJSONInput } from "../../types";

export function buildSelectors(
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: GeoJSONInput,
) {
  const value = dataSource.createFieldValueSelector(fieldPath, {
    defaultValue: null,
  });

  const errors = dataSource.createFieldErrorSelector(fieldPath);

  const touched = dataSource.createFieldTouchedSelector(fieldPath);

  const disabled = () =>
    dataSource.isReadOnly || Boolean(element.config.disabled);

  const required = () =>
    dataSource.createFieldRequiredSelector(fieldPath) ??
    element.config.nullable === false;

  return {
    value,
    errors,
    touched,
    disabled,
    required,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
