import React, { memo } from "react";
import { Edge, FlowElement } from "react-flow-renderer";

import {
  CreateState,
  CreateStateTransition,
  EditState,
  EditStateTransition,
} from ".";
import { useSelectedState } from "../utils";

import { WorkflowLandingPage } from "./WorkflowLandingPage";

type LandingPageProps = {
  list: FlowElement<any>[];
};

export const RightPanel = memo<LandingPageProps>(({ list }) => {
  const { selectedElement } = useSelectedState();

  const defaultComponent = <WorkflowLandingPage list={list} />;

  switch (selectedElement?.type) {
    case "state":
      return selectedElement?.id ? <EditState /> : <CreateState />;
    case "transition":
      return selectedElement?.id ? (
        <EditStateTransition />
      ) : (selectedElement as Edge).source !== "end" ? (
        <CreateStateTransition />
      ) : (
        defaultComponent
      );
    default:
      return defaultComponent;
  }
});
