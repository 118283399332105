import { UseMutationOptions, UseQueryOptions } from "react-query";

import AdminService from "services/admin";
import { LDAPData } from "../../staticPages/admin/pages/ldap/types";
import {
  Query,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from "../utils";
import { QueryKeys } from "./queryKeys";

const services = AdminService.getInstance();

export const fetchLDAPConfig: Query<LDAPData> = {
  queryKey: QueryKeys.fetchLDAPConfig,
  queryFn: services.getLDAPConfig,
};

export const useLDAP = (options: UseQueryOptions<LDAPData, Error>) =>
  useAuthenticatedQuery(fetchLDAPConfig, undefined, options);
export const useSaveLDAP = (
  options?: UseMutationOptions<LDAPData, unknown, LDAPData>,
) => useAuthenticatedMutation(services.saveLDAPConfig, options);
export const useDeleteLDAP = (
  options?: UseMutationOptions<unknown, unknown, unknown>,
) => useAuthenticatedMutation(services.deleteLDAPConfig, options);
