import React, {
  MouseEvent,
  Reducer,
  memo,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from "react";

import { Menu, MenuItem } from "@material-ui/core";
import { stringify } from "querystring";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";

import { actions as routerActions } from "core/router/reduxModule";
import { AuditTableSetup } from "./auditTableSetup";
import { createTypeSafeContext } from "utils/createTypeSafeContext";
import { DEFAULT_LANGUAGE_CODE, Translation } from "../../../../../core";
import { DialogWrapperProps } from "../../../../../elementTypes/helpers/HOC/DialogWrapper/component";
import { getApiError } from "queries/utils";
import { KeyLookup } from "./keyLookup";
import { NodeData, WorkflowSetupAPIData } from "./types";
import { PermissionProvider, Permissions } from "../permissionComponent";
import { QueryBuilder } from "./queryBuilder";
import {
  QueryKeys,
  useColumnAliasQuery,
  useCreateSimpleQuery,
  useHistoryTrackingSwitch,
  useWorkflowSetup,
} from "../../../../../queries/admin";
import { StyledSchemaTableName } from "../components/styledSchemaTableName";
import { useErdTranslation } from "./translation";
import { useSnackbar } from "../../../../../utils/hooks/useSnackbar";
import { useStyles } from "./styles";
import DialogWrapper from "../../../../../elementTypes/helpers/HOC/DialogWrapper";
import WorkFlowSetup from "./workflowSetup";

enum DialogType {
  workflow = "workflow",
  generateQuery = "generateQuery",
  defineLookups = "defineLookups",
  auditTable = "auditTable",
}

enum actions {
  OPEN_MENU = "OPEN_MENU",
  OPEN_DIALOG = "OPEN_DIALOG",
  SET_INITIAL_STATE = "SET_INITIAL_STATE",
}

interface NodeMenuContext {
  onMenuOpen: (nodeData: NodeData) => (event: MouseEvent<HTMLElement>) => void;
}
const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  NodeMenuContext
>();

export const useTableNodeMenuContext = useTypeSafeContext;
export const MenuProvider = Provider;
export const MenuConsumer = Consumer;

type NodeMenuState = {
  nodeData: NodeData | null;
  anchorEl: HTMLElement | null;
  open: DialogType | null;
};

type NodeMenuDispatch = { type: actions; payload?: Partial<NodeMenuState> };

const initialState: NodeMenuState = {
  anchorEl: null,
  open: null,
  nodeData: null,
};

export const TableNodeMenuProvider = memo(({ children }) => {
  const [permissions, setPermissions] = useState<Permissions>({});
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const translation = useErdTranslation();
  const [{ nodeData, open, anchorEl }, dispatchAction] = useReducer<
    Reducer<NodeMenuState, NodeMenuDispatch>
  >(reducer, initialState);
  const showSnackbar = useSnackbar();
  const classes = useStyles();

  const createQuery = useCreateSimpleQuery({
    onSuccess: () => {
      showSnackbar(translation.createQuerySuccessMsg, "success");
      queryClient.invalidateQueries(QueryKeys.fetchQueries);
      handleClose();
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const { mutate } = useWorkflowSetup({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: () => {
      handleOpenWorkflow();
    },
  });

  const { mutate: switchHistoryTracking } = useHistoryTrackingSwitch({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: (_, variables) => {
      showSnackbar(
        variables.active
          ? translation.auditingEnabled
          : translation.auditingDisabled,
        "success",
      );
      queryClient.invalidateQueries(QueryKeys.fetchModel);
      handleClose();
    },
  });

  const columnAliasQuery = useColumnAliasQuery({
    onSuccess: () => {
      showSnackbar(translation.setLookupColumn, "success");
      queryClient.invalidateQueries(QueryKeys.fetchModel);
      handleClose();
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const handleOpenMenu = useCallback(
    (newNodeData: NodeData) => (event: MouseEvent<HTMLElement>) => {
      dispatchAction({
        type: actions.OPEN_MENU,
        payload: {
          anchorEl: event.currentTarget,
          nodeData: newNodeData,
        },
      });
    },
    [],
  );

  const handleClose = useCallback(() => {
    setPermissions({});
    dispatchAction({
      type: actions.SET_INITIAL_STATE,
    });
  }, []);

  const handleOpenDialog = useCallback(
    (type: DialogType) => () => {
      dispatchAction({
        type: actions.OPEN_DIALOG,
        payload: {
          open: type,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeData, dispatch],
  );

  const handleGenerateQuery = (dataWithWrongType: Record<string, unknown>) => {
    // TODO: we have to replace the wrong typing. How? Maybe with generics
    // Same thing in QueriesPanel.tsx
    const data = dataWithWrongType as {
      name: string;
      title: string;
    };
    if (!nodeData) {
      return;
    }
    const title = data.title.trim();

    const updated = {
      schema: nodeData.schemaName,
      table: nodeData.tableName,
      data: {
        viewName: data.name,
        permissions: Object.entries(permissions).map(
          ([grantee, privileges]) => ({
            grantee,
            privileges,
          }),
        ),
        i18n: {
          [DEFAULT_LANGUAGE_CODE]: {
            title,
          },
        } as Translation<"title">,
      },
    };

    createQuery.mutate(updated);
  };

  const handleOpenWorkflow = () => {
    if (!nodeData) {
      return;
    }
    dispatch(
      routerActions.push(
        `/admin/database/workflow?${stringify({
          schema: nodeData.schemaName,
          table: nodeData.tableName,
        })}`,
      ),
    );
    handleClose();
  };
  const handleColumnAliasQuery = (
    dataWithWrongType: Record<string, unknown>,
  ) => {
    const data = dataWithWrongType as { defaultLookup: string };

    if (!nodeData) {
      return;
    }

    const updated = {
      schema: nodeData.schemaName,
      table: nodeData.tableName,
      column: data.defaultLookup,
    };

    columnAliasQuery.mutate(updated);
  };

  const handleSwitchHistoryTracking = () => {
    if (!nodeData) {
      return;
    }
    const updated = {
      schema: nodeData.schemaName,
      table: nodeData.tableName,
      active: !nodeData.historyTrackerConfigured,
    };
    switchHistoryTracking(updated);
  };

  const workFlowConfigured = useMemo(
    () => nodeData && nodeData?.columns.find((e) => e.workflowConfigured),
    [nodeData],
  );

  const onWorkflowSubmit = (data: WorkflowSetupAPIData) => {
    if (!nodeData) {
      return;
    }
    // gkobluk: remove this once translation turns optional:
    const payload = {
      stateColumn: data.stateColumn,
      transitionToAll: data.transitionToAll,
      i18n: { en: { shortDescription: "fresh" } },
    } as WorkflowSetupAPIData;

    mutate({
      schema: nodeData.schemaName,
      table: nodeData.tableName,
      workflowSetupAPIData: payload,
    });
  };

  const dialogsProps = {
    [DialogType.workflow]: {
      title: translation.workflowDialogTitle,
      handleSubmit: onWorkflowSubmit,
      isForm: true,
      maxWidth: "sm",
    },
    [DialogType.generateQuery]: {
      title: translation.generateQueryTitle,
      contentText: nodeData ? (
        <StyledSchemaTableName
          schemaName={nodeData.schemaName}
          tableName={nodeData.tableName}
        />
      ) : null,
      isForm: true,
      handleSubmit: handleGenerateQuery,
    },
    [DialogType.defineLookups]: {
      title: `${translation.defineKeyLookupsTitle} ${nodeData?.schemaName}.${nodeData?.tableName}`,
      isForm: true,
      handleSubmit: handleColumnAliasQuery,
    },
    [DialogType.auditTable]: {
      actionsClass: classes.auditTableActions,
      title: translation.auditTableTitle,
      isForm: true,
      submitTitle: nodeData?.historyTrackerConfigured
        ? translation.auditTableDisable
        : translation.auditTableEnable,
      submitButtonProps: nodeData?.historyTrackerConfigured
        ? { className: classes.errorButton }
        : {},
      handleSubmit: handleSwitchHistoryTracking,
    },
  };

  const dialogWrapperProps = {
    open: Boolean(open),
    keepMounted: false,
    cancelTitle: translation.cancelTitle,
    submitTitle: translation.submitTitle,
    fullWidth: true,
    maxWidth: "md",
    handleClose,
    ...(open && { ...dialogsProps[open] }),
  } as DialogWrapperProps;

  const providerValue = useMemo(
    () => ({
      onMenuOpen: handleOpenMenu,
    }),
    [handleOpenMenu],
  );

  const content = {
    [DialogType.workflow]: <WorkFlowSetup nodeData={nodeData!} />,
    [DialogType.generateQuery]: <QueryBuilder nodeData={nodeData!} />,
    [DialogType.defineLookups]: <KeyLookup nodeData={nodeData!} />,
    [DialogType.auditTable]: <AuditTableSetup />,
  };

  return (
    <>
      <PermissionProvider value={{ permissions, setPermissions }}>
        <MenuProvider value={providerValue}>
          {children}
          <Menu
            id="table-node-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenDialog(DialogType.generateQuery)}>
              {translation.menuGenerateQuery}
            </MenuItem>
            {workFlowConfigured ? (
              <MenuItem onClick={handleOpenWorkflow}>
                {translation.menuWorkflowViewer}
              </MenuItem>
            ) : (
              <MenuItem onClick={handleOpenDialog(DialogType.workflow)}>
                {translation.menuWorkflowSetup}
              </MenuItem>
            )}
            <MenuItem onClick={handleOpenDialog(DialogType.defineLookups)}>
              {translation.menuKeyLookup}
            </MenuItem>
            <MenuItem onClick={handleOpenDialog(DialogType.auditTable)}>
              {translation.auditTableTitle}
            </MenuItem>
          </Menu>
          {open && (
            <DialogWrapper {...dialogWrapperProps}>
              {nodeData && content[open]}
            </DialogWrapper>
          )}
        </MenuProvider>
      </PermissionProvider>
    </>
  );
});

export function reducer(
  state: NodeMenuState,
  { type, payload }: NodeMenuDispatch,
): NodeMenuState {
  switch (type) {
    case actions.OPEN_MENU:
      return {
        ...state,
        ...payload,
      };
    case actions.OPEN_DIALOG:
      return {
        ...state,
        ...payload,
        anchorEl: null,
      };
    case actions.SET_INITIAL_STATE:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
