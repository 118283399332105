import React, { memo } from "react";

import Button from "elementTypes/common/Button";
import { AlertBox } from "elementTypes/common/AlertBox";

import { useCommonStaticPagesTranslation } from "../translation";

type WarningProps = {
  initializeConfig: () => void;
};

export const Warning = memo<WarningProps>(({ initializeConfig }) => {
  const translation = useCommonStaticPagesTranslation();

  return (
    <AlertBox
      message={translation.createLDAPConfigLabel}
      boxProps={{ px: 2 }}
      color="info"
      action={
        <Button
          data-test-id="createButtonTest"
          color="inherit"
          label="CREATE"
          iconRight="arrowRightAlt"
          size="small"
          onClick={initializeConfig}
        />
      }
    />
  );
});
