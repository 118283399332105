import React, { memo } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { Typography } from "@material-ui/core";
import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderOptionState,
} from "@material-ui/lab/Autocomplete";

import matchSorter from "match-sorter";

import { useListStyles, useStyles } from "../style";
import { SelectOption } from "../types";
import { CommonProps } from "./BaseAutocomplete";

type MultiAutocompleteProps = {
  value: SelectOption[];
  onChange: (value: string[] | null, reason?: string) => void;
  allowSameValue?: boolean;
  withCheckbox?: boolean;
  chipVariant?: any;
};

type Props = CommonProps & MultiAutocompleteProps;

export const MultiAutocomplete = memo<Props>(
  ({
    value,
    isClearable,
    options,
    disabled,
    onChange,
    renderInput,
    isLoading,
    size,
    chipVariant,
    allowSameValue,
    autosuggestHighlight,
    withCheckbox,
    virtualizedList,
    Listbox,
    searchInputValue,
    debugMode,
    getOptionLabel,
    highlightedOption,
    customRenderOption,
    onInputChange,
    getOptionDisabled,
  }) => {
    const { root, checkboxMargin, displayFlex } = useStyles();

    const handleChange = (_: any, valueObject: any, reason: string) => {
      const newValue = valueObject
        ? valueObject.map((obj: SelectOption) => obj.value)
        : null;
      onChange(newValue, reason);

      // If the user handle click on the clear icon button
      // It will clear the input value
      if (reason === "clear") {
        onInputChange?.("");
      }
    };

    const renderTags = (
      tags: SelectOption[],
      getTagProps: AutocompleteGetTagProps,
    ) =>
      tags?.length
        ? tags.map(
            (
              { label: tagLabel, value: tagValue }: SelectOption,
              index: number,
            ) => (
              <Chip
                key={`${index}-${tagValue}`}
                variant={chipVariant}
                label={tagLabel}
                {...getTagProps({ index })}
                size={size}
              />
            ),
          )
        : undefined;

    const renderOption = (
      option: any,
      { inputValue, selected }: AutocompleteRenderOptionState,
    ) => {
      const highlighted = highlightedOption(option, inputValue);

      return (
        <Typography noWrap={true} className={displayFlex}>
          {withCheckbox && !allowSameValue && (
            <Checkbox
              disabled={true}
              className={checkboxMargin}
              checked={selected}
            />
          )}
          {autosuggestHighlight ? highlighted : option.label}
        </Typography>
      );
    };

    const filterOptions = (
      selectOptions: SelectOption[],
      { inputValue }: any,
    ) => {
      const opts =
        allowSameValue || withCheckbox
          ? selectOptions
          : selectOptions.filter(
              (o: SelectOption) => !value.some((v) => v.value === o.value),
            );
      return matchSorter(opts, inputValue, { keys: ["label", "value"] });
    };

    const getOptionSelected = (
      option: SelectOption,
      valueOption: SelectOption,
    ) => !allowSameValue && option.value === valueOption.value;

    const classes = useListStyles();

    return (
      <Autocomplete
        classes={classes}
        debug={debugMode}
        multiple={true}
        className={root}
        options={options}
        getOptionLabel={getOptionLabel}
        disableClearable={!isClearable}
        onChange={handleChange}
        renderTags={renderTags}
        getOptionDisabled={getOptionDisabled}
        value={value}
        disabled={disabled}
        loading={isLoading}
        renderInput={renderInput}
        ListboxComponent={virtualizedList ? Listbox : undefined}
        size={size}
        renderOption={
          autosuggestHighlight || withCheckbox
            ? renderOption
            : customRenderOption
        }
        filterOptions={filterOptions}
        disableCloseOnSelect={withCheckbox}
        getOptionSelected={getOptionSelected}
        inputValue={searchInputValue}
        ListboxProps={{
          "data-cypress-selector": "autocomplete-options-popup",
        }}
      />
    );
  },
);

MultiAutocomplete.displayName = "MultiAutocomplete";
