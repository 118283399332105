import { OwnConfig } from "./types";
import { RequiredConfigProperties } from "core/types";

export const DEFAULT_CONFIG: RequiredConfigProperties<OwnConfig> = {
  geoJSONdefaultValue: "",
  latitude: 51.505,
  longitude: -0.09,
  zoom: 13,
  allowDelete: true,
  allowDrag: true,
  allowLine: true,
  allowPolygon: true,
  allowRectangle: true,
  tileLayerUrl: null,
  maximumFeatures: null,
};
