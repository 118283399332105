import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descTypography: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    dialogRoot: {
      padding: theme.spacing(2),
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(0, 0, 0, 2),
    },
    appInfo: {
      display: "flex",
      justifyContent: "space-between",
      "& :last-child": {
        // https://css-tricks.com/using-flexbox-and-text-ellipsis-together/
        flexShrink: 0,
      },
    },
    appInfoContainer: {
      padding: theme.spacing(0, 0, 1, 0),
    },
    cypexContainer: {
      padding: theme.spacing(1, 0, 0, 0),
    },
    cypexInfo: {
      display: "grid",
      gridTemplateColumns: "minmax(10ch, max-content) auto",
      gridAutoRows: theme.spacing(6),
      margin: theme.spacing(1),
      gridColumnGap: theme.spacing(2),
      alignItems: "baseline",
    },
  }),
);

export default useStyles;
