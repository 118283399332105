import {
  Theme,
  alpha,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing()}px 0`,
    },
    flexBox: {
      display: "flex",
      alignItems: "start",
      justifyContent: "space-between",
    },
    codeClass: {
      "& .CodeMirror": {
        minHeight: "50px",
      },
    },
    paperClass: {
      "& .CodeMirror": {
        minHeight: "100%",
        height: "unset",
      },
    },
    toggleButton: {
      color: alpha(theme.palette.primary.light, 0.4),
      borderColor: alpha(theme.palette.primary.main, 0.4),
      "&.Mui-selected": {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
);
