import React, { memo } from "react";

import { StylingComponent, TabsComponent } from "./components";

export const DefaultTabsEditor = memo(() => {
  return (
    <>
      <TabsComponent />
      <StylingComponent />
    </>
  );
});
