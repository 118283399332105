import React, { memo, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";

import { useRoles } from "../../../../queries/admin";
import { LoadingComponent } from "../../../../layouts/common/Loading";
import Button from "../../../../elementTypes/common/Button";
import { Table, TableRow } from "../../common";
import { useRolePagesTranslation } from "./translation";

const headers = [
  {
    name: "name",
    title: "Name",
  },
];

export const RolesPage = memo(() => {
  const { data, isLoading, isFetching, error, refetch } = useRoles();

  const rows = useMemo(
    () =>
      data?.map((role) => (
        <TableRow key={role.name} rowId={role.name}>
          <Typography>{role.name}</Typography>
        </TableRow>
      )),
    [data],
  );

  const translation = useRolePagesTranslation();

  return (
    <Box display="flex" flexDirection="column" gridGap={8} p={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{translation.pageTitle}</Typography>
        <Button
          color="primary"
          href="/admin/roles/create"
          iconLeft="add"
          label={translation.createBtnLabel}
        />
      </Box>
      <Box>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <Table
            rows={rows}
            headers={headers}
            onDataReload={refetch}
            loading={isFetching}
            error={error?.message}
          />
        )}
      </Box>
    </Box>
  );
});
