import { selectorScoper } from "core/utils/redux";
import { IState } from "./types";

import { IElement } from "core/types/element";
import { WithOptionalFieldDataSourceConfig } from "elementInterfaces";
import { FormInputConfig } from "elementTypes/common";

export function buildSelectors<
  T,
  Element extends IElement<WithOptionalFieldDataSourceConfig & FormInputConfig>
>(path: string[], element: Element) {
  const scopeSelector = selectorScoper<IState<T>>(path);

  const value = (state: any) => scopeSelector(state).value;
  const errors = (state: any) => scopeSelector(state).errors;
  const touched = (state: any) => scopeSelector(state).touched;
  const disabled = () => Boolean(element.config.disabled);
  const required = () => element.config.nullable === false;

  return {
    value,
    errors,
    touched,
    disabled,
    required,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
