import React, { memo, useState } from "react";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { StylingComponent, TranslationComponent } from "./components";
import { ColorInput as ColorInputComponent } from "../../common/ColorInput";
import { UntransformedColorInputConfig } from "../types";

export const ColorInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const {
    elementModel: {
      config: { dataSource, defaultValue },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedColorInputConfig>();

  const changeLanguage = (l: Language) => setLang(l);
  const handleDefaultValueInputChange = (value: string | null) =>
    changeConfigValue("defaultValue", value);

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["text"]}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        <ColorInputComponent
          value={defaultValue ?? ""}
          changeValue={handleDefaultValueInputChange}
          label={editorTranslation.defaultValueInputLabel}
          disabled={Boolean(dataSource)}
          placement="top"
        />
      </FormInputConfigEditorComponent>
      <StylingComponent />
    </>
  );
});
