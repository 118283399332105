import React, { ChangeEvent, memo, useState } from "react";
import TextField from "@material-ui/core/TextField";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import {
  AdvancedComponent,
  StylingComponent,
  TranslationComponent,
} from "./components";
import { UntransformedSliderInputConfig } from "../types";

export const SliderInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const {
    elementModel: {
      config: { dataSource, defaultValue },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedSliderInputConfig>();

  const changeLanguage = (l: Language) => setLang(l);

  const handleDefaultValueInputChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) =>
    changeConfigValue("defaultValue", Number(newValue));

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["number"]}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        <TextField
          disabled={Boolean(dataSource)}
          value={defaultValue ?? ""}
          name="defaultValue"
          type="number"
          label={editorTranslation.defaultValueInputLabel}
          fullWidth={true}
          onChange={handleDefaultValueInputChange}
        />
      </FormInputConfigEditorComponent>
      <StylingComponent />
      <AdvancedComponent />
    </>
  );
});
