import React, { memo } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import { LoadingComponent } from "layouts/common/Loading";
import { UserInfo } from "./components";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { ErrorMessage } from "layouts/common/ErrorMessage";
import BackButton from "elementTypes/common/BackButton";
import { useUser } from "../../../../../../queries/admin";

export const ViewUsersPage = memo(() => {
  const { paper } = useStyles();
  const { userId } = useSelector(routerSelectors.params);
  const { data: user, isLoading: loading, error } = useUser(
    { userId },
    { enabled: !!userId },
  );

  if (error) {
    return (
      <Box pb={2} display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon href="/admin/users" />
        <ErrorMessage error={error.message} />
      </Box>
    );
  }

  if (loading || !user) {
    return <LoadingComponent />;
  }

  return (
    <Box mt={1}>
      <Box pb={2} display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon href="/admin/users" />
        <Typography variant="h5">{user.email}</Typography>
      </Box>
      <Paper variant="outlined" className={paper}>
        <UserInfo label="Id" icon="format_list_numbered" value={user.id} />
        <UserInfo
          label="Username"
          icon="account_circle"
          value={`${user.userName ? user.userName : "-"}`}
        />
        <UserInfo label="Role" icon="assignment_ind" value={user.role} />
        <UserInfo label="Language" icon="language" value={user.language} />
        <div>
          <FormControlLabel
            disabled
            control={<Checkbox />}
            label="Active"
            checked={user.isActive}
          />
          <FormControlLabel
            disabled
            control={<Checkbox />}
            label="Administrator"
            checked={user.isAdmin}
          />
        </div>
      </Paper>
    </Box>
  );
});
