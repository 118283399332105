import React, { MouseEvent, memo } from "react";
import { useDispatch } from "react-redux";
import MLink, { LinkProps as MLinkProps } from "@material-ui/core/Link";

import { actions as routerActions } from "core/router/reduxModule";

interface ILinkProps extends MLinkProps {
  href?: string;
  component?: string;
}

export const Link = memo<ILinkProps>((props) => {
  const dispatch = useDispatch();

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (props.onClick) {
      props.onClick(event);
    }

    if (props.href) {
      event.preventDefault();

      dispatch(routerActions.push(props.href));
    }
  };

  return <MLink {...props} onClick={handleClick} />;
});
