import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: `calc(100% - ${theme.spacing(6)}px)`,
      width: "100%",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      gridArea: "top",
    },
    title: {
      flexGrow: 1,
    },
    formFooter: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(3),
    },
    logo: {
      display: "block",
      height: 250,
      objectFit: "contain",
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
  }),
);

export default useStyles;
