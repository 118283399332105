import React, { memo } from "react";

import { MiniMap as FlowMiniMap } from "react-flow-renderer";
import { useTheme } from "@material-ui/core/styles";

export const MiniMap = memo(() => {
  const { palette } = useTheme();

  return (
    <FlowMiniMap
      nodeStrokeColor={palette.grey[400]}
      nodeStrokeWidth={10}
      nodeColor={palette.common.black}
    />
  );
});
