import { createUseTranslation } from "core/session/translation";

const dataBaseTranslations = {
  en: {
    databasePageTitle: "Database - Administration",
    databaseOverviewTitle: "Database",
    entities: "Entities",
    queries: "Queries",
    layoutPlacement: "Node placement layout",
    horizontalTooltip: "Align Horizontally",
    verticalTooltip: "Align Vertically",
    noDataLabel: "No Data",
    createQuery: "Create Query",
    successDelete: "Query deleted successfully",
    refreshModelTooltip: "Refresh Data",
    treeItemSchemaTableSingular: "table",
    treeItemSchemaTablePlural: "tables",
  },
  es: {
    databaseOverviewTitle: "Vista de Base de Datos",
    entities: "Entidades",
    queries: "Consultas",
    layoutPlacement: "Disposición de nodos",
    horizontalTooltip: "Horizontal",
    verticalTooltip: "Vertical",
    successDelete: "Consulta eliminada",
    refreshModelTooltip: "Refrescar Datos",
  },
};

export const useDataBaseTranslation = createUseTranslation(
  dataBaseTranslations,
);
