import { createSelector } from "reselect";

import { selectorScoper } from "../../utils/redux";
import { MODULE_NAME } from "./constants";

import { ILocation, IState } from "./types";

const scopeSelector = selectorScoper<IState>([MODULE_NAME]);

export const loadingPage = (state: any) => scopeSelector(state).loadingPage;
export const page = (state: any) => scopeSelector(state).page;
export const creatingPage = (state: any) => scopeSelector(state).creatingPage;
export const staticPageId = (state: any) => scopeSelector(state).staticPageId;
export const isAdminPage = (state: any) => scopeSelector(state).isAdminPage;
export const error = (state: any) => scopeSelector(state).error;
export const notFound = (state: any) => scopeSelector(state).notFound;
export const preventLocationChange = (state: any) =>
  scopeSelector(state).preventLocationChange;
export const keysByKeyAliases = (state: any) =>
  scopeSelector(state).keysByKeyAliases;
export const compiledRoutes = (state: any) =>
  scopeSelector(state).compiledRoutes;

export const location = (state: any) => scopeSelector(state).location;
export const params = (state: any) => scopeSelector(state).params;
export const locationWithParams = createSelector(
  [location, params],
  (loc, par) =>
    ({
      ...loc,
      params: par,
    } as ILocation),
);

export const isStaticPage = createSelector([staticPageId], (id) => id !== null);

export const pageElement = createSelector([page], (p) =>
  p ? p.element : null,
);
