import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { getTranslatedTextSaga } from "core/session/translation/createUseTranslation";
import { sessionTranslation } from "core/session/translations";
import { CallButton } from "../types";
import { Actions, Types } from "./types";
import {
  ChartInterface,
  FormDataSource,
  TableDataSource,
} from "elementInterfaces";
import { getServerError } from "../../../core/utils/api";

export function buildSaga(actions: Actions, types: Types, element: CallButton) {
  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string | null = yield select(sessionSelectors.token);
    const { showNotification, callArgs: callArgsConfig } = element.config;

    try {
      const callArgsSelector =
        typeof callArgsConfig === "function"
          ? (state: any) => callArgsConfig(state)
          : () => callArgsConfig;
      const callArgs = (yield select(callArgsSelector)) as ReturnType<
        typeof callArgsSelector
      >;

      const callName = (yield select(element.config.callName)) as ReturnType<
        typeof element.config.callName
      >;

      const result: unknown = yield call(
        services.api.runProcedure,
        token,
        callName,
        "view",
        callArgs,
      );

      yield put(actions.loadSuccess(result));
      if (showNotification) {
        yield put(
          sessionActions.enqueueSnackbar({
            message: yield call(
              getTranslatedTextSaga,
              sessionTranslation,
              "success",
            ),
            options: {
              variant: "success",
            },
          }),
        );
      }
    } catch (error) {
      const errorMessage = getServerError(error);
      yield put(actions.loadError(errorMessage));
      yield put(
        sessionActions.enqueueSnackbar({
          message: errorMessage,
          options: {
            variant: "error",
          },
        }),
      );
    }
  }

  function* loadSuccessSaga() {
    if (element.config.onSuccess) {
      for (const successHandler of element.config.onSuccess) {
        if (successHandler.type === "reload_table") {
          const dataSource = TableDataSource.get(successHandler.elementId);
          yield put(dataSource.reload());
        } else if (successHandler.type === "save_form") {
          const dataSource = FormDataSource.get(successHandler.elementId);
          yield put(dataSource.save());
        } else if (successHandler.type === "refresh_chart") {
          const chartInterface = ChartInterface.get(successHandler.elementId);
          yield put(chartInterface.refresh());
        }
      }
    }
  }

  return function* mainSaga() {
    yield all([takeLatest(types.LOAD, loadSaga)]);
    yield all([takeLatest(types.LOAD_SUCCESS, loadSuccessSaga)]);
  };
}
