import React, { memo, useCallback, useMemo, useState } from "react";
import { formatDistanceToNow, formatRelative } from "date-fns";

import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { IFile } from "./types";
import { Table, TableRow } from "../../common";
import useStyles from "../../styles";
import { FILE_STORAGE_PREFIX } from "services/api/constants";
import { useDeleteFile, useFiles } from "queries/admin/fileData";
import { getApiError } from "queries/utils";
import { useCommonStaticPagesTranslation } from "./translation";
import { useSnackbar } from "utils/hooks/useSnackbar";

type Row = IFile & { deleteRow: (id: string | number) => void };

const Row = memo<Row>(
  ({
    id,
    realName,
    fileType,
    createdAt,
    typeGroup: { typeName },
    fileGroup: { name },
    fileName,
    deleteRow,
  }) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const { inlineAndEndFlex } = useStyles();
    const handleToggleDialog = () =>
      setOpenDeleteDialog((prevOpen) => !prevOpen);
    const handleDeleteFile = () => {
      deleteRow(id);
      setOpenDeleteDialog(false);
    };

    return (
      <>
        <TableRow rowId={id}>
          <Typography>{realName}</Typography>
          <Typography>{fileType}</Typography>
          <Tooltip title={formatRelative(new Date(createdAt), new Date())}>
            <Typography>
              {formatDistanceToNow(new Date(createdAt), {
                addSuffix: true,
              })}
            </Typography>
          </Tooltip>
          <Typography>{typeName}</Typography>
          <Typography>{name}</Typography>
          <Grid item={true} className={inlineAndEndFlex}>
            <Link href={`${FILE_STORAGE_PREFIX}${fileName}`} target="_blank">
              <IconButton icon="open_in_new" color="secondary" tooltip="Open" />
            </Link>
            <IconButton
              icon="delete"
              color="secondary"
              tooltip="Delete"
              onClick={handleToggleDialog}
            />
          </Grid>
        </TableRow>
        <DialogWrapper
          open={openDeleteDialog}
          title="Delete file"
          contentText={`Are you sure you want to delete the file ${realName}?`}
          submitTitle="Delete"
          handleSubmit={handleDeleteFile}
          cancelTitle="Cancel"
          handleClose={handleToggleDialog}
          fullWidth={true}
        />
      </>
    );
  },
);

export const FilesPageComponent = memo(() => {
  const { horizontallyCenter, inlineAndEndFlex } = useStyles();

  const translation = useCommonStaticPagesTranslation();

  const showSnackbar = useSnackbar();

  const files = useFiles();

  const deleteFile = useDeleteFile({
    onSuccess: () => {
      files.refetch();
      showSnackbar(translation.deletedMessage, "success");
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const handleDeleteRow = useCallback(
    (id: string | number) => {
      if (files.data) {
        deleteFile.mutate({
          fileName: files.data?.find((file) => file.id === id)
            ?.fileName as string,
        });
      }
    },
    [files.data, deleteFile],
  );

  const titles = [
    translation.tableNameLabel,
    translation.tableFileTypeLabel,
    translation.tableUploadLabel,
    translation.tableTypeGroupLabel,
    translation.tableAccessGroupLabel,
    translation.tableActionsLabel,
  ];

  const rows = useMemo(
    () =>
      files.data?.map((file: IFile) => (
        <Row key={file.id} deleteRow={handleDeleteRow} {...file} />
      )),
    [files.data, handleDeleteRow],
  );

  const dataRefetch = () => {
    files.refetch();
  };

  const headers = titles.map((title) => ({
    name: title.toLowerCase(),
    title,
  }));

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">Files</Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={inlineAndEndFlex}>
        <Button
          color="primary"
          label="UPLOAD"
          iconLeft="cloud_upload"
          href="/admin/files/upload"
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          rows={rows}
          headers={headers}
          onDataReload={dataRefetch}
          loading={files?.isLoading}
          error={files.error?.toString()}
        />
      </Grid>
    </Grid>
  );
});
