import React, { memo } from "react";

import { FormattingComponent, SetupComponent } from "./components";

export const DateTimeFieldEditor = memo(() => {
  return (
    <>
      <SetupComponent />
      <FormattingComponent />
    </>
  );
});
