import React, { memo, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import {
  FormInputConfigEditorComponent,
  FormInputDataSourceEditorComponent,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session/SessionContext";
import { Language } from "core/types";
import { TranslationComponent } from "./components";
import { UntransformedArrayTextInputConfig } from "../types";

export const ArrayTextInputEditor = memo(() => {
  const { language } = useSessionContext();
  const [lang, setLang] = useState<Language>(language);
  const editorTranslation = useEditorTranslation();
  const {
    elementModel: {
      config: { dataSource, defaultValue },
    },
    changeConfigValue,
  } = useElementEditorContext<UntransformedArrayTextInputConfig>();

  const changeLanguage = (l: Language) => setLang(l);
  const handleDefaultValueInputChange = (_: any, newData: string[] | null) =>
    changeConfigValue("defaultValue", newData);

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={true}
        allowedDataTypes={["text"]}
      />
      <TranslationComponent
        language={lang}
        handleChangeLanguage={changeLanguage}
      />
      <FormInputConfigEditorComponent>
        <Autocomplete<string, true, undefined, true>
          renderInput={(params: any) => (
            <TextField
              {...params}
              label={editorTranslation.defaultValueInputLabel}
            />
          )}
          onChange={handleDefaultValueInputChange}
          value={defaultValue ?? undefined}
          disabled={Boolean(dataSource)}
          multiple
          freeSolo
          options={[]}
        />
      </FormInputConfigEditorComponent>
    </>
  );
});
