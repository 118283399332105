import React, { memo } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import { Box, Chip } from "@material-ui/core";
import Icon from "@material-ui/icons/ZoomOutMap";
import classNames from "classnames";

import { useStyles } from "./style";
import { NodeData } from "./types";

export const StateNode = memo<NodeProps<NodeData>>((props) => {
  const {
    data: { label },
    selected,
  } = props;
  const classes = useStyles();

  return (
    <Chip
      color="secondary"
      variant={selected ? "default" : "outlined"}
      icon={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="custom-drag-handle"
          width="20px"
          height="20px"
        >
          <Icon fontSize="small" />
        </Box>
      }
      label={
        <Box position="relative" py={1} display="flex" alignItems="center">
          <Handle
            type="source"
            className={classes.handleClass}
            isValidConnection={(connection) =>
              connection.target !== connection.source
            }
            position={Position.Top}
          />
          {label}
        </Box>
      }
      className={classNames(classes.node, {
        [classes.nodeBgColor]: !selected,
      })}
    />
  );
});
