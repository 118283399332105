import React, { memo } from "react";

import { Filter } from "elementTypes/common/Echarts/editor";
import {
  SetupComponent,
  SortComponent,
  TranslationComponent,
} from "./components";

export const EchartsBarChartEditor = memo(() => {
  return (
    <>
      <SetupComponent />
      <TranslationComponent />
      <SortComponent />
      <Filter />
    </>
  );
});
