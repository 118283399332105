import { useCallback, useLayoutEffect, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";

export const useDimensions = (dependencies?: any[]) => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useResizeObserver<HTMLDivElement>({ ref });

  const [dimensions, setDimensions] = useState<DOMRect>({} as DOMRect);

  const updateDimensions = useCallback(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const rect = element.getBoundingClientRect().toJSON();
    setDimensions(rect);
  }, []);

  useLayoutEffect(() => {
    updateDimensions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(dependencies ?? []), ref.current, size]);

  const width = size?.width ?? dimensions?.width;
  const height = size?.height ?? dimensions?.height;

  return { ...dimensions, width, height, ref };
};
