import React, { memo } from "react";

import {
  FormInputDataSourceEditorComponent,
  Section,
  TranslationEditor,
  useEditorTranslation,
  useElementEditorContext,
} from "core/editor";
import { useSessionContext } from "core/session";
import { Switcher as LanguageSwitch } from "layouts/common/LanguageSwitch/Switcher";
import { useLanguage } from "utils/hooks/useLanguage";
import { GeoJSONInputConfig } from "../../types";

export const ConfigEditor = memo(() => {
  const {
    elementModel: { i18n },
    changeTranslation,
  } = useElementEditorContext<GeoJSONInputConfig>();

  const { language } = useSessionContext();
  const { lang, changeLanguage } = useLanguage(language);

  const { translationTitle } = useEditorTranslation();

  return (
    <>
      <FormInputDataSourceEditorComponent
        language={lang}
        allowedDataTypeIsArray={false}
        allowedDataTypes={["json"]}
      />
      <Section
        title={translationTitle}
        wrapped={true}
        cardActions={
          <LanguageSwitch
            language={lang}
            changeLanguage={changeLanguage}
            colorVariant="dark"
            fullWidth={true}
          />
        }
      >
        <TranslationEditor
          translationKey="label"
          i18n={i18n}
          changeTranslation={changeTranslation}
          language={lang}
        />
      </Section>
    </>
  );
});
