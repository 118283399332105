import { WithFieldDataSourceConfig } from "../../elementInterfaces/FormDataSource/types";

type DataSource = WithFieldDataSourceConfig["dataSource"];

export function getColumnName(dataSource?: DataSource): string {
  return dataSource && dataSource.fieldPath
    ? dataSource.fieldPath[0].toString() // since `fieldPath[0]` can be a number
    : "";
}

// used to make element selectable by the editor
export const NON_BREAKING_SPACE = "\u00A0";

// the marker used to show required field
export const ASTERISK_SYMBOL = "\u00a0*";
