import { createTypeSafeContext } from "utils/createTypeSafeContext";

import { IElementModel, IElementType, ILayoutComponent } from "./types";

interface IElementTypesContext {
  elementTypes: Record<string, IElementType>;
  availableElementTypes: Record<string, IElementType>;
  getElementType: (element: IElementModel) => IElementType;
  getLayout: (name?: string) => ILayoutComponent;
}

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  IElementTypesContext
>();

export const useElementTypesContext = useTypeSafeContext;
export const ElementTypesProvider = Provider;
export const ElementTypesConsumer = Consumer;
