import { History, Location } from "history";
import { eventChannel } from "redux-saga";
import {
  all,
  call,
  getContext,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import omit from "lodash/omit";

import {
  actions as sessionActions,
  selectors as sessionSelectors,
  types as sessionTypes,
} from "../../session/reduxModule";
import { AllServices } from "../../buildStore";
import { actions, types } from "./actions";
import { DEFAULT_APP_URL } from "./constants";
import history from "./history";
import { selectors } from "./selectors";
import {
  compileRoutes,
  matchRoute,
  parseQueries,
  parseUrl,
  stringifyQueries,
} from "./utils";
import { Actions } from "./types";

/*
 * Set the appMetadata in the router service so it sets the routes and only then dispatch the initial
 * locationChange action with the current browser history location.
 */
function* appMetadataLoaded(
  action: ReturnType<typeof sessionActions.loadAppMetadataSuccess>,
) {
  const appMetadata: ReturnType<typeof sessionSelectors.appMetadata> = yield select(
    sessionSelectors.appMetadata,
  );

  if (!appMetadata) {
    throw Error("appMetadata is null, this must never happen");
  }

  const compiledRoutes = compileRoutes(appMetadata);
  yield put(actions.setCompiledRoutes(compiledRoutes));

  const services: AllServices = yield getContext("services");
  yield call(services.loader.hide);

  if (action.payload.redirect) {
    yield put(actions.replace(action.payload.redirect));
  } else {
    /**
     * If this is the first time we enter the app, make sure the UI is added to the url.
     */
    const ui: ReturnType<typeof sessionSelectors.ui> = yield select(
      sessionSelectors.ui,
    );
    if (!ui) {
      throw Error("ui is null, this must never happen");
    }
    // TODO check if router location selector can be used here
    const queries = parseQueries(history.location.search);
    const search = stringifyQueries({ ...queries, ui: ui.name });
    const currentPathname = history.location.pathname;
    const replaceLocation = {
      ...history.location,
      search,
      pathname: currentPathname === "/" ? DEFAULT_APP_URL : currentPathname,
    };
    yield call([history, "replace"], replaceLocation);
  }
}

/*
 * Dispatch a locationChange action every time the browser history location changes, except when the
 * `preventLocationChange` flag is found in the state.
 */
function* historyLocationChangeSaga(location: Location) {
  const preventLocation: ReturnType<typeof selectors.preventLocationChange> = yield select(
    selectors.preventLocationChange,
  );

  if (preventLocation) {
    yield put(
      actions.setPreventLocationChange(false, { addKeyAlias: location.key }),
    );
  } else {
    /**
     * When we use the `updateQueryStringValues` action, we replace the location to include the new query string
     * but prevent the `changeLocation` action from firing, but the browser location does change, so it gets a
     * new location key. To prevent unwanted behavior, we keep a map of the keys produced by a location change
     * that was prevented and treat them as aliases of the original location key.
     */
    const keysByKeyAliases: ReturnType<typeof selectors.keysByKeyAliases> = yield select(
      selectors.keysByKeyAliases,
    );
    const realKey = location.key
      ? keysByKeyAliases[location.key] || location.key
      : location.key;

    yield put(
      actions.locationChange(
        location.pathname,
        location.search,
        location.state,
        realKey,
      ),
    );
  }
}

/**
 * React to the history location change, match the url to the routes and dispatch a locationChange or
 * a notFound action.
 */
function* locationChangeSaga(action: ReturnType<Actions["locationChange"]>) {
  const isLoggedIn: ReturnType<typeof sessionSelectors.isLoggedIn> = yield select(
    sessionSelectors.isLoggedIn,
  );
  const isAdmin: ReturnType<typeof sessionSelectors.isAdmin> = yield select(
    sessionSelectors.isAdmin,
  );
  /**
   * If the action is a query string change, don't load the page again.
   */
  if (action.meta.noPageReload) {
    return;
  }

  const compiledRoutes: ReturnType<typeof selectors.compiledRoutes> = yield select(
    selectors.compiledRoutes,
  );
  const allPages: ReturnType<typeof selectors.allPages> = yield select(
    selectors.allPages,
  );
  const services: AllServices = yield getContext("services");

  const match = matchRoute(
    compiledRoutes,
    services.router.staticRoutes,
    action.payload.pathname,
    omit(action.payload.queries, ["ui"]),
    allPages,
  );

  if (!match) {
    yield put(actions.notFound());
  } else if ("staticPageId" in match) {
    if (match.auth && !isLoggedIn) {
      yield put(actions.notFound());
      return;
    }

    if (match.isAdmin && !isAdmin) {
      yield put(actions.notFound());
      return;
    }

    // If a static page matches before loading the app metadata, we hide the loader.
    yield call(services.loader.hide);
    yield put(
      actions.staticPageLoadSuccess(
        match.staticPageId,
        match.params,
        match.isAdmin,
      ),
    );
  } else {
    yield put(actions.loadPage(match.page, match.params));
  }
}

/**
 * Load the pageElement from the API, set the page reducers in the store and dispatch a page loaded action.
 */
/**
 * TODO:
 * All the pages get now loaded at startup, so this saga isn't necessary anymore. Remove it
 */
function* pageLoadSaga(action: ReturnType<Actions["loadPage"]>) {
  yield put(
    actions.loadPageSuccess(action.payload.page, action.payload.params),
  );

  // const services: AllServices = yield getContext("services");
  // const token: string = yield select(sessionSelectors.token);
  //
  // try {
  //   const pageElement = yield call(
  //     services.api.loadPageElement,
  //     action.payload.pageId,
  //     { token, params: action.payload.params },
  //   );
  //   document.title = pageElement.i18n.label;
  //   yield put(actions.loadPageSuccess(pageElement, action.payload.params));
  // } catch (error) {
  //   console.error(error);
  //   yield put(actions.loadPageError(error.toString()));
  // }
}

/**
 * Get a queryString representing the current UI.
 */
function* getUiLocationSearch(query?: Record<string, unknown>) {
  const ui: ReturnType<typeof sessionSelectors.ui> = yield select(
    sessionSelectors.ui,
  );
  return stringifyQueries({ ...(ui && { ui: ui.name }), ...query });
}

/*
 * Simple navigation sagas.
 */

function* pushSaga(action: ReturnType<Actions["push"]>) {
  const urlParsed = parseUrl(action.payload.url);
  const search: string = yield getUiLocationSearch(urlParsed.query);

  yield call([history, "push"], {
    pathname: urlParsed.url,
    state: action.payload.state,
    /**
     * TODO:
     * The push actions should take an optional parameter "query", in which case the payload query has to be merged
     * with the ui location query.
     */
    search,
  });
}

function* replaceSaga(action: ReturnType<Actions["replace"]>) {
  const urlParsed = parseUrl(action.payload.url);
  const search: string = yield getUiLocationSearch(urlParsed.query);

  yield call([history, "replace"], {
    pathname: urlParsed.url,
    state: action.payload.state,
    /**
     * TODO:
     * The push actions should take an optional parameter "query", in which case the payload query has to be merged
     * with the ui location query.
     */
    search,
  });
}

function* goSaga(action: ReturnType<Actions["go"]>) {
  yield call([history, "go"], action.payload.index);
}

function* goBackSaga() {
  yield call([history, "goBack"]);
}

function* goForwardSaga() {
  yield call([history, "goForward"]);
}

/*
 * Set the prevent location change flag to true to avoid firing a page reload (unless indicated otherwise)
 * and update the location to include the new queryString values.
 */
function* updateQueryStringValuesSaga(
  action: ReturnType<Actions["updateQueryStringValues"]>,
) {
  const location: ReturnType<typeof selectors.location> = yield select(
    selectors.location,
  );
  if (!action.payload.fireLocationChange) {
    yield put(actions.setPreventLocationChange(true));
  }
  yield call([history, "replace"], {
    ...location,
    search: stringifyQueries({ ...location.queries, ...action.payload }),
  });
}

/*
 * A channel factory for connecting history to sagas.
 */
function historyListener(historyEntry: History) {
  return eventChannel((emitter) => historyEntry.listen(emitter));
}

export function* saga() {
  yield all([
    takeLatest(historyListener(history), historyLocationChangeSaga),
    takeLatest(sessionTypes.APP_METADATA_LOAD_SUCCESS, appMetadataLoaded),
    takeLatest(types.LOCATION_CHANGE, locationChangeSaga),
    takeLatest(types.PAGE_LOAD, pageLoadSaga),
    takeLatest(types.PUSH, pushSaga),
    takeLatest(types.REPLACE, replaceSaga),
    takeLatest(types.GO, goSaga),
    takeLatest(types.GO_BACK, goBackSaga),
    takeLatest(types.GO_FORWARD, goForwardSaga),
    takeEvery(types.QUERY_STRING_UPDATE_VALUES, updateQueryStringValuesSaga),
  ]);
}
