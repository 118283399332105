import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useEntryStyles = makeStyles(() =>
  createStyles({
    firstLine: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }),
);
export const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }),
);
