import React, { memo } from "react";
import { useQueryClient } from "react-query";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { IApp, IUser } from "../../../types";

import DefaultDataDisplay from "elementTypes/default_data_display/DataDisplay";
import { Echarts } from "elementTypes/common/Echarts";
import { QueryKeys, useApps, useUsers } from "../../../../queries/admin";

export const AdminPageComponent = memo(() => {
  const queryClient = useQueryClient();
  const users = useUsers();
  const apps = useApps();
  const usersList = (users.data ?? []) as IUser[];
  const appList = (apps.data ?? []) as IApp[];

  const totalUsers = usersList.length;
  const totalApps = appList.length;
  const activeUsers = usersList.filter((user) => user.isActive).length;
  const load = (key: string) => () => queryClient.invalidateQueries(key);

  const option = {
    title: null,
    tooltip: { trigger: "item", formatter: "{b}: {c} ({d}%)" },
    legend: { orient: "vertical", left: "left", show: true },
    series: [
      {
        avoidLabelOverlap: true,
        label: { show: true },
        type: "pie",
        radius: ["55%", "75%"],
        data: [
          {
            name: "Active Users",
            value: activeUsers,
          },
          {
            name: "Deactivated Users",
            value: totalUsers - activeUsers,
          },
        ],
      },
    ],
  };

  return (
    <>
      <Box pb={2}>
        <Typography variant="h5">Home</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box display="grid" gridGap={8}>
            <DefaultDataDisplay
              label="Total Apps"
              config={{
                icon: "folder_special",
                barPosition: "bottom",
              }}
              formattedData={totalApps}
              error={null}
              loading={apps.isFetching || apps.isLoading}
              load={load(QueryKeys.fetchApps)}
              color="info"
            />
            <DefaultDataDisplay
              label="Total Users"
              config={{
                icon: "people_alt",
                barPosition: "bottom",
              }}
              formattedData={totalUsers}
              error={null}
              loading={users.isLoading || users.isFetching}
              load={load(QueryKeys.fetchUsers)}
              color="info"
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Echarts option={option as any} />
        </Grid>
      </Grid>
    </>
  );
});
