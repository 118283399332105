import React, { memo, useState } from "react";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { IFileWithMeta } from "react-dropzone-uploader";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { actions as routerActions } from "core/router/reduxModule";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Box, Typography } from "@material-ui/core";

import Button from "elementTypes/common/Button";
import BackButton from "elementTypes/common/BackButton";
import { FileForm } from "./types";
import useStyles from "./styles";
import { useCommonStaticPagesTranslation } from "../../translation";
import { UploadZone } from "./components";
import {
  useFileGroupOptions,
  useFileTypeOptions,
  useSaveFile,
} from "queries/admin/fileData";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { getApiError } from "queries/utils";
import { LoadingComponent } from "../../../../../../layouts/common/Loading";

export const CreateAppsPage = memo(() => {
  const { root, footer, dividerFooter } = useStyles();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
    errors,
  } = useForm<FileForm>();

  const translation = useCommonStaticPagesTranslation();

  const dispatch = useDispatch();

  const showSnackbar = useSnackbar();

  const [fileWithMeta, setFileWithMeta] = useState<IFileWithMeta | null>(null);

  const fileOptions = useFileTypeOptions();

  const groupOptions = useFileGroupOptions();

  const uploadFile = useSaveFile({
    onSuccess: () => {
      showSnackbar(translation.saveSuccessMessage, "success");
      setFileWithMeta(null);
      dispatch(routerActions.goBack());
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(translation.saveErrorMessage + msg, "error");
    },
  });

  const submit = (data: FileForm) => {
    if (fileWithMeta) {
      data = {
        ...data,
        file: fileWithMeta.file,
      };

      uploadFile.mutate(data);
    }
  };

  if (groupOptions.isLoading || fileOptions.isLoading) {
    return <LoadingComponent />;
  }

  if (!groupOptions.data || !fileOptions.data) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box pb={2} display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon href="/admin/files" />
        <Typography variant="h5">Upload Files</Typography>
      </Box>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              options={groupOptions.data}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Group"
                  name="groupName"
                  error={Boolean(errors?.groupName)}
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={fileOptions.data}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type"
                  name="typeGroupName"
                  error={Boolean(errors?.typeGroupName)}
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <UploadZone onChange={setFileWithMeta} />
          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              iconRight="forward"
              type="submit"
              label="Upload"
              disabled={!fileWithMeta || isSubmitting || uploadFile.isLoading}
              processing={uploadFile.isLoading}
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
