import React, { memo, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Element } from "core";

import { useStyles } from "./styles";
import { DefaultModalDialogProps } from "./types";
import Button from "../common/Button";
import IconButton from "elementTypes/common/IconButton";
import { NON_BREAKING_SPACE } from "elementTypes/common/utils";
import { Typography } from "@material-ui/core";

const DefaultModalDialogComponent = memo<DefaultModalDialogProps>(
  ({
    element: {
      id,
      children: { content },
      i18n: { openButtonLabel, modalTitle },
    },
    openButtonDisabled,
  }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = useStyles();

    const handleButtonClick = () => {
      setOpenDialog(true);
    };

    const handleClose = () => setOpenDialog(false);

    const handleDialogClose = (
      _ev: Record<string, unknown>,
      reason?: "backdropClick" | "escapeKeyDown",
    ) => {
      // do not automatically close when clicking outside of the dialog
      if (reason !== "backdropClick") {
        handleClose();
      }
    };

    return (
      <>
        <Button
          className={classes.root}
          onClick={handleButtonClick}
          aria-label="open"
          label={openButtonLabel}
          disabled={openButtonDisabled}
        />
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby={`dialog-title-${id}`}
          fullWidth
          maxWidth="md"
          // allows the element panel inputs to be focused
          disableEnforceFocus
          // allows the element panel to be used in edit mode
          disablePortal
          classes={{ paper: classes.dialogContentPaper }}
        >
          <DialogTitle
            id={`dialog-title-${id}`}
            className={classes.dialogTitle}
            disableTypography
          >
            <Typography variant="h6">
              {modalTitle || NON_BREAKING_SPACE}
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.dialogCloseIcon}
              onClick={handleClose}
              icon="close"
            />
          </DialogTitle>
          <DialogContent className={classes.dialogRoot}>
            {content.elements.map((el) => (
              <Element key={el.id} element={el} />
            ))}
          </DialogContent>
        </Dialog>
      </>
    );
  },
);

export default DefaultModalDialogComponent;
