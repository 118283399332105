import React, { useMemo } from "react";

import { ListItemText, Tooltip } from "@material-ui/core";

import { AutocompleteOption } from "elementTypes/common/Autocomplete";
import {
  BaseAutocomplete,
  IAutocompleteValue,
} from "elementTypes/default_autocomplete_input/components";
import { getFileOptions } from "queries/admin/utils";
import { useEditorStorageImageTranslation } from "../translation";
import { useGetFilesByGroupType } from "queries/admin/fileData";

const customRenderOption = (option: AutocompleteOption) => (
  <Tooltip title={option["title"]}>
    <ListItemText primary={option.label} />
  </Tooltip>
);

export default function FileSelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: IAutocompleteValue) => void;
}) {
  const files = useGetFilesByGroupType(
    { groupType: "image" },
    { select: getFileOptions },
  );

  const translation = useEditorStorageImageTranslation();

  const selectedOption = useMemo(
    () => files.data?.find((option) => option.value === value) ?? null,
    [value, files],
  );

  return (
    <BaseAutocomplete
      options={files.data || []}
      onChange={onChange}
      valueObject={selectedOption}
      name="file-select"
      label={translation.chooseFile}
      isLoading={files.isFetching}
      virtualizedList={true}
      defaultItemSize={48}
      customRenderOption={customRenderOption}
    />
  );
}
