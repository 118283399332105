import React, { memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { IFileWithMeta } from "react-dropzone-uploader";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { actions as routerActions } from "core/router/reduxModule";

import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { IUICreateForm } from "./types";
import useStyles from "./styles";
import BackButton from "elementTypes/common/BackButton";
import { UploadZone } from "./components";
import { useCreateApp } from "queries/admin/appData";
import { useRoles } from "queries/admin";
import { getApiError } from "queries/utils";
import { useSnackbar } from "utils/hooks/useSnackbar";

import { useCommonStaticPagesTranslation } from "../../translation";

export const CreateAppsPage = memo(() => {
  const { root, footer, dividerFooter } = useStyles();
  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, isValid },
  } = useForm<IUICreateForm>({
    mode: "onChange",
    defaultValues: {
      role: "",
      name: "",
      file: null,
    },
  });

  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();
  const translation = useCommonStaticPagesTranslation();

  const rolesList = useRoles();

  const createApp = useCreateApp({
    onSuccess: () => {
      showSnackbar(translation.createAppMessage, "success");
      dispatch(routerActions.goBack());
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });

  const submit = async (data: IUICreateForm) => {
    if (data.file === null) {
      return;
    }
    const text = await data.file.text();
    createApp.mutate({
      name: data.name,
      role: data.role,
      description: data.description,
      definition: JSON.parse(text),
    });
  };

  const handleRefreshClick = () => rolesList.refetch();

  const items = useMemo(
    () =>
      rolesList.data?.map(({ name }) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      )),
    [rolesList.data],
  );

  if (!rolesList.data) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box display="flex" alignItems="center" gridGap={8}>
        <BackButton isIcon href="/admin/apps" />
        <Typography variant="h5">{translation.importAppTitle}</Typography>
      </Box>
      <Paper variant="outlined" className={root}>
        <Grid container spacing={1}>
          <Grid item={true} xs={12}>
            <TextField
              name="name"
              label="Application name"
              fullWidth={true}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              name="description"
              label="Description"
              fullWidth={true}
              inputRef={register({
                required: true,
              })}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Controller
              as={
                <TextField
                  select
                  label="Owner"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        size="small"
                        icon="refresh"
                        tooltip="Refresh"
                        onClick={handleRefreshClick}
                      />
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {items}
                </TextField>
              }
              name="role"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl fullWidth={true}>
              <FormLabel component="p">Definition</FormLabel>
              <Controller
                as={UploadZone}
                name="file"
                onChange={([fileWithMeta]: Array<IFileWithMeta | null>) => {
                  return fileWithMeta?.file;
                }}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className={footer}>
            <Divider className={dividerFooter} />
            <Button
              color="secondary"
              disabled={isSubmitting || !isValid}
              processing={isSubmitting}
              iconRight="forward"
              type="submit"
              label="Create"
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
});
