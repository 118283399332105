import React, { FC, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FormControl, Grid, ListItemText, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import FingerprintIcon from "@material-ui/icons/Fingerprint";

import { NodeData } from "./types";
import { sortColumns } from "./utils";
import { useErdTranslation } from "./translation";
import { Autocomplete } from "elementTypes/common/Autocomplete";

type Props = {
  nodeData: NodeData;
};

export const KeyLookup: FC<Props> = ({
  nodeData: { columns, lookupLabelColumn },
}) => {
  const translation = useErdTranslation();
  const { control, errors } = useFormContext();

  const items = useMemo(
    () =>
      sortColumns(columns).map((column) => ({
        value: column.name,
        label: column.name,
        column,
      })),
    [columns],
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* TODO translation 🙃 */}
          <Typography>
            Select the{" "}
            <Typography style={{ fontWeight: 700 }} component="span">
              Default Lookup column
            </Typography>{" "}
            that should be used in foreign key resolutions by default.
          </Typography>
          <Alert style={{ marginTop: 16 }} variant="outlined" severity="info">
            <AlertTitle>Recommendation</AlertTitle>
            <Typography>
              Use a{" "}
              <Typography style={{ fontWeight: 500 }} component="span">
                unique
              </Typography>{" "}
              and human readable{" "}
              <Typography style={{ fontWeight: 500 }} component="span">
                text
              </Typography>{" "}
              column.
            </Typography>
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Controller
              as={
                <Autocomplete
                  label={translation.keyLookupSelectColumn}
                  options={items}
                  customRenderOption={(option) => (
                    <ListItemText
                      primary={
                        <>
                          {option.label}
                          {option.column.unique && (
                            <FingerprintIcon fontSize="small" />
                          )}
                        </>
                      }
                      primaryTypographyProps={{
                        style: {
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                      secondary={option.column.type}
                      secondaryTypographyProps={{
                        style: {
                          color: "#999",
                        },
                      }}
                      style={
                        // display non-text columns lighter, so it's easier to select the correct column
                        option.column.type !== "text"
                          ? { color: "#777" }
                          : undefined
                      }
                    />
                  )}
                  variant="outlined"
                />
              }
              error={Boolean(errors.defaultLookup)}
              name="defaultLookup"
              control={control}
              defaultValue={
                !lookupLabelColumn ? items[0].value : lookupLabelColumn
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
