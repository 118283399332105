import { createSelector } from "reselect";
import { selectorScoper } from "core/utils/redux";
import { FieldPath, FormDataSourceImplementation } from "elementInterfaces";
import { AutocompleteInput } from "../../types";
import { IState } from "./types";

export function buildSelectors(
  path: string[],
  fieldPath: FieldPath,
  dataSource: FormDataSourceImplementation,
  element: AutocompleteInput,
) {
  const scopeSelector = selectorScoper<IState>(path);

  const { config } = element;
  const value = (state: any) =>
    dataSource.createFieldValueSelector(fieldPath, {
      defaultValue: null,
    })(state) as string | string[];

  const errors = (state: any) =>
    dataSource.createFieldErrorSelector(fieldPath)(state);

  const touched = (state: any) =>
    dataSource.createFieldTouchedSelector(fieldPath)(state);

  const { filter } = element.config;

  const fixedFilter = (state: any) => filter?.(state);

  const searchInputValue = (state: any) =>
    scopeSelector(state).searchInputValue;
  const loadingOptions = (state: any) => scopeSelector(state).loadingOptions;
  const allOptions = (state: any) => scopeSelector(state).options;
  const optionsError = (state: any) => scopeSelector(state).optionsError;
  const options = createSelector([allOptions, value], (all, val) =>
    all
      ? val
        ? config.isMulti
          ? all.filter((o) => !val?.includes(o))
          : all.filter((o) => o !== val)
        : all
      : [],
  );
  const rawOptions = (state: any) => scopeSelector(state).rawOptions;
  const valueObject = (state: any) => scopeSelector(state).valueObject;
  const disabled = () =>
    dataSource.isReadOnly || Boolean(element.config.disabled);

  const rawValueObject = createSelector(
    [rawOptions, value],
    (raw, currentValue) => {
      if (config.reference) {
        const arrayifiedCurrentValue = Array.isArray(currentValue)
          ? currentValue
          : [currentValue];
        const rawValueObjects = arrayifiedCurrentValue.map(
          (v) =>
            raw?.find((rawRow) => rawRow[config.reference?.columnName] === v) ??
            null,
        );
        return Array.isArray(currentValue)
          ? rawValueObjects
          : rawValueObjects[0];
      } else {
        return null;
      }
    },
  );

  const required = () =>
    dataSource.createFieldRequiredSelector(fieldPath) ??
    element.config.nullable === false;

  return {
    value,
    searchInputValue,
    options,
    loadingOptions,
    optionsError,
    errors,
    touched,
    valueObject,
    rawOptions,
    rawValueObject,
    fixedFilter,
    disabled,
    required,
  };
}

export type Selectors = ReturnType<typeof buildSelectors>;
