import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    cardHeight: {
      flex: "1 1 50%",
    },
    card: {
      display: "flex",
      flexDirection: "column",
    },
    cardHeader: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    cardHeaderAction: {
      marginBottom: -8,
    },
    treeStyle: {
      height: "100%",
      minHeight: "0",
      overflow: "auto",
    },

    row: {
      padding: theme.spacing(0.5, 2),

      "&$oddRow": { backgroundColor: theme.palette.grey[100] },

      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    oddRow: {},
    deleteRow: {
      justifyContent: "flex-end",
    },
    treeItemTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0.5, 0.5, 0, 0),
    },
    tableSizeTitle: {
      color: grey[500],
      fontStyle: "italic",
      fontWeight: 350,
      marginRight: theme.spacing(1),
    },
    tableCountTitle: {
      color: grey[800],
      fontStyle: "italic",
      fontWeight: 350,
      marginRight: theme.spacing(1),
    },
  }),
);
