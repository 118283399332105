import { createTypeSafeContext } from "utils/createTypeSafeContext";
import { ITableToolsContext } from "./types";

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  ITableToolsContext
>();

export const useTableToolsContext = useTypeSafeContext;
export const TableToolsProvider = Provider;
export const TableToolsConsumer = Consumer;
