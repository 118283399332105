import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";

import { getApiError } from "queries/utils";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { useWorkflowActivateTranslations } from "./translation";
import { QueryKeys, useWorkflowActivationSwitch } from "queries/admin";

export default function EnforceWorkflow({ value = false }: { value: boolean }) {
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();
  const location = useSelector(routerSelectors.location);
  const {
    activationSuccess,
    deactivationSuccess,
    activateLabel,
    helperText,
  } = useWorkflowActivateTranslations();
  const { mutate } = useWorkflowActivationSwitch({
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
    onSuccess: (_result, variables) => {
      queryClient.invalidateQueries(QueryKeys.fetchWorkflow);
      showSnackbar(
        variables.active ? activationSuccess : deactivationSuccess,
        "success",
      );
    },
  });

  const handleChange = (
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const {
      queries: { schema, table },
    } = location;
    mutate({ schema, table, active: checked });
  };

  return (
    <Tooltip title={helperText}>
      <FormControlLabel
        control={
          <Switch onChange={handleChange} checked={value} color="primary" />
        }
        label={activateLabel}
      />
    </Tooltip>
  );
}
