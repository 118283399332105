import { IDefaultElement } from "core";
import { FormTypes, Types, UntransformedFormConfig } from "../types";

export const defaultElement: IDefaultElement<UntransformedFormConfig> = {
  config: {
    dataSource: {
      viewName: "",
    },
    type: FormTypes.create,
    linkTo: { type: Types.stay },
  },
  children: {
    content: {
      elements: [],
    },
  },
};
