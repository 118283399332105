import React, { memo } from "react";

import Box, { BoxProps } from "@material-ui/core/Box";
import { Tooltip } from "@material-ui/core";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { NON_BREAKING_SPACE } from "../utils";

export enum Colors {
  primary = "primary.main",
  secondary = "secondary.main",
  success = "success.main",
  error = "error.main",
  warning = "warning.main",
  info = "info.main",
  default = "text.primary",
  lighter = "text.secondary",
  disabled = "text.disabled",
  white = "common.white",
  black = "common.black",
}

export enum ContrastColors {
  primary = "primary.contrastText",
  secondary = "secondary.contrastText",
  success = "success.contrastText",
  error = "error.contrastText",
  warning = "warning.contrastText",
  info = "info.contrastText",
  default = "background.paper",
  lighter = "background.paper",
  disabled = "background.paper",
  white = "common.black",
  black = "common.white",
}

export type Color = keyof typeof Colors;

export type IStyledTypography = {
  text: string | number | null;
  color?: Color | null;
  typographyProps?: TypographyProps;
  boxProps?: BoxProps;
  fitContent?: boolean;
  tooltip?: string;
};

export const StyledTypography = memo<IStyledTypography>(
  ({ color, text, fitContent, boxProps, typographyProps, tooltip }) => {
    const background = boxProps?.bgcolor;
    const borderColor = boxProps?.borderColor;

    const blockProps = {
      ...(!fitContent && {
        width: "100%",
        height: "100%",
      }),
      ...(borderColor && {
        borderColor: Colors[borderColor],
      }),
      ...(background && {
        bgcolor: Colors[background],
      }),
    };

    const typographyText = (
      <Typography
        component={background || borderColor ? "span" : "p"}
        {...typographyProps}
      >
        {text ?? NON_BREAKING_SPACE}
      </Typography>
    );

    const component = (
      <Box
        color={
          background && (!color || color === "default")
            ? ContrastColors[background]
            : Colors[color ?? borderColor ?? "default"]
        }
        display={fitContent ? "inline-flex" : "flex"}
        {...boxProps}
        {...blockProps}
        clone
      >
        {tooltip ? (
          <Tooltip title={tooltip}>{typographyText}</Tooltip>
        ) : (
          typographyText
        )}
      </Box>
    );
    return component;
  },
);
