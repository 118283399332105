import { createTypeSafeContext } from "utils/createTypeSafeContext";

import { IFilterField, UpdateParams } from "./types";

type IFilterContext = {
  fields: IFilterField[];
  addFilterGroup: (params: UpdateParams) => void;
  addFilterRule: (params: UpdateParams & { field: IFilterField }) => void;
  removeFilter: (params: UpdateParams) => void;
};

const { Provider, Consumer, useTypeSafeContext } = createTypeSafeContext<
  IFilterContext
>();

export const useFilterContext = useTypeSafeContext;
export const FilterProvider = Provider;
export const FilterConsumer = Consumer;
