import { Edge, Elements, Node } from "react-flow-renderer";
import { groupBy, prop, reduceBy, sortBy } from "ramda";

import {
  Column,
  Constraint,
  DataModel,
  EdgeData,
  NodeData,
  Table,
} from "./types";

const sortByPosition = sortBy(prop("position"));
const groupBySchema = groupBy((table: Table) => table.schema);
const groupNames = (acc: string[], { name }: { name: string }) =>
  acc.concat(name);

const toNode = (table: Table) =>
  ({
    id: `${table.schema}.${table.name}`,
    data: {
      tableName: table.name,
      schemaName: table.schema,
      columns: table.columns,
      lookupLabelColumn: table.alias,
      historyTrackerConfigured: table.historyTrackerConfigured,
    },
    type: "table",
    position: { x: 0, y: 0 },
  } as Node<NodeData>);

const toEdge = (c: Constraint) =>
  ({
    // id must be unique - constraint names are only unique scoped by table
    id: `${c.sourceSchema}.${c.sourceTable}-${c.name}`,
    data: { source_column: c.sourceColumn, target_column: c.targetColumn },
    source: `${c.sourceSchema}.${c.sourceTable}`,
    sourceHandle: `${c.sourceSchema}.${c.sourceTable}.${c.sourceColumn}`,
    target: `${c.targetSchema}.${c.targetTable}`,
    targetHandle: `${c.targetSchema}.${c.targetTable}.${c.targetColumn}`,
  } as Edge<EdgeData>);

export const modelToReactFlowElements = (model: DataModel) => {
  const MAX_NODES_QUANTITY = 300;
  const elements: Elements = [];

  model.tables.slice(0, MAX_NODES_QUANTITY).forEach((t: Table) => {
    elements.push(toNode(t));
  });
  model.constraints.forEach((c: Constraint) => {
    elements.push(toEdge(c));
  });

  return elements as Elements;
};

export const sortColumns = (columns: Column[]) => {
  const keysFirst = columns.map((c: Column, index: number) => ({
    ...c,
    position: c.primaryKey ? 0 : c.foreignKey ? 0.1 + index / 100 : c.position,
  }));

  return sortByPosition(keysFirst) as Column[];
};

export const tablesToEntitiesBySchema = (tables: Table[]) =>
  groupBySchema(tables);

export const tableNamesBySchema = (tables: Table[]) =>
  reduceBy(groupNames, [], prop("schema"), tables);
