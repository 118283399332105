import React, { memo } from "react";
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps,
} from "@material-ui/lab";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { Theme, makeStyles } from "@material-ui/core/styles";

type Props = {
  tooltip?: string;
  placement?: TooltipProps["placement"];
  fullWidth?: boolean;
} & ToggleButtonProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: ({ fullWidth }: { fullWidth?: boolean }) => (fullWidth ? 1 : "none"),
    color: theme.palette.primary.main,
    "&[class*='Mui-selected']": {
      color: theme.palette.primary.main,
    },
  },
}));

export const ToggleButton = memo<Props>(
  ({ tooltip, placement, fullWidth, ...rest }) => {
    const classes = useStyles({ fullWidth });
    const btn = <MuiToggleButton {...rest} classes={classes} />;

    return tooltip ? (
      <Tooltip title={tooltip} placement={placement}>
        {btn}
      </Tooltip>
    ) : (
      btn
    );
  },
);
