import React, { memo } from "react";

import { ConfigComponent, TranslationComponent } from "./components";
import { useCreateInitialGridElement } from "../../../utils/hooks/useCreateInitialElement";
import { DefaultModalDialogConfig } from "../types";

export const DefaultModalDialogEditor = memo(() => {
  useCreateInitialGridElement<DefaultModalDialogConfig>("container_grid");

  return (
    <>
      <ConfigComponent />
      <TranslationComponent />
    </>
  );
});
