import { format, startOfToday } from "date-fns";

export function toUpperCamelCase(s: string): string {
  const words = s.split(/\s|_|-/);
  let result = "";
  let word;
  for (word of words) {
    result += word.charAt(0).toUpperCase() + word.slice(1);
  }
  return result;
}

export function stringToDateObject(str: string | null): Date | null {
  if (str === null) {
    return null;
  }

  let value: Date | null;

  value = new Date(str);
  if (isNaN(value.getTime())) {
    // handle time value
    // time string is in format "HH:MM:SS"
    value = new Date(format(startOfToday(), "yyyy-MM-dd ") + str);
  }
  if (isNaN(value.getTime())) {
    value = null;
  }
  return value;
}

export function isStringExists(string?: string | null): boolean {
  return Boolean(string && !!string.trim().length);
}

export const capitalizeFirstLetter = (str: string) =>
  str.replace(/^\w/, (c: string) => c.toUpperCase());

export const allowedCharactersPattern = new RegExp("^([a-zA-Z0-9 _-]+)$");

export const isAlphaNumeric = (value: string): boolean =>
  allowedCharactersPattern.test(value);

export const isURL = (str: string) =>
  /^(?:\w+:)?\/\/([^\s]+\S{2}|localhost[?\d]*)\S*$/.test(str);

export const isImagePath = (str: string) => /\.(jpeg|jpg|svg|png)$/.test(str);

export const urlPathPattern = new RegExp(
  "^/(([a-zA-Z0-9]+[a-zA-Z0-9-_]+/)*[a-zA-Z0-9-_]*[a-zA-Z0-9])?$",
);

export const humanize = (str: string) => {
  const humanizedStr = str
    .replace(/-/g, " ")
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\s{2,}/g, " ")
    .trim();

  return capitalizeFirstLetter(humanizedStr);
};
