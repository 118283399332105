import get from "lodash/get";
import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import {
  ILocation,
  selectors as routerSelectors,
} from "core/router/reduxModule";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "core/session/reduxModule";
import { AllServices } from "core/buildStore";
import { State, StateChange } from "../context";
import { AdminStateChangeGraph } from "../types";
import { Actions, Selectors, Types } from "./types";
import { getServerError } from "../../../core/utils/api";

function buildEqFilter(fieldName: string, id: number) {
  return { and: `(${fieldName}.eq.${id})` };
}

export function buildSaga(
  actions: Actions,
  types: Types,
  element: AdminStateChangeGraph,
  selectors: Selectors,
) {
  const { dataSource } = element.config;

  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    const id: number = yield getId();

    const filter = buildEqFilter("object_id", id);

    yield call(
      loadData,
      services,
      token,
      filter,
      dataSource.stateChanges,
      "stateChanges",
    );
    yield call(loadData, services, token, filter, dataSource.states, "states");
    yield call(loadData, services, token, filter, dataSource.users, "users");
  }

  function* loadData(
    services: AllServices,
    token: string,
    filter: any,
    view: { viewName: string; identifier: string },
    viewType: string,
  ) {
    try {
      const data: Record<string, unknown> = yield call(
        services.api.loadViewData,
        token,
        view.viewName,
        {
          ...(view.viewName !== "users" && filter),
          offset: 0,
        },
      );
      yield put(actions.loadDataSuccess({ [viewType]: data }));
    } catch (error) {
      const msg = getServerError(error);
      yield put(actions.loadDataError(msg));
      yield put(
        sessionActions.enqueueSnackbar({
          message: msg,
          options: {
            variant: "error",
          },
        }),
      );
    }
  }

  // TODO: Add necessary types
  function* createSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    const bufferData: {
      network: Record<string, unknown>;
      type: string;
      callback: ((data: Record<string, unknown> | null) => void) | null;
    } | null = yield select(selectors.bufferData);
    const data: Record<string, unknown> & {
      stateChanges: any;
      states: any;
    } = yield select(selectors.data);

    if (bufferData === null) {
      return;
    }

    const { type, network, callback, ...newData } = bufferData;
    const viewName =
      type === "edge"
        ? dataSource.stateChanges.viewName
        : dataSource.states.viewName;
    const objectId: number = yield getId();

    try {
      const nextData: Record<string, unknown> = yield call(
        services.api.createViewData,
        token,
        viewName,
        {
          ...newData,
          object_id: objectId,
        },
      );
      if (type === "edge") {
        // Set addEdgeMode in vis.js to show changes
        (network as any).addEdgeMode();

        nextData.from = nextData.pre_state || -1;
        nextData.to = nextData.post_state || -1;
        yield put(
          actions.createDataSuccess({
            stateChanges: [...data.stateChanges, nextData],
          }),
        );
      } else {
        // Set addNodeMode in vis.js to show changes
        (network as any).addNodeMode();
        yield put(
          actions.createDataSuccess({ states: [...data.states, nextData] }),
        );
      }
      callback?.(nextData);
    } catch (error) {
      const msg = getServerError(error);
      yield put(actions.createDataError(msg));
      yield put(
        sessionActions.enqueueSnackbar({
          message: msg,
          options: {
            variant: "error",
          },
        }),
      );
    }
  }

  function* editSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    const bufferData: Record<string, unknown> & {
      callbackValues: Record<string, unknown>;
      callback: (data: Record<string, unknown> | null) => void | null;
    } = yield select(selectors.bufferData);
    const data: { states: State[]; stateChanges: StateChange[] } = yield select(
      selectors.data,
    );
    const {
      editableId,
      type,
      callbackValues,
      network,
      callback,
      ...newData
    } = bufferData;
    const viewName =
      type === "edge"
        ? dataSource.stateChanges.viewName
        : dataSource.states.viewName;
    const idName =
      type === "edge"
        ? dataSource.stateChanges.identifier
        : dataSource.states.identifier;
    const objectId: number = yield getId();

    try {
      const nextData: Record<string, unknown> = yield call(
        services.api.updateViewData,
        token,
        viewName,
        {
          ...newData,
          object_id: objectId,
        },
        idName,
        editableId,
      );

      if (type === "edge") {
        (network as any).editEdgeMode();

        const newStateChanges = data.stateChanges.map(
          (stateChange: StateChange) =>
            stateChange.id === nextData.id ? nextData : stateChange,
        );

        yield put(actions.editDataSuccess({ stateChanges: newStateChanges }));
      } else {
        const newStates = data.states.map((state: State) =>
          state.id === nextData.id ? nextData : state,
        );
        yield put(actions.editDataSuccess({ states: newStates }));
      }

      callback?.(callbackValues);
    } catch (error) {
      const msg = getServerError(error);
      yield put(actions.editDataError(msg));
      yield put(
        sessionActions.enqueueSnackbar({
          message: msg,
          options: {
            variant: "error",
          },
        }),
      );
    }
  }

  function* deleteSaga() {
    const { nodeData, callback } = yield select(selectors.bufferData);
    const { callbackValues, stateChanges, states } = yield getFilteredData(
      nodeData,
    );

    callback(callbackValues);
    yield put(actions.deleteDataSuccess({ stateChanges, states }));
  }

  // get data after deletion
  function* getFilteredData(nodeData: Record<string, any>) {
    const data: { states: State[]; stateChanges: StateChange[] } = yield select(
      selectors.data,
    );
    const { edges, nodes } = nodeData;
    let callbackValues = { ...nodeData };
    let stateChanges = [...data.stateChanges];
    let states = [...data.states];

    for (const edge of edges) {
      try {
        yield deleteItem({ value: edge, type: "edge" });
        stateChanges = stateChanges.filter(
          (stateChange: any) => stateChange.id !== edge,
        );
      } catch (error) {
        callbackValues = {
          ...callbackValues,
          edges: callbackValues.edges.filter((e: typeof edge) => e !== edge),
        };
        const msg = getServerError(error);
        yield put(actions.deleteDataError(msg));
        yield put(
          sessionActions.enqueueSnackbar({
            message: msg,
            options: {
              variant: "error",
            },
          }),
        );
      }
    }

    for (const node of nodes) {
      try {
        yield deleteItem({ value: node, type: "node" });
        states = states.filter((state: any) => state.id !== node);
      } catch (error) {
        callbackValues = {
          ...callbackValues,
          nodes: callbackValues.nodes.filter((n: typeof node) => n !== node),
        };
        const msg = getServerError(error);
        yield put(actions.deleteDataError(msg));
        yield put(
          sessionActions.enqueueSnackbar({
            message: msg,
            options: {
              variant: "error",
            },
          }),
        );
      }
    }

    return {
      callbackValues,
      stateChanges,
      states,
    };
  }

  function* deleteItem({
    type,
    value,
  }: {
    type: string;
    value: number | string;
  }) {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);
    const viewName =
      type === "edge"
        ? dataSource.stateChanges.viewName
        : dataSource.states.viewName;
    const id =
      type === "edge"
        ? dataSource.stateChanges.identifier
        : dataSource.states.identifier;

    yield call(services.api.deleteViewData, token, viewName, id, value);
  }

  function* getId() {
    const {
      config: { objectId },
    } = element;
    const router: ILocation = yield select(routerSelectors.location);
    return get(router, objectId, null);
  }

  return function* mainSaga() {
    yield all([
      takeLatest(types.LOAD_DATA, loadSaga),
      takeLatest(types.CREATE_DATA, createSaga),
      takeLatest(types.EDIT_DATA, editSaga),
      takeLatest(types.DELETE_DATA, deleteSaga),
    ]);

    yield put(actions.loadData());
  };
}
