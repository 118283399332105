import React, { ReactNode, lazy, memo, useCallback, useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import classNames from "classnames";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CodeIcon from "@material-ui/icons/Code";

import { useCustomExpression } from "utils/hooks";
import { withLazyLoading } from "elementTypes/helpers/HOC/LazyLoading";
import { CodeEditor } from "elementTypes/common/CodeEditor";
import { useElementTypesContext } from "core/ElementTypesContext";
import { selectors as routerSelectors } from "core/router/reduxModule";
import { InterfaceType, Type, types } from "core/runtime-typing";
import { objectToType } from "core/runtime-typing/utils";
import {
  IElementModel,
  SelectorTypes,
  TypeFactory,
  buildCustomExpressionValue,
  getCustomExpressionScopeLocation,
  getExpression,
} from "core/types";
import {
  findParentAndChildKey,
  getElementChildrenTypePropTypes,
} from "core/utils/element";
import { useElementEditorContext } from "../../EditorLayout";
import { selectors } from "../../reduxModule";
import { useStyles } from "./style";
import { useExpressionTranslation } from "./translation";
import { NonExpressionEditorProps } from "./types";
import { FormatButton } from "./components";

type Props = {
  value: string;
  config: Record<string, any>;
  onChange: (value: string) => void;
  children?: any;
  label?: string;
  labelTooltip?: string;
  nonExpressionEditor?: ({
    value,
    onChange,
  }: NonExpressionEditorProps) => ReactNode;
  switcherLabel?: string;
  switcherDisabled?: boolean;
  onToggleMode?: (wasExpression: boolean) => void;
  /**
   * hide the switcher
   */
  disableSwitcher?: boolean;
  /**
   * additional autocompletion scope
   */
  additionalScope?: Record<string, Type>;
};

const CustomExpressionDescription = withLazyLoading(
  lazy(() => import("../CustomExpressionDescription")),
  true,
);

const CustomExpressionEditor = memo<Props>(
  ({
    value,
    config,
    onChange,
    label,
    labelTooltip,
    nonExpressionEditor,
    switcherLabel,
    switcherDisabled,
    onToggleMode,
    disableSwitcher,
    additionalScope,
  }) => {
    const { toggleLabel } = useExpressionTranslation();
    const { root, codeClass, flexBox, toggleButton } = useStyles();

    const {
      value: valueExpression,
      onValueChange: onValueExpressionChange,
      toggleIsExpression,
      isExpression,
    } = useCustomExpression(value, onChange);

    const onCodeChange = useCallback(
      (_: any, __: any, newValue: string) => onValueExpressionChange(newValue),
      [onValueExpressionChange],
    );

    const handleToggleAdvancedMode = useCallback(() => {
      toggleIsExpression();
      onToggleMode?.(isExpression);
    }, [onToggleMode, isExpression, toggleIsExpression]);

    const { elementModel, type } = useElementEditorContext();

    const { getElementType } = useElementTypesContext();

    const location = useSelector(routerSelectors.location);
    const pageElement = useSelector(routerSelectors.pageElement)!;
    const updatedElements = useSelector(selectors.updatedElements);

    const state = useStore().getState();

    const scopeLocation = useMemo(
      () => getCustomExpressionScopeLocation(location),
      [location],
    );

    const { selectorTypes } = type;
    const selectorsAutocompleteType = useMemo(
      () =>
        getSelectorTypesType(
          selectorTypes || {},
          config,
          state,
          "The current element properties",
        ),
      [selectorTypes, config, state],
    );

    const elementsModels = getFlattenElements(
      pageElement,
      updatedElements,
      elementModel.id,
    );

    const parentModelAndChildKey = useMemo(
      () => findParentAndChildKey(elementModel, elementsModels),
      [elementModel, elementsModels],
    );

    const propsType: Type = useMemo(() => {
      let parentPropsType: Type | null = null;
      if (parentModelAndChildKey) {
        const [parentModel, childKey] = parentModelAndChildKey;
        const parentType = parentModelAndChildKey
          ? getElementType(parentModel)
          : null;
        if (parentType && parentType.childrenType) {
          parentPropsType = getElementChildrenTypePropTypes(
            parentType.childrenType,
            parentModel.config,
            state,
          )[childKey];
          if (parentPropsType) {
            parentPropsType = types.interface(
              (parentPropsType as InterfaceType).fields,
              `The properties passed down by parent element of type ${parentType.name}`,
            );
          }
        }
      }
      return (
        parentPropsType ||
        types.interface(
          {},
          "This object is empty because no parent object is passing any property",
        )
      );
    }, [parentModelAndChildKey, getElementType, state]);

    const elementsAutocompleteType = useMemo(
      () =>
        types.interface(
          elementsModels.reduce((acc, el) => {
            const { selectorTypes: selTypes } = getElementType(el);
            return selTypes
              ? {
                  ...acc,
                  [el.id]: getSelectorTypesType(selTypes, el.config, state),
                }
              : acc;
          }, {}),
          "All elements with properties on the current page",
        ),
      [elementsModels, getElementType, state],
    );

    const elementType = getElementType(elementModel);
    const i18nType = useMemo(
      () =>
        // record type doesn't work with autocompletion
        types.interface(
          elementType.translationKeys?.reduce(
            (acc, next) => ({ ...acc, [next]: types.string() }),
            {},
          ) ?? {},
          "Translated texts in the current language",
        ),
      [elementType],
    );

    const locationType = useMemo(
      () =>
        types.interface(
          {
            pathname: types.string("Current URL without queries"),
            queries: objectToType(
              scopeLocation.queries,
              "Arguments passed to the page",
            ),
          },
          "Information about the current URL",
        ),
      [scopeLocation.queries],
    );

    const globalScope = useMemo(
      () =>
        types.interface({
          ...additionalScope,
          i18n: i18nType,
          location: locationType,
          element: selectorsAutocompleteType,
          elements: elementsAutocompleteType,
          props: propsType,
        }),
      [
        i18nType,
        locationType,
        selectorsAutocompleteType,
        elementsAutocompleteType,
        propsType,
        additionalScope,
      ],
    );

    const codeEditorOptions = useMemo(
      () => ({
        hintOptions: {
          globalScope,
          // do not automatically complete if only 1 option is available
          completeSingle: false,
        } as any,
      }),
      [globalScope],
    );

    const editor = (
      <CodeEditor
        className={codeClass}
        value={valueExpression}
        onChange={onCodeChange}
        language="javascript"
        onAutoComplete="autocomplete"
        options={codeEditorOptions}
      />
    );

    let labelChild = label && (
      <Typography variant="subtitle2">{label}</Typography>
    );
    if (labelChild && labelTooltip) {
      labelChild = (
        <Tooltip placement="left" arrow title={labelTooltip}>
          {labelChild}
        </Tooltip>
      );
    }

    const btn = (
      <ToggleButton
        aria-label="expression"
        selected={isExpression}
        onChange={handleToggleAdvancedMode}
        disabled={Boolean(switcherDisabled)}
        className={toggleButton}
        size="small"
        value={isExpression}
      >
        <CodeIcon />
      </ToggleButton>
    );

    return (
      <>
        {!disableSwitcher ? (
          <Grid container justifyContent="space-between" alignItems="center">
            <FormLabel>{switcherLabel ?? label}</FormLabel>
            {switcherDisabled ? (
              btn
            ) : (
              <Tooltip title={toggleLabel}>{btn}</Tooltip>
            )}
          </Grid>
        ) : (
          labelChild
        )}
        <div
          className={classNames(root, {
            [flexBox]: isExpression,
          })}
        >
          {isExpression && !switcherDisabled && (
            <>
              {editor}
              <ButtonGroup orientation="vertical">
                <CustomExpressionDescription />
                <FormatButton
                  value={valueExpression}
                  onChange={onValueExpressionChange}
                />
              </ButtonGroup>
            </>
          )}
          {nonExpressionEditor &&
            !isExpression &&
            nonExpressionEditor({
              value: valueExpression,
              onChange: onValueExpressionChange,
            })}
        </div>
      </>
    );
  },
);

const getFlattenElements = (
  element: IElementModel,
  updatedElements: Record<string, IElementModel>,
  omit: string,
) => {
  const elements: IElementModel[] = [];
  collectElements(element, elements, updatedElements, omit);
  return elements;
};

const collectElements = (
  element: IElementModel,
  elements: IElementModel[],
  updatedElements: Record<string, IElementModel>,
  omit: string,
) => {
  const updatedElement = updatedElements[element.id] || element;
  if (element.id !== omit) {
    elements.push(updatedElement);
  }
  for (const key of Object.keys(updatedElement.children)) {
    const child = updatedElement.children[key];
    for (const childElement of child.elements || [child.element]) {
      collectElements(childElement, elements, updatedElements, omit);
    }
  }
};

const getSelectorTypesType = (
  selectorTypes: SelectorTypes,
  config: Record<string, any>,
  state: any,
  description?: string,
) =>
  types.interface(
    Object.keys(selectorTypes).reduce(
      (acc, k) => ({ ...acc, [k]: getType(selectorTypes[k], config, state) }),
      {},
    ),
    description,
  );

const getType = (
  typeOrFactory: Type | TypeFactory,
  config: IElementModel["config"],
  state: any,
) =>
  typeof typeOrFactory === "function"
    ? typeOrFactory({ config, state })
    : typeOrFactory;

CustomExpressionEditor.displayName = "CustomExpressionEditor";

export default CustomExpressionEditor;

/**
 * should be used with the `CustomExpressionEditor` `onToggleMode` callback
 *
 * this handler implements simple graceful fallback based on an array of allowed
 * values
 *
 * @example
 * <CustomExpressionEditor
 *   label={label}
 *   value={config.color}
 *   config={config}
 *   onChange={onChange}
 *   nonExpressionEditor={customColorSelect}
 *   onToggleMode={allowedValuesToggleModeHandler(colors)(
 *     config.color,
 *     onChange,
 *   )}
 * />

 */
export function allowedValuesToggleModeHandler(allowedValues: string[]) {
  return (
    /**
     * the actual value
     */
    expressionValue: string | null,
    onChange: (value: string) => void,
    /**
     * used as initial value or if unable to extract valid value
     */
    fallbackValue?: string | null,
  ) => {
    return (wasExpression: boolean) => {
      if (!wasExpression) {
        onChange(
          buildCustomExpressionValue(
            expressionValue || fallbackValue
              ? `"${expressionValue ?? fallbackValue}"`
              : // if the value is falsy, just write it as literal
                fallbackValue + "",
          ),
        );
      } else {
        if (!expressionValue) {
          if (fallbackValue) {
            onChange(fallbackValue);
          }
        } else {
          const value = getExpression(expressionValue);
          const match = value.match(
            new RegExp(`^"(${allowedValues.join("|")})"`),
          );
          if (match && match.length >= 2) {
            // match[1] is the regex group match
            const color = match[1];
            onChange(color);
          } else if (fallbackValue) {
            onChange(fallbackValue);
          }
        }
      }
    };
  };
}
