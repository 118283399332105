import React, { memo } from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { MuiIcon } from "elementTypes/common/MuiIcon";

type UserInfo = {
  label: string;
  icon: string;
  value: string;
};

export const UserInfo = memo<UserInfo>(({ label, icon, value }) => {
  return (
    <Box py={1}>
      <FormControl fullWidth variant="outlined">
        <InputLabel>{label}</InputLabel>

        <OutlinedInput
          value={value}
          labelWidth={label.length * 10}
          disabled
          startAdornment={
            <InputAdornment position="start">
              <MuiIcon icon={icon} fontSize="medium" />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
});
