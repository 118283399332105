import { IStaticRouteConfig } from "core/router/types";

import {
  AppsPageRoute,
  AuditsPageRoute,
  DatabasePageRoute,
  FilesPageRoute,
  LdapPageRoute,
  RolesPageRoute,
  SettingsPageRoute,
  UsersPageRoute,
} from "./pages";
import { AdminPage } from "./pages/home";

export const route: IStaticRouteConfig = {
  Component: AdminPage,
  auth: true,
  isAdmin: true,
  routes: {
    "/admin/roles": RolesPageRoute,
    "/admin/database": DatabasePageRoute,
    "/admin/apps": AppsPageRoute,
    "/admin/users": UsersPageRoute,
    "/admin/ldap": LdapPageRoute,
    "/admin/settings": SettingsPageRoute,
    "/admin/audits": AuditsPageRoute,
    "/admin/files": FilesPageRoute,
  },
};
