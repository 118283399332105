import React, { memo, useCallback, useMemo, useState } from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { GridCellEditCommitParams } from "@material-ui/data-grid";
import deepEqual from "fast-deep-equal";
import { assocPath, remove } from "ramda";

import Button from "elementTypes/common/Button";
import DialogWrapper from "elementTypes/helpers/HOC/DialogWrapper";
import { LoadingComponent } from "layouts/common/Loading";
import useStyles from "../../styles";
import {
  useDeleteLDAP,
  useLDAP,
  useRoles,
  useSaveLDAP,
} from "../../../../queries/admin";
import { getRoleOptions } from "queries/admin/utils";
import { getApiError } from "queries/utils";
import { useSnackbar } from "../../../../utils/hooks/useSnackbar";
import { useComponentStyles } from "./styles";
import { LDAPData, ValidationModel, Value } from "./types";
import { getErrors, objectHasValue } from "./utils";
import { useCommonStaticPagesTranslation } from "./translation";
import { LDAPProvider } from "./context";
import { FormLDAP, RoleMappingTable, Warning } from "./components";

const INITIAL_CREATING_DATA: LDAPData = {
  ldapConfiguration: {
    url: "",
    searchAttribute: "",
    baseDn: "",
    bindDn: "",
    bindPassword: "",
    roleAttribute: "",
    defaultRole: null,
    roleMapping: false,
  },
  roleMappings: [],
};

export const LdapPageComponent = memo(() => {
  const { horizontallyCenter } = useStyles();
  const { dialogButtons } = useComponentStyles();
  const translation = useCommonStaticPagesTranslation();
  const showSnackbar = useSnackbar();

  // local state for the config and errors
  const [dataConfig, setDataConfig] = useState<LDAPData | null>(null);

  const getValidationErrors = getErrors(translation);
  const [validationModel, setValidationModel] = useState<ValidationModel>({
    ldapConfiguration: {},
    roleMappings: {},
  });

  const handleSetDataConfig = (nextData: LDAPData | null) => {
    setDataConfig(nextData);
    setValidationModel(
      nextData
        ? getValidationErrors(
            { ldapConfiguration: {}, roleMappings: {} },
            ["ldapConfiguration"],
            nextData.ldapConfiguration,
            nextData.roleMappings,
          )
        : { ldapConfiguration: {}, roleMappings: {} },
    );
  };

  // get server data
  const ldapData = useLDAP({
    onSuccess: (fetchedConfig) => {
      handleSetDataConfig(fetchedConfig);
    },
  });
  const { data: roleOptions } = useRoles({
    select: getRoleOptions,
  });

  // local state
  const roleMappings = useMemo(() => dataConfig?.roleMappings ?? [], [
    dataConfig,
  ]);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState(false);
  const toggleDialog = () => setOpenDialog((prevOpen) => !prevOpen);

  // update server data
  const deleteLDAP = useDeleteLDAP({
    onSuccess: () => {
      handleSetDataConfig(null);
      showSnackbar(translation.deletedMessage, "success");
    },
    onError: (error) => {
      const msg = getApiError(error);
      showSnackbar(msg, "error");
    },
  });
  const updateLDAP = useSaveLDAP({
    onSuccess: async (nextConfig) => {
      handleSetDataConfig(nextConfig);
      showSnackbar(translation.saveSuccessMessage, "success");

      await ldapData.refetch();
    },
    onError: (error) => {
      const msg = getApiError(error);
      const errorDetails =
        `${(error as Record<"errors", Record<string, any>[]>)?.errors?.map(
          (err: Record<string, any>) => {
            return err?.children?.map(
              (fieldError: Record<string, Record<string, unknown>>) => {
                return Object.values(fieldError?.constraints ?? {}).toString();
              },
            );
          },
        )}` ?? "";

      showSnackbar(
        `${translation.saveErrorMessage} ${msg} ${errorDetails}`,
        "error",
      );
    },
  });

  const setInitialData = () =>
    handleSetDataConfig(ldapData.data ?? INITIAL_CREATING_DATA);

  const initializeConfig = () => handleSetDataConfig(INITIAL_CREATING_DATA);

  const handleChange = useCallback(
    (path: string[], value: Value) => {
      setDataConfig((prevValue) => {
        const nextState = assocPath(path, value, prevValue);
        const errors = getValidationErrors(
          validationModel,
          path,
          value,
          nextState?.roleMappings ?? [],
        );

        setValidationModel(errors);
        return nextState;
      });
    },
    [validationModel, getValidationErrors],
  );

  const handleSubmit = () => {
    if (
      objectHasValue(validationModel.roleMappings) ||
      objectHasValue(validationModel.ldapConfiguration)
    ) {
      setSubmitted(true);
    } else {
      if (dataConfig) {
        updateLDAP.mutate(dataConfig);
        setSubmitted(false);
      }
    }
  };

  const handleDeleteConfig = () => {
    toggleDialog();
    deleteLDAP.mutate({});
  };

  const defaultPostgresRole = roleOptions?.[0]?.value ?? "";

  const handleAddRow = useCallback(() => {
    handleChange(
      ["roleMappings"],
      [...roleMappings, { ldapRole: "", postgresRole: defaultPostgresRole }],
    );
  }, [roleMappings, defaultPostgresRole, handleChange]);

  const handleDeleteRow = useCallback(
    (id: string | number) => () =>
      handleChange(
        ["roleMappings"],
        remove(id as number, 1, roleMappings ?? []),
      ),
    [roleMappings, handleChange],
  );

  const handleCommitCellChange = useCallback(
    (params: GridCellEditCommitParams) => {
      handleChange(
        ["roleMappings"],
        roleMappings.map((row, index) =>
          index === params.id ? { ...row, [params.field]: params.value } : row,
        ),
      );
    },
    [roleMappings, handleChange],
  );

  const deleteDialog = (
    <DialogWrapper
      keepMounted={false}
      open={openDialog}
      title={translation.dialogTitle}
      submitTitle={translation.DeleteDialogButton}
      cancelTitle={translation.CancelButton}
      handleClose={toggleDialog}
      handleSubmit={handleDeleteConfig}
      fullWidth={false}
      maxWidth="sm"
      actionsClass={dialogButtons}
    >
      <Typography variant="h6">{translation.dialogContent}</Typography>
    </DialogWrapper>
  );

  const formHasChanges = !deepEqual(ldapData.data, dataConfig);

  const formActions = (
    <Box
      display="grid"
      gridGap={8}
      gridTemplateColumns="max-content 1fr repeat(2, max-content)"
      gridAutoFlow="column"
      mt={2}
    >
      <Button
        data-test-id="saveButtonTest"
        color="primary"
        label={translation.SaveButton}
        onClick={handleSubmit}
        disabled={!formHasChanges}
      />
      <div>{deleteDialog}</div>
      <Button
        data-test-id="deleteButtonTest"
        color="secondary"
        label={translation.DeleteButton}
        iconLeft="delete_outlined"
        onClick={toggleDialog}
      />
      <Button
        color="secondary"
        label={translation.CancelButton}
        onClick={setInitialData}
      />
    </Box>
  );

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">{translation.ldapTitle}</Typography>
      </Grid>
      <Grid item xs={12}>
        {ldapData.isLoading && <LoadingComponent />}
        {!ldapData.isLoading && ldapData.isFetchedAfterMount && !dataConfig && (
          <Warning initializeConfig={initializeConfig} />
        )}
        {dataConfig && (
          <LDAPProvider
            value={{
              ...dataConfig,
              roleOptions,
              ...(submitted && {
                errors: validationModel,
              }),
              onChange: handleChange,
              onRowAdd: handleAddRow,
              onRowDelete: handleDeleteRow,
              onCommitChange: handleCommitCellChange,
            }}
          >
            <Paper variant="outlined">
              <Box p={2}>
                <FormLDAP formActions={formActions}>
                  <RoleMappingTable />
                </FormLDAP>
              </Box>
            </Paper>
          </LDAPProvider>
        )}
      </Grid>
    </Grid>
  );
});
