import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { DEFAULT_APP_URL, actions } from "../../core/router/reduxModule";

export const useRoute = () => {
  const dispatch = useDispatch();

  return useCallback(
    (type?: "push" | "replace", path?: string) => {
      const url = path ?? DEFAULT_APP_URL;
      const actionType = type ?? "goBack";

      dispatch(actions[actionType](url));
    },
    [dispatch],
  );
};
