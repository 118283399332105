import React, { memo, useCallback, useMemo } from "react";
import { actions as sessionActions } from "core/session/reduxModule";
import { useDispatch } from "react-redux";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { formatDistanceToNow, formatRelative } from "date-fns";
import { exportToJsonFile } from "utils/json";

import Button from "elementTypes/common/Button";
import IconButton from "elementTypes/common/IconButton";
import { IAdminUi, IUi } from "core";
import useStyles from "../../styles";
import { Table, TableRow } from "../../common";
import { useTranslation } from "core/session";
import { Align } from "../../common/components/Table";
import { useApps } from "queries/admin";
import { useDeleteApp, useExportApp, useUIList } from "queries/admin/appData";
import { useSnackbar } from "utils/hooks/useSnackbar";
import { useCommonStaticPagesTranslation } from "./translation";
import { getApiError as getError } from "../../../../queries/utils";

const tableConfig: Array<{ align: Align; title: string; name: string }> = ([
  { title: "Name", align: "left" },
  { title: "Title", align: "left" },
  { title: "Description", align: "left" },
  { title: "Modified", align: "left" },
  { title: "Owner", align: "left" },
  { title: "Published release", align: "center" },
  { title: "Actions", align: "center" },
] as const).map((config) => ({ ...config, name: config.title.toLowerCase() }));

type Row = {
  ui: IAdminUi;
  handleClick: (props: IAdminUi) => void;
  handleDelete: (uiName: string) => void;
  handleExport: (uiName: string) => void;
};
const Row = memo<Row>(({ ui, handleClick, handleDelete, handleExport }) => {
  const {
    id,
    name,
    description,
    lastUpdated,
    role,
    i18nPublished,
    publishedRelease,
    i18nLatest,
  } = ui;

  const translation = useCommonStaticPagesTranslation();

  const onClick = () => handleClick(ui);
  const onDelete = () => handleDelete(name);
  const onExport = () => handleExport(name);

  const publishedTranslated = useTranslation(i18nPublished);
  const latestTranslated = useTranslation(i18nLatest);

  const publishedLabel = publishedTranslated.label;
  const latestLabel = latestTranslated.label;

  return (
    <TableRow rowId={id}>
      <Typography component="span">{name}</Typography>

      <Typography component="span">
        {publishedLabel}
        {publishedLabel !== latestLabel ? (
          <Tooltip title={"Unpublished"} arrow>
            <Box
              component="span"
              color="text.disabled"
              fontWeight="fontWeightBold"
              fontSize={12}
              marginLeft={1}
            >
              {latestLabel}{" "}
            </Box>
          </Tooltip>
        ) : null}
      </Typography>

      <Typography>{description}</Typography>

      <Tooltip title={formatRelative(new Date(lastUpdated), new Date())}>
        <Typography component="span">
          {formatDistanceToNow(new Date(lastUpdated), {
            addSuffix: true,
          })}
        </Typography>
      </Tooltip>

      <Typography>{role}</Typography>

      <Typography>{publishedRelease.versionName}</Typography>

      <Box display="flex" alignItems="center" justifyContent="center">
        <IconButton
          tooltip={translation.editTooltip}
          icon="create"
          color="primary"
          data-testid="admin-apps-action-edit"
          href={`/admin/apps/edit/${name}`}
        />
        <IconButton
          tooltip={translation.startTooltip}
          icon="slideshow"
          color="primary"
          data-testid="admin-apps-action-start"
          onClick={onClick}
        />
        <IconButton
          tooltip={translation.exportTooltip}
          icon="cloud_download"
          onClick={onExport}
          color="primary"
          data-testid="admin-apps-action-export"
        />
        <IconButton
          tooltip={translation.deleteTooltip}
          icon="delete_outline"
          onClick={onDelete}
          color="error.main"
          data-testid="admin-apps-action-delete"
        />
      </Box>
    </TableRow>
  );
});

export const AppsPage = memo(() => {
  const dispatch = useDispatch();
  const translation = useCommonStaticPagesTranslation();

  const showSnackbar = useSnackbar();

  const ui = useUIList();
  const uiList = useMemo(() => (ui.data ?? []) as IUi[], [ui.data]);

  const apps = useApps();
  const appList = useMemo(() => (apps.data ?? []) as IAdminUi[], [apps.data]);

  const deleteUi = useDeleteApp({
    onSuccess: () => {
      apps.refetch();
      showSnackbar(translation.deletedMessage, "success");
    },
    onError: (error) => {
      const msg = getError(error);
      showSnackbar(msg, "error");
    },
  });

  const exportUi = useExportApp({
    onSuccess: (data, params) => {
      exportToJsonFile(data.release.definition, params.uiName + ".json");
      showSnackbar(translation.exportAppMessage, "success");
    },
    onError: (error) => {
      const msg = getError(error);
      showSnackbar(msg, "error");
    },
  });

  const {
    horizontallyCenter,
    inlineAndEndFlex,
    applicationsHeaderButton,
  } = useStyles();

  const handleAppClick = useCallback(
    (app: IAdminUi) => {
      const nextUi = uiList.find((uiItem) => uiItem.id === app.id);
      if (nextUi) {
        dispatch(sessionActions.changeUi(nextUi));
      }
    },
    [dispatch, uiList],
  );
  const handleDeleteApp = useCallback(
    (uiName: string) => deleteUi.mutate({ uiName }),
    [deleteUi],
  );

  const handleDataReload = () => {
    apps.refetch();
  };
  const handleExportJsonApp = useCallback(
    (uiName: string) => {
      exportUi.mutate({ uiName });
    },
    [exportUi],
  );

  const rows = useMemo(
    () =>
      appList?.map((app) => (
        <Row
          key={app.id}
          ui={app}
          handleClick={handleAppClick}
          handleDelete={handleDeleteApp}
          handleExport={handleExportJsonApp}
        />
      )),
    [appList, handleAppClick, handleDeleteApp, handleExportJsonApp],
  );

  return (
    <Grid container spacing={2} className={horizontallyCenter}>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5">{translation.appTitle}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={inlineAndEndFlex}>
        <Button
          color="primary"
          href="/admin/apps/generate"
          iconLeft="add"
          label={translation.generateLabel}
          className={applicationsHeaderButton}
        />
        <Button
          color="primary"
          href="/admin/apps/create"
          iconLeft="cloud_upload"
          label={translation.createLabel}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          alignment={tableConfig}
          rows={rows}
          headers={tableConfig}
          onDataReload={handleDataReload}
          loading={apps.isLoading}
          error={apps.error?.toString()}
        />
      </Grid>
    </Grid>
  );
});
