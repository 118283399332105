import React, { FC, memo, useMemo } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";

import { Autocomplete } from "../../../../../elementTypes/common/Autocomplete";
import { useErdTranslation } from "./translation";
import { Column, NodeData } from "./types";
import { sortColumns } from "./utils";
import { WorkflowSetupColumns } from "./workflowSetupColumns";

type WorkFlowProps = {
  nodeData: NodeData;
};

const WorkflowSetup: FC<WorkFlowProps> = ({
  nodeData: { columns, schemaName, tableName },
}) => {
  const { control, watch } = useFormContext();
  const {
    transitionsToAllLabel,
    workflowNone,
    workflowSelectColumn,
  } = useErdTranslation();

  const selectedColumn = watch("stateColumn") ?? "";

  const options = useMemo(
    () => [
      { label: workflowNone, value: "" },
      ...sortColumns(columns).map((column: Column) => ({
        label: column.name,
        value: column.name,
      })),
    ],
    [columns, workflowNone],
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Controller
              as={
                <Autocomplete label={workflowSelectColumn} options={options} />
              }
              name="stateColumn"
              control={control}
              defaultValue=""
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <WorkflowSetupColumns
            schema={schemaName}
            table={tableName}
            column={selectedColumn}
          />
        </Grid>
      </Grid>
      <Box py={1}>
        <FormControl>
          <Controller
            as={
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={transitionsToAllLabel}
              />
            }
            name="transitionToAll"
            control={control}
            defaultValue={false}
          />
        </FormControl>
      </Box>
    </>
  );
};

export default memo(WorkflowSetup);
