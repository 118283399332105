import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    toggleButtons: {
      width: "100%",
      marginBottom: theme.spacing(),
      "& > button": {
        flex: 1,
      },
    },
  }),
);

export default useStyles;
