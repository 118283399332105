import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { ReactReduxContext, useSelector } from "react-redux";
import { useIsMounted } from "../utils/hooks";
import { ExtendedStore } from "./buildStore";
import { EditableElementWrapper } from "./editor";
import { selectors as editorSelectors } from "./editor/reduxModule";
import { useElementTypesContext } from "./ElementTypesContext";

import ReduxModuleContext, {
  useReduxModuleContext,
} from "./ReduxModuleContext";
import { useSessionContext } from "./session";
import {
  GetModule,
  IElement,
  IElementModel,
  IElementProps,
  IElementType,
  IReduxModule,
  IReduxModuleContext,
} from "./types";
import { ElementWithError, getTranslatedTexts } from "./utils/element";
import { shallowEqual } from "./utils/shallowEqual";

import { ErrorBoundary } from "react-error-boundary";

interface IProps {
  [name: string]: any;
  element: IElementModel;
  elementProps?: IElementProps;
  dynamic?: boolean;
}

interface IState {
  element: IElement | null;
  elementModel: IElementModel | null;
  elementProps: IElementProps;
  unmount: (() => void) | null;
  module: IReduxModule | null;
  childrenReduxModuleContext: IReduxModuleContext | null;
  getModule: GetModule | null;
  elementType: IElementType | null;
  error: any;
}

type UpdateElementAction = {
  type: "updateElement";
  payload: {
    element: IElement;
    elementProps: IElementProps;
    elementModel: IElementModel;
    unmount: () => void;
    module: IReduxModule | null;
    childrenReduxModuleContext: IReduxModuleContext;
    getModule: GetModule;
    elementType: IElementType;
  };
};

type ErrorAction = {
  type: "error";
  payload: {
    error: any;
    elementModel: IElementModel;
    elementProps: IElementProps;
    elementType: IElementType | null;
  };
};

type Action = UpdateElementAction | ErrorAction;

const initialState: IState = {
  elementModel: null,
  element: null,
  elementProps: {},
  unmount: null,
  module: null,
  childrenReduxModuleContext: null,
  getModule: null,
  elementType: null,
  error: null,
};

function reducer(state: IState, action: Action): IState {
  switch (action.type) {
    case "updateElement":
      return {
        ...state,
        ...action.payload,
        error: null,
      };
    case "error":
      return {
        ...state,
        error: action.payload.error,
        elementModel: action.payload.elementModel,
        elementProps: action.payload.elementProps,
        elementType: action.payload.elementType,
        unmount: null,
      };
    default:
      throw new Error();
  }
}

const Element: FC<IProps> = ({
  elementProps = {},
  element: elementModel,
  dynamic = false,
  ...props
}) => {
  /**
   * TODO:
   * Optimize selector to only select the element, either by passing a wrapped selector
   * `(state) => editorSelectors.updatedElements(state)[id]` or by passing a custom compare
   * function (see react-redux documentation to check for caveats)
   */
  const updatedElements = useSelector(editorSelectors.updatedElements);
  const updatedElementModel = updatedElements[elementModel.id] || elementModel;

  const isMounted = useIsMounted();

  const reduxModuleContext = useReduxModuleContext();
  const { getElementType } = useElementTypesContext();
  const {
    store: { reducerManager },
  } = useContext<{ store: ExtendedStore }>(ReactReduxContext as any);

  const { language } = useSessionContext();

  const [
    {
      element,
      module,
      unmount,
      elementType,
      getModule,
      childrenReduxModuleContext,
      error,
      elementModel: prevElementModel,
      elementProps: prevElementProps,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const translatedTexts = useMemo(
    () => (element ? getTranslatedTexts(language, element.i18n) : {}),
    [element, language],
  );

  useEffect(() => {
    if (
      updatedElementModel !== prevElementModel ||
      !shallowEqual(elementProps, prevElementProps)
    ) {
      if (unmount) {
        unmount();
      }

      let mounted: ReturnType<typeof reducerManager.mountElement> | null = null;
      let nextElementType: IElementType | null = null;
      try {
        nextElementType = getElementType(updatedElementModel);
        mounted = reducerManager.mountElement(
          updatedElementModel,
          reduxModuleContext,
          elementProps,
          dynamic,
        );
      } catch (err) {
        // catches redux-saga bootstrapping (root saga) errors
        // does NOT catch redux-saga errors
        dispatch({
          type: "error",
          payload: {
            elementProps,
            error: err,
            elementModel: updatedElementModel,
            elementType: nextElementType,
          },
        });
      }

      if (mounted && nextElementType) {
        dispatch({
          type: "updateElement",
          payload: {
            elementProps,
            elementModel: updatedElementModel,
            elementType: nextElementType,
            element: mounted.element,
            module: mounted.module,
            unmount: mounted.unmount,
            childrenReduxModuleContext: mounted.childrenReduxModuleContext,
            getModule: mounted.getModule,
          },
        });
      }
    }

    return () => {
      if (!isMounted() && unmount) {
        unmount();
      }
    };
  }, [
    elementProps,
    updatedElementModel,
    getElementType,
    prevElementProps,
    prevElementModel,
    reducerManager,
    reduxModuleContext,
    unmount,
    error,
    isMounted,
    dynamic,
  ]);

  let elementComponent: JSX.Element;

  if (error) {
    elementComponent = (
      <ElementWithError error={error.message ?? error.toString()} />
    );
  } else if (
    !element ||
    !getModule ||
    !childrenReduxModuleContext ||
    !elementType
  ) {
    return null;
  } else {
    const { component: Component } = elementType;

    Component.displayName = "ElementComponent";

    const elementTranslated = {
      ...element,
      i18n: translatedTexts,
    };

    elementComponent = (
      // catches react errors
      <ErrorBoundary
        fallbackRender={(p) => (
          <ElementWithError error={p.error?.message ?? error.toString()} />
        )}
        resetKeys={[elementModel, elementProps]}
      >
        <Component
          element={elementTranslated}
          elementModel={elementModel}
          module={module}
          getReduxModule={getModule}
          reduxContext={reduxModuleContext.context}
          {...props}
        />
      </ErrorBoundary>
    );
  }

  if (elementType && elementType.editorComponent) {
    elementComponent = (
      <EditableElementWrapper
        elementModel={elementModel}
        elementType={elementType}
      >
        {elementComponent}
      </EditableElementWrapper>
    );
  }

  if (childrenReduxModuleContext) {
    return (
      <ReduxModuleContext.Provider value={childrenReduxModuleContext}>
        {elementComponent}
      </ReduxModuleContext.Provider>
    );
  } else {
    return elementComponent;
  }
};

Element.displayName = "ElementWrapper";

const MemoizedElement = memo(Element);

export default MemoizedElement;
