import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { AllServices } from "core/buildStore";
import { selectors as sessionSelectors } from "core/session/reduxModule";
import { AdvancedGoogleMaps, IMarker } from "../types";
import { Actions, Types } from "./types";
import { getServerError } from "../../../core/utils/api";

export function buildSaga(
  actions: Actions,
  types: Types,
  element: AdvancedGoogleMaps,
) {
  function* loadSaga() {
    const services: AllServices = yield getContext("services");
    const token: string = yield select(sessionSelectors.token);

    const { dataSource } = element.config;

    try {
      const data: IMarker[] | null = yield call(
        services.api.loadViewData,
        token,
        dataSource.viewName,
      );

      yield put(actions.loadSuccess(Array.isArray(data) ? data : []));
    } catch (error) {
      yield put(actions.loadError(getServerError(error)));
    }
  }

  return function* mainSaga() {
    yield all([takeLatest(types.LOAD, loadSaga)]);

    yield put(actions.load());
  };
}
