import React, { memo, useCallback, useMemo, useState } from "react";
import { Box, Tooltip, Typography } from "@material-ui/core";
import {
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import clsx from "classnames";

import IconButton from "../../../../../elementTypes/common/IconButton";
import { getCustomColumnProps } from "../../../../../elementTypes/common/DataGridTable/utils";
import {
  DataGridTable,
  useStyles,
} from "../../../../../elementTypes/common/DataGridTable";
import { BaseAutocomplete } from "../../../../../elementTypes/default_autocomplete_input/components";
import { GeneralType } from "../../../../../core";

import { useLDAPContext } from "../context";
import { objectHasValue } from "../utils";
import { useCommonStaticPagesTranslation } from "../translation";

const ACTIONS_NAME = "@actions";
const CELLS = ["ldapRole", "postgresRole"];

export const Toolbar = memo<{
  title: string;
  toolTipTitle: string;
  onRowAdd: () => void;
}>(({ title, toolTipTitle, onRowAdd }) => (
  <GridToolbarContainer>
    <Box flex="1" pl={1}>
      <Typography variant="h6">{title}</Typography>
    </Box>
    <IconButton
      icon="add"
      onClick={onRowAdd}
      tooltip={toolTipTitle}
      data-test-id="addRowTest"
    />
  </GridToolbarContainer>
));

export const RoleMappingTable = memo(() => {
  const translation = useCommonStaticPagesTranslation();
  const classes = useStyles();

  const {
    roleMappings: data,
    roleOptions: options,
    errors: validationErrors,
    onRowAdd,
    onRowDelete,
    onCommitChange,
  } = useLDAPContext();

  const errors = objectHasValue(validationErrors?.roleMappings ?? {})
    ? validationErrors?.roleMappings
    : null;

  // create actions column
  const renderActionsCell = (params: GridCellParams) => (
    <IconButton
      icon="delete_outline"
      tooltip={translation.deleteTooltip}
      onClick={onRowDelete(params.id)}
    />
  );

  const actionColumn: GridColDef = {
    field: ACTIONS_NAME,
    headerName: translation.actionColumnTitle,
    width: 100,
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderCell: renderActionsCell,
  };
  const generalType = {
    isArray: false,
    type: "text",
  } as GeneralType;

  const columns = [
    ...CELLS.map((cell) => {
      const customProps = getCustomColumnProps({
        generalType,
        ...(cell === "postgresRole" && {
          editComponent: BaseAutocomplete,
          editProps: {
            options,
          },
        }),
      });

      return {
        field: cell,
        headerName: translation[`${cell}ColumnTitle`],
        flex: 1,
        editable: true,
        nullable: false,
        ...customProps,
        ...(cell === "ldapRole" && {
          cellClassName: (params: GridCellParams) => {
            const hasError = errors?.[params.id];

            return clsx({
              [classes.errorTextField]: hasError,
            });
          },
        }),
        disableColumnMenu: true,
      };
    }),
    actionColumn,
  ];

  const GridToolbar = useCallback(
    () => (
      <Toolbar
        title={translation.tableTitle}
        toolTipTitle={translation.addTooltip}
        onRowAdd={onRowAdd}
      />
    ),
    [onRowAdd, translation.tableTitle, translation.addTooltip],
  );

  const rows = useMemo(
    () => data.map((row, i: number) => ({ id: i, ...row })),
    [data],
  );

  const [cellError, setCellError] = useState<{
    anchorEl: HTMLDivElement;
    msg: string;
  } | null>(null);

  const handleCellEnter = useCallback(
    (params: GridCellParams, ev: any) => {
      const errorMsg = errors?.[params.id]?.[params.colDef.field];

      if (errorMsg) {
        setCellError({ anchorEl: ev.target, msg: errorMsg });
      } else {
        setCellError(null);
      }
    },
    [errors],
  );

  const handleCellLeave = useCallback(
    (params: GridCellParams) => {
      const errorMsg = errors?.[params.id]?.[params.colDef.field];
      if (cellError && errorMsg) {
        setCellError(null);
      }
    },
    [cellError, errors],
  );

  return (
    <>
      <DataGridTable
        columns={columns}
        rows={rows}
        onCellEditCommit={onCommitChange}
        components={{
          Toolbar: GridToolbar,
        }}
        hideFooterSelectedRowCount={true}
        onCellEnter={handleCellEnter}
        onCellLeave={handleCellLeave}
      />
      <Tooltip
        title={cellError?.msg ?? ""}
        open={Boolean(cellError?.anchorEl)}
        PopperProps={{
          anchorEl: cellError?.anchorEl ?? null,
        }}
        placement="top-start"
      >
        <span />
      </Tooltip>
    </>
  );
});
