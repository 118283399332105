import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    node: {
      textTransform: "uppercase",
      height: "auto",
      borderRadius: theme.spacing(0.5),

      "& .custom-drag-handle": {
        cursor: "grab",
        transform: "rotate(45deg)",
        "& > svg": {
          height: "0.7em",
        },
      },
      paddingRight: theme.spacing(1),
      cursor: "pointer",
    },
    nodeBgColor: {
      backgroundColor: theme.palette.background.paper,
    },
    stateContent: {
      padding: theme.spacing(0.5, 0.5),
      textTransform: "uppercase",
    },
    handleClass: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "transparent",
      transform: "none",
      border: "none",
      borderRadius: 8,
    },
  }),
);
