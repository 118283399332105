import React, { memo, useCallback } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";

import { Section, useElementEditorContext } from "core/editor";
import { PlacementSelector } from "core/editor/common/PlacementSelector";
import { BoolInputConfig } from "../../types";
import { useBoolInputEditorTranslation } from "../translation";

export const StylingComponent = memo(() => {
  const {
    elementModel: {
      config: { placement, isSwitch },
    },
    changeConfigValue,
  } = useElementEditorContext<BoolInputConfig>();
  const translation = useBoolInputEditorTranslation();

  const handlePlacementChange = useCallback(
    (newValue: BoolInputConfig["placement"]) =>
      changeConfigValue("placement", newValue),
    [changeConfigValue],
  );
  const handleIsSwitchChange = useCallback(
    (newValue: BoolInputConfig["isSwitch"]) =>
      changeConfigValue("isSwitch", newValue),
    [changeConfigValue],
  );

  const handleIsSwitchInputChange = () => handleIsSwitchChange(!isSwitch);

  return (
    <Section title={translation.stylingTitle}>
      <PlacementSelector value={placement} onChange={handlePlacementChange} />
      <Box px={2} pb={1}>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(isSwitch)}
              onChange={handleIsSwitchInputChange}
            />
          }
          label={translation.isSwitchLabel}
        />
      </Box>
    </Section>
  );
});
