import React, {
  ComponentPropsWithoutRef,
  MouseEvent,
  ReactElement,
  forwardRef,
  memo,
} from "react";
import clsx from "classnames";
import { useDispatch } from "react-redux";
import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { actions as routerActions } from "core/router/reduxModule";
import { MuiIcon } from "../MuiIcon";
import { isDefaultSupportedColor } from "../IconButton/utils";
import { useStyles } from "./style";

interface IHrefProps {
  href: string;
  external?: boolean;
}

interface IOwnProps {
  /**
   * label of the button
   *
   * automatically handles text overflow
   */
  label?: string;
  tooltip?: string;
  iconRight?: string;
  iconLeft?: string;
  disabled?: boolean;
  component?: any;
  className?: string;
  processing?: boolean;
  color?: string;
}

export type IButtonProps = (
  | (IHrefProps & Partial<ComponentPropsWithoutRef<"a">>)
  | Record<string, unknown>
) &
  IOwnProps &
  Omit<ButtonProps, "color">;

const Button = memo<IButtonProps>(
  ({
    onClick,
    tooltip,
    iconRight,
    iconLeft,
    innerRef,
    color,
    variant,
    disabled,
    size,
    fullWidth,
    className,
    type,
    disableRipple,
    href,
    external,
    processing,
    ...props
  }) => {
    const classes = useStyles({ color, variant });
    const dispatch = useDispatch();
    const leftIconCode = iconLeft && <MuiIcon icon={iconLeft} />;
    const rightIconCode = iconRight && <MuiIcon icon={iconRight} />;

    const withTooltip = (children: ReactElement<IButtonProps>) =>
      tooltip && !disabled ? (
        <Tooltip title={tooltip}>{children}</Tooltip>
      ) : (
        children
      );

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }

      if (href && !external) {
        event.preventDefault();

        dispatch(routerActions.push(href));
      }
    };

    const canHandleColor = !color || isDefaultSupportedColor(color);

    const buttonChildren =
      "label" in props
        ? !!props.label?.length && (
            <Typography
              color="inherit"
              component="div"
              className={classes.label}
            >
              {props.label}
            </Typography>
          )
        : props.children;

    const button = (
      <MuiButton
        {...props}
        onClick={handleClick}
        disabled={disabled}
        size={size}
        variant={variant}
        color={canHandleColor ? (color as ButtonProps["color"]) : undefined}
        fullWidth={fullWidth}
        ref={innerRef}
        className={clsx(className, classes.root)}
        type={type}
        disableRipple={disableRipple}
        startIcon={leftIconCode}
        endIcon={
          processing ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            rightIconCode
          )
        }
        href={href}
      >
        {buttonChildren}
      </MuiButton>
    );

    return withTooltip(button);
  },
);

Button.displayName = "CommonButton";

export default forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => (
  <Button ref={ref} {...props} />
));
